import React, { useState } from 'react'
import { connect } from 'react-redux'


const VideoAssetsBox = (props) => {




    return (
        <div>
            {/* <h6 className="sidebar-header-title">Adjust Timer</h6> */}
        
        <div className="card" style={{marginBottom:"10px", marginTop:"0px", textAlign:"center"}}>
         <div className="card-body" style={{padding:"10px 10px 10px 10px"}}>
         {/* <div class="marker pos-absolute t-10 l-10">Adjust Timer</div> */}
         {/* <h6 className="card-title">Videos</h6> */}
         <a
         href='/'
            className="btn btn-xs btn-dark w-100"
            style={{ marginRight: "10px", fontSize:'14px', marginBottom:'10px' }}
          >
            <i
              className="icon ion-ios-play"
              style={{ marginRight: "10px", color:'white' }}
            ></i>
            Play Intro Video
          </a>

          <a
         href='/'
            className="btn btn-xs btn-dark w-100"
            style={{ marginRight: "10px", fontSize:'14px', marginBottom:'10px' }}
          >
            <i
              className="icon ion-ios-play"
              style={{ marginRight: "10px", color:'white' }}
            ></i>
            Play Victory Video
          </a>

          <a
         href='/'
            className="btn btn-xs btn-dark w-100"
            style={{ marginRight: "10px", fontSize:'14px', marginBottom:'10px' }}
          >
            <i
              className="icon ion-ios-play"
              style={{ marginRight: "10px", color:'white' }}
            ></i>
            Fail Video
          </a>
         

        
            
        </div>
        
        </div>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return{
        remote: state.remote
    }
}

export default connect(mapStateToProps, null)(VideoAssetsBox)
