import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../../store/actions/authActions'

const Header = (props) => {

    const { selected } = props;

    return (
        <React.Fragment>
        <header className="navbar navbar-header navbar-header-fixed">
      <a href="" id="mainMenuOpen" className="burger-menu"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></a>
      <div className="navbar-brand">
      <a href="/">
        <img src="/bolt-logo.svg" alt="Logo" style={{width:"115px"}}></img>
      </a>
      </div>
      <div id="navbarMenu" className="navbar-menu-wrapper" style={{marginLeft:'40px', fontSize:'16px'}}>
        <ul className="nav navbar-menu">
          <li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
          <li className="nav-item">

              {selected === 'create-account' ? (
                    <span><b>1. Create Account</b></span>
              ): (
                    <span>1. Create Account</span>
              )}
            
            </li>
        
        {/* <li className="nav-item">
            <span>2. Confirm Plan</span>
        </li>

        <li className="nav-item">
            <span>3. Start Trial</span>
        </li> */}
        </ul>
      </div>


      <div className="navbar-right" style={{width:"400px"}}>
      <div className="tx-16 tx-center">Already have an account? <a href="/login">Sign in</a></div>
      </div>
    </header>
    </React.Fragment>
    )
}


export default Header
