import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

import { UpgradeAccount } from "../UpgradeAccount/UpgradeAccount";
import TimerSettings from "./Components/TimerSettings";
import VictorySettings from "./Components/VictorySettings";
import FailSettings from "./Components/FailSettings";
import SoundtrackAlert from "./Components/SoundtrackAlert";
import VideoBrief from "./Components/VideoBrief";
import HintIconSettings from "./Components/HintIconSettings";
import HintScreen from "./Components/HintScreen";

import SnackBar from "./SnackBar";

import {
  saveGameSettings,
  deleteGame,
  uploadAudioAssets,
  saveLiveWindowSettings,
  deleteFile,
  saveAssetsSettings,
} from "../../../store/actions/settingsActions";
import { updateTimer } from "../../../store/actions/timerActions";

import DeleteRoomModal from "./Modals/DeleteRoomModal";

export class GameSettings extends Component {
  state = {
    game_data: this.props.game ? this.props.game[0] : {},
    settings: this.props.game ? this.props.game[0] : {},
    timer_settings: {},
    livewindow_settings: {},
    editAudioSoundtrack: false,
    editAudioAlert: false,
    editAudioSuccess: false,
    editAudioFail: false,
    editVideoBrief: false,
    videoUploadError: "",
    lw_bg_color: "",
    editBackgroundImage: false,
    editClueIconImage: false,
    subscriptionStatus: "",
    savingLiveWindowSettings: false,
    savingBasicSettings: false,
    editBackgroundColor: false,
    trial_expired: false,
    updatePreview: false,
  };

  componentDidMount() {
    document.title = "Game Settings - Bolt";
    // this.checkSubscription();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lw_settings !== prevProps.lw_settings) {
      this.setState({
        livewindow_settings: {
          ...this.state.livewindow_settings,
          ...this.props.lw_settings,
        },
      });
    }

    if (this.props.game !== prevProps.game) {
      this.setState({
        settings: { ...this.props.game },
        timer_settings: { ...this.props.game.timer },
      });
    }

    if (this.props.game_save_success !== prevProps.game_save_success) {
      this.setState({
        settings: { ...this.props.game },
        timer_settings: { ...this.props.game.timer },
      });
    }

    if (this.props.account !== prevProps.account) {
      const { account } = this.props;
    }
  }

  handleEdit = (type) => {
    if (this.props.upload_progress > 0) return;

    if (type === "soundtrack") {
      this.setState({ editAudioSoundtrack: true });
    } else if (type === "alert") {
      this.setState({ editAudioAlert: true });
    } else if (type === "success") {
      this.setState({ editAudioSuccess: true });
    } else if (type === "fail") {
      this.setState({ editAudioFail: true });
    } else if (type === "video_brief") {
      this.setState({ editVideoBrief: true });
    } else if (type === "background_image") {
      this.setState({ editBackgroundImage: true });
    } else if (type === "clue_icon_image") {
      this.setState({ editClueIconImage: true });
    }
  };

  handleChangeSettings = (e) => {
    const update = {
      [e.target.name]: e.target.value,
    };
    this.setState({
      settings: { ...this.state.settings, ...update },
    });
  };

  handleChangeLiveWindowSettings = (e) => {
    const update = {
      [e.target.name]: e.target.value,
    };
    this.setState({
      livewindow_settings: { ...this.state.livewindow_settings, ...update },
    });
  };

  handleChangeTimerSettings = (e) => {
    const newData = {
      [e.target.name]: e.target.value,
    };

    if (e.target.name === "timer") {
      const time = {
        m: String(e.target.value / 60),
        s: "00",
      };
      const timer = {
        ...this.state.timer_settings,
        seconds: e.target.value,
        time,
      };

      this.setState({
        settings: { ...this.state.settings, timer },
      });

      this.props.saveGameSettings(this.props.game.id, "timer", timer);
    } else {
      const timer = {
        ...this.state.timer_settings,
        ...newData,
      };

      this.setState({
        settings: { ...this.state.settings, timer },
      });

      this.props.saveGameSettings(this.props.game.id, this.state.settings);
    }
  };

  handleSoundtrackFile = (file) => {
    this.props.uploadAudioAssets(
      file,
      "audio_soundtrack",
      this.props.match.params.id
    );
    this.setState({ editAudioSoundtrack: false });
  };

  handleAlertFile = (file) => {
    this.setState({ editAudioAlert: false });
    this.props.uploadAudioAssets(
      file,
      "audio_alert",
      this.props.match.params.id
    );
  };

  handleSuccessFile = (file) => {
    this.props.uploadAudioAssets(
      file,
      "audio_success",
      this.props.match.params.id
    );
    this.setState({ editAudioSuccess: false });
  };

  handleFailFile = (file) => {
    this.props.uploadAudioAssets(
      file,
      "audio_fail",
      this.props.match.params.id
    );
    this.setState({ editAudioFail: false });
  };

  handleVideoFile = (file) => {
    if (file.size > 100000000) {
      this.setState({ videoUploadError: "File size too large." });
    } else {
      this.props.uploadAudioAssets(
        file,
        "video_brief",
        this.props.match.params.id
      );
      this.setState({
        editVideoBrief: false,
        videoUploadError: "",
      });
    }
  };

  handleUploadFile = (file, type, id) => {
    this.props.uploadAudioAssets(file, type, this.props.match.params.id, id);

    if (type === "video_brief") this.setState({ editVideoBrief: false });
    if (type === "audio_fail") this.setState({ editAudioFail: false });
    if (type === "audio_success") this.setState({ editAudioSuccess: false });
    if (type === "audio_soundtrack")
      this.setState({ editAudioSoundtrack: false });
    if (type === "clue_icon_image") this.setState({ editClueIconImage: false });
    if (type === "background_image")
      this.setState({ editBackgroundImage: false });
  };

  handleDeleteFile = (type, file) => {
    console.log('file', file)
    this.props.deleteFile(this.props.match.params.id, type, file);

    if (type === "video_brief") this.setState({ editVideoBrief: true });
    if (type === "audio_fail") this.setState({ editAudioFail: true });
    if (type === "audio_success") this.setState({ editAudioSuccess: true });
    if (type === "audio_soundtrack")
      this.setState({ editAudioSoundtrack: true });
    if (type === "clue_icon_image") this.setState({ editClueIconImage: true });
    if (type === "background_image")
      this.setState({ editBackgroundImage: true });
  };

  handleBackgroundColor = (color) => {
    this.setState({
      livewindow_settings: {
        ...this.state.livewindow_settings,
        background_color: color.hex,
      },
    });
  };

  // async isSubscribed() {
  //   let promise = new Promise((resolve, reject) => {
  //     const { trial_expired } = this.state;
  //     const user_account = this.props.account;
  //     const status = user_account.subscription.status;
  //     const product_id = user_account.subscription.product_id;
  //     const pro_plan = "prod_IIlkUib4nS3RBH";
  //     const basic_plan = "prod_IIkAc15oZKrJPG";

  //     if (!status && !trial_expired) resolve("Trial");
  //     if (!status && trial_expired) resolve("Not Subscribed")
  //     if (status === "active" && product_id === pro_plan) resolve("Pro");
  //     if (status === "active" && product_id === basic_plan) resolve("Basic");
  //     if (status === "cancelled" || !status) resolve("Not Subscribed");
  //   });

  //   let result = await promise;
  //   return result;
  // }

  // checkSubscription() {
  //   return this.isSubscribed().then((x) => {
  //     this.setState({ subscriptionStatus: x });
  //   });
  // }

  handleSaveLiveWindowSettings() {
    this.setState({
      savingLiveWindowSettings: true,
    });
    this.props.saveLiveWindowSettings(
      this.props.game.id,
      this.state.livewindow_settings
    );
  }

  updatePreview = () => {
    this.setState({ updatePreview: !this.updatePreview });
  };

  deleteGame = (id) => {
    this.props.deleteGame(id);
    this.props.history.push("/");
  };

  render() {
    const {
      game,
      assets,
      upload_progress,
      editVideoBrief,
      lw_settings,
      account,
    } = this.props;
    const {
      editAudioSoundtrack,
      editAudioAlert,
      editAudioFail,
      editAudioSuccess,
      editBackgroundImage,
      editClueIconImage,
      videoUploadError,
      subscriptionStatus,
      livewindow_settings,
      editBackgroundColor,
      trial_expired,
    } = this.state;

   

    return (
      <div
        style={
          this.props.settings.game_save_success === "saving" ||
          this.props.settings.livewindow_save_success === "saving"
            ? { opacity: "0.5", pointerEvents: "none" }
            : null
        }
      >
        <SnackBar
          message="Successfully Saved"
          game_save_success={this.props.game_save_success}
          livewindow_save_success={this.props.settings.livewindow_save_success}
        />

        <div className="pd-20 pd-lg-35 pd-xl-20" style={{ margin: "0 auto" }}>
          {!game ? (
            <div
              className="text-center"
              style={{
                left: "50%",
                marginLeft: "-2em",
                position: "absolute",
                top: "40%",
              }}
            >
              <div
                className="spinner-border"
                style={{ width: "5rem", height: "5rem" }}
              ></div>
            </div>
          ) : (
            <div className="d-sm-flex align-items-center justify-content-between mg-b-10 mg-t-10">
              <div style={{ position: "absolute", top: "15px", left: "15px" }}>
                <a href="/" style={{ fontWeight: "bold" }}>
                  <i
                    className="icon ion-ios-arrow-back"
                    style={{ marginRight: "8px" }}
                  />
                  Back to Dashboard
                </a>
              </div>

              <div style={{ margin: "0 auto" }}>
                {/* <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                    <li className="breadcrumb-item">
                      Bolt
                    </li>
                    <li className="breadcrumb-item">
                      Edit Game Settings
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {game.name}
                    </li>
                  </ol>
                </nav> */}
                {/* <a href="/" style={{ fontWeight: "bold" }}>
                  <i
                    className="icon ion-ios-arrow-back"
                    style={{ marginRight: "8px" }}
                  />
                  Back to Dashboard
                </a> */}
                <h1 style={{ marginTop: "20px" }}>
                  <b>Settings /</b> {game.name}
                </h1>

                <div
                  style={{
                    marginTop: "30px",
                    marginBottom: "75px",
                    width: "100%",
                    maxWidth: "1000px",
                  }}
                >
                  <div>
                    <div
                      className="pd-b-20 bd-b"
                      style={{ marginTop: "20px", marginBottom: "20px" }}
                    >
                      <div className="mg-b-40 bd-b">
                        <h3>Room</h3>
                      </div>
                      <form style={{ width: "100%" }}>
                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-3 col-form-label"
                            style={{ textAlign: "right" }}
                          >
                            <b>Name*</b>
                          </label>
                          <div className="col-sm-4">
                            <input
                              name="name"
                              onChange={(e) => this.handleChangeSettings(e)}
                              type="text"
                              className="form-control"
                              value={
                                this.state.settings
                                  ? this.state.settings.name
                                  : ""
                              }
                            />
                          </div>
                        </div>

                        <div className="form-group row">
                          <label
                            htmlFor="inputEmail3"
                            className="col-sm-3 col-form-label"
                            style={{ textAlign: "right" }}
                          >
                            <b>Default time limit*</b>
                          </label>
                          <div className="col-sm-4">
                            <select
                              id="timer"
                              name="timer"
                              onChange={(e) =>
                                this.handleChangeTimerSettings(e)
                              }
                              className="form-control"
                              defaultValue={game.timer.seconds}
                              value={
                                this.state.timer_settings
                                  ? this.state.timer_settings.seconds
                                  : ""
                              }
                            >
                              <option value="7200">120 min</option>
                              <option value="5400">90 min</option>
                              <option value="4500">75 min</option>
                              <option value="4200">70 min</option>
                              <option value="3600">60 min</option>
                              <option value="3300">55 min</option>
                              <option value="3000">50 min</option>
                              <option value="2700">45 min</option>
                              <option value="2100">35 min</option>
                              <option value="1800">30 min</option>
                              <option value="1500">25 min</option>
                              <option value="1200">20 min</option>
                              <option value="900">15 min</option>
                              <option value="600">10 min</option>
                              <option value="300">5 min</option>
                            </select>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            onClick={() =>
                              this.props.saveGameSettings(
                                game.id,
                                "name",
                                this.state.settings
                              )
                            }
                            type="button"
                            className="btn btn-primary"
                          >
                            <b>Save</b>
                          </button>
                        </div>
                      </form>
                    </div>

                    {/* <label for="inputEmail3" className="col-form-label tx-18 mg-b-20">
            <b>Timer</b>
          </label> */}

                    {/* <div className="form-group row pd-b-10">
                      <label
                        htmlFor="inputEmail3"
                        className="col-sm-3 tx-18 col-form-label"
                      >
                        <b>Time Limit</b>
                      </label>
                      <div className="col-sm-9">
                        <select
                          id="timer"
                          name="timer"
                          onChange={(e) => this.handleChangeTimerSettings(e)}
                          className="form-control"
                          defaultValue={game.timer.seconds}
                        >
                          <option value="7200">120 minutes</option>
                          <option value="5400">90 minutes</option>
                          <option value="4500">75 minutes</option>
                          <option value="4200">70 minutes</option>
                          <option value="3600">60 minutes</option>
                          <option value="2700">45 minutes</option>
                          <option value="1800">30 minutes</option>
                        </select>
                      </div>
                    </div> */}

                    <div className="pd-t-20">
                      {/* <div className="mg-b-40 bd-b">
                        <h3>Player Screen</h3>
                      </div> */}
                      <label
                        htmlFor="inputEmail3"
                        className="col-form-label tx-18"
                      >
                        <b>Player Screen Appearance</b>
                        {/* <span className="badge badge-warning mg-l-10 tx-13">
                          <b>BETA</b>
                        </span> */}
                      </label>
                      <p className="tx-13 mg-b-40">
                        Customize the look and feel of the player screen.
                      </p>

                      <div style={{ display: "flex" }}>
                        <ul
                          className="nav nav-line"
                          style={{ margin: "0 auto" }}
                          id="screen-tabs"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="timer"
                              data-toggle="tab"
                              href="#timer-tab"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              <i class="fa-solid fa-timer" style={{marginRight:'5px'}}></i>
                              Timer
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="hint"
                              data-toggle="tab"
                              href="#hint-tab"
                              role="tab"
                              aria-controls="home"
                              aria-selected="true"
                            >
                              <i class="fa-solid fa-message-quote" style={{marginRight:'5px'}}></i>
                              Clue On Screen
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="victory"
                              data-toggle="tab"
                              href="#victory-tab"
                              role="tab"
                              aria-controls="home"
                              aria-selected="false"
                            >
                              <i class="fa-solid fa-trophy-star" style={{marginRight:'5px'}}></i>
                              Room Complete
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="fail"
                              data-toggle="tab"
                              href="#fail-tab"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                            >
                              <i class="fa-solid fa-face-sad-tear" style={{marginRight:'5px'}}></i>
                              Room Fail
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="tab-content">
                        <div
                          className="tab-pane fade active show pd-t-20"
                          id="timer-tab"
                          role="tabpanel"
                          aria-labelledby="home-tab5"
                        >
                          <TimerSettings
                            game_id={game.id}
                            game_data={game}
                            game_assets={assets}
                            lw_settings={lw_settings}
                            saveGameSettings={this.props.saveGameSettings}
                            saveLiveWindowSettings={
                              this.props.saveLiveWindowSettings
                            }
                            handleDeleteFile={this.handleDeleteFile}
                            handleUploadFile={this.handleUploadFile}
                            handleVideoFile={this.handleVideoFile}
                            updatePreview={this.updatePreview}
                            upload_progress={upload_progress}
                          />
                        </div>

                        <div
                          className="tab-pane fade pd-t-20"
                          id="hint-tab"
                          role="tabpanel"
                          aria-labelledby="home-tab5"
                        >
                          {lw_settings ? (
                            <HintScreen
                              game_id={game.id}
                              lw_settings={lw_settings}
                              saveLiveWindowSettings={
                                this.props.saveLiveWindowSettings
                              }
                              updatePreviewState={this.state.updatePreview}
                              game_assets={assets}
                            />
                          ) : null}
                        </div>

                        <div
                          className="tab-pane fade pd-t-20"
                          id="victory-tab"
                          role="tabpanel"
                          aria-labelledby="home-tab5"
                        >
                          {lw_settings ? (
                            <VictorySettings
                              game_id={game.id}
                              game_data={lw_settings}
                              assets={assets}
                              editAudioSuccess={editAudioSuccess}
                              saveLiveWindowSettings={
                                this.props.saveLiveWindowSettings
                              }
                              saveAssetsSettings={this.props.saveAssetsSettings}
                              handleEdit={this.handleEdit}
                              handleDeleteFile={this.handleDeleteFile}
                              handleSuccessFile={this.handleSuccessFile}
                              handleUploadFile={this.handleUploadFile}
                              upload_progress={upload_progress}
                            />
                          ) : null}
                        </div>

                        <div
                          className="tab-pane fade pd-t-20"
                          id="fail-tab"
                          role="tabpanel"
                          aria-labelledby="profile-tab5"
                        >
                          {lw_settings ? (
                            <FailSettings
                              game_id={game.id}
                              game_data={lw_settings}
                              assets={assets}
                              editAudioFail={editAudioFail}
                              saveLiveWindowSettings={
                                this.props.saveLiveWindowSettings
                              }
                              saveAssetsSettings={this.props.saveAssetsSettings}
                              handleEdit={this.handleEdit}
                              handleDeleteFile={this.handleDeleteFile}
                              handleFailFile={this.handleFailFile}
                              handleUploadFile={this.handleUploadFile}
                              upload_progress={upload_progress}
                            />
                          ) : null}
                        </div>
                      </div>

                      <div
                        className="pd-t-20"
                        style={{ marginTop: "20px", marginBottom: "40px" }}
                      >
                        {lw_settings && (
                          <HintIconSettings
                            game_id={game.id}
                            saveLiveWindowSettings={
                              this.props.saveLiveWindowSettings
                            }
                            lw_settings={lw_settings}
                            account={account}
                            upload_progress={upload_progress}
                            handleUploadFile={this.handleUploadFile}
                            handleDeleteFile={this.handleDeleteFile}
                            game_assets={assets}
                          />
                        )}
                      </div>

                      <VideoBrief
                        game_id={game.id}
                        account={account}
                        upload_progress={upload_progress}
                        handleUploadFile={this.handleUploadFile}
                        handleDeleteFile={this.handleDeleteFile}
                        assets={assets}
                      />
                      <SoundtrackAlert
                        game_id={game.id}
                        game_data={lw_settings}
                        assets={assets}
                        editAudioSoundtrack={editAudioSoundtrack}
                        editAudioAlert={editAudioAlert}
                        saveAssetsSettings={this.props.saveAssetsSettings}
                        handleEdit={this.handleEdit}
                        handleDeleteFile={this.handleDeleteFile}
                        handleSoundtrackFile={this.handleSoundtrackFile}
                        handleAlertFile={this.handleAlertFile}
                        handleUploadFile={this.handleUploadFile}
                        account={account}
                        upload_progress={upload_progress}
                      />

                      <div
                        className="pd-t-20 bd-t"
                        style={{ marginTop: "20px", marginBottom: "40px" }}
                      >
                        <p className="tx-18 mg-b-10">
                          <b>Delete Game</b>
                        </p>
                        <p className="tx-gray-700">
                          By deleting this game, you will lose access to all
                          data associated with <b>{game.name}</b>. This action
                          cannot be undone.
                        </p>
                        <a
                          href="#deleteRoomModal"
                          data-toggle="modal"
                          type="button"
                          className="btn btn-outline-danger"
                          //onClick={() => this.deleteGame(game.id)}
                        >
                          <b>Delete {game.name}</b>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <DeleteRoomModal game={game} deleteGame={this.deleteGame} />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const account_id = state.firebase.profile.account_id
    ? state.firebase.profile.account_id
    : null;

  return {
    game: state.firestore.ordered.gamemaster
      ? state.firestore.ordered.gamemaster[0]
      : null,
    settings: state.settings,
    uid: state.firebase.auth.uid ? state.firebase.auth.uid : "123",
    profile: state.firebase.profile,
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts[0]
      : null,
    account_id: account_id,
    assets: state.firestore.data.assets ? state.firestore.data.assets : null,
    upload_progress: state.settings.upload_audio_progress,
    lw_settings: state.firestore.ordered.livewindow_settings
      ? state.firestore.ordered.livewindow_settings[0]
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveGameSettings: (game_doc, type, settings) =>
      dispatch(saveGameSettings(game_doc, type, settings)),
    saveLiveWindowSettings: (game_doc, settings) =>
      dispatch(saveLiveWindowSettings(game_doc, settings)),
    deleteGame: (game_doc) => dispatch(deleteGame(game_doc)),
    uploadAudioAssets: (file, type, game_doc, id) =>
      dispatch(uploadAudioAssets(file, type, game_doc, id)),
    deleteFile: (game_doc, type, file) => dispatch(deleteFile(game_doc, type, file)),
    saveAssetsSettings: (game_doc, type, settings) =>
      dispatch(saveAssetsSettings(game_doc, type, settings)),
    updateTimer: (data) => dispatch(updateTimer(data)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "gamemaster",
      doc: props.match.params.id,
    },
    {
      collection: "gamemaster",
      doc: props.match.params.id,
      subcollections: [
        {
          collection: "assets",
        },
      ],
      storeAs: "assets",
    },
    {
      collection: "gamemaster",
      doc: props.match.params.id,
      subcollections: [
        {
          collection: "settings",
          doc: "live_window",
        },
      ],
      storeAs: "livewindow_settings",
    },
    {
      collection: "user_accounts",
      doc: props.account_id,
    },
  ])
)(GameSettings);
