import React from "react";
import { connect } from "react-redux";

const StartButton = (props) => {
  const {
    timerRunning,
    videoIsPlaying,
    startTimer,
    pauseTimer,
    videoButtonLoading,
    gameComplete,
    gameIsReady,
  } = props;

  const buttonText = () => {
    if (videoButtonLoading) {
      return (
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    } else if (timerRunning) {
      return (
        <span style={{ fontSize: "14px", fontWeight: "600" }}>
          <i
            className="icon ion-md-pause"
            style={{ marginRight: "5px", color: "white" }}
          ></i>{" "}
          Pause
        </span>
      );
    } else if (!timerRunning) {
      return (
        <span style={{ fontSize: "14px", fontWeight: "600" }}>
          <i
            className="icon ion-ios-play"
            style={{ marginRight: "8px", color: "white" }}
          ></i>
          Start
        </span>
      );
    }
  };

  const dataUpload = () => {
    if (props.gameMaster.endgame_data_upload === "loading") {
      return false;
    } else {
      return true;
    }
  };

  if (gameComplete) {
    return (
      <button disabled type="button" className="button-start-dark disabled">
        {buttonText()}
      </button>
    );
  } else if (!dataUpload()) {
    return (
      <button disabled type="button" className="button-start-dark disabled">
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </button>
    );
  } else if (timerRunning && !videoIsPlaying) {
    return (
      <button
        onClick={pauseTimer}
        type="button"
        className="button-pause-dark w-100"
      >
        {buttonText()}
      </button>
    );
  } else if (!timerRunning && videoIsPlaying) {
    return (
      <button disabled type="button" className="button-start-dark disabled">
        {buttonText()}
      </button>
    );
  } else if (timerRunning && videoIsPlaying) {
    return (
      <button
        onClick={pauseTimer}
        type="button"
        className="button-pause-dark w-100"
      >
        {buttonText()}
      </button>
    );
  } else if (!timerRunning && !videoIsPlaying) {
    return (
      <button
        id="startButton"
        onClick={startTimer}
        type="button"
        className="button-start-dark"
      >
        {buttonText()}
      </button>
    );
  }
};

const mapStateToProps = (state) => {

  return {
    gameMaster: state.gameMaster,
  };
};

export default connect(mapStateToProps, null)(StartButton);
