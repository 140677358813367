import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import { SketchPicker } from "react-color";

import { get, set, createStore, del } from "idb-keyval";
import {
  gameId,
  addToDB,
  deleteAsset,
  readBlob,
} from "../Functions/SettingsFunctions";

import HintPreview from "./LiveScreens/HintPreview";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

function valuetext(value) {
  return `${value}°C`;
}

const marks = [
  {
    value: 10,
    label: "S",
  },
  {
    value: 16,
    label: "M",
  },
  {
    value: 22,
    label: "L",
  },
  {
    value: 32,
    label: "XL",
  },
];

const HintScreen = (props) => {
  const classes = useStyles();
  const { game_data, lw_settings, game_id, game_assets } = props;

  gameId(game_id);

  const [settings, setSettings] = useState(lw_settings);
  useEffect(() => {
    
    setSettings(lw_settings);
    setTextColor(
      lw_settings && lw_settings.timer ? lw_settings.timer.hintColor : "#fff"
    );
    setBackgroundColor(
      lw_settings && lw_settings.timer ? lw_settings.timer.bg_color : "#fff"
    );
    setHintTimerBGColor(
      lw_settings && lw_settings.timer
        ? lw_settings.timer.bg_color_hintTimer
        : "#000"
    );
    setTimerTextColor(
      lw_settings && lw_settings.timer
        ? lw_settings.timer.hintTimerColor
        : "#fff"
    );
    setHintSize(
      lw_settings && lw_settings.timer
        ? lw_settings.timer.size_hintText
        : "25px"
    );
  }, [lw_settings]);

  const changeTimerLiveSettings = (e) => {
    if (e.target.name === "show_hintTimer") {
      setSettings({
        ...settings,
        timer: { ...settings.timer, show_hintTimer: e.target.checked },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          show_hintTimer: e.target.checked,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    } else {
      setSettings({
        ...settings,
        timer: { ...settings.timer, [e.target.name]: e.target.value },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          [e.target.name]: e.target.value,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Edit Text Size
  var typingTimer;
  const [hintSize, setHintSize] = useState("25");
  const handleChange = (event, newValue) => {
    setHintSize(newValue);

    if (hintSize !== newValue) {
    
      setTimeout(() => {
        setSettings({
          ...settings,
          timer: { ...settings.timer, size_hintText: newValue },
        });
        const newData = {
          ...settings,
          timer: {
            ...settings.timer,
            last_updated: new Date(),
            size_hintText: newValue,
          },
        };

        props.saveLiveWindowSettings(game_id, newData);
      }, 500);
    }
  };

  const [hintPadding, setHintPadding] = useState("20");

  // Edit Text Color
  const [editTextColor, setEditTextColor] = useState(false);
  const [textColor, setTextColor] = useState("");
  const handleChangeTextColor = (color) => {
    setEditTextColor(!editTextColor);

    if (textColor !== settings.timer.hintColor) {
      setSettings({
        ...settings,
        timer: { ...settings.timer, hintColor: textColor },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          hintColor: textColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Edit Background Color
  const [editBackgroundColor, setEditBackgroundColor] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const handleChangeBackgroundColor = (color) => {
    setEditBackgroundColor(!editBackgroundColor);

    if (hintTimerBGColor !== settings.timer.bg_color_hintTimer) {
      setSettings({
        ...settings,
        timer: { ...settings.timer, bg_color_hintTimer: hintTimerBGColor },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          bg_color_hintTimer: hintTimerBGColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  // Custom Icon
  const [customIcon, setCustomIcon] = useState();

  //Edit Timer Colors
  const [hintTimerBGColor, setHintTimerBGColor] = useState("#000");
  const [hintTimerTextColor, setTimerTextColor] = useState("#fff");
  const [editTimerTextColor, setEditTimerTextColor] = useState(false);
  const handleChangeTimerTextColor = (color) => {
    setEditTimerTextColor(!editTimerTextColor);

    if (hintTimerTextColor !== settings.timer.hintTimerColor) {
      setSettings({
        ...settings,
        timer: { ...settings.timer, hintTimerColor: hintTimerTextColor },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          hintTimerColor: hintTimerTextColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  const changeColor = (color, type) => {


    const rgb =
      "rgb(" + color.r + " " + color.g + " " + color.b + " / " + color.a + ")";
    if (type === "timer_bg") setHintTimerBGColor(rgb);
    if (type === "timer_text") setTimerTextColor(rgb);
  };

  // IndexDB Asset Items
  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    const assetsStore = createStore(game_id, "assets");

    // Get Background Image
    get("background_image", assetsStore).then((val) => {
      if (val) {
        const blob = readBlob(val);
        var url = URL.createObjectURL(blob);
        setBackgroundImage({ ...val, url });
      } else {
        setBackgroundImage();
      }
    });

    // Get Custom Icon
    get("custom_icon", assetsStore).then((val) => {
      if (val) {
        const blob = readBlob(val);
        var url = URL.createObjectURL(blob);
        setCustomIcon({ ...val, url });
      } else {
        setCustomIcon();
      }
    });
  }, [game_assets]);

 

  return (
    <div style={{ marginTop: "20px", marginBottom: "50px" }}>
      {/* <h5 className="mg-b-20 tx-18"><b>Timer</b></h5> */}

      <form style={{ width: "100%" }}>
        <div className="form-group row">
          <div className="col-sm-3">
            <div className="pd-b-10">
              <p className="mg-b-5">
                <b>Text Color</b>
              </p>
              <div
                className="color-picker-swatch"
                onClick={() => setEditTextColor(!editTextColor)}
              >
                <div
                  className="color-picker-bubble"
                  style={{ background: textColor }}
                />
                <p className="mg-b-0">{textColor}</p>
              </div>

              {editTextColor ? (
                <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "50px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => handleChangeTextColor()}
                  />
                  <SketchPicker
                    color={textColor}
                    onChange={(e) => setTextColor(e.hex)}
                    onChangeComplete={(e) => setTextColor(e.hex)}
                    disableAlpha={false}
                    presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'transparent']}
                  />
                </div>
              ) : null}
            </div>

            <div className="pd-t-10 pd-b-10 mg-t-10 bd-t">
              <p className="mg-b-5">
                <b>Font</b>
              </p>
              <select
                name="hintFont"
                onChange={(e) => changeTimerLiveSettings(e)}
                className="form-control"
                value={
                  settings && settings.timer ? settings.timer.hintFont : null
                }
              >
                <option value="Roboto">Roboto (Default)</option>
                <option style={{ fontFamily: "Arial" }} value="Arial">
                  Arial
                </option>
                <option
                  style={{ fontFamily: "Arial Black" }}
                  value="Arial Black"
                >
                  Arial Black
                </option>
                <option
                  style={{ fontFamily: "Comic Sans MS" }}
                  value="Comic Sans MS"
                >
                  Comic Sans
                </option>
                <option
                  style={{ fontFamily: "Courier New" }}
                  value="Courier New"
                >
                  Courier New
                </option>
                <option style={{ fontFamily: "Georgia" }} value="Georgia">
                  Georgia
                </option>
                <option style={{ fontFamily: "Impact" }} value="Impact">
                  Impact
                </option>
                <option
                  style={{ fontFamily: "Lucida Console" }}
                  value="Lucida Console"
                >
                  Lucida Console
                </option>
                <option
                  style={{ fontFamily: "Lucida Sans Unicode" }}
                  value="Lucida Sans Unicode"
                >
                  Llucida Sans
                </option>
                <option
                  style={{ fontFamily: "Palatino Linotype" }}
                  value="Palatino Linotype"
                >
                  Palatino Linotype
                </option>
                <option style={{ fontFamily: "Tahoma" }} value="Tahoma">
                  Tahoma
                </option>
                <option
                  style={{ fontFamily: "Times New Roman" }}
                  value="Times New Roman"
                >
                  Times New Roman
                </option>
                <option style={{ fontFamily: "Verdana" }} value="Verdana">
                  Verdana
                </option>
              </select>
            </div>

            <div className='pd-t-10'>
        <Slider
            
            defaultValue={settings && settings.timer && settings.timer.size_hintText ? settings.timer.size_hintText : 25}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            style={{color: '#0168fa'}}
            //valueLabelDisplay="auto"
            step={null}
            marks={marks}
            min={8}
            max={32}
            onChange={handleChange}
          />
        </div>

            {/* <div className="pd-t-10 mg-t-10 bd-t">
              <p className="mg-b-5">
                <b>Hint Size</b>
              </p>
              <div className={classes.root}>
                <Slider
                  defaultValue={
                    settings && settings.timer && settings.timer.size_hintText
                      ? settings.timer.size_hintText
                      : "25"
                  }
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider"
                  style={{ color: "#0168fa" }}
                  //valueLabelDisplay="auto"
                  //step={null}
                  //marks={marks}
                  min={8}
                  max={75}
                  onChange={(e) => {
                    handleChange(e.target.ariaValueNow, typingTimer);
                    setHintSize(e.target.ariaValueNow);
                  }}
                />
              </div>
            </div> */}
{/* 
            <div>
              <p className="mg-b-5">
                <b>Padding</b>
              </p>
              <div className={classes.root}>
                <Slider
                  //defaultValue={settings && settings.timer && settings.timer.size_hintText ? settings.timer.size_hintText : '25'}
                  getAriaValueText={valuetext}
                  aria-labelledby="discrete-slider"
                  style={{ color: "#0168fa" }}
                  //valueLabelDisplay="auto"
                  //step={null}
                  //marks={marks}
                  min={0}
                  max={200}
                  onChange={(e) => {
                    //handleChange(e.target.ariaValueNow, typingTimer)
                    setHintPadding(e.target.ariaValueNow);
                  }}
                />
              </div>
            </div> */}

            <div className="custom-control custom-switch pd-t-20 mg-t-10 bd-t">
              <input
                name="show_hintTimer"
                onInput={(e) => changeTimerLiveSettings(e)}
                defaultChecked={
                  settings && settings.timer
                    ? settings.timer.show_hintTimer
                    : null
                }
                type="checkbox"
                className="custom-control-input"
                id="customSwitch4"
              />
              <label className="custom-control-label" htmlFor="customSwitch4">
                <b>Show Timer</b>
              </label>
            </div>

            <div className="pd-t-10 mg-t-10">
              <p className="mg-b-5">
                <b>Timer Text Color</b>
              </p>
              <div
                className="color-picker-swatch"
                onClick={() => setEditTimerTextColor(!editTimerTextColor)}
              >
                <div
                  className="color-picker-bubble"
                  style={{ background: hintTimerTextColor }}
                />
                <p className="mg-b-0">{hintTimerTextColor}</p>
              </div>

              {editTimerTextColor ? (
                <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "50px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => handleChangeTimerTextColor()}
                  />
                  <SketchPicker
                    color={hintTimerTextColor}
                    onChange={(e) => changeColor(e.rgb, "timer_text")}
                    onChangeComplete={(e) => changeColor(e.rgb, "timer_text")}
                    disableAlpha={false}
                    presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'transparent']}
                  />
                </div>
              ) : null}
            </div>
            <div className="mg-t-10">
              <p className="mg-b-5">
                <b>Timer BG Color</b>
              </p>
              <div
                className="color-picker-swatch"
                onClick={() => setEditBackgroundColor(!editBackgroundColor)}
              >
                <div
                  className="color-picker-bubble"
                  style={{ background: hintTimerBGColor }}
                />
                <p className="mg-b-0">{hintTimerBGColor}</p>
              </div>

              {editBackgroundColor ? (
                <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
                  <div
                    style={{
                      position: "fixed",
                      top: "50px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                    }}
                    onClick={() => handleChangeBackgroundColor()}
                  />
                  <SketchPicker
                    color={hintTimerBGColor}
                    onChange={(e) => changeColor(e.rgb, "timer_bg")}
                    onChangeComplete={(e) => changeColor(e.rgb, "timer_bg")}
                    disableAlpha={false}
                    presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'transparent']}
                  />
                </div>
              ) : null}
            </div>
          </div>

          <div
            className="col-sm-9"
            style={{ display: "flex", alignItems: "start" }}
          >
            <HintPreview
              backgroundColor={backgroundColor}
              backgroundImage={backgroundImage}
              hintTimerBGColor={hintTimerBGColor}
              hintTimerTextColor={hintTimerTextColor}
              hintPadding={hintPadding}
              textColor={textColor}
              settings={settings}
              game_data={game_data}
              hintSize={hintSize}
              customIcon={customIcon}
            />
          </div>
          <small className="tx-gray-500" style={{ marginLeft: "auto" }}>
            *This preview is a close approximation of the real live window.
          </small>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    settings: state.settings,
    upload_progress: state.settings.upload_audio_progress,
  };
};

export default connect(mapStateToProps, null)(HintScreen);
