import React  from 'react'
import { connect } from 'react-redux'
//import uuid from 'react-uuid'

import { addNewClue } from '../../../../store/actions/gmActions'

const DeleteRoomModal = (props) => {

   const { deleteGame, game } = props;

   const game_name = game ? game.name : null;

    return (
        <div 
        className="modal effect-scale" 
        id="deleteRoomModal" 
        tabIndex="-1" 
        role="dialog" 
        aria-hidden="true"
        >

      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
            <a href="/" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </a>
            <div className="media align-items-center">
              <span className="tx-color-03 d-none d-sm-block">
                <i className="icon ion-ios-flash" style={{fontSize:"50px"}}></i></span>
              <div className="media-body mg-sm-l-20">
                <h4 className="tx-18 tx-sm-20 mg-b-2">Are you sure you want to delete this game?</h4>
                <p className="mg-b-0">By deleting this game, you will lose access to all data associated with <b>{game_name}</b>. This action cannot be undone.</p>
              </div>
            </div>
          </div>
          <div className="modal-footer pd-x-20 pd-y-15">
            <button type="button" className="btn btn-xs btn-white" data-dismiss="modal"><b>Cancel</b></button>
            <button onClick={() => deleteGame(game.id)} type="button" className="btn btn-xs btn-danger" data-dismiss="modal"><b>Delete {game_name}</b></button>
          </div>
        </div>
      </div>
    </div>
    )
}


export default connect(null, null)(DeleteRoomModal)
