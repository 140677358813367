import { Dialog, Transition } from "@headlessui/react";
import { Tooltip } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { classNames } from "../../../../utils/Utils";
// import PostGameChart from "./PostGameChart";
import PostGameChart from "./TestChart";

export default function PostGameReport(props) {
  const {
    timerRunning,
    handleCompleteGame,
    handleResetGame,
    time,
    messages,
    puzzles,
    puzzlesDone,
    hintsUsed,
    addedMinutes,
    objectivesCompletePercentage,
    startingSeconds,
    gameStartedAt,
    gameFinishedAt,
    gameFail,
    gameComplete,
    gameDetails,
    game_name
  } = props;
  const [open, setOpen] = useState(false);
  const [openCopyTooltip, setOpenCopyTooltip] = useState(false);

  const [teamName, setTeamName] = useState("");

  // Game Details
  const [activePlayers, setActivePlayers] = useState(gameDetails.active_players);
  const [totalPlayers, setTotalPlayers] = useState(gameDetails.total_players);


  const [reportData, setReportData] = useState({});

  const cancelButtonRef = useRef(null);
  const completeGame = () => {
    setOpen(true);
    handleCompleteGame();
  };

  useEffect(() => {
    if (gameComplete && gameFail) setOpen(true);
  }, [gameFail]);


  // Update GameDetails
  useEffect(() => {
    setActivePlayers(gameDetails.active_players)
    setTotalPlayers(gameDetails.total_players)
  }, [gameDetails]);

  const copyLeaderboardLink = () => {
    // Get the text field
    var copyText = document.getElementById("leaderboard-link");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);

    setOpenCopyTooltip(true);
    setTimeout(() => {
      setOpenCopyTooltip(false);
    }, 2000);
  };

  const saveReport = (status) => {
    if (status === "save") {
      setOpen(false);
      setTimeout(() => {
        handleResetGame("saveToDB");
      }, 600);
    } else {
      setOpen(false);
      setTimeout(() => {
        handleResetGame();
      }, 600);
    }
  };

  const filterMessages = () => {
    var newMessages = [];
    messages.forEach((msg) => {
      if (msg.type === "text" || msg.type === "audio")
        return newMessages.push(msg);
    });
    return newMessages.length;
  };

  const getElapsedTime = () => {
    // const startingMinutes = (gameStartedAt / 60) - 1;
    // const elapsedMin = startingMinutes - time.m
    // const elapsedSec = 60 - time.s;

    var startTime = moment(gameStartedAt);
    var endTime = moment(gameFinishedAt);

    // calculate total duration
    var duration = moment.duration(endTime.diff(startTime));

    // duration in hours
    var secs = parseInt(duration.asSeconds());

    // duration in minutes
    var mins = parseInt(duration.asMinutes()) % 60;

    let divisor_for_minutes = Math.floor(secs % (120 * 60));

    let minutes = Math.floor(divisor_for_minutes / 60);
    // if (minutes < 10) {
    //   minutes = "0" + minutes;
    // }

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    //let seconds = Math.floor(divisor_for_seconds);
    // if (seconds < 10) {
    //   seconds = "0" + seconds;
    // }

    return {
      m: minutes,
      s: seconds,
    };
  };

  //   useEffect(()=> {

  //     if (open){
  //         setReportData({
  //             date: moment(Date.now().format('LL')),
  //             game_name: "",
  //             time_remaining: {
  //                 m: time.m,
  //                 s: time.s
  //             },
  //             time_duration: {
  //                 m: getElapsedTime().m,
  //                 s: getElapsedTime().s
  //             },
  //             team_name: "",
  //             team_stats: {
  //                 objectives_complete: objectivesCompletePercentage,
  //                 messages_sent: filterMessages(),
  //                 players: 0
  //             },
  //             leaderboard_url: ""
  //           })
  //     }

  //   }, [open])

  return (
    <React.Fragment>
      <Tooltip title="End game" placement="top" arrow>
        <span className="w-full">
          <button
            onClick={() => !timerRunning ? null : completeGame()}
            id="completeGameButton"
            //disabled={!timerRunning ? true : false}
            className={classNames(
              "  duration-75 w-full h-[40px] rounded-sm flex items-center justify-center space-x-2 focus:outline-none px-3 disabled:opacity-50",
              !timerRunning
                ? "bg-v1_dark-hover hover:bg-gray-700 opacity-50"
                : "bg-red-600 hover:bg-red-500 opacity-100"
            )}
          >
            <svg className="w-3 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
            <span className="text-white font-medium">End</span>
          </button>
        </span>
      </Tooltip>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-70 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    "delay-500 relative transform overflow-hidden rounded-lg bg-v1_dark-hover px-4 pt-2 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6",
                    gameFail
                      ? "border-t-4 border-t-red-500"
                      : "border-t-4 border-t-green-500"
                  )}
                >
                  <div>
                    <div className="sm:mt-5">
                      <div className="mt-2">
                        <div className="grid grid-cols-2 pb-3 border-b border-gray-600">
                          <div>
                            <div className="mb-4 ">
                              <p className="text-xl font-medium text-white">
                                Post-Game Report
                              </p>
                              <p className="text-gray-400">
                                {moment(new Date()).format('LL')}
                              </p>
                            </div>
                            <div className="mb-2">
                              <p className="text-xs text-gray-400 font-medium">
                                Game
                              </p>
                              <p className="text-white ">{game_name ? game_name : ""}</p>
                            </div>
                            {/* <div className="mb-2">
                              <p className="text-xs text-gray-400 font-medium">
                                Team Name
                              </p>
                              <input
                                type="text"
                                placeholder="Add team name"
                                value={teamName}
                                onChange={(e)=>setTeamName(e.target.value)}
                                className="w-1/2 placeholder:text-gray-500 text-white bg-transparent focus:border-b focus:border-dotted focus:border-b-gray-600 focus:outline-none"
                              ></input>
                            </div> */}
                          </div>

                          <div className="text-right text-white">
                            <div
                              className={classNames(
                                "bg-gradient-to-r from-transparent py-0 px-2 w-2/3 ml-auto rounded-sm",
                                gameFail ? "to-red-500" : "to-green-600"
                              )}
                            >
                              <p
                                className={classNames(
                                  "text-xl font-semibold mb-3 text-white"
                                  // gameFail ? "text-red-500" : "text-green-500"
                                )}
                              >
                                {gameFail ? "Game Lost" : "Game Won"}
                              </p>
                            </div>

                            <div className="flex space-x-4 float-right divide-x divide-gray-500">
                              <div className="mb-2">
                                <p className="text-white text-3xl font-semibold">
                                  {time.m}:{time.s}
                                </p>
                                <p className="text-gray-400">Time remaining</p>
                              </div>
                              <div className="mb-2 pl-3">
                                <p className="text-white text-3xl font-semibold">
                                  {getElapsedTime().m}
                                  <span className="text-lg">m</span>{" "}
                                  {getElapsedTime().s}
                                  <span className="text-lg">s</span>
                                </p>
                                <p className="text-gray-400">Game duration</p>
                              </div>
                            </div>

                            {/* <div className="mb-2">
                              <p className="text-xs text-gray-400">
                                Time Remaining
                              </p>
                              <p className="text-xl font-semibold">45:01</p>
                            </div>

                            <div>
                              <p className="text-xs text-gray-400">
                                Time Elapsed
                              </p>
                              <p className="text-xl font-semibold">15m 59s</p>
                            </div> */}
                          </div>
                        </div>

                        <div className="py-3">
                        {/* <div className="flex justify-center relative bottom-[-75px]">
                           <p className="text-gray-500 text-lg">No objective or clue data</p>
                          </div> */}
                          <canvas
                            id="analytics-card-01"
                            width="800"
                            height="200"
                          > </canvas>

                          

                          {/* <testChart /> */}
                        </div>

                        <div className="mb-3">
                          <div className="grid grid-cols-3">
                            <div className="col-span-2">
                              <p className="text-white mb-2 font-medium">
                                Team stats
                              </p>
                              <div className="flex items-center space-x-4 divide-x divide-gray-500">
                                <div className="mb-2 px-2">
                                  <p className="text-white text-4xl font-semibold">
                                    {objectivesCompletePercentage}%
                                  </p>
                                  <p className="text-gray-400 text-xs">
                                    Complete
                                  </p>
                                </div>
                                <div className="mb-2 px-4">
                                  <p className="text-white text-4xl font-semibold">
                                    {messages && filterMessages()}
                                  </p>
                                  <p className="text-gray-400 text-xs">
                                    Messages
                                  </p>
                                </div>
                                <div className="mb-2 px-4">
                                  <p className="text-white text-4xl font-semibold">
                                    {activePlayers ? (
                                      <span>
                                        {activePlayers}<span className="text-lg ml-1">/ {totalPlayers}</span>
                                      </span>
                                    ) : (
                                      <span className="text-gray-400">-</span>
                                    )}
                                  </p>
                                  <p className="text-gray-400 text-xs">
                                    Players
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-span-1">
                              <p className="text-white mb-2 font-medium">
                                Average stats
                              </p>
                              <div className="flex items-center space-x-4 divide-x divide-gray-500">
                                <div className="mb-2 px-2">
                                  <p className="text-gray-400 text-4xl font-semibold">
                                    -
                                  </p>
                                  <p className="text-gray-400 text-xs">
                                    Escape rate
                                  </p>
                                </div>
                                <div className="mb-2 px-4">
                                  <p className="text-gray-400 text-4xl font-semibold">
                                    -
                                    {/* 2<span className="text-lg">nd</span> */}
                                  </p>
                                  <p className="text-gray-400 text-xs">
                                    Out of -- games
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div>
                          <p className="text-white mb-2 font-medium">
                            Share report
                          </p>
                          <div className="w-full flex items-center space-x-2">
                            <Tooltip
                              title="Copied!"
                              placement="top"
                              arrow
                              open={openCopyTooltip}
                            >
                              <input
                                id="leaderboard-link"
                                value="https://reports.boltgamemaster.com/ald020e-ddledoe330"
                                className="w-full bg-gray-600 text-white px-2 py-1 rounded-sm focus:outline-none"
                              />
                            </Tooltip>
                            <button
                              onClick={() => copyLeaderboardLink()}
                              className="button-outline-dark flex items-center"
                            >
                              <i class="fa-solid fa-link mr-2"></i> Copy
                            </button>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    type="button"
                    className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3"
                  >
                    <button
                      type="button"
                      className="button-outline-dark"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Back to game
                    </button>
                    {/* <button
                      type="button"
                      className="button-outline-dark"
                      onClick={() => saveReport()}
                      ref={cancelButtonRef}
                    >
                      Print
                    </button> */}
                    <button
                      onClick={() => saveReport("save")}
                      className="button-blue-dark "
                    >
                      Save and reset
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>

            {gameComplete && (
              <PostGameChart
              messages={messages}
              puzzlesDone={puzzlesDone}
              time={time}
              startingSeconds={startingSeconds}
            />
            )}
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
}
