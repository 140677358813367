import React from "react";

type Props = {};

export const Message_ClearScreen = (props: Props) => {
  return (
    <div key={Math.random() * 1000} className="chat-group-divider text-muted">
      <i className="icon ion-md-tv" style={{ marginRight: "5px" }}></i> Screen Cleared
    </div>
  );
};
