import authReducer from './authReducer'
import settingsReducer from './settingsReducer'
import gmReducer from './gmReducer'
import timerReducer from './timerReducer'
import remoteReducer from './remoteReducer'
import appReducer from './appReducer'
import GameDetailsReducer from './GameDetailsReducer'
import soundsReducer from './soundsReducer'

import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'

const rootReducer = combineReducers({
    app: appReducer,
    auth: authReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    gameMaster: gmReducer,
    timer: timerReducer,
    settings: settingsReducer,
    remote: remoteReducer,
    gameDetails: GameDetailsReducer,
    sounds: soundsReducer
})

export default rootReducer