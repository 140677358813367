import React, { useState } from "react";
import moment from "moment";

import { Style } from "./Exports";

type Props = {
  messageObj: any;
};

export const Message_TimerAdjust = (props: Props) => {
  const { messageObj } = props;
  const [hover, setHover] = useState(false);

  return (
    <div
      key={Math.random() * 1000}
      className="w-full flex py-2 items-center justify-center text-gray-400 text-xs"
    >
      <span
        className="font-semibold
       mr-1"
      >
        {messageObj.message}
      </span>{" "}
      the game clock <span className="text-gray-500 px-1">&#183;</span>{" "}
      <span>{messageObj && moment(messageObj.timestamp).format("LT")}</span>
    </div>
  );
};
