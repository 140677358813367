import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { classNames } from "../../../../utils/Utils";

function PlayVideoBriefSection(props) {
  const { handleStartVideoBrief, handleStopVideoBrief, videoIsPlaying } = props;

  const [expandDetailsSection, setExpandDetailsSection] = useState(true);

  useEffect(() => {
    try {
      const serializedState = localStorage.getItem("BOLT_preferences");

      if (serializedState === null) {
        return localStorage.setItem("BOLT_preferences");
      }
      return setExpandDetailsSection(
        JSON.parse(serializedState).videoBriefToggle
      );
    } catch (err) {
      return false;
    }
  }, []);

  const ExpandSection = (bool) => {
    setExpandDetailsSection(bool);
    localStorage.setItem(
      "BOLT_preferences",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("BOLT_preferences")),
        videoBriefToggle: bool,
      })
    );
  };

  return (
    <div className="border-b border-v1_dark-hover">
      <button
        onClick={() => ExpandSection(!expandDetailsSection)}
        className={classNames(
          "px-3 py-2 flex justify-between items center space-between w-full focus:outline-none duration-100 hover:bg-v1_dark-hover hover:bg-opacity-20"
        )}
      >
        <div>
          <div className="flex items-center space-x-2">
            <p className="text-white font-medium">Video Brief</p>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <button
            onClick={() => ExpandSection(!expandDetailsSection)}
            className={classNames(
              "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75",
              !expandDetailsSection ? "rotate-[180deg]" : "rotate-[270deg]"
            )}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
        </div>
      </button>

      <div
        className={classNames(
          "px-3 py-2 mb-2",
          !expandDetailsSection ? "hidden" : ""
        )}
      >
        <div>
          {!videoIsPlaying ? (
            <button
              onClick={() => handleStartVideoBrief()}
              className="bg-green-700 hover:bg-green-600 duration-75 w-full text-white py-1.5  rounded-sm flex items-center justify-center space-x-2 focus:outline-none disabled:opacity-50"
            >
              <svg
                className="w-2 fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
              </svg>
              <span className="font-medium">Play video</span>
            </button>
          ) : (
            <button
              onClick={() => handleStopVideoBrief()}
              className="bg-gray-700 hover:bg-gray-600 duration-75 w-full text-white py-1.5  rounded-sm flex items-center justify-center space-x-2 focus:outline-none disabled:opacity-50"
            >
              <svg
                className="w-2 fill-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
              >
                <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
              </svg>
              <span className="font-medium">Stop video</span>
            </button>
          )}

          <div className="mt-2 flex items-center space-x-2">
          <svg className="w-3 fill-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-13.3 0-24 10.7-24 24V264c0 13.3 10.7 24 24 24s24-10.7 24-24V152c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
          <p className="text-xs text-gray-400">Live window must be open for video to play.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     gameDetails: state.gameDetails
//   }
// };

export default connect(null, null)(PlayVideoBriefSection);
