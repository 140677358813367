export const initializeState = () => {
  return (dispatch, getState) => {
    dispatch({ type: "INITIALIZE_STATE" });
  };
};

export const updateTimer = (timer) => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_TIMER", timer });
  };
};

export const completeGame = (state) => {
  return (dispatch, getState) => {
    dispatch({ type: "COMPLETE_GAME", state });
  };
};

export const updateClueOnScreen = (clue, func) => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_CLUE_ON_SCREEN", clue, func });
  };
};

export const updateClueOnScreenCount = (clueCountArray, count, game_doc) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    dispatch({ type: "UPDATE_CLUE_ON_SCREEN_COUNT", clueCountArray });

    if (game_doc) {
      db.collection("gamemaster")
        .doc(game_doc)
        .update({
          clueCount: count || 0,
        })
        .then(() => {
          console.log("success");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

export const updateHideCluesOnScreen = (boolean) => {
    return (dispatch, getState) => {
      dispatch({ type: "UPDATE_HIDE_CLUES_ON_SCREEN", status:boolean });
    };
  };

export const updateInfiniteClues = (bool) => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_INFINITE_CLUES", bool });
  };
};

export const updateControlAfterVideo = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_CONTROL_AFTER_VIDEO", data });
  };
};
