import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import moment from 'moment'
import {
  signIn,
  signInWithGoogle,
  checkUserExists,
} from "../../../store/actions/authActions";
import { Redirect, Link } from "react-router-dom";
//Toasts
import { Toaster } from "sonner";

import { classNames } from "../../../utils/Utils";
import { quotes } from "./Quotes";

function UpdatePassword(props) {
  const { authError, auth, token } = props;
  if (auth.uid) return <Redirect to="/" />;


  const [password1, setPassword1] = useState(null);
  const [password2, setPassword2] = useState(null);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [tokenData, setTokenData] = useState(token);
  const [selectedQuote, setSelectedQuote] = useState()

  useEffect(()=> {
    setSelectedQuote(quotes[Math.floor(Math.random() * quotes.length)])
  }, [])


  document.title = "Reset Password - Bolt"

  useEffect(()=> {
    if (token) setTokenData(token)
    if (token){
        console.log('expiration', moment(token.expires.seconds*1000).format('lll'))
        console.log('date', moment(new Date().getTime()).format('lll'))
        if ((token.expires.seconds * 1000) < new Date()){
            console.log('expired')
            window.location.replace('/')
        } else {
            console.log('not expired')
        }
    }
  }, [token])



  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)

    if (password1 === password2) {
    console.log('url', "https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/updatePassword?email=" + token.email + "&newPassword=" + password1 + "&user_id=" + token.user_id + "&verification_id=" + token.verification_id)


      fetch(
        "https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/updatePassword?email=" + token.email + "&newPassword=" + password1 + "&user_id=" + token.user_id + "&verification_id=" + token.verification_id,
        {
          mode: "no-cors",
        }
      )
        .then(() => {
          console.log("fetch complete");
          setSuccess(true);
          setLoading(false)
          setTimeout(() => {
            window.location.replace('/login')
          }, 3000);
        })
        .catch((error) => {
          console.log("fetch error", error);
          setSuccess(false)
          setLoading(false)
          setError(error);
        });
    } else {
      setError("Passwords must match.");
    }
  };

  const checkPasswords = () => {
    if (!password1 && password2) return true
    if (password1 && !password2) return true
    if (password1 && password2) return false
    if (!password1 && !password2) return true
    
  }

  console.log('token data', tokenData)

  if (!token) return null

  return (
    <React.Fragment>
      <Toaster richColors position="top-center" />
      <div className="w-full flex h-screen bg-gray-100">
        <div className="w-full lg:max-w-lg px-20 pt-12 border-r border-gray-200 h-full bg-white">
          <div>
            <div
              className="text-center flex items-center justify-center flex-col"
              style={{ paddingBottom: "20px" }}
            >
              <Link to="/">
                <img
                  src="/bolt-logo.svg"
                  alt="Logo"
                  style={{
                    width: "175px",
                    marginTop: "-8px",
                    marginBottom: "30px",
                  }}
                ></img>
              </Link>

              {success && <i class="fa-solid fa-check text-5xl mb-2"></i>}

              {!success ? (
                <p className="text-2xl font-medium">Reset password</p>
              ) : (
                <p className="text-2xl font-medium">Password successfully reset</p>
              )}

              {success && (
                <p className="text-gray-500 mb-2 text-center">You'll be redirected momentarily.</p>
              )}
            </div>

            <div className={classNames("mb-4", success ? "hidden" : null)}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-3">
                  <input
                    type="password"
                    id="password1"
                    className="auth-input"
                    placeholder="New password"
                    onChange={(e) => setPassword1(e.target.value)}
                    value={password1}
                  ></input>
                </div>

                <div className="mb-3">
                  <input
                    type="password"
                    id="password2"
                    className="auth-input"
                    placeholder="Re-enter your new password"
                    onChange={(e) => setPassword2(e.target.value)}
                    value={password2}
                  ></input>
                </div>

                <div>
                  <button
                    type="submit"
                    disabled={password1 && password1 === password2 ? false : true}
                    className="auth-button flex justify-center"
                  >
                    <svg
                      className={classNames(
                        !loading
                          ? "hidden"
                          : "w-[22px] fill-gray-300  animate-spin"
                      )}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                    </svg>

                    <span className={classNames(loading ? "hidden" : null)}>
                      Send reset email
                    </span>
                  </button>
                </div>
              </form>
            </div>

            <p
              className={classNames(
                "text-xs text-gray-500 text-center",
                success ? "hidden" : null
              )}
            >
              Need help?{" "}
              <a
                className="text-blue-500 hover:text-blue-600"
                href="https://www.boltgamemaster.com/sign-up?type=start"
              >
                Chat with us!
              </a>
            </p>
          </div>
        </div>

        <div className="w-full justify-center items-center hidden lg:flex">
          {selectedQuote && (
            <div className="max-w-md">
            <p className="text-3xl leading-10">"{selectedQuote.quote}"</p>
            <div className="py-4">
              <p className="text-lg font-medium">{selectedQuote.name}</p>
              <div className="flex items-center space-x-2">
                <p>{selectedQuote.company}</p>
                <p>&#x2022;</p>
                <p>{selectedQuote.location}</p>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state, props) => {
  console.log(state);
  return {
    authError: state.auth.authError,
    authLoading: state.auth.authLoading,
    auth: state.firebase.auth,
    token: state.firestore.ordered.user_resetPassword ? state.firestore.ordered.user_resetPassword[0] : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    checkUserExists: (email) => dispatch(checkUserExists(email)),
    signInWithGoogle: () => dispatch(signInWithGoogle()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "user_resetPassword",
      doc: new URLSearchParams(props.location.search).get("token"),
    },
  ])
)(UpdatePassword);
