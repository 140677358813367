import React, { useState } from "react";
import { connect } from "react-redux";

import { addNewRoom } from "../../../store/actions/gmActions";

const NewRoomModal = (props) => {
  const [roomName, setRoomName] = useState("Untitled");
  const [timer, setTimer] = useState("3600");

  console.log("timer", timer);

  return (
    <div
      className="modal effect-scale"
      id="NewRoomModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-lg font-medium text-black mb-3">New Room</p>
            <div className="mb-3 flex flex-col">
              {/* <label className="label-light">
                Room Name
              </label> */}
              <p className="label-light">Room Name</p>
              <input
                onChange={(e) => setRoomName(e.target.value)}
                type="text"
                className="input-light"
                placeholder="The Wizard's Quest"
              ></input>
            </div>
            <div>
              {/* <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">
                Timer
              </label> */}
              <p className="label-light">Time limit</p>
              <select
                defaultValue="3600"
                onChange={(e) => setTimer(e.target.value)}
                className="input-light w-full"
              >
                <option value="7200">120 min</option>
                <option value="5400">90 min</option>
                <option value="4500">75 min</option>
                <option value="4200">70 min</option>
                <option value="3600">
                  60 min
                </option>
                <option value="3300">55 min</option>
                <option value="3000">50 min</option>
                <option value="2700">45 min</option>
                <option value="2100">35 min</option>
                <option value="1800">30 min</option>
                <option value="1500">25 min</option>
                <option value="1200">20 min</option>
                <option value="900">15 min</option>
                <option value="600">10 min</option>
                <option value="300">5 min</option>
              </select>
            </div>
          </div>

          <div className="modal-footer pd-x-20 pd-y-15">
            <button
              type="button"
              className="button-outline-light"
              data-dismiss="modal"
            >
             Cancel
            </button>
            <button
              onClick={() => props.addNewRoom(roomName, timer)}
              type="button"
              className="button-black-light"
              data-dismiss="modal"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewRoom: (name, timer) => dispatch(addNewRoom(name, timer)),
  };
};

export default connect(null, mapDispatchToProps)(NewRoomModal);
