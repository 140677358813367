import React from "react";
import { connect } from "react-redux";
//import uuid from 'react-uuid'

import { addNewClue } from "../../../../store/actions/gmActions";

const GameCompleteModal = (props) => {
  const {
    time,
    handleResetGame,
    puzzles,
    puzzlesDone,
    hintsUsed,
    addedMinutes,
  } = props;

  return (
    <div
      className="modal effect-scale"
      id="gameCompleteModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div
            className="modal-header pd-y-20 pd-x-20 pd-sm-x-30"
            style={{ border: "none" }}
          >
            <a
              href="/"
              role="button"
              className="close pos-absolute t-15 r-15"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </a>
            <div className="media w-full">
              <span className="tx-color-03 d-none d-sm-block">
                {/* <i className="icon ion-ios-flash" style={{fontSize:"50px"}}></i> */}
                <i
                  class="fa-solid fa-trophy-star"
                  style={{ fontSize: "40px" }}
                ></i>
              </span>

              <div className="media-body mg-sm-l-20">
                <p className="text-xl font-medium text-white mb-2">Game complete</p>
                <p style={{ marginBottom: "0px" }}>
                  The players ended with{" "}
                  <b>
                    {time ? time.m : "00"}:{time ? time.s : "00"}
                  </b>{" "}
                  remaining.
                </p>

                <div className="w-full pt-4 flex justify-between">
                <div
                    style={{ padding: "0px 3px", textAlign: "center" }}
                  >
                    <p className="text-2xl text-white font-medium">
                      {time ? time.m : "00"}<span className="text-normal">m</span> {time ? time.s : "00"}<span className="text-normal">s</span>
                    </p>
                    <p className="text-xs font-medium">Remaining</p>
                  </div>
                  <div
                    style={{ padding: "0px 3px", textAlign: "center" }}
                  >
                    <p className="text-2xl text-white font-medium">
                      {puzzlesDone ? puzzlesDone.length : 0}/
                      {puzzles ? puzzles.length : 0}
                    </p>
                    <p className="text-xs font-medium">Puzzles Done</p>
                  </div>
                  <div
                    style={{ padding: "0px 3px", textAlign: "center" }}
                  >
                    <p className="text-2xl text-white font-medium">{hintsUsed}</p>
                    <p className="text-xs font-medium">
                      Messages
                    </p>
                  </div>
                  <div
                    style={{ padding: "0px 3px", textAlign: "center" }}
                  >
                    <p className="text-2xl text-white font-medium">
                      {/* <ElapsedTime time={timerStartedAt} /> */}
                      {addedMinutes}<span className="text-normal ml-1">m</span>
                    </p>
                    <p className="text-xs font-medium">
                      Time Adjusted
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer-dark">
            <button
              onClick={() => handleResetGame()}
              type="button"
              className="button-red-dark mr-2"
              data-dismiss="modal"
            >
              <b>Reset Game</b>
            </button>
            <button
              type="button"
              className="button-outline-dark"
              data-dismiss="modal"
            >
              <b>Back to Game</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewClue: (data, id) => dispatch(addNewClue(data, id)),
  };
};

export default connect(null, mapDispatchToProps)(GameCompleteModal);
