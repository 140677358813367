import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

import { Howl } from "howler";

import { classNames } from "../../../../../utils/Utils";

export default function SoundItem(props) {
  const {
    clueObj,
    loadedClue,
    handleLoadClueClick,
    selectedPuzzleObj,
    isSent,
    userPermissions,
    sounds_updated,
    sounds,
  } = props;
  const [showControls, setShowControls] = useState(true);

  const selectedPuzzleID = selectedPuzzleObj ? selectedPuzzleObj.puzzle_id : 0;

  const clueObject = {
    ...clueObj,
  };

  // Sound States
  const [soundPlaying, setSoundPlaying] = useState(false);
  const [soundDuration, setSoundDuration] = useState(0);
  const [soundSeek, setSoundSeek] = useState(0);
  const [soundPosition, setSoundPosition] = useState(0);
  const [audioId, setAudioId] = useState(0);
  const [sound, setSound] = useState(null);

  return (
    <button disabled className={classNames(
        "clue-item-dark group flex justify-between border group bg-[#2f3036] animate-pulse",
        clueObj.puzzle_id !== selectedPuzzleID ? "hidden" : null,
    )}>
      <div className="w-[90%] flex items-start space-x-2">
        <div>
          <button disabled className="w-[35px] h-[35px] rounded-full bg-green-800 flex items-center justify-center focus:outline-none opacity-50">
            <i className="fa-solid fa-play text-gray-100"></i>
          </button>
        </div>

        <div className="w-[80%]">
          <div class="h-2.5 rounded bg-gray-600 w-full mb-2 mt-1"></div>
          <div className="flex items-center space-x-2">
            <div class="h-2 rounded bg-gray-700 w-[30%] mb-[8px]"></div>
          </div>
        </div>
      </div>
    </button>
  );
}
