import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { updateControlAfterVideo } from '../../../../store/actions/timerActions';
import {loadStateFromLocalStorage, loadAudioState} from '../../../../localStorage'
import { remoteEditVideoIsPlaying, remoteEditTimerIsRunning, remoteSendClue, resetTimerAdjustment} from '../../../../store/actions/remoteActions'


import FadeIn from 'react-fade-in'

export class LiveWindowRemote extends Component {
    constructor(props) {
        super();
        this.state = {
            playVideoBrief: false,
            videoIsPlaying: false,
            gamePaused: true,
            originalSeconds: '',
            endingText: '',
            windowIsLoading: true,
            timer_adjustments: []
        }

        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.pauseTimer = this.pauseTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.handleCheckForVideo = this.handleCheckForVideo.bind(this)
        this.secondsToTime = this.secondsToTime.bind(this)
    }



    componentDidMount() {
        document.body.style.backgroundColor = "#1e1d23";
        setTimeout(() => {
            const id = this.props.gamemaster_live_id;
            this.props.remoteEditVideoIsPlaying(false, id)
            this.props.remoteEditTimerIsRunning(false, id)
            this.props.remoteSendClue('', id)
            this.props.resetTimerAdjustment(id)
            this.setState({
                windowIsLoading: false,
            })
        }, 3000);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.gamemaster_live !== prevProps.gamemaster_live) {
            const { gamemaster_live } = this.props;
            const localStorage = loadStateFromLocalStorage()
            this.setState({
                ...gamemaster_live,
                video_brief: localStorage.timer.video_brief
            })
        }

        if (this.props.details !== prevProps.details) {
            const { details } = this.props;
            this.setState({
                ...details
            })
        }

        if (this.state.gameComplete !== prevState.gameComplete){
            this.setState({endingText: this.state.endingTextWin})
        } 


        if (this.state.video_brief !== prevState.video_brief){
            this.handleCheckForVideo()
        }


        if (this.state.timer_adjustments !== prevState.timer_adjustments){
            console.log("HANDLE TIMER ADJUST")
            this.handleTimerAdjustment()
        }


        if (this.state.timerRunning !== prevState.timerRunning) {
    
            if (this.state.timerRunning){
                this.startTimer()
            } else {
                this.pauseTimer()
            }
        } 
        
    }

    secondsToTime(secs){
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(secs / 60);
        if (minutes < 10){
            minutes = "0" + minutes
        }
    
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        if (seconds < 10){
            seconds = "0" + seconds
        }
    
        let obj = {
          "m": minutes,
          "s": seconds
        };
        return obj;
    }

    startTimer() {

            console.log("STARTING TIMER")
            if (this.timer >= 0 && this.state.seconds > 0) {
                this.timer = setInterval(this.countDown, 1000);

// TODO ADD SOUND BACK IN
                if (this.props.gameMaster.assets.audio_soundtrack.sound && !this.props.gameMaster.assets.audio_soundtrack.sound.playing()){
                    this.props.gameMaster.assets.audio_soundtrack.sound.play()
                }
            
              }
        }

    pauseTimer() {
        console.log("PAUSE TIMER")
        // this.setState({
        //     ...this.props.timer,
        //     timerRunning: false
        // })
        clearInterval(this.timer)
    }
    
    countDown() {
        let seconds = this.state.seconds - 1;
        const currentTime = new Date();
        let minutes = seconds / 60;


        this.setState({
          time: this.secondsToTime(seconds),
          seconds: seconds,
          estimatedFinshTime: currentTime.setMinutes(currentTime.getMinutes() + minutes)
        });

        //this.props.updateTimer({...this.state})
        
        // Check if we're at zero.
        if (seconds <= 0) { 
          clearInterval(this.timer);
          this.setState({endingText: this.state.endingTextFail})
          this.handleFailGame()
        }
      }


    handleCheckForVideo = () => {

        console.log("Check for Video")

        if (this.state.videoIsPlaying){
            const audioState = loadAudioState()
            this.refs.vidRef.addEventListener('ended',this.handleVideoFinished,true)
            //this.refs.vidRef.onended = this.handleVideoFinished

            var playVideoBriefPromise = this.refs.vidRef.play();
            
            const savedAudioState = audioState['audio_video_brief'];
            this.refs.vidRef.volume = savedAudioState

            if (playVideoBriefPromise !== undefined) {
                playVideoBriefPromise
                .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("video brief is playing");
                })
                .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("video error", error);
                    this.handleVideoFinished()
                });
            }
        } else {
            console.log("no vidref found")
        }
    }

    handleVideoFinished = () => {
        
        if (this.state.videoIsPlaying){
            console.log("updating control after video")

            setTimeout(() => {
                const id = this.props.gamemaster_live_id;
                this.props.remoteEditVideoIsPlaying(false, id)
                this.props.remoteEditTimerIsRunning(true, id)

                this.setState({
                    playVideoBrief: false,
                    videoIsPlaying: false,
                })
            }, 2000);
        }
 
        
    }

    toggleFullScreen = () => {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||    
         (!document.mozFullScreen && !document.webkitIsFullScreen)) {
          if (document.documentElement.requestFullScreen) {  
            document.documentElement.requestFullScreen();  
          } else if (document.documentElement.mozRequestFullScreen) {  
            document.documentElement.mozRequestFullScreen();  
          } else if (document.documentElement.webkitRequestFullScreen) {  
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);  
          }  
        } else {  
          if (document.cancelFullScreen) {  
            document.cancelFullScreen();  
          } else if (document.mozCancelFullScreen) {  
            document.mozCancelFullScreen();  
          } else if (document.webkitCancelFullScreen) {  
            document.webkitCancelFullScreen();  
          }  
        }  
      }

    handleTimerAdjustment = () => {
        const adjustment = this.state.timer_adjustments ? this.state.timer_adjustments.pop() : null;
        if (adjustment){
            this.setState({
                seconds: this.state.seconds + (adjustment.update * 60)
            })
            if (!this.state.timerRunning){
                this.setState({
                    time: this.secondsToTime(this.state.seconds + (adjustment.update * 60)),
                })
            }
        }
    }
    


    render() {

        const {time, clueOnScreen, clueOnScreenCount, gameComplete, endingText, videoIsPlaying, isInfiniteClues} = this.state;

        const video_brief = this.state.video_brief ? this.state.video_brief.url : null

        console.log("STATE", this.state)
        
        const showClues = () => {
            if (!isInfiniteClues){
                return (
                    <React.Fragment>
                        {clueOnScreenCount ? Object.keys(clueOnScreenCount).map((clue, index) => {

                        if (clueOnScreenCount[clue] === true){
                            return(
                                <FadeIn>
                                <i className="icon ion-ios-flash" style={{color:"rgb(228, 246, 28)"}}></i>
                                </FadeIn>
                            )
                        } else {
                            return(
                                <FadeIn>
                                <i className="icon ion-md-close" style={{color:"rgb(221, 55, 55)"}}></i>
                                </FadeIn>
                            )
                        }

                    }) : null}
                    </React.Fragment>
                )
            } else {
                return (
                    <FadeIn>
                    <i className="icon ion-md-infinite" style={{color:"rgb(228, 246, 28)"}}></i>
                    </FadeIn>
                )
            }
        }

        const showView = () => {
            if (gameComplete){
                return (
                    <div id="successBox" className="row" style={{position:"fixed", top:"50%", left:"50%", transform: "translate(-50%, -70%)", width:"80%"}}>
                    <div className="col-sm-12">
                        <div className="text-center" style={{marginTop:"20px", marginBottom:"10px !important"}}>
                        <FadeIn>
                            <h1 style={{fontSize:"125px", fontWeight:"bold", color:"#fafafa"}}>{endingText}</h1>
                            <h1 style={{fontSize:"72px", color:"#fafafa"}}>{time.m}:{time.s}</h1>
                       </FadeIn>
                            </div>
                        </div>
                    </div>
                )
            } else if (!gameComplete && !videoIsPlaying){
                
                return (
                    <React.Fragment>
          {clueOnScreen ? (
            <FadeIn>
                <div style={{position:"absolute", top:"20px", right:"0px"}}>
                {time ? (
                    <h1 style={{fontSize:"62px", color:"#fafafa"}}>{time.m}:{time.s}</h1>
                ) : null }
                </div>
            </FadeIn>
        ) : null }
            
                                 

        {clueOnScreen ? null : (
            <FadeIn>
            <div id="timerBox" className="row" style={{position:"fixed", top:"50%", left:"52%", transform: "translate(-50%, -80%)"}}>
            <div className="col-sm-12">
            <div className="u-mv-large text-center" style={{marginBottom:"10px !important", color:"#fafafa"}}>
            {time ? (
                <React.Fragment>
                    <p className="small-header-text" style={{fontSize:"18px", marginBottom:"0px !important"}}>Time Remaining</p>

                    <h1 style={{fontSize:"180px", fontWeight:"bold", color:"#fafafa"}}>
                        {time.m}:{time.s}
                    </h1>
                </React.Fragment>
                ) : (
                    <div className="text-center">
                        <div className="spinner-border" style={{color:"#fafafa"}}></div>
                    </div>
                ) }
                </div>
            </div>
        </div>
        </FadeIn>
        )}
            

    {clueOnScreen ? (
        <FadeIn>
        <div id="clueBox" className="row" style={{position:"fixed", top:"50%", left:"52%", transform: "translate(-50%, -70%)", width:"80%"}}>
        
        <div className="col-sm-12">
            <div className="text-center" style={{marginTop:"20px", marginBottom:"10px !important"}}>
            <h1 style={{fontSize:"62px", color:"#fafafa"}}>
                {clueOnScreen}
            </h1>
                </div>
            </div>
        </div>
            </FadeIn>

    ) : null}

            
        <div style={{position:"absolute", width:"100%", bottom: "20px", textAlign:"center"}}>
        <div className="u-mv-large u-text-center" style={{marginBottom: "10px !important"}}>
        <FadeIn>
        <div id="liveWindowClues" className="purple-pill-bottom" 
        style={{padding:"0px 30px", fontSize:"80px", width:"290px", margin:"0 auto", display:"flex", flexDirection:"row", justifyContent:"space-around", background:"#272c33", borderRadius:"40px"}}>

            {showClues()}

       

                </div>
                </FadeIn>
        </div>
        </div>

    </React.Fragment>
                )

            } else if (videoIsPlaying){

                return(
                    <FadeIn>
                {video_brief ? (
                    <video style={{position:"fixed", right:"0", bottom:"0",
                    minWidth:"100%", minHeight:"100%"}} ref="vidRef" src={video_brief} type="video/mp4"></video>
                ) : null}
            
        </FadeIn>
                )
            }
        }
        return (
            <div onClick={() => this.toggleFullScreen()} style={{width:"100%", height:"100%"}}>
                <div className="container" style={{marginRight:"0px",marginLeft:"0px", maxWidth:"95%"}}>

                    {this.state.windowIsLoading ? (
                        <div className="text-center" style={{position:"absolute", top:"45%", left:"47%"}}>
                            <div className="spinner-border" style={{color:"#fafafa"}}></div>
                         </div>

                    ) : (
                        showView()
                    )}

                </div>
            </div>
           
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    console.log(state)


    var item = state.firestore.data.gamemaster_live;
    var remote = item ? Object.values(item)[0] : null

    var details_item = state.firestore.data.gamemaster_live_details;
    var details = details_item ? Object.values(details_item)[0] : null

    var id = item ? Object.keys(item)[0] : null

    return {
        gamemaster_live: remote,
        details: details,
        gamemaster_live_id: id,
        gameMaster: state.gameMaster,
        timer: state.timer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateControlAfterVideo: (update) => dispatch(updateControlAfterVideo(update)),
        remoteEditVideoIsPlaying: (bool, doc) => dispatch(remoteEditVideoIsPlaying(bool, doc)),
        remoteEditTimerIsRunning: (bool, doc) => dispatch(remoteEditTimerIsRunning(bool, doc)),
        remoteSendClue: (clue, doc) => dispatch(remoteSendClue(clue, doc)),
        resetTimerAdjustment: (doc) => dispatch(resetTimerAdjustment(doc))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
        {
            collection: 'gamemaster_live', 
            doc: props.match.params.id
        },
        {
            collection: 'gamemaster_live_details', 
            doc: props.match.params.id
        }
        
    ])
)(LiveWindowRemote)
