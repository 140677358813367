import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  signUpV2,
  checkUserExists
} from "../../../store/actions/authActions";
import { Redirect, Link } from "react-router-dom";
//Toasts
import { Toaster } from "sonner";

import { classNames } from "../../../utils/Utils";

function SignUp(props) {
  const { authError, auth } = props;
  if (auth.uid) return <Redirect to="/" />;

  const [email, setEmail] = useState();
  const [password1, setPassword1] = useState();
  const [password2, setPassword2] = useState();
  const [loading, setLoading] = useState();
  const [emailExists, setEmailExists] = useState(false);
  const [steps, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const handleSignUp = (e) => {
    const newUser = {
        email: email,
        password: password1,
      }

      props.signUp(newUser)
  };

  const checkEmail = async () => {
    setLoading(true);
    props.checkUserExists(email).then((exists) => {
      setTimeout(() => {
        if (exists){
            window.location.replace('/login')
        } else {
            setLoading(false);
            setShowPassword(true)
        }
        setLoading(false);
      }, 1000);
    });
  };

  return (
    <React.Fragment>
      <Toaster richColors position="top-center" />
      <div
        className="w-full flex h-screen bg-gray-100 justify-center items-center
    "
      >
        <div className="w-full max-w-lg px-16 py-12 border border-gray-200  bg-white shadow-sm mb-20">
          <div>
            <div
              className="text-center flex items-center justify-center flex-col"
              style={{ paddingBottom: "20px" }}
            >
              <Link to="/">
                <img
                  src="/bolt-favi-yellow.png"
                  alt="Logo"
                  style={{
                    width: "50px",
                    marginTop: "-8px",
                    marginBottom: "30px",
                  }}
                ></img>
              </Link>

              {!emailExists ? (
                <p className="text-2xl font-medium mb-2">Get started with Bolt</p>
              ) : (
                <p className="text-2xl font-medium">Enter your password</p>
              )}

              <p className="text-gray-400 text-xs">We love helping teams succeed. We've helped send over 190,000 clues to players around the world.</p>
            </div>

            <div className="mb-4">
              <div className={classNames(emailExists ? "hidden" : "mb-3")}>
                <input
                  type="email"
                  id="email"
                  className="auth-input"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                ></input>
              </div>
              <div className={classNames(!showPassword ? "hidden" : "mb-2")}>
                <input
                  type="password"
                  id="password"
                  className="auth-input"
                  placeholder="Your password"
                  onChange={(e) => setPassword1(e.target.value)}
                  value={password1}
                ></input>
              </div>
              
              <div className={classNames(!showPassword ? "hidden" : "mb-3")}>
                <input
                  type="password"
                  id="password2"
                  className="auth-input"
                  placeholder="Re-enter your password"
                  onChange={(e) => setPassword2(e.target.value)}
                  value={password2}
                ></input>
              </div>

              <div>
                <button
                  onClick={() => checkEmail()}
                  disabled={!email || loading ? true : false}
                  className={classNames(
                    "auth-button flex justify-center",
                    showPassword ? "hidden" : null
                  )}
                >
                  <svg
                    className={classNames(
                      !loading
                        ? "hidden"
                        : "w-[22px] fill-gray-300  animate-spin"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                  </svg>

                  <span className={classNames(loading ? "hidden" : null)}>
                    Next
                  </span>
                </button>

                <button
                  onClick={() => handleSignUp()}
                  disabled={password1 && password1 === password2 ? false : true}
                  className={classNames(
                    "auth-button flex justify-center",
                    !showPassword  ? "hidden" : null
                  )}
                >
                  <svg
                    className={classNames(
                      !loading
                        ? "hidden"
                        : "w-[22px] fill-gray-300  animate-spin"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                  </svg>

                  <span className={classNames(loading ? "hidden" : null)}>
                    Create account
                  </span>
                </button>

                <button
                  onClick={() => handleLogin()}
                  disabled={!password1 ? true : false}
                  className={classNames(
                    "auth-button flex justify-center",
                    !emailExists ? "hidden" : null
                  )}
                >
                  <svg
                    className={classNames(
                      !loading
                        ? "hidden"
                        : "w-[22px] fill-gray-300  animate-spin"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                  </svg>

                  <span className={classNames(loading ? "hidden" : null)}>
                    Sign in
                  </span>
                </button>
              </div>
            </div>

            <p className="text-xs text-gray-500 text-center">
                Already have an account?{" "}
                <a
                  className="text-blue-500 hover:text-blue-600"
                  href="/login"
                >
                  Sign in.
                </a>
              </p>

            
          </div>
        </div>

      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    authError: state.auth.authError,
    authLoading: state.auth.authLoading,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (newUser, type) => dispatch(signUpV2(newUser, type)),
    checkUserExists: (email) => dispatch(checkUserExists(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
