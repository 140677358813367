import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import AddPuzzleModal from "./modals/AddPuzzleModal";
import PuzzleItem from "./ListItems/PuzzleItemV3";
import { reorderPuzzles, deletePuzzle } from "../../../store/actions/gmActions";

// React Sortable
import { ReactSortable } from "react-sortablejs";

import DropdownSettings from "./ListItems/Components/Dropdown";

import { classNames } from "../../../utils/Utils";

const PropsSidebar = (props) => {
  const {
    //handleGreyOutClue,
    handleSelectPuzzle,
    puzzles,
    id,
    selectedPuzzle,
    puzzlesDone,
    markPuzzleAsDone,
    selectedPuzzleObj,
    userPermissions,
  } = props;

  const [sortablePuzzles, setSortablePuzzles] = useState(puzzles);

  // Section Buttons
  const [expandSection, setExpandSection] = useState(true);
  const [currentGroup, setCurrentGroup] = useState("open");

  useEffect(() => {
    setSortablePuzzles(puzzles);
  }, [puzzles]);

  const sortableChange = () => {
    sortablePuzzles.forEach((puzzle, index) => {
      props.reorderPuzzles(props.id, puzzle.id, index);
    });
  };

  const delete_puzzle = (id, selectedPuzzle) => {
    props.deletePuzzle(id, selectedPuzzle);
    handleSelectPuzzle();
  };

  const [puzzlesLocked, setPuzzlesLocked] = useState(false);

  return (
    <React.Fragment>
      <div
        className="chat-sidebar puzzle-sidebar shadow z-10 border-r border-v1_dark-bg"
        id="puzzles-sidebar"
        style={{ width: "260px", top: "40px", bottom: "42px" }}
      >
        {/* <div className="box-header" style={{ paddingRight: "10px" }}>
        <p className="mb-0 text-gray-300">
          <i class="fa-regular fa-key-skeleton-left-right" style={{marginRight:'8px'}}></i>Room Flow</p>
        </div> */}

        {/* <div className="chat-content-header" style={{ paddingRight: "10px" }}>
          <h6 style={{ marginBottom: "0px" }}>Puzzles</h6>
        </div> */}

        <div className={classNames(
          "py-2 px-3 flex justify-between items center",
          !expandSection ? "border-b border-v1_dark-hover" : "border-0"
        )}>
          <p className="text-white font-medium">Objectives</p>

          <div className="flex items-center justify-center">
            <DropdownSettings userPermissions={userPermissions} />
            {/* <button
              onClick={() => setExpandSection(!expandSection)}
              className={classNames(
                "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75",
                !expandSection ? "rotate-[180deg]" : "rotate-[270deg]"
              )}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button> */}
          </div>
        </div>

        <div className="chat-sidebar-body overflow-scroll" style={{ top: "40px" }}>
          <div
            className="flex-fill pd-y-20 pd-x-10"
            style={{
              paddingTop: "0px",
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
          >
            {expandSection && (
              <React.Fragment>
                <div className="pb-3 px-3 flex space-x-2 font-medium">
                  <button
                    onClick={() => setCurrentGroup("open")}
                    className={classNames(
                      " text-xs rounded-full px-2 py-0.5 text-white duration-75 focus:outline-none",
                      currentGroup === "open"
                        ? "bg-blue-800 hover:bg-blue-900"
                        : "bg-gray-600 hover:bg-gray-700"
                    )}
                  >
                    {sortablePuzzles.length - puzzlesDone.length} Open
                  </button>
                  <button
                    onClick={() => setCurrentGroup("complete")}
                    className={classNames(
                      " text-xs rounded-full px-2 py-0.5 text-white duration-75 focus:outline-none",
                      currentGroup === "complete"
                        ? "bg-blue-800 hover:bg-blue-900"
                        : "bg-gray-600 hover:bg-gray-700"
                    )}
                  >
                    {puzzlesDone.length} Complete
                  </button>
                  <button
                    onClick={() => setCurrentGroup("all")}
                    className={classNames(
                      " text-xs rounded-full px-2 py-0.5 text-white duration-75 focus:outline-none",
                      currentGroup === "all"
                        ? "bg-blue-800 hover:bg-blue-900"
                        : "bg-gray-600 hover:bg-gray-700"
                    )}
                  >
                    {sortablePuzzles.length} All
                  </button>
                </div>

                {currentGroup === "open" && sortablePuzzles.length - puzzlesDone.length === 0 && sortablePuzzles.length > 0 && (
                  <div className={classNames(
                    "mt-2 px-3 w-full text-center",
                    !expandSection ? "hidden" : ""
                  )}>
                    {/* <ion-icon
                      style={{ color: "#a8b8ba" }}
                      size="large"
                      name="flash-outline"
                    ></ion-icon> */}
                    <i class="fa-regular fa-bolt text-yellow-500 text-2xl mb-2"></i>
                    <p className="text-white font-medium text-sm">
                      All objectives complete
                    </p>
                    {/* <p className="text-gray-500 text-xs"></p> */}
                  </div>
                )}


                <div
                  id="chatDirectMsg"
                  className="chat-msg-list"
                  style={{ marginBottom: "40px" }}
                >
                  {sortablePuzzles.length > 0 ? (

                    <ReactSortable
                      disabled={
                        userPermissions.isAdmin ||
                        userPermissions.canEditPuzzles
                          ? false
                          : true
                      }
                      list={sortablePuzzles}
                      setList={setSortablePuzzles}
                      animation={200}
                      delayOnTouchStart={true}
                      delay={5}
                      onSort={() => sortableChange()}
                      className="divide-y divide-x-0 divide-v1_dark-hover divide-solid"
                    >
                      {sortablePuzzles.map((puzzleObj, idx) => {
                        var isDone = false;
                        //if (puzzlesDone.includes(puzzleObj.id)) isDone = true;
                        puzzlesDone.filter((donePuzzle) => {
                          if (donePuzzle.id === puzzleObj.id)
                            return (isDone = true);
                        });

                        return (
                          <PuzzleItem
                            key={puzzleObj.id}
                            data-id={puzzleObj.id}
                            selectedPuzzle={selectedPuzzle}
                            puzzleObj={puzzleObj}
                            handleSelectPuzzle={handleSelectPuzzle}
                            isDone={isDone}
                            markPuzzleAsDone={markPuzzleAsDone}
                            currentGroup={currentGroup}
                            index={idx + 1}
                          />
                        );
                      })}
                    </ReactSortable>

                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "60px",
                      }}
                    >
                      <a
                        href="#createPuzzleModal"
                        data-toggle="modal"
                        className="btn btn-xs btn-primary"
                      >
                        <b>Add Puzzle</b>
                      </a>
                    </div>
                  )}

        {/* <button className="px-3 py-1.5 mt-4 w-full hover:bg-v1_dark-hover text-white flex items-center space-x-1 focus:outline-none">
          <p className="text-xs font-medium">Preferences</p>
          <i class="fa-solid fa-chevron-right text-xs"></i>
        </button> */}

        <button onClick={()=> drift.api.toggleChat()} className="mt-3 px-3 py-1.5 w-full hover:bg-v1_dark-hover text-white flex items-center space-x-1 focus:outline-none">
          <p className="text-xs font-medium">Help</p>
          <i class="fa-solid fa-chevron-right text-xs"></i>
        </button>


                </div>
              </React.Fragment>
            )}
          </div>
          
        </div>
        

       

        {/* <div
          className="bolt-box-shadow"
          id="puzzles-action-bar"
          style={{
            display:
              userPermissions.isAdmin || userPermissions.canEditPuzzles
                ? null
                : "none",
            position: "absolute",
            bottom: "0px",
            padding: "3px 10px",
            width: "100%",
            background: "rgb(35 35 43)",
            borderTop: "1px solid black",
          }}
        >
          <Tooltip title="New Puzzle" placement="top">
            <IconButton
              aria-label="add"
              size="small"
              id="addPuzzleModal"
              style={{ marginRight: "5px" }}
            >
              <AddIcon
                fontSize="small"
                style={{ color: "#9f9793" }}
                href="#createPuzzleModal"
                data-toggle="modal"
              />
            </IconButton>
          </Tooltip>
        </div> */}

        {/* <div
          style={{
            position: "absolute",
            bottom: "0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "10px",
            justifyContent: "space-between",
            width: "100%",
            background: "#303038",
          }}
        >
          <Tooltip title="Add Puzzle" placement="top" arrow>
            <button
              href={
                userPermissions.isAdmin || userPermissions.canEditPuzzles
                  ? "#createPuzzleModal"
                  : "#"
              }
              id="addPuzzleModal"
              data-toggle="modal"
              className="bolt-icon-button"
            >
              <i class="fa-solid fa-square-plus"></i>
            </button>
          </Tooltip>
        </div> */}
      </div>

      <AddPuzzleModal id={id} />
      {/* <DeletePuzzleModal delete_puzzle={delete_puzzle} id={id} selectedPuzzle={selectedPuzzleObj} /> */}

      {/* <ReactTooltip place="top" type="dark" effect="solid" delayShow={1000}/> */}
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    reorderPuzzles: (doc_id, puzzle_id, index) =>
      dispatch(reorderPuzzles(doc_id, puzzle_id, index)),
    deletePuzzle: (doc_id, puzzle_id) =>
      dispatch(deletePuzzle(doc_id, puzzle_id)),
  };
};

export default connect(null, mapDispatchToProps)(PropsSidebar);
