export const initState = {
    app_version: '1.17.0 - Beta',
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default authReducer;