import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import FadeIn from "react-fade-in";

import { addNewRoom, deleteRoom } from "../../store/actions/gmActions";
import NewRoomModal from "./modals/NewRoomModal";
import SubsModal from "../account/modals/SubsModal";
import { handleCheckoutClick } from "../../utils/Utils";
import Upgrade from "./components/Upgrade";

function Dashboard3(props) {
  const { account, rooms } = props;

  const [permissions, setPermissions] = useState({
    isAdmin: false,
    role: null,
    canEditLiveWindow: false,
  });
  const [roomLimit, setRoomLimit] = useState(0);
  const [loading, setLoading] = useState(true);
  const [charge_failed, setChargeFailed] = useState(false);
  const [stripe_id, setStripeId] = useState(null);
  const [subscription_status, setSubscriptionStatus] = useState(null);
  const [subscription_active, setSubscriptionActive] = useState(false);

  useEffect(() => {
    if (account) document.title = "Dashboard - Bolt Gamemaster";
  }, []);

  useEffect(() => {
    if (account) {
      const perms = account && account.permissions ? account.permissions : null;

      function search(id, myArray) {
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].user_id === id) {
            return myArray[i];
          }
        }
      }

      if (perms) {
        const gm_permissions = search(props.uid, perms);
        if (gm_permissions) {
          setPermissions(gm_permissions);
          setLoading(false);
        }
      } else {
        setPermissions({ isAdmin: true });
        setLoading(false);
      }
    }

    if (account) {
      const plan_type = account ? account.subscription.product_id : null;
      const subscription_status_type =
        account && account.subscription_status
          ? account.subscription_status.type
          : null;

      // Plan Types
      if (plan_type === "prod_JDMsnc2gCLWWkA" || plan_type === undefined)
        setRoomLimit(1);
      if (plan_type === "prod_JDMvOig4rtxg0U") setRoomLimit(100);
      if (plan_type === "prod_JDMw9iECHFJwCs") setRoomLimit(100);

      // Set Charge Failed
      if (subscription_status_type === "charge.failed") setChargeFailed(true);

      // Set Stripe Customer ID
      const stripe_customer_id = account.subscription.customer_id
        ? account.subscription.customer_id
        : null;

      if (stripe_customer_id) setStripeId(stripe_customer_id);

      // Set Subscription Status
      if (subscription_status_type)
        setSubscriptionStatus(subscription_status_type);

      // Set Subscription Active
      if (plan_type) setSubscriptionActive(true);
    }
  }, [account]);

  if (loading) {
    return (
      <div className="content ht-100v pd-0">
        <div
          className="text-center"
          style={{
            left: "50%",
            marginLeft: "-2em",
            position: "absolute",
            top: "40%",
          }}
        >
          <div
            className="spinner-border"
            style={{ width: "5rem", height: "5rem" }}
          ></div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {charge_failed && (
        <div style={{ marginTop: "20px" }}>
          <div
            className="alert alert-danger d-flex align-items-center"
            role="alert"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <svg
                width="24px"
                fill="currentColor"
                style={{ marginRight: "10px" }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path d="M96 360C96 346.7 106.7 336 120 336H168C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360zM224 360C224 346.7 234.7 336 248 336H360C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360zM96 280C96 266.7 106.7 256 120 256H456C469.3 256 480 266.7 480 280C480 293.3 469.3 304 456 304H120C106.7 304 96 293.3 96 280zM456 128C469.3 128 480 138.7 480 152V200C480 213.3 469.3 224 456 224H376C362.7 224 352 213.3 352 200V152C352 138.7 362.7 128 376 128H456zM0 96C0 60.65 28.65 32 64 32H512C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96zM48 96V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V96C528 87.16 520.8 80 512 80H64C55.16 80 48 87.16 48 96z" />
              </svg>{" "}
              <b>Payment Failed</b>
              <span style={{ marginLeft: "5px" }}>
                {" "}
                Please update your payment method.
              </span>
            </div>
            <a
              className="btn btn-xs btn-primary"
              style={{ marginLeft: "5px" }}
              href="/account/billing"
            >
              {" "}
              Go to billing
              <i
                className="fa-solid fa-arrow-right"
                style={{ marginLeft: "5px" }}
              ></i>
            </a>
          </div>
        </div>
      )}

      <div className="p-4" style={{ margin: "0 auto" }}>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <div>
            <p className="text-2xl font-bold text-black">Dashboard</p>
            <p className="text-gray-600 font-medium">
              {account ? account.title :  ""}
            </p>
          </div>


          <div
            style={
              permissions.isAdmin || permissions.role === "Admin"
                ? null
                : { display: "none" }
            }
          >
            {subscription_active && (
              <button
                href="#NewRoomModal"
                data-toggle="modal"
                className="button-black-light"
              >
                New Room
              </button>
            )}

            <Upgrade
              rooms={rooms}
              subscription_status={subscription_status}
              subscription_active={subscription_active}
            />

          </div>
        </div>

        <div className="row row-xs">
          {rooms && rooms.length === 0 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  maxWidth: "300px",
                  textAlign: "center",
                  padding: "20px",
                }}
              >
                <i
                  className="fa-regular fa-person-to-door"
                  style={{
                    fontSize: "28px",
                    marginBottom: "10px",
                    opacity: "1",
                  }}
                ></i>
                <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                  No rooms
                </p>
                <p style={{ opacity: "0.6" }}>
                  Get started by creating a new room
                </p>
                <div className="py-4">
                  <a
                    href="#NewRoomModal"
                    data-toggle="modal"
                    className="button-outline-light"
                  >
                    {/* <i
                      className="fa-solid fa-plus"
                      style={{ marginRight: "5px" }}
                    ></i> */}
                    New Room
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
            <table className="table">
              <thead style={{ background: "#f5f5f5" }}>
                <tr>
                  <th
                    scope="col"
                    style={{ fontWeight: "700", color: "#737373" }}
                  >
                    <i
                      className="fa-solid fa-door-open"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Rooms
                  </th>
                  <th
                    scope="col"
                    style={{ fontWeight: "700", color: "#737373" }}
                  >
                    Success %
                  </th>
                  <th
                    scope="col"
                    style={{ fontWeight: "700", color: "#737373" }}
                  >
                    Victories
                  </th>
                  <th
                    scope="col"
                    style={{ fontWeight: "700", color: "#737373" }}
                  >
                    Fails
                  </th>
                  <th
                    scope="col"
                    style={{ fontWeight: "700", color: "#737373" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {rooms ? (
                  rooms.map((item, index) => {
                    const stats = item.stats ? item.stats : null;


                    return (
                      <tr
                        key={index}
                        style={{
                          borderBottom: "1px solid rgba(72, 94, 144, 0.16)",
                          opacity: !subscription_active && index > 0 ? '0.5' : '1',
                        }}
                      >
                        <td className="games-table" style={{ border: "none" }}>
                          <div style={{ display: "flex" }}>
                            {/* <ion-icon name="flash" style={{marginRight:"20px", fontSize:'32px', color:'black'}}></ion-icon> */}
                            <i
                              className="fa-solid fa-bolt"
                              style={{
                                marginRight: "20px",
                                fontSize: "24px",
                                color: "black",
                              }}
                            ></i>

                            <div>
                              <p
                                style={{
                                  marginBottom: "0",
                                  fontSize: "18px",
                                  fontWeight: "700",
                                  color: "black",
                                }}
                              >
                                {item.name}
                                {/* {item.status && item.status.timer_running ? (
                                <div className='game-live' style={{width:'8px', height:'8px', borderRadius:'50%',background:'#15c115'}}></div>
                              ): null} */}
                              </p>
                              <small>
                                Time Limit: {item.timer.time.m}:
                                {item.timer.time.s}
                                {/* &#x2022; {moment(item.created_at.seconds * 1000).format('ll')} */}
                              </small>
                            </div>
                          </div>
                        </td>
                        <td className="games-table" style={{ border: "none" }}>
                          <p
                            style={{
                              marginBottom: "0",
                              fontSize: "18px",
                              fontWeight: "700",
                              color: "black",
                            }}
                          >
                            {stats && stats.escape_rate
                              ? stats.escape_rate.toFixed(0) + "%"
                              : "0%"}
                          </p>
                          <small>Success Rate</small>
                        </td>
                        <td className="games-table" style={{ border: "none" }}>
                          <p
                            style={{
                              marginBottom: "0",
                              fontSize: "18px",
                              fontWeight: "700",
                              color: "black",
                            }}
                          >
                            {stats && stats.wins ? stats.wins : "0"}
                          </p>
                          <small>Victories</small>
                        </td>
                        <td className="games-table" style={{ border: "none" }}>
                          <p
                            style={{
                              marginBottom: "0",
                              fontSize: "18px",
                              fontWeight: "700",
                              color: "black",
                            }}
                          >
                            {stats && stats.losses ? stats.losses : "0"}
                          </p>
                          <small>Fails</small>
                        </td>
                        <td
                          className="games-table"
                          style={{ border: "none", width: "300px" }}
                        >
                          <a
                            href={!subscription_active && index > 0 ? null : "/r/" + item.id + "?view=puzzles"}
                            className="button-black-light py-2 mr-2"
                          >
                            {/* <i className="icon ion-ios-flash" style={{ marginRight: "5px" }}></i> */}

                            Run Room
                            <i
                              className="fa-solid fa-chevron-right ml-2"
                            ></i>
                          </a>

                          {permissions.canEditLiveWindow ? (
                            <a
                              href={!subscription_active && index > 0 ? null : "/settings/game/" + item.id}
                              className="button-outline-light py-2"
                            >
                              {/* <i className="icon ion-ios-cog" style={{ marginRight: "5px" }}></i> */}
                              <i
                                className="fa-solid fa-gear"
                                style={{ marginRight: "5px" }}
                              ></i>
                              Settings
                            </a>
                          ) : (
                            <a
                              href="#"
                              className="button-outline-light py-2"
                            >
                              <i
                                className="icon ion-ios-cog"
                                style={{ marginRight: "5px" }}
                              ></i>
                              <b>Settings</b>
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : null}
              </tbody>
            </table>
            </React.Fragment>
          )}
        </div>
      </div>

      <NewRoomModal />
      <SubsModal
        handleCheckoutClick={handleCheckoutClick}
        stripe_id={stripe_id}
        subscription_status={subscription_status}
      />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  //console.log("state2", state);

  const account_id = state.firebase.profile.account_id
    ? state.firebase.profile.account_id
    : null;

  return {
    rooms: state.firestore.ordered.gamemaster,
    auth: state.firebase.auth,
    account_id: account_id,
    uid: state.firebase.auth.uid ? state.firebase.auth.uid : "123",
    profile: state.firebase.profile,
    app: state.app,
    account: state.firestore.ordered.user_accounts[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewRoom: (name) => dispatch(addNewRoom(name)),
    deleteRoom: (room_id) => dispatch(deleteRoom(room_id)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "gamemaster",
      where: [["account_id", "==", props.account_id]],
      orderBy: ["created_at", "desc"],
    },
  ])
)(Dashboard3);
