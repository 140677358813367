import React, {useState, useEffect} from 'react'
import moment from 'moment'

var interval;

function ElapsedTime(props) {

    const {time} = props;

    const [elapsed, setElapsed] = useState()

    useEffect(() => {
        if (time) {
            interval = setInterval(() => {
                
                    var minutes = moment.duration(moment().diff(time)).minutes();
                    var seconds = moment.duration(moment().diff(time)).seconds();
                
                    if (seconds < 10) seconds = '0' + seconds;
                    if (minutes < 10) minutes = '0' + minutes;
                
                    setElapsed(minutes + ":" + seconds)
            }, 1000)
        } else {
            setElapsed('00:00')
            clearInterval(interval)
        }
        
        return () => {
            setElapsed('00:00')
            clearInterval(interval)
        }
    }, [time])


    return (
        <div>
            {elapsed}
        </div>
    )
}

export default ElapsedTime
