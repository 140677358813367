import React, {useState, useRef, useEffect} from 'react'
import FadeIn from 'react-fade-in'
import {
    loadAudioState,
    saveAudioState
  } from "../../../../../localStorage";

  import Hints from './Hints'

function TimerScreen(props) {

    const { settings, clueOnScreen, time, video_brief, videoIsPlaying, audio_volume, gameComplete, isInfiniteClues, clueOnScreenCount, background_image, customIcon, hideCluesOnScreen} = props;

    const bg_video = useRef()
    const [anyVideoPlaying, setAnyVideoPlaying] = useState(false)
    const [bgVideoPlaying, setBGVideoPlaying] = useState(false)
    
    const [bg_video_src, setBGVideo] = useState()
    const [video_brief_src, setVideoBrief] = useState()
    const [videoKey, setVideoKey] = useState()
    

    useEffect(() => {
        if (video_brief){
            setVideoBrief(video_brief.url)
            //bg_video.current.volume = audio_volume.audio_video_brief;
        }
    }, [video_brief])

    useEffect(() => {
        if (videoIsPlaying) {
            setAnyVideoPlaying(true)
            setBGVideoPlaying(false)
            bg_video.current.pause()
            bg_video.current.loop = false;
            setTimeout(() => {
                setVideoKey('video_brief')
                handlePlayVideo()
            }, 500);
        } else if (!videoIsPlaying && videoKey === 'video_brief' && background_image && background_image.type && background_image.type.startsWith('video/')) {
        

            setAnyVideoPlaying(false)
            handleStopVideo()
            

            setBGVideoPlaying(true)
            setVideoKey('videoBG')
            setBGVideo(background_image.url)
            setTimeout(() => {
                bg_video.current.play()
                bg_video.current.loop = true;
            }, 0);
        } else {
            setAnyVideoPlaying(false)
            handleStopVideo()
        }
    }, [videoIsPlaying])


    useEffect(() => {
        if (!videoIsPlaying && background_image && background_image.type && background_image.type.startsWith('video/')) {
            console.log('bg video play')
            setBGVideoPlaying(true)
            setVideoKey('videoBG')
            setBGVideo(background_image.url)
            setTimeout(() => {
                bg_video.current.play()
                bg_video.current.loop = true;
            }, 0);
        }
    }, [background_image])



    const convertPXtoVH = (px) => {
      return (px * (100 / [document.documentElement.clientHeight])) * 3.4  + 'vh'
    }


    const timerTextStyle = (position) => {
        var style = {};
  
        if (position === 'main'){
          style = {
            fontSize: settings.timer && settings.timer.size ? settings.timer.size + 'vh' : '22vh',
            fontWeight: "bold",
            color: settings.timer && settings.timer.color ? settings.timer.color : "#fafafa",
            display: settings.timer && settings.timer.show === false ? 'none' : null,
            fontFamily: settings.timer && settings.timer.font ? settings.timer.font : null
          }
        } 
        
        if (position === 'small'){
          style = {
            fontSize: "8vh", 
            fontWeight: "bold",
            color: settings.timer && settings.timer.hintTimerColor ? settings.timer.hintTimerColor : "#fafafa",
            fontFamily: settings.timer && settings.timer.font ? settings.timer.font : null,
            marginBottom:'5px'
          }
        }
  
        return {
          ...style,
        }
      }


    const handlePlayVideo = () => {
        // if VideoIsPlaying

        if (videoIsPlaying) {
          if (bg_video) {
            
    
            // Set Background Style
            document.body.style.backgroundColor = "#000";
            document.body.style.backgroundImage = '';
    
    
            const audioState = loadAudioState();
            

            bg_video.current.onended = function(e) {
              /*Do things here!*/
              setTimeout(() => {
                console.log('video ended')
                props.handleVideoFinished()
              }, 500);
            }.bind(this);

            bg_video.current.ontimeupdate = function(e) {
              var currentTime = bg_video.current.currentTime;
              var duration = bg_video.current.duration;


              saveAudioState('video_brief_current', currentTime.toFixed(0))
              saveAudioState('video_brief_duration', duration.toFixed(0))
              //console.log('currentTime', currentTime)
              //console.log('duration', duration)
            };
      
            // Do not autoplay
            bg_video.current.autoplay = false;
            bg_video.current.loop = false;

      
          // Audio State
          const savedAudioState = audioState["audio_video_brief"];
          if (savedAudioState) bg_video.current.volume = savedAudioState;
          //console.log('AUDIO', savedAudioState)
    
          var playVideoBriefPromise = bg_video.current.play();
          if (playVideoBriefPromise !== undefined) {
            playVideoBriefPromise
              .then((_) => {
                // Automatic playback started!
                // Show playing UI.
                console.log("video brief is playing");
              })
              .catch((error) => {
                // Auto-play was prevented
                // Show paused UI.
                console.log("video error", error);
                props.handleVideoFinished();
              });
    
          }
        }
        
        } else {
          document.body.style.backgroundColor = this.state.settings.background_color ? this.state.settings.background_color : "#1e1d23";
          bg_video.current.load();
          bg_video.current.oncanplaythrough = function(e) {
              console.log("Can play through video without stopping", e);
          };
        }
      }


    const handleStopVideo = () => {
        if (bg_video){
            bg_video.current.pause();
            bg_video.current.currentTime = 0;
        }
      }

    const bgStyle = () => {

        if (!videoIsPlaying && background_image && background_image.type.startsWith('image/')) {
            return {
              background: 'url(' + background_image.url + ')',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }
          } else if (videoIsPlaying || background_image && background_image.type.startsWith('video/')) {
            return {
              background: '#000'
            }
          } else {
            return {
              background: settings.timer && settings.timer.bg_color ? settings.timer.bg_color : '#000'
            }
          }
    
    
      }


    return (
        <div className="container pd-0" 
        style={{ 
            ...bgStyle(),
            maxWidth: "100%",
            height: '100%',
            //display: gameComplete ? 'none': 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            display:'flex',
            position:'fixed'
              }}>


            <video width='100%' className='fade-in' style={{ 
                    objectFit: 'cover',
                    position:'fixed',
                    minWidth:'100%',
                    minHeight:'100%',
                    display: !anyVideoPlaying && !bgVideoPlaying ? 'none' : null
                }}
                    muted={bgVideoPlaying && !videoIsPlaying ? true : false}
                    preload="auto"
                    key={videoKey}
                    ref={bg_video}
                >

            {bgVideoPlaying && !videoIsPlaying && (
                <source 
                    
                    key="bg-video"
                    src={bg_video_src ? bg_video_src : null} 
                    type="video/mp4"
                    />
            )}  

                <source 
                    key="video-brief"
                    src={video_brief_src ? video_brief_src : null} 
                    type="video/mp4" />
                </video>
       
       
        {clueOnScreen && !videoIsPlaying && !gameComplete ? (
              <FadeIn>
                <div
                  style={{ 
                    position: "fixed", 
                    top: "2vw", 
                    right: "2vw",
                    backgroundColor: settings && settings.timer && settings.timer.bg_color_hintTimer ? settings.timer.bg_color_hintTimer  : 'black',
                    padding: '2vh 4vh',
                    borderRadius:'3vh',
                    display: settings && settings.timer && !settings.timer.show_hintTimer ? 'none' : null,
                  }}
                >
                  {time ? (
                    <h1 style={timerTextStyle('small')}>
                      {time.m}:{time.s}
                    </h1>
                  ) : null}
                </div>
              </FadeIn>
            ) : null}



            {clueOnScreen || videoIsPlaying || gameComplete  ? null : (
              <FadeIn>
                <div
                  id="timerBox"
                  className="row"
                  style={{
                    marginRight:'0px',
                    marginLeft:'0px',
                    marginBottom: settings && settings.timer && settings.timer.show_hintIcons ? '15vh' : '5vh',
                  }}
                >
                  <div className="col-sm-12">
                    <div
                      className="u-mv-large text-center"
                      style={{
                        marginBottom: "10px !important",
                        color: "#fafafa",
                      }}
                    >
                      {time ? (
                        <React.Fragment>
                          <p
                            className="small-header-text"
                            style={{
                              fontSize: "3vh",
                              color: settings.timer && settings.timer.color ? settings.timer.color : "#fafafa",
                              marginBottom: "0px !important",
                              display: settings.timer && settings.timer.show_timeRemaining === false ? 'none' : null
                            }}
                          >
                            Time Remaining
                          </p>

                          <h1
                            style={timerTextStyle('main')}
                          >
                            {time.m}:{time.s}
                          </h1>
                        </React.Fragment>
                      ) : (
                        <div className="text-center">
                          <div
                            className="spinner-border"
                            style={{ color: "#fafafa" }}
                          ></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </FadeIn>
            )}

            {clueOnScreen && !videoIsPlaying && !gameComplete ? (
              <FadeIn>
                <div
                  id="clueBox"
                  className="row"
                  style={{
                    marginRight:'0px',
                    marginLeft:'0px',
                    marginBottom:'10vh',
                    padding: '0px 20vh'
                  }}
                >
                  <div className="col-sm-12">
                    <div
                      className="text-center"
                      style={{
                        marginTop: "20px",
                        marginBottom: "10px !important",
                      }}
                    >
                      <h1 style={{ 
                        //fontSize: "7vh", 
                        fontSize: settings.timer && settings.timer.size_hintText ? convertPXtoVH(settings.timer.size_hintText) : '7vh',
                        lineHeight: settings.timer && settings.timer.size_hintText ? convertPXtoVH(settings.timer.size_hintText) : '7vh',
                        color: settings.timer && settings.timer.hintColor ? settings.timer.hintColor : '#fafafa',
                        whiteSpace: 'pre-wrap',
                        fontFamily: settings.timer && settings.timer.hintFont ? settings.timer.hintFont : null
                         }}>
                        {clueOnScreen}
                      </h1>
                    </div>
                  </div>
                </div>
              </FadeIn>
            ) : null}


        {!videoIsPlaying && !gameComplete && settings && settings.timer && settings.timer.show_hintIcons &&  (
            <Hints isInfiniteClues={isInfiniteClues} clueOnScreenCount={clueOnScreenCount} settings={settings} customIcon={customIcon} hideCluesOnScreen={hideCluesOnScreen} />
        )}
            

            
        </div>
    )
}

export default TimerScreen
