import React, { useState } from "react";
import { connect } from "react-redux";

import { updateTimer } from "../../../../../store/actions/timerActions";

import { Style, ProfilePic } from "./Exports";

type Props = {
  id: any;
  game: any;
  isPlayerWindowOpen: boolean;
  updateTimer: any;
  timer: any;
};

const Message_Intro = (props: Props) => {
  const { game, isPlayerWindowOpen, id } = props;

  const game_name = game ? game.name : "";
  const timer = game && game.timer ? game.timer.time : { m: "00", s: "00" };

  const openLiveWindow = () => {
    const { id } = props;
    var params = "width=" + (window.screen.width - 200);
    params += ", height=" + (window.screen.height - 200);
    params += ", left=1000";
    params += ", menubar=1";

    props.updateTimer({
      ...props.timer,
      isPlayerWindowOpen: true,
    });

    window.open("/r/" + id + "/live", "windowname4", params);
  };

  return (
    <div style={{ marginBottom: "50px" }}>
      <div
        key={Math.random() * 1000}
        className="media w-full"
        style={Style(null, null, null, "intro")}
      >
        <div
          className="media-body w-full"
          style={{
            marginLeft: "10px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="w-full">
            <div
            className="w-full"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "23px",
                marginBottom: "0px",
                marginTop: "0px",
                height: "18px",
              }}
            ></div>

            <div
            className="w-full"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              {/* <ProfilePic icon="rocket" type="intro" /> */}
              <div>
                <p className="mb-0 text-lg whitespace-pre-wrap text-white">
                <i className="fa-regular fa-person-to-door mr-2 text-green-600"></i>{game_name}
                </p>

                <p className="mb-0 whitespace-pre-wrap text-white">
                  Players will have{" "}
                  <b>
                    {timer.m}m {timer.s}s 
                  </b>{" "}
                  to complete the room.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {!isPlayerWindowOpen && (
        <div style={{ padding: "10px 80px" }}>
          <button
            id="openPlayerScreen"
            onClick={openLiveWindow}
            className="bg-yellow-500 border border-yellow-500 hover:bg-yellow-600 duration-75 focus:outline-none px-3 py-1.5 rounded-sm text-gray-900 font-medium"
          >
            <i
              className="fa-solid fa-square-exclamation mr-2"
            ></i>
            Open timer window
            <i className="fa-solid fa-arrow-up-right-from-square ml-2"></i>
          </button>
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    timer: state.timer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateTimer: (timer: any) => dispatch(updateTimer(timer)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message_Intro);
