import uuid from "react-uuid";

export const saveAccountSettings = (settings) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const user_account = getState().firebase.profile.account_id;

    db.collection("user_accounts")
      .doc(user_account)
      .update({
        ...settings,
      })
      .then(() => {
        dispatch({ type: "SAVE_ACCOUNT_SETTINGS" });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SAVE_ACCOUNT_SETTINGS_ERROR", error });
      });
  };
};

export const saveGameSettings = (game_doc, type, settings) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    dispatch({ type: "SAVE_GAME_SETTINGS", status: "saving" });

    var data = {};
    if (type === "timer") {
      data = {
        timer: settings,
      };
    }

    if (type === "name") {
      data = {
        name: settings.name,
      };
    }

    db.collection("gamemaster")
      .doc(game_doc)
      .update({
        ...data,
      })
      .then(() => {
        dispatch({ type: "SAVE_GAME_SETTINGS", status: "save_success" });
        setTimeout(() => {
          dispatch({ type: "SAVE_GAME_SETTINGS", status: "" });
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SAVE_GAME_SETTINGS_ERROR", error });
      });
  };
};

export const saveLiveWindowSettings = (game_doc, settings) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    //Set Status to 'Saving'
    dispatch({ type: "SAVE_LIVEWINDOW_SETTINGS", status: "saving" });

    db.collection("gamemaster")
      .doc(game_doc)
      .collection("settings")
      .doc("live_window")
      .set({
        ...settings,
      })
      .then(() => {
        console.log("save success");

        //Set Status to 'Success'
        dispatch({ type: "SAVE_LIVEWINDOW_SETTINGS", status: "save_success" });

        setTimeout(() => {
          //Set Status to ''
          dispatch({ type: "SAVE_LIVEWINDOW_SETTINGS", status: "" });
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SAVE_LIVEWINDOW_SETTINGS_ERROR", error });
      });
  };
};

export const saveAssetsSettings = (game_doc, type, settings) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    dispatch({ type: "SAVE_GAME_SETTINGS", status: "saving" });

    db.collection("gamemaster")
      .doc(game_doc)
      .collection("assets")
      .doc(type)
      .update({
        ...settings,
      })
      .then(() => {
        dispatch({ type: "UPLOAD_AUDIO_PROGRESS", progress: 0 });
      })
      .catch((error) => {
        console.log(error);
        //dispatch({type: "SAVE_GAME_SETTINGS_ERROR", error})
      });
  };
};

export const deleteGame = (game_doc) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    db.collection("gamemaster")
      .doc(game_doc)
      .delete()
      .then(() => {
        //dispatch({type: "SAVE_GAME_SETTINGS"})
      })
      .catch((error) => {
        console.log(error);
        //dispatch({type: "SAVE_GAME_SETTINGS_ERROR", error})
      });
  };
};

export const saveProfileSettings = (settings) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = getFirestore();
    const uid = getState().firebase.auth.uid;

    firebase.auth.currentUser
      .updateEmail({
        ...settings,
      })
      .then(function (userRecord) {
        db.collection("users")
          .doc(uid)
          .update({
            ...settings,
          });
      })
      .then(() => {
        dispatch({ type: "SAVE_ACCOUNT_SETTINGS", save: true });
        setTimeout(() => {
          dispatch({ type: "SAVE_ACCOUNT_SETTINGS", save: false });
        }, 1000);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "SAVE_ACCOUNT_SETTINGS_ERROR", error });
      });
  };
};

// User Permissions & Team Members
export const updateUserPermissions = (newPermissions, uid, type) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const user_account = getState().firebase.profile.account_id;

    let ref = db.collection("user_accounts").doc(user_account);

    let transaction = db
      .runTransaction((t) => {
        return t.get(ref).then((doc) => {
          let permissions = doc.data().permissions
            ? doc.data().permissions
            : [
                {
                  ...newPermissions,
                  user_id: uid,
                },
              ];

          if (type === "delete") {
            permissions = permissions.filter((rules) => {
              if (rules.user_id !== uid) return rules;
            });

            // console.log('new clues', clues)
          } else {
            permissions = permissions.map((rules) =>
              rules.user_id === uid ? { ...rules, ...newPermissions } : rules
            );
          }

          return t.update(ref, {
            permissions: permissions,
          });
        });
      })
      .then((result) => {
        console.log("Update User Permissions Success!");
        //dispatch({ type: "UPDATE_PUZZLE_NAME" });
      })
      .catch((error) => {
        console.log("Update User Permissions ERROR", error);
      });
  };
};

export const inviteNewUser = (permissions, email, account) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const batch = db.batch();

    //Send Email
    // ==> User Confirms
    // == app.hintspace.io/newUser?account=123

    // Add Pending User
    const pendingUser = {
      email: email,
      user_id: null,
      status: "pending",
      ...permissions,
    };

    // Create Doc in Collection - Users
    const invite_id = uuid().substring(0, 10);
    const create_user = db.collection("user_invites").doc(invite_id);
    batch.set(create_user, {
      account_title: account.title,
      account_owner: account.account_email,
      account_id: account.id,
      invite_id: invite_id,
      created_at: new Date(),
      email: email,
      emailVerified: false,
      type: "invited",
      permissions: permissions,
    });

    // Update Account with Permissions
    // const update_account = db.collection("user_accounts").doc(account.id)
    // batch.update(update_account, {
    //     permissions: firebase.firestore.FieldValue.arrayUnion(pendingUser)
    // });

    return batch
      .commit()
      .then(() => {
        console.log("Success");
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
};

// Upload Audio Assets
export const uploadAudioAssets = (file, type, game_doc, unique_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const user_account = getState().firebase.profile.account_id;

    // Create a root reference
    var storageRef = firebase.storage().ref();

    // Create the file metadata
    var metadata = {
      cacheControl: "private,max-age=86400",
      customMetadata: {
        location: "Yosemite, CA, USA",
        activity: "Hiking",
      },
    };

    console.log("file", file);

    const fileName = file.name + "_" + uuid().substring(0, 10);

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("gamemaster/" + user_account + "/" + game_doc + "/" + fileName)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        // Dispatch - Trigger Upload Progress Bar
        dispatch({
          type: "UPLOAD_AUDIO_PROGRESS",
          file_type: type,
          progress: progress,
        });

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);

          db.collection("gamemaster")
            .doc(game_doc)
            .collection("assets")
            .doc(type)
            .set({
              url: downloadURL,
              name: file.name,
              type: file.type,
              volume: 1,
              fileName: fileName,
              id: unique_id,
            })
            .then(() => {
              dispatch({ type: "UPLOAD_AUDIO_PROGRESS", progress: 0 });
            })
            .catch((error) => {
              console.log(error);
              //dispatch({type: "SAVE_GAME_SETTINGS_ERROR", error})
            });
        });
      }
    );
  };
};

export const deleteFile = (game_doc, type, file) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const user_account = getState().firebase.profile.account_id;

    console.log("FILE", file);

    const fileName = file.fileName ? file.fileName : file.name;

    // Create a root reference
    var storageRef = firebase.storage().ref();
    var ref = storageRef.child(
      "gamemaster/" + user_account + "/" + game_doc + "/" + fileName
    );

    db.collection("gamemaster")
      .doc(game_doc)
      .collection("assets")
      .doc(type)
      .delete()
      .then(() => {
        //dispatch({type: "SAVE_GAME_SETTINGS"})
        // Delete the file
        ref
          .delete()
          .then(() => {
            // File deleted successfully
            console.log("Success delete file");
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
        //dispatch({type: "SAVE_GAME_SETTINGS_ERROR", error})
      });
  };
};

// User Profile Settings
export const uploadUserProfilePic = (file) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const user_account = getState().firebase.profile.account_id;

    // Create a root reference
    var storageRef = firebase.storage().ref();

    // Create the file metadata
    var metadata = {
      cacheControl: "private,max-age=86400",
    };

    console.log("file", file);

    const fileName = "profile_pic";

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child("gamemaster/" + user_account + "/" + fileName)
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        // Dispatch - Trigger Upload Progress Bar
        dispatch({
          type: "UPLOAD_PROFILE_PIC_PROGRESS",
          file_type: file.type,
          progress: progress,
        });

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);

          firebase
            .auth()
            .currentUser.updateProfile({
              photoURL: downloadURL,
            })
            .then(() => {
              // Update successful
              dispatch({ type: "UPLOAD_PROFILE_PIC_PROGRESS", progress: 0 });
            })
            .catch((error) => {
              // An error occurred
              console.log(error);
            });
        });
      }
    );
  };
};

export const createAccount = (settings) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const user_account = getState().firebase.profile.account_id;
    const profile = getState().firebase.profile;
    const uid = getState().firebase.auth.uid;


    db.collection('user_accounts').doc(user_account).set({
        admins: [uid],
        staff: [],
        company_title: settings.companyInfo.name,
        company_size: settings.companyInfo.size,
        company_type: settings.companyInfo.type,
        location: {
          city: settings.companyInfo.city,
          googleMapLink: settings.companyInfo.googleMapLink,
          full_location: settings.companyInfo.location,
          place_id: settings.companyInfo.place_id
        },
        subscription: {
          customer_id: "",
        },
        account_email: profile.email,
        created_at: new Date(),
        permissions: [
          {
            user_id: uid,
            isAdmin: true,
            role: "Admin",
            canEditHints: true,
            canEditPuzzles: true,
            canEditLiveWindow: true,
          },
        ],
        onboarding: { gm: false },
      })

          
      .then(() => {
        //dispatch({ type: "SAVE_ACCOUNT_SETTINGS" });
      })
      .catch((error) => {
        console.log(error);
        //dispatch({ type: "SAVE_ACCOUNT_SETTINGS_ERROR", error });
      });
  };
};
