import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Rating from 'react-rating'

import IconButton from '@mui/material/IconButton';
import Backspace from '@mui/icons-material/Backspace';

import { updateClue } from '../../../../store/actions/gmActions'

const EditDeleteClueModal = (props) => {

   const { loadedClue, gm_id } = props;
   
    const [update, setUpdate] = useState({
        message: loadedClue.message ? loadedClue.message : '',
        rating: loadedClue.rating >=0 ? loadedClue.rating : 0
      });

      
    
    useEffect(() => {
        setUpdate({
          message: loadedClue.message,
          rating: loadedClue.rating ? loadedClue.rating : 0
        });
      }, [loadedClue]);

      const updateData = (e) => {
        setUpdate({
          ...update,
          [e.target.id]: e.target.value
        })
      };

      const updateRating = (rating) => {
        setUpdate({
          ...update,
          rating
        })
      };

      const handleUpdateClue = () => {
        props.updateClue(gm_id, loadedClue.puzzle_doc_id, loadedClue.id, update)
        setUpdate({})
      }

      const handleDeleteClue = () => {
        props.updateClue(gm_id, loadedClue.puzzle_doc_id, loadedClue.id, update, 'delete')
        setUpdate({})
      }

    return (
        <div className="modal effect-scale" id="editDeleteClueModal" tabIndex="-1" role="dialog" aria-hidden="true">

      <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
        <div className="modal-content">
          <div className="modal-body">

        <p className='text-lg mb-1 text-white font-medium'>Edit clue</p>

            <div className="flex flex-col">
              <label className="label-dark">Message</label>
              {/* <input onChange={(e) => updateData("message", e.target.value)} type="text" className="form-control" value={update.message}></input> */}
              <input onChange={(e)=> updateData(e)} type="text" id='message' className="input-dark" value={update.message}></input>
            </div>

            {/* <div className="form-group">
              <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Rating</label>
              <div style={{display:'flex', alignItems:'center'}}>
              <Rating 
                placeholderRating={update.rating} 
                emptySymbol={<i className='icon ion-ios-radio-button-off' style={{fontSize:'22px', marginRight:'1px'}}></i>} 
                fullSymbol={<i className='icon ion-ios-radio-button-on' style={{fontSize:'22px', color: 'rgb(236 236 88)'}}></i>} 
                placeholderSymbol={<i className='icon ion-ios-radio-button-on' style={{fontSize:'22px', color: 'rgb(236 236 88)'}}></i>} 
                onChange={(rating) => updateRating(rating)}
            />

          {update.rating >= 1 && (
            <IconButton onClick={() => updateRating(0)} aria-label="add" size="small" style={{marginLeft:'20px'}}>    
            <Backspace 
            fontSize="small" 
            style={{color:'#9f9793'}}
             />
          </IconButton>
          )}

              </div>
            </div> */}

            



        

            
          </div>
          <div className="modal-footer-dark">
            <div className='flex justify-between'>
            <button type="button" className="button-outline-dark" data-dismiss="modal"
            style={{marginRight:'auto'}}
            onClick={() => handleDeleteClue()}>Delete clue</button>
            <div className='flex'>
              <button type="button" className="button-outline-dark mr-2" data-dismiss="modal">Cancel</button>
              <button type="button" onClick={() => handleUpdateClue()} className="button-blue-dark" data-dismiss="modal">Save clue</button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
        updateClue: (doc_id, puzzle_id, clue_id, update, type) => dispatch(updateClue(doc_id, puzzle_id, clue_id, update, type))
    }
}

export default connect(null, mapDispatchToProps)(EditDeleteClueModal)
