import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { audio_control } from "../../../store/actions/gmActions";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { toast } from "sonner";
import { Howler } from "howler";

import {
  updateTimer,
  completeGame,
  updateClueOnScreenCount,
} from "../../../store/actions/timerActions";
import { loadStateFromLocalStorage } from "../../../localStorage";
import {
  remoteEditTimerIsRunning,
  remoteAdjustTimer,
} from "../../../store/actions/remoteActions";

// Sentry.io
import * as Sentry from "@sentry/react";

import GameCompleteModal from "./modals/GameCompleteModal";
import AdjustTimer from "./AdjustTimer";
import StartButton from "./CustomComponents/StartButton";
import StartButtonNew from "./CustomComponents/StartButtonNew";
import StopButtonNew from "./CustomComponents/StopButtonNew";

import GameDetailsSection from "./Sections/GameDetailsSection";
import PlayVideoBriefSection from "./Sections/PlayVideoBriefSection";
import PostGameReport from "./PostGameReport/PostGameReport";

import { get, createStore } from "idb-keyval";

import VideoAssetsBox from "./VideoAssetsBox";
import ElapsedTime from "./CustomComponents/ElapsedTime";

import { classNames } from "../../../utils/Utils";
import ThreeClues from "./ThreeClues";

export class CountdownTimer extends Component {
  constructor(props) {
    super();

    // ADD STATE ITEMS TO TIMER REDUCER
    this.state = {
      // time: {
      //   m: "00",
      //   s: "00",
      // },
      // seconds: 0,
      // startingSeconds: 0,
      // timerRunning: false,
      // gameOver: false,
      // gameStarted: false,
      // timerSpeed: 1000,
      // gameComplete: false,
      // videoIsPlaying: false,
      // loopAudioSoundtrack: true,
      // isVideoBrief: false,
      // estimatedFinshTime: "",
      // clueOnScreenCount: { 0: true, 1: true, 2: true },
      // isInfiniteClues: false,
      // interval: 1000, // was 1000 as of July 3, 2021
      // clock_halfTime: false,
      // expected: Date.now() + 1000,
      // gameIsReady: true,
      // videoBriefExists: false,
      // puzzlesDoneLength: 0,
      // openEndGameModal: false,
      // active_players: 0,
      // team_name: null
    };

    //this.liveWindow = null;
    this.timer = 0;
    this.gameStartedAt = null;
    this.gameFinishedAt = null;
    this.expected = Date.now() + 1000;
    this.expected_end = "";
    this.adjusted_end = "";
    this.startTimer = this.startTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.handleAdjustTimer = this.handleAdjustTimer.bind(this);
    this.handleStartVideoBrief = this.handleStartVideoBrief.bind(this);
    this.handleCompleteGame = this.handleCompleteGame.bind(this);
    this.handleFailGame = this.handleFailGame.bind(this);
    this.handleResetGame = this.handleResetGame.bind(this);
    this.videoHasFinished = this.videoHasFinished.bind(this);
    this.countDown2 = this.countDown2.bind(this);
  }

  togglePlaySoundtrack = () => {
    if (this.state.audio_soundtrack) {
      this.state.audio_soundtrack.volume = 0.5;
      this.state.audio_soundtrack.play();

      if (this.state.loopAudioSoundtrack) {
        this.state.audio_soundtrack.addEventListener(
          "ended",
          this.togglePlaySoundtrack,
          true
        );
      }
    }
  };

  secondsToTime(secs) {
    // let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = Math.floor(secs % (120 * 60));

    let minutes = Math.floor(divisor_for_minutes / 60);
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    //let seconds = Math.floor(divisor_for_seconds);
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    let obj = {
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentDidMount() {
    this.setState({
      timerRunning: false,
      gameStarted: false,
    });

    const game_id = this.props.id;
    const assetsStore = createStore(game_id, "assets");

    // if (this.props.game_assets.video_brief){

    //   this.props.updateTimer({
    //     ...this.props.timer,
    //     videoBriefExists: true,
    //   })
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.timer !== prevProps.timer) {
      this.setState({
        ...this.props.timer,
      });

      //T-663 Build Automation system
      //this.check_automation()
    }

    if (this.props.timer.video_brief !== prevProps.timer.video_brief) {
      if (this.props.timer.video_brief.url) {
        this.props.updateTimer({
          ...this.props.timer,
          videoBriefExists: true,
        });
      } else {
        this.props.updateTimer({
          ...this.props.timer,
          videoBriefExists: false,
        });
      }
    }

    if (this.props.gameMaster.assets !== prevProps.gameMaster.assets) {
      this.props.updateTimer({
        ...this.props.timer,
        video_brief: this.props.gameMaster.assets.video_brief,
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    Sentry.configureScope((scope) => scope.clear());
  }

  // Build Automation System
  check_automation = () => {
    const { time } = this.state;
    const currentTime = time.m + ":" + time.s;

    const { assets } = this.props.gameMaster;

    const automations = [
      { time: "59:57", type: "send_message", message: "Hello" },
      { time: "59:55", type: "send_message", message: "Okay" },
      { time: "59:53", type: "send_message", message: "Awesome" },
      { time: "59:50", type: "play_sound", sound_id: "audio_alert" },
      { time: "59:48", type: "send_message", message: "Radical" },
      { time: "59:46", type: "send_message", message: "epic" },
      { time: "59:44", type: "send_message", message: "no way" },
      { time: "59:30", type: "send_message", message: "last one" },
    ];

    const rules = [
      {
        trigger_type: "timer",
        operator: "===",
        target: "59:57",
        event_payload: {
          type: "send_hint",
          message: "59 Minutes Left...",
        },
      },
      {
        trigger_type: "onCompleteGame",
        event_payload: {
          type: "play_audio",
          destination:
            "https://firebasestorage.googleapis.com/v0/b/escaperoomwaiver-v2.appspot.com/o/gamemaster%2Ft7NnbAu4lokByhzZr85X%2Fhudztester-room-3-b3156d-1ba%2FEarthquake%2C%20Breaking%20Buildings%2C%20Rocks%20Falling.mp3?alt=media&token=2bfe3c9a-ea9d-4fbb-b4fe-a2c4423997bf",
        },
      },
      {
        trigger_type: "onCompleteGame",
        event_payload: {
          type: "set_background_color",
          destination: "#fff",
        },
      },
    ];

    rules.forEach((rule) => {
      if (rule.trigger_type === "timer") {
        const { operator, target, event_payload } = rule;

        if (target === currentTime) {
          if (event_payload.type === "send_hint") {
            this.props.pushToMessagesFeed("automation", rule.message);
          }
        }
      }

      if (rule.trigger_type === "onCompleteGame") {
        if (this.state.gameComplete) {
          const { event_payload } = rule;
          if (event_payload.type === "set_background_color") {
          }
        }
      }

      // if (automation.time === currentTime){
      //     if (automation.type === "send_message"){
      //       this.props.pushToMessagesFeed('automation', automation.message)
      //     } else if (automation.type === "play_sound"){
      //         assets[automation.sound_id].sound.play()
      //     }
      // }
    });
  };

  startTimer() {
    if (!this.props.remoteLiveWindow) {
      if (!this.state.timerRunning) {
        if (this.timer >= 0 && this.props.timer.seconds > 0) {
          //this.timer = setInterval(this.countDown, 1000);

          console.log("test");

          if (!this.gameStartedAt) {
            this.expected = Date.now() + this.state.interval;
            this.gameStartedAt = Date.now();
          }

          if (this.state.clock_halfTime) {
            const secsDouble = this.state.seconds * 2;
            this.expected_end = Date.now() + secsDouble * 1000;
          }

          if (this.state.clock_doubleTime) {
            const secsHalf = this.state.seconds / 2;
            this.expected_end = Date.now() + secsHalf * 1000;
          }

          if (!this.state.clock_doubleTime && !this.state.clock_halfTime) {
            this.expected_end = Date.now() + this.state.seconds * 1000;
          }

          console.log(
            "start expected end",
            moment(this.expected_end).format("LT")
          );

          // Start Countdown (1000 milliseconds to start *not interval time)
          this.timer = setTimeout(this.countDown2, this.state.interval);

          if (
            this.props.timer.time.m === this.props.timer.startingTime.m &&
            this.props.timer.time.s === this.props.timer.startingTime.s
          ) {
            this.props.handleStartTimerMessage("timer-start");
          }

          if (
            this.props.gameMaster.assets.audio_soundtrack.sound &&
            !this.props.gameMaster.assets.audio_soundtrack.sound.playing()
          ) {
            this.props.gameMaster.assets.audio_soundtrack.sound.play();
          }

          // this.setState({
          //   ...this.state,
          // });

          this.props.updateTimer({
            ...this.props.timer,
            timerRunning: true,
            gameStarted: true,
          });
        }
      }
      // this.setState({
      //   timerRunning: true,
      //   gameIsReady: true,
      //   gameStarted: true
      // });
      this.props.updateTimer({
        ...this.props.timer,
        timerRunning: true,
        gameIsReady: true,
        gameStarted: true,
      });
    } else {
      if (this.timer >= 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, this.state.interval);

        if (
          this.props.gameMaster.assets.audio_soundtrack.sound &&
          !this.props.gameMaster.assets.audio_soundtrack.sound.playing()
        ) {
          this.props.gameMaster.assets.audio_soundtrack.sound.play();
        }
      }

      this.props.remoteEditTimerIsRunning(true, this.props.id);
    }
  }

  pauseTimer() {
    if (!this.props.remoteLiveWindow) {
      //this.setState({ timerRunning: false });
      this.props.updateTimer({
        ...this.props.timer,
        timerRunning: false,
      });
      clearTimeout(this.timer);
    } else {
      this.props.remoteEditTimerIsRunning(false, this.props.id);
      clearInterval(this.timer);
    }
  }

  setClockHalfTime() {
    if (!this.state.clock_halfTime) {
      const secsDouble = this.state.seconds * 2;
      this.expected_end = Date.now() + secsDouble * 1000;
      this.props.updateTimer({
        ...this.props.timer,
        interval: 2000,
        clock_doubleTime: false,
        clock_halfTime: true,
      });
    } else {
      this.expected_end = Date.now() + this.state.seconds * 1000;
      this.props.updateTimer({
        ...this.props.timer,
        interval: 1000,
        clock_halfTime: false,
      });
    }
  }

  setClockDoubleTime() {
    if (!this.state.clock_doubleTime) {
      const secsHalf = this.state.seconds / 2;
      this.expected_end = Date.now() + secsHalf * 1000;
      this.props.updateTimer({
        ...this.props.timer,
        interval: 500,
        clock_halfTime: false,
        clock_doubleTime: true,
      });
    } else {
      this.expected_end = Date.now() + this.state.seconds * 1000;
      this.props.updateTimer({
        ...this.props.timer,
        interval: 1000,
        clock_doubleTime: false,
      });
    }
  }

  countDown2() {
    const interval = this.state.interval;

    // Check if Clock is 1/2 speed
    if (this.state.clock_halfTime) {
      const secsDouble = this.state.seconds * 2;
      this.adjusted_end = Date.now() + (secsDouble - 1) * 1000;
      //console.log("1/2 speed", moment(this.adjusted_end).format("LT"));
    }
    // Check if Clock is 2x speed
    if (this.state.clock_doubleTime) {
      const secsHalf = this.state.seconds / 2;
      this.adjusted_end = Date.now() + (secsHalf - 1) * 1000;
      //console.log("2x speed", moment(this.adjusted_end).format("LT"));
    }
    // Check if Clock is Normal speed
    if (!this.state.clock_doubleTime && !this.state.clock_halfTime) {
      this.adjusted_end = Date.now() + (this.state.seconds - 1) * 1000;
      // console.log("normal speed", moment(this.adjusted_end).format("LT"));
    }

    // console.log('clock_halfTime', this.state.clock_halfTime)
    // console.log('expected end', moment(this.expected_end).format('LT'))

    var dt = 0; // the drift (positive for overshooting)
    if (this.adjusted_end > this.expected_end) {
      if (this.adjusted_end - this.expected_end > 100)
        dt = this.adjusted_end - this.expected_end;
    }

    let milliseconds = this.state.seconds * 1000;
    let timeWithDrift = milliseconds - dt;

    let seconds = (timeWithDrift - 1000) / 1000;
    //let seconds = this.state.seconds - 1;
    if (seconds < 1) seconds = 0;

    const currentTime = new Date();
    let minutes = seconds / 60;

    console.log("interval w/drift", interval - dt);

    // this.setState({
    //   time: this.secondsToTime(seconds),
    //   seconds: seconds,
    // });

    this.props.updateTimer({
      ...this.props.timer,
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // document.title =
    //   this.state.time.m +
    //   ":" +
    //   this.state.time.s +
    //   " - " +
    //   this.props.game_name;

    // Check if we're at zero.
    if (seconds < 1) {
      //clearInterval(this.timer);
      clearTimeout(this.timer);
      //this.setState({ timerRunning: false, gameOver: true });
      this.props.updateTimer({
        ...this.state,
        timerRunning: false,
        gameOver: true,
      });
      this.handleFailGame();
    } else {
      //this.expected += interval;
      //this.timer = setTimeout(this.countDown2, Math.max(0, interval - dt))
      this.timer = setTimeout(this.countDown2, interval - dt);
    }
  }

  countDown() {
    if (!this.props.remoteLiveWindow) {
      let seconds = this.state.seconds - 1;
      const currentTime = new Date();
      let minutes = seconds / 60;

      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
        estimatedFinshTime: currentTime.setMinutes(
          currentTime.getMinutes() + minutes
        ),
      });

      this.props.updateTimer({ ...this.state });

      // Check if we're at zero.
      if (seconds <= 0) {
        clearInterval(this.timer);
        this.setState({ timerRunning: false, gameOver: true });
        this.props.updateTimer({ ...this.state });
        this.handleFailGame();
      }
    } else {
      let seconds = this.state.seconds - 1;
      const currentTime = new Date();
      let minutes = seconds / 60;

      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
        estimatedFinshTime: currentTime.setMinutes(
          currentTime.getMinutes() + minutes
        ),
      });

      // Check if we're at zero.
      if (seconds <= 0) {
        clearInterval(this.timer);
        //this.setState({endingText: this.state.endingTextFail})
        this.handleFailGame();
      }
    }
  }

  handleAdjustTimer = (adjusted_time) => {
    const newTime = Number(adjusted_time);
    const newTimeToSeconds = newTime * 60;
    const seconds = Number(this.state.seconds) + newTimeToSeconds;

    this.props.handleAddedMinutes(newTime);

    if (this.state.clock_halfTime) {
      const secsDouble = this.state.seconds * 2;
      this.expected_end = Date.now() + secsDouble * 1000;
    }

    if (this.state.clock_doubleTime) {
      const secsHalf = this.state.seconds / 2;
      this.expected_end = Date.now() + secsHalf * 1000;
    }

    if (!this.state.clock_doubleTime && !this.state.clock_halfTime) {
      this.expected_end = Date.now() + this.state.seconds * 1000;
    }

    if (this.props.remoteLiveWindow) {
      this.props.remoteAdjustTimer(newTime, this.props.id);
    } else {
      this.props.updateTimer({
        ...this.props.timer,
        time: this.secondsToTime(seconds),
        seconds: seconds,
      });

      var message;
      if (newTime >= 0) {
        const unit = newTime === 1 ? "minute" : "minutes";
        message = newTime + " " + unit + " to";
      } else {
        const unit = newTime >= -1 ? "minute" : "minutes";
        message = newTime + " " + unit + " from";
      }
      this.props.handleTimerAdjustMessage("timer-adjust", message, newTime);
    }
  };

  handleCompleteGame = (e) => {
    const { assets } = this.props.gameMaster;

    // Stop all sounds
    Howler.stop();

    // Stop Soundtrack
    // if (assets.audio_soundtrack.sound) assets.audio_soundtrack.sound.stop();
    // Play Success Track
    if (assets.audio_success.sound) assets.audio_success.sound.play();

    // this.setState({
    //   timerRunning: false,
    //   gameComplete: true,
    //   gameSuccess: true,
    //   gameFail: false,
    //   didWinGame: true });

    clearTimeout(this.timer);
    this.gameFinishedAt = Date.now();

    // Send Game Complete Notification
    this.props.handleEndGameMessage("game-ended", "complete");

    // Update Timer Props
    this.props.updateTimer({
      ...this.props.timer,
      time: this.state.time,
      seconds: this.state.seconds,
      estimatedFinshTime: this.state.estimatedFinshTime,
      timerRunning: false,
      gameComplete: true,
      gameSuccess: true,
      gameFail: false,
      endingText: "Success!",
      didWinGame: true,
      gameIsReady: false,
      gameStartedAt: 0,
    });
  };

  handleFailGame = () => {
    const { assets } = this.props.gameMaster;
    // Stop all sounds
    Howler.stop();

    // Stop Soundtrack
    // if (assets.audio_soundtrack.sound) assets.audio_soundtrack.sound.stop();
    // Play Fail Track
    // if (assets.audio_fail.sound) assets.audio_fail.sound.play();

    // this.setState({
    //   timerRunning: false,
    //   gameComplete: true,
    //   gameSuccess: false,
    //   gameFail: true,
    //   didWinGame: false
    // });

    clearTimeout(this.timer);
    this.gameFinishedAt = Date.now();

    // Send Game Complete Notification
    this.props.handleEndGameMessage("game-ended", "fail");

    this.props.updateTimer({
      ...this.props.timer,
      time: this.state.time,
      seconds: this.state.seconds,
      estimatedFinshTime: this.state.estimatedFinshTime,
      timerRunning: false,
      gameComplete: true,
      gameSuccess: false,
      gameFail: true,
      endingText: "Game Over",
      didWinGame: false,
      gameIsReady: false,
      gameOver: true,
    });
  };

  handleResetGame = (type) => {
    // Reset Timer
    clearInterval(this.timer);

    this.gameStartedAt = null;
    this.gameFinishedAt = null;

    const localStorage = loadStateFromLocalStorage();

    this.props.updateTimer({
      ...this.props.timer,
      time: localStorage.timer.startingTime,
      seconds: localStorage.timer.startingSeconds,
      timerRunning: false,
      gameOver: false,
      timerSpeed: 1000,
      gameComplete: false,
      gameSuccess: false,
      gameFail: false,
      clueOnScreen: "",
      estimatedFinshTime: null,
      didWinGame: false,
      gameStarted: false,
      clock_doubleTime: false,
      clock_halfTime: false,
    });

    this.props.handleResetChat();
    this.props.handleResetSentClues();
    this.props.updateClueOnScreenCount(this.props.timer.clueOnScreenCount);

    //Save game data
    if (navigator.onLine && type === "saveToDB") {
      console.log("******RESET GAME", this.state.gameComplete);
      const save_data = {
        timeRemaining: this.state.time,
        didWinGame: this.state.didWinGame,
        active_players: this.props.gameDetails.active_players,
        team_name: this.props.gameDetails.team_name,
        notes: this.props.gameDetails.notes,
      };

      this.props.handleSaveGameData(save_data);
    }

    // Stop all sounds
    Howler.stop();

    // const { assets } = this.props.gameMaster;

    // if (assets) {
    //   // Stop Soundtrack
    //   if (assets.audio_soundtrack.sound) assets.audio_soundtrack.sound.stop();
    //   // Stop Fail
    //   if (assets.audio_fail.sound) assets.audio_fail.sound.stop();
    //   // Stop Alert
    //   if (assets.audio_alert.sound) assets.audio_alert.sound.stop();
    //   // Stop Success
    //   if (assets.audio_success.sound) assets.audio_success.sound.stop();
    // }

    // Reset Start Button
    // setTimeout(() => {
    //   this.setState({
    //     gameIsReady: true
    //   })
    // }, 1000);
  };

  handleStartVideoBrief = () => {
    if (this.props.gameMaster.assets.audio_soundtrack.sound) {
      this.props.gameMaster.assets.audio_soundtrack.sound.pause();
    }

    if (this.props.gameMaster.assets.audio_fail.sound) {
      this.props.gameMaster.assets.audio_fail.sound.pause();
    }

    if (this.props.gameMaster.assets.audio_success.sound) {
      this.props.gameMaster.assets.audio_success.sound.pause();
    }

    //this.props.handlePlayVideoMessage("video-play");

    this.props.updateTimer({
      ...this.props.timer,
      videoIsPlaying: true,
    });

    this.setState({
      videoIsPlaying: true,
      gameStartedAt: Date.now(),
    });

    window.addEventListener("storage", this.videoHasFinished);
  };

  videoHasFinished(e) {
    const localStorage = JSON.parse(e.storageArea.state);

    if (!localStorage.timer.videoIsPlaying) {
      this.setState({
        videoIsPlaying: false,
      });

      this.props.updateTimer({
        ...this.props.timer,
        videoIsPlaying: false,
      });

      this.startTimer();

      window.removeEventListener("storage", this.videoHasFinished);
    }
  }

  handleStopVideoBrief = () => {
    window.removeEventListener("storage", window);

    this.props.updateTimer({
      ...this.props.timer,
      videoIsPlaying: false,
    });

    this.setState({
      videoIsPlaying: false,
    });
  };

  openLiveWindow = () => {
    const { id } = this.props;
    var params = "width=" + (window.screen.width - 200);
    params += ", height=" + (window.screen.height - 200);
    params += ", left=1000";
    params += ", menubar=1";

    this.props.updateTimer({
      ...this.props.timer,
      isPlayerWindowOpen: true,
    });

    this.liveWindow = window.open("/r/" + id + "/live", "windowname4", params);
  };

  render() {
    const {
      timerRunning,
      time,
      gameComplete,
      videoIsPlaying,
      video_brief,
      gameIsReady,
      gameStartedAt,
      //estimatedFinshTime
      puzzlesDoneLength,
      expandDetailsSection,
    } = this.state;

    const { messages, puzzlesDone, puzzles, hintsUsed, game_name } = this.props;

    Sentry.setContext("countdown timer state", {
      ...this.props.timer,
    });

    const calculateLength = () => {
      //const startingSeconds = this.state.startingSeconds;
      //const seconds = this.state.seconds;

      const done = puzzlesDone ? puzzlesDone.length : 0;
      const total = puzzles ? puzzles.length : 0;

      //const calculation = (seconds / startingSeconds) * 100;
      const calculation = (done / total) * 100;
      return calculation.toFixed(0);
    };

    const showCompleteButton = () => {
      if (timerRunning && calculateLength() > 99) {
        return (
          <a
            id="completeGameButton"
            href="#gameCompleteModal"
            data-toggle="modal"
            onClick={this.handleCompleteGame}
            className="btn btn-purple w-100"
            style={{
              marginRight: "10px",
              fontSize: "14px",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            <i
              class="fa-solid fa-trophy-star"
              style={{ marginRight: "8px" }}
            ></i>
            Finish Room
          </a>
        );
      } else if (timerRunning) {
        return (
          <a
            id="completeGameButton"
            href="#gameCompleteModal"
            data-toggle="modal"
            onClick={this.handleCompleteGame}
            className="btn btn-purple w-100"
            style={{
              marginRight: "10px",
              fontSize: "14px",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            <i
              class="fa-solid fa-trophy-star"
              style={{ marginRight: "8px" }}
            ></i>
            Finish Room
          </a>
        );
      } else if (!timerRunning && gameComplete) {
        return (
          <button
            id="completeGameButton"
            type="button"
            disabled
            className="btn btn-dark w-100 disabled"
            style={{
              marginRight: "10px",
              fontSize: "14px",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            <i
              class="fa-solid fa-trophy-star"
              style={{ marginRight: "8px" }}
            ></i>
            Finish Room
          </button>
        );
      } else if (!timerRunning) {
        return (
          <button
            id="completeGameButton"
            type="button"
            disabled
            className="btn btn-dark w-100"
            style={{
              marginRight: "10px",
              fontSize: "14px",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            {/* <i
              className="icon ion-ios-flash"
              style={{ marginRight: "10px" }}
            ></i> */}
            <i
              class="fa-solid fa-trophy-star"
              style={{ marginRight: "8px" }}
            ></i>
            Finish Room
          </button>
        );
      }
    };

    const ShowVideoButton = (props) => {
      const { videoBriefExists } = props.state;

      if (gameComplete) {
        return (
          <button
            disabled
            type="button"
            className="btn btn-outline-dark w-100 disabled"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            <i
              className="icon ion-ios-play"
              style={{ marginRight: "10px" }}
            ></i>
            Start Game with Video
          </button>
        );
      } else if (videoBriefExists && video_brief && !videoIsPlaying) {
        return (
          <button
            onClick={this.handleStartVideoBrief}
            type="button"
            className="btn btn-dark w-100"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            <i
              className="icon ion-ios-play-circle"
              style={{ marginRight: "10px", color: "white" }}
            ></i>
            Play Video Brief
          </button>
        );
      } else if (videoBriefExists && video_brief && videoIsPlaying) {
        return (
          <button
            onClick={this.handleStopVideoBrief}
            type="button"
            className="btn btn-danger w-100"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            <i
              className="icon ion-md-stop"
              style={{ marginRight: "10px", color: "white" }}
            ></i>
            Stop Video
          </button>
        );
      } else if (!videoBriefExists || !video_brief) {
        return (
          <button
            disabled
            type="button"
            className="btn btn-outline-dark w-100"
            style={{ fontSize: "14px", fontWeight: "600" }}
          >
            <i
              className="icon ion-ios-play-circle"
              style={{ marginRight: "10px" }}
            ></i>
            Play Video Brief
          </button>
        );
      }
    };

    const showResetButton = () => {
      if (gameComplete) {
        return (
          <button
            onClick={this.handleResetGame}
            type="button"
            className="btn btn-dark w-100"
            style={{
              marginTop: "10px",
            }}
          >
            <b>Reset Room</b>
          </button>
        );
      } else if (timerRunning) {
        return (
          <button
            onClick={this.handleResetGame}
            type="button"
            disabled
            className="btn btn-dark w-100"
            style={{
              marginTop: "10px",
            }}
          >
            <b>Reset Room</b>
          </button>
        );
      } else {
        return (
          <button
            onClick={this.handleResetGame}
            type="button"
            className="btn btn-dark w-100"
            style={{ marginTop: "10px" }}
          >
            <b>Reset Room</b>
          </button>
        );
      }
    };

    return (
      <React.Fragment>
        <div className="px-3 py-3 border-b border-v1_dark-hover" id="timer-box">
          {/* <div className={classNames(" flex justify-between items center")}>
            <div>
              <div className="flex items-center space-x-2">
                <p className="text-white font-medium">Timer</p>
              </div>
            </div>
          </div> */}

          <div className="card-body" style={{ padding: "0px" }}>
            <div style={{ textAlign: "center" }}>
              <div
                className={classNames(
                  "flex flex-col items-center justify-center bg-[rgb(47,48,54)] border border-v1_dark-hover p-1 rounded-sm mb-3 mt-0 shadow-sm"
                )}
              >
                <p className="text-gray-200 text-sm font-medium my-1">
                  Time Remaining
                </p>
                {timerRunning ? (
                  <p className="text-[65px] h-[70px] mb-2 flex items-center text-gray-200 space-x-3 tracking-tighter font-semibold">
                    <span>{time ? time.m : "00"}</span>
                    <span className="text-5xl">:</span>
                    <span>{time ? time.s : "00"}</span>
                  </p>
                ) : (
                  <p className="text-[65px] h-[70px] mb-2 flex items-center text-gray-200 space-x-3 tracking-tighter font-semibold">
                    <span>{time ? time.m : "00"}</span>
                    <span className="text-5xl">:</span>
                    <span>{time ? time.s : "00"}</span>
                  </p>
                )}

                <div className="flex items-center justify-center w-full space-x-4 mb-1 px-1">
                  <div className="flex items-center space-x-2">
                  <button
                    onClick={() => this.setClockHalfTime()}
                    className={classNames(
                      " duration-75 w-[40px] h-[25px] rounded-sm flex items-center justify-center focus:outline-none",
                      this.state.clock_halfTime
                        ? "bg-green-600 hover:bg-green-500"
                        : "bg-gray-700 hover:bg-gray-600"
                    )}
                  >
                    {/* <i class="fa-solid fa-backward text-gray-200"></i> */}
                    <span className="text-xs text-white ml-1 font-medium">
                      0.5x
                    </span>
                  </button>

                  <button
                    onClick={() => this.setClockDoubleTime()}
                    className={classNames(
                      " duration-75 w-[40px] h-[25px] rounded-sm flex items-center justify-center focus:outline-none",
                      this.state.clock_doubleTime
                        ? "bg-green-600 hover:bg-green-500"
                        : "bg-gray-700 hover:bg-gray-600"
                    )}
                  >
                    {/* <i class="fa-solid fa-forward text-gray-200"></i> */}
                    <span className="text-xs text-white ml-1 font-medium">
                      2x
                    </span>
                  </button>
                  </div>

                  {/* <AdjustTimer
                    handleAdjustTimer={this.handleAdjustTimer}
                    handleChangeTimerSpeed={this.handleChangeTimerSpeed}
                    startingSeconds={this.state.startingSeconds}
                    seconds={this.props.timer.seconds}
                  /> */}
                </div>
              </div>

              {/* 
              <div className="mb-3">
                <div className="flex items-center mb-2">
                  <p className="text-white font-medium">Progress</p>
                </div>
                <div
                  className="flex bg-gray-700 rounded-sm"
                  style={{
                    marginBottom: "0px",
                    width: "100%",
                    height: "5px",
                    // background: "rgb(221 221 221)",
                  }}
                >
                  <div
                    className="progress-bar rounded-sm"
                    style={{
                      width: calculateLength() + "%",
                      // background:'#ffc107' yellow background
                    }}
                    role="progressbar"
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div> */}

              <div>
                <div className="flex items-center justify-between mb-2 space-x-2">
                  <button
                    disabled={timerRunning ? true : false}
                    onClick={() => this.startTimer()}
                    className={classNames(
                      "w-full h-[40px] rounded-sm flex items-center justify-center duration-100 focus:outline-none bg-indigo-600 hover:bg-indigo-500 space-x-2 disabled:opacity-50"
                    )}
                  >
                    <svg
                      className="w-2 fill-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                    </svg>

                    <span className="text-white font-medium">Start</span>
                  </button>

                  <button
                    disabled={!timerRunning ? true : false}
                    onClick={() => this.pauseTimer()}
                    className={classNames(
                      "w-full h-[40px] rounded-sm flex items-center justify-center focus:outline-none bg-gray-500 hover:bg-gray-600 duration-100 space-x-2 disabled:opacity-50"
                    )}
                  >
                    {/* <svg
                          className="w-2 fill-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z" />
                        </svg> */}
                    <svg
                      className="w-2 fill-white"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                    </svg>
                    <span className="text-white font-medium">Stop</span>
                  </button>

                  <PostGameReport
                    game_name={game_name}
                    timerRunning={timerRunning}
                    handleCompleteGame={this.handleCompleteGame}
                    time={this.props.timer.time}
                    handleResetGame={this.handleResetGame}
                    messages={messages}
                    puzzles={puzzles}
                    puzzlesDone={puzzlesDone}
                    hintsUsed={hintsUsed}
                    addedMinutes={this.props.addedMinutes}
                    objectivesCompletePercentage={calculateLength()}
                    startingSeconds={this.state.startingSeconds}
                    gameStartedAt={this.gameStartedAt}
                    gameFinishedAt={this.gameFinishedAt}
                    gameFail={this.props.timer.gameFail}
                    gameComplete={this.props.timer.gameComplete}
                    gameDetails={this.props.gameDetails}
                  />

                  <div
                    className={classNames(
                      "w-full flex items-center space-x-2 mr-2 hidden"
                    )}
                  >
                    {!videoIsPlaying ? (
                      <Tooltip
                        title="Start timer with video"
                        placement="top"
                        enterDelay={500}
                        arrow
                      >
                        <button
                          onClick={() => this.handleStartVideoBrief()}
                          className={classNames(
                            "w-full h-[40px] rounded-sm flex items-center justify-center space-x-2 duration-100 focus:outline-none bg-green-700 hover:bg-green-600"
                          )}
                        >
                          <svg
                            className="w-2 fill-white"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                          >
                            <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z" />
                          </svg>
                          <span className="text-white font-medium">Video</span>
                        </button>
                      </Tooltip>
                    ) : (
                      <button
                        onClick={() => this.handleStopVideoBrief()}
                        className={classNames(
                          "w-full h-[40px] rounded-sm flex items-center justify-center focus:outline-none bg-red-600 hover:bg-red-500 duration-100"
                        )}
                      >
                        <svg
                          className="w-3 fill-white"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 384 512"
                        >
                          <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                        </svg>
                      </button>
                    )}
                  </div>

                  <div className="flex items-center space-x-2 col-span-1 hidden">
                    <button
                      onClick={() => this.setClockHalfTime()}
                      className={classNames(
                        " duration-75 w-full h-[40px] rounded-sm flex items-center justify-center focus:outline-none",
                        this.state.clock_halfTime
                          ? "bg-green-600 hover:bg-green-500"
                          : "bg-v1_dark-hover hover:bg-gray-700"
                      )}
                    >
                      {/* <i class="fa-solid fa-backward text-gray-200"></i> */}
                      <span className="text-xs text-white ml-1 font-medium">
                        0.5x
                      </span>
                    </button>

                    <button
                      onClick={() => this.setClockDoubleTime()}
                      className={classNames(
                        " duration-75 w-full h-[40px] rounded-sm flex items-center justify-center focus:outline-none",
                        this.state.clock_doubleTime
                          ? "bg-green-600 hover:bg-green-500"
                          : "bg-v1_dark-hover hover:bg-gray-700"
                      )}
                    >
                      {/* <i class="fa-solid fa-forward text-gray-200"></i> */}
                      <span className="text-xs text-white ml-1 font-medium">
                        2x
                      </span>
                    </button>
                  </div>
                </div>

                <div className="flex items-center justify-between space-x-2">
                  <Tooltip title="Reset game" placement="top" arrow>
                    <span className="w-full">
                      <button
                        onClick={() =>
                          timerRunning ? null : this.handleResetGame()
                        }
                        //disabled={timerRunning ? true : false}
                        className={classNames(
                          "bg-v1_dark-hover hover:bg-gray-700 duration-75 w-full h-[40px]  rounded-sm flex items-center justify-center space-x-2 focus:outline-none disabled:opacity-50",
                          timerRunning ? "opacity-50" : null
                        )}
                      >
                        <i class="fa-solid fa-clock-rotate-left text-gray-200"></i>
                        <span className="text-white font-medium">Reset</span>
                      </button>
                    </span>
                  </Tooltip>

                  <Tooltip title="Open live window" placement="top" arrow>
                    <button
                      onClick={this.openLiveWindow}
                      className={classNames(
                        "bg-v1_dark-hover hover:bg-gray-700 duration-75 w-full h-[40px]  rounded-sm flex items-center space-x-2 justify-center focus:outline-none"
                        // !this.state.isPlayerWindowOpen
                        //   ? "animate-pulse bg-gray-500 hover:bg-gray-500"
                        //   : "animate-none"
                      )}
                    >
                      {/* <i class="fa-solid fa-tv text-gray-200"></i> */}
                      <svg
                        className="w-4 fill-gray-200"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 576 512"
                      >
                        <path d="M480 96H96c-17.7 0-32 14.3-32 32V320c0 17.7 14.3 32 32 32h69.5l-50.7 50.7c-4 4-7.4 8.5-10.2 13.3H96c-53 0-96-43-96-96V128C0 75 43 32 96 32H480c53 0 96 43 96 96V320c0 53-43 96-96 96h-8.6c-2.8-4.8-6.2-9.2-10.2-13.3L410.5 352H480c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32zM265.4 297.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H160c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128z" />
                      </svg>
                      <span className="text-white font-medium">
                        Live window
                      </span>
                    </button>
                  </Tooltip>

                  {/* <PostGameReport
                    game_name={game_name}
                    timerRunning={timerRunning}
                    handleCompleteGame={this.handleCompleteGame}
                    time={this.props.timer.time}
                    handleResetGame={this.handleResetGame}
                    messages={messages}
                    puzzles={puzzles}
                    puzzlesDone={puzzlesDone}
                    hintsUsed={hintsUsed}
                    addedMinutes={this.props.addedMinutes}
                    objectivesCompletePercentage={calculateLength()}
                    startingSeconds={this.state.startingSeconds}
                    gameStartedAt={this.gameStartedAt}
                    gameFinishedAt={this.gameFinishedAt}
                    gameFail={this.props.timer.gameFail}
                    gameComplete={this.props.timer.gameComplete}
                    gameDetails={this.props.gameDetails}
                  /> */}
                </div>
              </div>

              <div
                className="hidden"
                style={{ marginBottom: "5px", textAlign: "center" }}
              >
                <StartButton
                  timerRunning={timerRunning}
                  videoIsPlaying={videoIsPlaying}
                  gameIsReady={gameIsReady}
                  startTimer={this.startTimer}
                  pauseTimer={this.pauseTimer}
                  gameComplete={gameComplete}
                />

                <ShowVideoButton state={this.state} />

                {showResetButton()}

                {/* <div
                  className="btn-group btn-group-sm w-100"
                  role="group"
                  aria-label="Basic example"
                  style={{ marginBottom: "0px" }}
                >
                  {!this.props.remoteLiveWindow ? (
                    <StartButton
                      timerRunning={timerRunning}
                      videoIsPlaying={videoIsPlaying}
                      gameIsReady={gameIsReady}
                      startTimer={this.startTimer}
                      pauseTimer={this.pauseTimer}
                      gameComplete={gameComplete}
                    />
                  ) : (
                    <StartButton
                      timerRunning={this.props.remote.timerRunning}
                      videoIsPlaying={this.props.remote.videoIsPlaying}
                      startTimer={this.startTimer}
                      pauseTimer={this.pauseTimer}
                      gameComplete={gameComplete}
                      videoButtonLoading={this.props.remote.videoButtonLoading}
                    />
                  )}
                  <ShowVideoButton />
                </div> */}

                {/* <div style={{marginTop:'10px'}}>
{showCompleteButton()}
</div> */}

                {/* <div className='col-6' style={{paddingLeft:'5px'}}>
                    <ShowVideoButton />
                  </div> */}
              </div>

              {/* <AdjustTimer
                handleAdjustTimer={this.handleAdjustTimer}
                handleChangeTimerSpeed={this.handleChangeTimerSpeed}
                startingSeconds={this.state.startingSeconds}
                seconds={this.props.timer.seconds}
              /> */}
            </div>
          </div>
        </div>

        <div className="px-3 py-3 border-b border-v1_dark-hover">
          {/* <div>{showCompleteButton()}</div> */}

          {/* <div style={{ textAlign: "left", marginTop: "20px" }}>
            <p className="text-gray-500 mb-1">
              Room Progress - {calculateLength()}%
            </p>
          </div>
          <div
            className="progress"
            style={{
              marginBottom: "0px",
              width: "100%",
              height: "10px",
              background: "rgb(221 221 221)",
            }}
          >
            <div
              className="progress-bar"
              style={{
                width: calculateLength() + "%",
                // background:'#ffc107' yellow background
              }}
              role="progressbar"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div> */}

          <div style={{ textAlign: "center" }}>
            <div className="row" style={{ marginBottom: "0px" }}>
              <div className="col-4" style={{ padding: "0px 3px" }}>
                <p className="mb-0 text-white text-lg font-medium">
                  {puzzlesDone ? puzzlesDone.length : 0}/
                  {puzzles ? puzzles.length : 0}
                </p>
                <p className="text-gray-500 text-xs">Puzzles done</p>
              </div>
              <div className="col-4" style={{ padding: "0px 3px" }}>
                <p className="mb-0 text-white text-lg font-medium">
                  {hintsUsed}
                </p>
                <p className="text-gray-500 text-xs">Messages sent</p>
              </div>
              <div className="col-4" style={{ padding: "0px 3px" }}>
                <p className="mb-0 text-white text-lg font-medium">
                  {this.props.addedMinutes}m
                </p>
                <p className="text-gray-500 text-xs">Time adjusted</p>
              </div>
            </div>
          </div>

          {/* <div
            className="btn-group btn-group-sm w-100"
            role="group"
            aria-label="Basic example"
            style={{ marginBottom: "0px" }}
          >
            <button
              id="openLiveWindow"
              onClick={this.openLiveWindow}
              type="button"
              className="btn btn-dark"
              style={{
                fontSize: "12px",
                padding: "0px 10px",
                height: "25px",
              }}
            >
              <b>Open Player Window</b>
              <i
                className="icon ion-md-open"
                style={{ marginLeft: "5px", color: "white" }}
              ></i>
            </button>
          </div> */}
        </div>
        {this.props.videoBriefExists && <PlayVideoBriefSection
          handleStartVideoBrief={this.handleStartVideoBrief}
          handleStopVideoBrief={this.handleStopVideoBrief}
          videoIsPlaying={videoIsPlaying}
        />}
        <GameDetailsSection gameStartedAt={this.gameStartedAt} />

        {/* <AdjustTimer
          handleAdjustTimer={this.handleAdjustTimer}
          handleChangeTimerSpeed={this.handleChangeTimerSpeed}
        /> */}

        {/* <div style={{ 
          marginBottom: "10px", 
          padding:'10px 20px 10px 20px', 
          borderBottom:'1px solid black' 
          }}>

        {showCompleteButton()}
          
        </div> */}

        {/* <div style={{ marginBottom: "10px", borderBottom:'1px solid black', padding:'0px 20px 10px 20px' }}>{showCompleteButton()}</div> */}

        {/* <AdjustTimer
          handleAdjustTimer={this.handleAdjustTimer}
          handleChangeTimerSpeed={this.handleChangeTimerSpeed}
        /> */}

        <GameCompleteModal
          time={time}
          handleResetGame={this.handleResetGame}
          messages={messages}
          puzzles={puzzles}
          puzzlesDone={puzzlesDone}
          hintsUsed={hintsUsed}
          addedMinutes={this.props.addedMinutes}
        />

        {/* <VideoAssetsBox /> */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  // const gm = state.firestore;
  // const id = gm ? gm[0].id : null

  return {
    gameMaster: state.gameMaster,
    timer: state.timer,
    remote: state.remote,
    gameDetails: state.gameDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTimer: (timer) => dispatch(updateTimer(timer)),
    audio_control: (type, audio) => dispatch(audio_control(type, audio)),
    completeGame: (state) => dispatch(completeGame(state)),
    remoteEditTimerIsRunning: (bool, doc) =>
      dispatch(remoteEditTimerIsRunning(bool, doc)),
    remoteAdjustTimer: (string, doc) =>
      dispatch(remoteAdjustTimer(string, doc)),
    updateClueOnScreenCount: (clueCountArray) =>
      dispatch(updateClueOnScreenCount(clueCountArray)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CountdownTimer);
