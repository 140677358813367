import React, {useState, useEffect} from "react";
import { connect } from "react-redux";

import { updateGameDetails } from "../../../../store/actions/gameDetailsActions";

import { classNames } from "../../../../utils/Utils";
import moment from "moment";

import TimeSlot from "./TimeSlot";

function GameDetailsSection(props) {

  const {gameStartedAt} = props;

    const [expandDetailsSection, setExpandDetailsSection] = useState(true)
    const [dateAndTime, setDateAndTime] = useState(null)

    // Details States
    const [activePlayers, setActivePlayers] = useState(props.gameDetails.activePlayers)
    const [totalPlayers, setTotalPlayers] = useState(props.gameDetails.totalPlayers)
    const [notes, setNotes] = useState(props.gameDetails.notes)
    const [timeSlot, setTimeSlot] = useState(()=> {
      var d = new Date();
      d.setMinutes (d.getMinutes() + 30);
      return d.setMinutes (0);
    })


    useEffect(()=> {
      setDateAndTime(gameStartedAt)
    }, [gameStartedAt])

    useEffect(()=> {
      try {
        const serializedState = localStorage.getItem('BOLT_preferences')

        if (serializedState === null){
            return localStorage.setItem('BOLT_preferences')
        }
        return setExpandDetailsSection(JSON.parse(serializedState).gameDetailsToggle)

    } catch (err){
        return false;
    }
    }, [])

    const ExpandSection = (bool) => {
      setExpandDetailsSection(bool)
      localStorage.setItem('BOLT_preferences', JSON.stringify({
        ...JSON.parse(localStorage.getItem('BOLT_preferences')),
        gameDetailsToggle: bool
      }))
    }


    const changeActivePlayers = (players) => {
      setActivePlayers(players)
      props.updateGameDetails({
        ...props.gameDetails,
        active_players : Number(players)
      })
    }

    const changeTotalPlayers = (players) => {
      setTotalPlayers(players)
      props.updateGameDetails({
        ...props.gameDetails,
        total_players : Number(players)
      })
    }

    const changeNotes = (notes) => {
      setNotes(notes)
      props.updateGameDetails({
        ...props.gameDetails,
        notes : notes
      })
    }

    // console.log('date and time', moment(dateAndTime).format('lll'))
      console.log('GAME STARTED', gameStartedAt)
  return (
    <div className="border-b border-v1_dark-hover">
      <button 
      onClick={() => ExpandSection(!expandDetailsSection)}
      className={classNames(
        "px-3 py-2 flex justify-between items center space-between w-full focus:outline-none duration-100 hover:bg-v1_dark-hover hover:bg-opacity-20"
      )}>
        <div>
          <div className="flex items-center space-x-2">
            <p className="text-white font-medium">Game details</p>
            {notes && <p className="text-green-500"> Notes</p>}
          </div>
        </div>

        <div className="flex items-center justify-center">
          {/* <DropdownSettings userPermissions={userPermissions} /> */}
          <button
            onClick={() =>
              ExpandSection(!expandDetailsSection)
            }
            className={classNames(
              "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75",
              !expandDetailsSection ? "rotate-[180deg]" : "rotate-[270deg]"
            )}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
        </div>
      </button>

      <div
        className={classNames("px-3 py-2 ", !expandDetailsSection ? "hidden" : "")}
      >
        <div className="grid grid-cols-2 space-x-2">
          <div className="mb-3 col-span-1">
            <p className="text-gray-400 text-xs mb-1.5">Active players</p>
            {/* <p className="text-white">8</p> */}
            <input
              onChange={(e)=>changeActivePlayers(e.target.value)}
              type="number"
              placeholder="0"
              className="w-full placeholder:text-gray-600 placeholder:text-xs text-white bg-v1_dark-sidebar focus:border-b focus:border-dotted focus:border-b-gray-600 focus:outline-none font-medium "
            ></input>
          </div>

          <div className="mb-3 col-span-1">
            <p className="text-gray-400 text-xs mb-1.5">Max players allowed</p>
            {/* <p className="text-white">8</p> */}
            <input
              onChange={(e)=>changeTotalPlayers(e.target.value)}
              type="number"
              placeholder="0"
              className="w-full placeholder:text-gray-600 placeholder:text-xs text-white bg-v1_dark-sidebar focus:border-b focus:border-dotted focus:border-b-gray-600 focus:outline-none font-medium "
            ></input>
          </div>

          {/* <div className="mb-3 col-span-1">
            <p className="text-gray-400 text-xs mb-2">Time slot</p>
            <TimeSlot timeSlot={timeSlot} />
          </div> */}
        </div>

        <div className="mb-3 ">
            <p className="text-gray-400 text-xs mb-1.5">Date & time</p>
            <p className="text-white font-medium">{dateAndTime ? moment(dateAndTime).format('lll') : "-"}</p>
            
          </div>

          <div className="mb-1">
            <p className="text-gray-400 text-xs mb-1.5">Notes</p>
            <textarea onChange={(e)=> changeNotes(e.target.value)} className=" bg-v1_dark-hover/50 focus:bg-v1_dark-hover/100 w-full rounded-sm resize-none focus:outline-none text-xs text-white py-1 px-2"></textarea>
            
          </div>

        {/* <div className="mb-3">
          <p className="text-gray-400 text-xs mb-2">Game master</p>
          <div className="flex items-center space-x-2">
            <div className="bg-gray-700 w-[35px] h-[35px] rounded-full flex items-center justify-center">
              <p className="text-gray-200 text-xs font-semibold">GM</p>
            </div>
            <div className="flex justify-center flex-col text-left items-start">
              <p className="text-white font-semibold mb-0">Hudson</p>
              <button className="text-xs text-blue-500 hover:text-blue-600 duration-75 focus:outline-none">
                Change
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    gameDetails: state.gameDetails
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGameDetails: (data) => dispatch(updateGameDetails(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GameDetailsSection);
