import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Howl } from "howler";

import { updateSound } from "../../../../store/actions/gmActions";

type Props = {
  loadedClue: {
    title: string;
    file: {
      url: string;
      name: string;
      size: number;
    };
    volume: number;
    puzzle_doc_id: string;
    id: string;
    audio_id: number;
    loop: boolean;
    duck_soundtrack: boolean;
  };
  gm_id: string;
  updateSound: (
    doc_id: string,
    puzzle_id: string,
    sound_obj: any,
    update: any,
    type: string
  ) => void;
};

const EditDeleteSoundModal = (props: Props) => {
  const { loadedClue, gm_id } = props;

  const [audio_id, setAudioId] = useState(0);

  const [update, setUpdate] = useState({
    title: "",
    file: {
      url: "",
      name: "",
      size: 0,
    },
    volume: 1,
    loop: false,
    duck_soundtrack: false,
  });

  useEffect(() => {
    setUpdate({
      title: loadedClue.title,
      file: loadedClue.file,
      volume: loadedClue.volume,
      loop: loadedClue.loop || false,
      duck_soundtrack: loadedClue.duck_soundtrack || false,
    });

    setAudioId(loadedClue.audio_id);
  }, [loadedClue]);

  const updateData = (e: any) => {
    setUpdate({
      ...update,
      [e.target.id]: e.target.value,
    });
  };

  const updateLoop = (e: any) => {
    setUpdate({
      ...update,
      loop: e.target.checked,
    });
  };

  const updateDuck = (e: any) => {
    setUpdate({
      ...update,
      duck_soundtrack: e.target.checked,
    });
  };

  const handleUpdateClue = () => {
    console.log('Update Sound!')
    props.updateSound(
      gm_id,
      loadedClue.puzzle_doc_id,
      loadedClue,
      update,
      "update"
    );
    setUpdate({
      title: "",
      file: {
        url: "",
        name: "",
        size: 0,
      },
      volume: 1,
      loop: false,
      duck_soundtrack: false,
    });
  };

  const handleDelete = () => {
    props.updateSound(
      gm_id,
      loadedClue.puzzle_doc_id,
      loadedClue,
      update,
      "delete"
    );
    setUpdate({
      title: "",
      file: {
        url: "",
        name: "",
        size: 0,
      },
      volume: 1,
      loop: false,
      duck_soundtrack: false,
    });
  };

  //   console.log("loaded clue", loadedClue);
  return (
    <div
      className="modal effect-scale"
      id="editDeleteSoundModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-lg mb-1 text-white font-medium">
              Sound settings
            </p>

            <div className="flex flex-col mb-3">
              <label className="label-dark">Name</label>
              {/* <input onChange={(e) => updateData("message", e.target.value)} type="text" className="form-control" value={update.message}></input> */}
              <input
                onChange={(e) => updateData(e)}
                type="text"
                id="title"
                className="input-dark"
                value={update.title}
              ></input>
            </div>

            <div className="mb-3">
              <label className="label-dark">Volume</label>

              <input
                onChange={(e) => updateData(e)}
                id="volume"
                type="range"
                min="0"
                max="100"
                value={update.volume}
                className="w-full h-2 rounded-lg"
                // className="w-full h-2 rounded-lg appearance-none cursor-pointer bg-gray-700"
              />
            </div>

            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <input
                  id="loop"
                  type="checkbox"
                  onChange={(e) => updateLoop(e)}
                  checked={update.loop}
                  className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                />
                <label className="label-dark mb-0 pb-0">Loop sound</label>
              </div>

              <div className="flex items-center">
                <input
                  id="duck_soundtrack"
                  type="checkbox"
                  onChange={(e) => updateDuck(e)}
                  checked={update.duck_soundtrack}
                  className="w-4 h-4 text-blue-600 rounded mr-2 focus:ring-0  bg-gray-700 border-gray-600"
                />
                <label className="label-dark mb-0 pb-0">Duck soundtrack</label>
              </div>
            </div>
          </div>
          <div className="modal-footer-dark">
            <div className="flex justify-between">
              <button
                type="button"
                className="button-outline-dark"
                data-dismiss="modal"
                style={{ marginRight: "auto" }}
                onClick={() => handleDelete()}
              >
                Delete sound
              </button>
              <div className="flex">
                <button
                  type="button"
                  className="button-outline-dark mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => handleUpdateClue()}
                  className="button-blue-dark"
                  data-dismiss="modal"
                >
                  Save sound
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateSound: (
      doc_id: string,
      puzzle_id: string,
      sound: string,
      update: any,
      type: string
    ) => dispatch(updateSound(doc_id, puzzle_id, sound, update, type)),
  };
};

export default connect(null, mapDispatchToProps)(EditDeleteSoundModal);
