import React, { useState } from "react";
import { connect } from "react-redux";

const AdjustTimer = (props) => {
  const {
    handleAdjustTimer,
    startingSeconds,
    //handleChangeTimerSpeed
  } = props;
  const [timer, setTimer] = useState(1);

  const buttonText = () => {
    if (props.remote.adjustTimerButtonLoading) {
      return (
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      );
    } else {
      if (timer < 0) return "Remove time";
      else return "Add time";
    }
  };

  const handleTimer = (time) => {
    const max = startingSeconds / 60;
    var positiveTime;
    var originalTime = time;

    if (time < 0) positiveTime = time * -1;

    if (positiveTime >= max || originalTime >= max) {
      console.log("TOO BIG");
    } else {
      setTimer(time);
    }
  };

  return (
    <div
      id="adjust-timer-box"
    >
        {/* <div className="flex items-center space-x-2 mb-2">
            <p className="text-white font-medium">Adjust timer</p>
          </div> */}

      <div
        style={{ marginBottom: "0px", marginTop: "0px", textAlign: "center" }}
      >
        <div className="card-body" style={{ padding: "0px 0px 0px 0px" }}>
          <div
          className=""
          >
            <div className="grid grid-cols-3">
            <button
              onClick={() => setTimer(timer - 1)}
              type="button"
              className="col-span-1 bg-v1_dark-hover hover:bg-gray-700 duration-75 px-2 h-[25px] w-[30px] rounded-sm flex items-center justify-center focus:outline-none text-white"
            >
              <i className="icon ion-md-remove"></i>
            </button>
            <input
              type="text"
              className="input-dark w-full h-[25px] col-span-1 text-xs"
              style={{
                textAlign: "center",
                background: "none",
                border: "none",
                lineHeight: "0px",
                backgroundColor: "none",
              }}
              value={timer}
              onChange={(e) => handleTimer(e.target.value)}
            ></input>

            <button
              //onClick={() => setTimer(timer + 1)}
              onClick={() => handleAdjustTimer(Number(timer))}
              type="button"
              className="col-span-1 bg-v1_dark-hover hover:bg-gray-700 duration-75 px-2 h-[25px] w-[30px] rounded-sm flex items-center justify-center focus:outline-none text-white"

            >
              <i className="icon ion-md-add"></i>
            </button>
            </div>

           <div>
           <button
              onClick={() => handleAdjustTimer(Number(timer))}
              type="button"
              className="bg-v1_dark-hover hover:bg-gray-700 duration-75 px-2 h-[25px] w-full rounded-sm flex items-center justify-center focus:outline-none text-white text-xs"
            >

              Update
            </button>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    remote: state.remote,
  };
};

export default connect(mapStateToProps, null)(AdjustTimer);
