import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import Select, { components } from "react-select";

import { get, set, createStore, del } from "idb-keyval";
import {
  gameId,
  addToDB,
  deleteAsset,
  readBlob,
} from "../Functions/SettingsFunctions";

//Check if Subscribed
import { isSubscribed } from "../../../../utils/Utils";

import HintSettingsPreview from "./HintSettingsPreview";

function HintIconSettings(props) {
  const { game_id, account, lw_settings, upload_progress, game_assets } = props;

  // Init IndexDB
  gameId(game_id);

  const [settings, setSettings] = useState(lw_settings);
  useEffect(() => {
    setSettings(lw_settings);
    setBackgroundColor(
      lw_settings && lw_settings.timer
        ? lw_settings.timer.bg_color_hintBox
        : "#fff"
    );
    setWindowBGColor(
      lw_settings && lw_settings.timer
        ? lw_settings.timer.bg_color
        : "rgb(26, 26, 26)"
    );
    setIconColor(
      lw_settings && lw_settings.timer
        ? lw_settings.timer.hintIconColor
        : "rgb(26, 26, 26)"
    );
  }, [lw_settings]);

  const [windowBGColor, setWindowBGColor] = useState("rgb(26, 26, 26)");

  // Edit Custom Icon
  const [customIcon, setCustomIcon] = useState();
  const [editCustomIcon, setEditCustomIcon] = useState(false);

  const uploadCustomIcon = (file) => {
    // Create Blob
    addToDB(file, "custom_icon", setCustomIcon, customIcon);

    // Upload to Database
    props.handleUploadFile(file, "custom_icon");
  };

  const deleteCustomIcon = (file) => {
    // Upload to Database
    props.handleDeleteFile("custom_icon", customIcon)

    // Create Blob
    deleteAsset("custom_icon", setCustomIcon)

  };

  // Edit Background Color
  const [editBackgroundColor, setEditBackgroundColor] = useState(false);
  const [editIconColor, setEditIconColor] = useState(false);
  const [iconColor, setIconColor] = useState("rgb(228, 246, 28)");
  const [backgroundColor, setBackgroundColor] = useState("#fff");
  const handleChangeBackgroundColor = (color) => {
    setEditBackgroundColor(!editBackgroundColor);

    if (backgroundColor !== settings.timer.bg_color_hintBox) {
      setSettings({
        ...settings,
        timer: { ...settings.timer, bg_color_hintBox: backgroundColor },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          bg_color_hintBox: backgroundColor,
        },
      };

      

      props.saveLiveWindowSettings(game_id, newData);
    }
  };
  const handleChangeIconColor = (color) => {
    setEditIconColor(!editIconColor);

    if (iconColor !== settings.timer.hintIconColor) {
      setSettings({
        ...settings,
        timer: { ...settings.timer, hintIconColor: iconColor },
      });
      const newData = {
        ...settings,
        timer: {
          ...settings.timer,
          last_updated: new Date(),
          hintIconColor: iconColor,
        },
      };

      props.saveLiveWindowSettings(game_id, newData);
    }
  };

  const changeColor = (color) => {
  

    const rgb =
      "rgb(" + color.r + " " + color.g + " " + color.b + " / " + color.a + ")";
    setBackgroundColor(rgb);
  };

  // IndexDB Asset Items
  const [backgroundImage, setBackgroundImage] = useState("");


  useEffect(() => {
    if (game_assets && game_assets.background_image) {
      setBackgroundImage(game_assets.background_image);
    }

    if (game_assets && game_assets.custom_icon) {
      setCustomIcon(game_assets.custom_icon);
    }

    return () => {
      setBackgroundImage();
      setCustomIcon();
    };
  }, [game_assets]);

  // useEffect(() => {
  //   const assetsStore = createStore(game_id, "assets");

  //   // Get Background Image
  //   get("background_image", assetsStore).then((val) => {
  //     if (val) {
  //       const blob = readBlob(val);
  //       var url = URL.createObjectURL(blob);
  //       setBackgroundImage({ ...val, url });
  //     } else {
  //       setBackgroundImage()
  //     }
  //   });

  //   // Get Custom Icon
  //   get("custom_icon", assetsStore).then((val) => {
  //     if (val) {
  //       const blob = readBlob(val);
  //       var url = URL.createObjectURL(blob);
  //       setCustomIcon({ ...val, url });
  //     } else {
  //       setCustomIcon()
  //     }
  //   });
  // }, [game_assets]);

  const options = [
    { label: "Flash", icon: "flash" },
    { label: "Egg", icon: "egg" },
    { label: "Eye", icon: "eye" },
    { label: "Finger Print", icon: "finger-print" },
    { label: "Flag", icon: "flag" },
    { label: "Flame", icon: "flame" },
    { label: "Flask", icon: "flask" },
    { label: "Flower", icon: "flower" },
    { label: "Soccer Ball", icon: "football" },
    { label: "Gift", icon: "gift" },
    { label: "Hand", icon: "hand" },
    { label: "Happy", icon: "happy" },
    { label: "Heart", icon: "heart" },
    { label: "House", icon: "house" },
    { label: "Key", icon: "key" },
    { label: "Leaft", icon: "leaf" },
    { label: "Lock", icon: "lock", style: "md" },
  ];

  const { Option } = components;
  const IconOption = (props) => {
    return (
      <Option {...props}>
        <div style={{ padding: "10px 0px", display: "flex" }}>
          <i
            className={"icon ion-ios-" + props.data.icon}
            style={{ fontSize: "18px" }}
          ></i>
          <p style={{ marginLeft: "10px", paddingBottom: "0" }}>
            {props.data.label}
          </p>
        </div>
      </Option>
    );
  };

  return (
    <div className="form-group row bd-t pd-t-20 pd-b-20">
      <div className="col-sm-3">
        <label htmlFor="inputEmail3" className="col-form-label tx-18">
          <b>
            {/* <ion-icon
                name="image-outline"
                style={{ marginRight: "10px" }}
              ></ion-icon> */}
            Clue Icons
          </b>
        </label>
        <p className="tx-13">
          Customize the clue icons at the bottom of the player screen.
        </p>
{/* 
        <div className="pd-t-10 mg-t-10 bd-t">
          <p className="mg-b-5">
            <b>Box Color</b>
          </p>
          <div
            className="color-picker-swatch"
            onClick={() => setEditBackgroundColor(!editBackgroundColor)}
          >
            <div
              className="color-picker-bubble"
              style={{ background: backgroundColor }}
            />
            <p className="mg-b-0">{backgroundColor}</p>
          </div>

          {editBackgroundColor ? (
            <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
              <div
                style={{
                  position: "fixed",
                  top: "50px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={() => handleChangeBackgroundColor()}
              />
              <SketchPicker
                color={backgroundColor}
                onChange={(e) => changeColor(e.rgb)}
                onChangeComplete={(e) => changeColor(e.rgb)}
                disableAlpha={false}
              />
            </div>
          ) : null}
        </div> */}

        <div className="mg-t-10">
          <p className="mg-b-5">
            <b>Icon Color</b>
          </p>
          <div
            className="color-picker-swatch"
            onClick={() => setEditIconColor(!editIconColor)}
          >
            <div
              className="color-picker-bubble"
              style={{ background: iconColor }}
            />
            <p className="mg-b-0">{iconColor}</p>
          </div>

          {editIconColor ? (
            <div style={{ position: "absolute", zIndex: "2", top: "50px" }}>
              <div
                style={{
                  position: "fixed",
                  top: "50px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                }}
                onClick={() => handleChangeIconColor()}
              />
              <SketchPicker
                color={iconColor}
                onChange={(e) => setIconColor(e.hex)}
                onChangeComplete={(e) => setIconColor(e.hex)}
                disableAlpha={false}
                    presetColors={['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF', 'transparent']}
              />
            </div>
          ) : null}
        </div>

        <div className="pd-t-10 pd-b-10 mg-t-10 bd-t">
          <p className="mg-b-5">
            <b>Custom Icon</b>
          </p>

          {customIcon && !customIcon.error && !editCustomIcon ? (
            <div>
              <div
                style={{
                  display: "flex",
                  background: "#f5f6fa",
                  padding: "5px",
                  borderRadius: "5px",
                }}
              >
                <p
                  style={{
                    marginBottom: "0px",
                    marginRight: "20px",
                    //marginTop: "5px",
                    padding: "5px",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "155px",
                    }}
                  >
                    {upload_progress.type === "custom_icon" &&
                    upload_progress.progress > 0 ? (
                      <React.Fragment>
                        <b>
                          Uploading: {upload_progress.progress.toFixed(1)}%{" "}
                        </b>
                        <div
                          className="progress"
                          style={{
                            marginTop: "10px",
                            width: "100%",
                            height: "5px",
                          }}
                        >
                          <div
                            className="progress-bar"
                            style={{ width: upload_progress.progress + "%" }}
                            role="progressbar"
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <a href={customIcon.url} target="_blank">
                          <b>{customIcon.name}</b>
                          <i
                            style={{ marginLeft: "5px" }}
                            className="fa-regular fa-up-right-from-square"
                          ></i>
                        </a>
                      </React.Fragment>
                    )}
                  </div>
                </p>

                <button
                  disabled={isSubscribed(account) ? false : true}
                  className="btn btn-icon btn-outline-danger"
                  type="button"
                  style={{ marginLeft: "auto", padding: "0px 10px" }}
                  // onClick={() => props.handleDeleteFile("video_brief")}
                  onClick={() => deleteCustomIcon()}
                >
                  <i
                    className="icon ion-ios-trash tx-18"
                    style={{ padding: "0px 0px" }}
                  ></i>
                </button>
              </div>

              <small>Max size: 100MB. *.jpg, *.jpeg, *.png, *.gif</small>
            </div>
          ) : (
            <React.Fragment>
              {customIcon && customIcon.error ? (
                <p className="tx-danger mg-b-0">{customIcon.error}</p>
              ) : null}
              <div className="custom-file">
                <input
                  // onChange={(e) =>
                  //   props.handleVideoFile(e.target.files[0])
                  // }
                  disabled={isSubscribed(account) ? false : true}
                  // onChange={(e) =>
                  //   addToDB(
                  //     e.target.files[0],
                  //     "video_brief",
                  //     setVideoBrief,
                  //     videoBrief
                  //   )
                  // }
                  onChange={(e) => uploadCustomIcon(e.target.files[0])}
                  accept=".jpg, .jpeg, .png, .gif"
                  name="custom_icon"
                  type="file"
                  className="custom-file-input"
                  id="custom_icon"
                />
                <label className="custom-file-label" htmlFor="customFile">
                  Choose file
                </label>
              </div>
              {isSubscribed(account) ? (
                <small>Max size: 100MB. *.jpg, *.jpeg, *.png, *.gif</small>
              ) : (
                <small>Please <a href="/account/billing">
                {" "}
                upgrade your account.
              </a></small>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="col-sm-9 pd-t-20">
        <div
          style={{
            display: "flex",
            padding: "50px",
            justifyContent: "center",
            backgroundImage:
              backgroundImage &&
              backgroundImage.type &&
              backgroundImage.type.startsWith("image/")
                ? "url(" + backgroundImage.url + ")"
                : null,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundColor:
              backgroundImage &&
              backgroundImage.type &&
              backgroundImage.type.startsWith("video/")
                ? "#000"
                : windowBGColor,
            backgroundPositionY: "bottom",
            borderRadius: "10px",
            border: "1px solid #e6e6e6",
          }}
        >
          <HintSettingsPreview
            customIcon={customIcon}
            iconColor={iconColor}
            clueOnScreenCount={[true, true, true]}
            backgroundColor={backgroundColor}
          />
        </div>
      </div>
    </div>
  );
}

export default HintIconSettings;
