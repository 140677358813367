import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import AddClueModal from "./modals/AddClueModal";
import AddSoundModal from "./modals/AddSoundModal";
import DeletePuzzleModal from "./modals/DeletePuzzleModal";
import EditDeleteClueModal from "./modals/EditDeleteClueModal";
import EditDeleteSoundModal from "./modals/EditDeleteSoundModal";
import EditPuzzleModal from "./modals/EditPuzzleModal";

import {
  addNewClue,
  deletePuzzle,
  reorderClues,
  updatePuzzle,
} from "../../../store/actions/gmActions";
import { AudioClueButton } from "./CustomComponents/AudioClueButton";
import { ClueItem } from "./ListItems/ClueItem/ClueItemV2";

// React Sortable
import { ReactSortable } from "react-sortablejs";

import { classNames } from "../../../utils/Utils";
import DropdownSettingsClue from "./ListItems/Components/DropdownClue";
import SoundClues from "./Sections/SoundClues";

const LeftSideBar = (props) => {
  const {
    handleLoadClueClick,
    allClues,
    clues,
    //sounds,
    id,
    puzzles,
    selectedPuzzleName,
    selectedPuzzleObj,
    handleClearFilteredClues,
    addNewClue,
    //handleRefreshClues,
    markPuzzleAsDone,
    loadedClue,
    gm_id,
    puzzlesDone,
    userPermissions,
    handleSelectPuzzle,
  } = props;

  const [puzzleDoneStatus, setPuzzleDoneStatus] = useState(
    puzzlesDone.filter((donePuzzle) => {
      if (donePuzzle.id === selectedPuzzleObj.id) return donePuzzle;
    })
  );
  const doneStatus = () => {
    const puzzleDone = puzzlesDone.filter((donePuzzle) => {
      if (donePuzzle.id === selectedPuzzleObj.id) return donePuzzle;
    });
    if (puzzleDone) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddNewClue = (data, id) => {
    addNewClue(data, id);
  };

  const delete_puzzle = (id, selectedPuzzle) => {
    props.deletePuzzle(id, selectedPuzzle);
    handleSelectPuzzle();
  };

  const addNewClueUsingInput = (message) => {
    if (message.newClue) {
      const data = {
        puzzle_id: selectedPuzzleObj.id,
        message: message.newClue,
      };

      addNewClue(data, id, selectedPuzzleObj.id);
    }
  };

  const puzzleUpdate = (update) => {
    props.updatePuzzle(id, selectedPuzzleObj.id, update);
    handleClearFilteredClues();
  };

  const [puzzleName, setPuzzleName] = useState("Puzzle Name");
  const [note, setNote] = useState("Note");
  const [contains, setContains] = useState();

  // Sections
  const [expandClueSection, setExpandClueSection] = useState(true);
  const [expandDetailsSection, setExpandDetailsSection] = useState(true);
  const [hoverOverSettings, setHoverOverSettings] = useState(false);

  const [currentGroup, setCurrentGroup] = useState("open");

  // Bolt Local Storage Preferences
  useEffect(() => {
    try {
      const serializedState = localStorage.getItem("BOLT_preferences");

      if (serializedState === null) {
        return localStorage.setItem("BOLT_preferences");
      }
      setExpandDetailsSection(JSON.parse(serializedState).detailsSectionToggle);
    } catch (err) {
      return false;
    }
  }, []);

  // Expand Details Section
  const ExpandDetailsSection = (bool) => {
    setExpandDetailsSection(bool);
    localStorage.setItem(
      "BOLT_preferences",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("BOLT_preferences")),
        detailsSectionToggle: bool,
      })
    );
  };

  const [sortClueStatus, setSortClueStatus] = useState(false);
  const [currentGroupLoader, setCurrentGroupLoader] = useState(false);
  const [showDoneButton, setShowDoneButton] = useState(false);

  useEffect(() => {
    if (selectedPuzzleObj && selectedPuzzleObj.done) setShowDoneButton(true);
    if (selectedPuzzleObj && !selectedPuzzleObj.done) setShowDoneButton(false);
    if (selectedPuzzleObj && selectedPuzzleObj.color)
      setPuzzleColor(selectedPuzzleObj.color);
    if (selectedPuzzleObj && !selectedPuzzleObj.color) setPuzzleColor("#fff");

    setPuzzleDoneStatus(
      puzzlesDone.filter((donePuzzle) => {
        if (donePuzzle.id === selectedPuzzleObj.id) return donePuzzle;
      })
    );
  }, [selectedPuzzleObj]);

  useEffect(() => {
    setPuzzleName(selectedPuzzleName);
  }, [selectedPuzzleName]);

  // Current Group Loader
  useEffect(() => {
    setCurrentGroupLoader(true);
    setTimeout(() => {
      setCurrentGroupLoader(false);
    }, 500);
  }, [currentGroup]);

  useEffect(() => {
    setNote(selectedPuzzleObj ? selectedPuzzleObj.note : "Note");
    setContains(selectedPuzzleObj ? selectedPuzzleObj.contains : null);
  }, [selectedPuzzleObj]);

  // Sortable Clues
  const [sortableClues, setSortableClues] = useState(clues);
  const [sortableSounds, setSortableSounds] = useState(null);
  useEffect(() => {
    setSortableClues(clues);
  }, [clues]);

  const [sentClues, setSentClues] = useState(props.sentClues);
  useEffect(() => {
    setSentClues(props.sentClues);
  }, [props.sentClues]);

  const sortableChange = () => {
    props.reorderClues(id, selectedPuzzleObj.id, sortableClues);
  };

  //Set Puzzle Color
  const [canEditPuzzleColor, setCanEditPuzzleColor] = useState(false);
  const [puzzleColor, setPuzzleColor] = useState(
    selectedPuzzleObj && selectedPuzzleObj.color
      ? selectedPuzzleObj.color
      : "#000"
  );

  // OLD
  const changePuzzleColor = (color) => {
    if (userPermissions.isAdmin || userPermissions.canEditHints) {
      setCanEditPuzzleColor(!canEditPuzzleColor);

      if (puzzleColor !== selectedPuzzleObj.color) {
        setPuzzleColor(puzzleColor);
        const update = {
          color: puzzleColor,
        };

        props.updatePuzzle(id, selectedPuzzleObj.id, update);
      }
    }
  };

  const [showEditPuzzle, setShowEditPuzzle] = useState(false);
  const [puzzleEditColor, setPuzzleEditColor] = useState(false);

  const [showHintDetails, setShowHintDetails] = useState(false);

  return (
    <React.Fragment>
      <div
        id="clue-box"
        className="chat-sidebar clues-sidebar border-r border-v1_dark-hover"
        style={{
          left: "260px",
          bottom: "42px",
          top: "40px"
        }}
      >
        {selectedPuzzleName && (
          <div
            className={classNames(
              "py-2 px-3 flex w-full justify-between items center focus:outline-none duration-100 hover:bg-v1_dark-hover hover:bg-opacity-20 z-0",
              !expandDetailsSection
                ? "border-b border-v1_dark-hover"
                : "border-0"
            )}
          >
            <div className="left">
              <div className="flex items-center space-x-2">
                <i class="fa-light w-[12px] fa-circle-info text-white mb-[1px]"></i>
                <p className="text-white font-medium">Details</p>
                {puzzleDoneStatus.length > 0 && (
                  <div>
                    <div className="flex items-center ml-2">
                      <i class="fa-solid fa-badge-check text-green-500 mr-1"></i>{" "}
                      <p className="text-xs text-white">Complete</p>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex items-center">
                <p className="text-gray-400 text-xs overflow-hidden whitespace-nowrap text-left text-ellipsis w-[200px]">
                  {selectedPuzzleName}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center">
              <DropdownSettingsClue userPermissions={userPermissions} />
              <button
                onClick={() => ExpandDetailsSection(!expandDetailsSection)}
                className={classNames(
                  "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75",
                  !expandDetailsSection ? "rotate-[180deg]" : "rotate-[270deg]"
                )}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
            </div>
          </div>
        )}

        <div className="chat-sidebar-body" style={{ top: "37px" }}>
          {selectedPuzzleName ? (
            <React.Fragment>
              <div
                className={classNames(
                  "px-3 py-2 border-b",
                  expandDetailsSection
                    ? "border-b border-v1_dark-hover"
                    : "border-0"
                )}
                // style={{
                //   padding: "20px",
                //   color: "#a8b8ba",
                //   fontWeight: "500",
                //   borderTop: "none",
                //   borderBottom: "1px solid #404040",
                // }}
              >
                {/* <div>{doneStatus()}</div> */}

                <div
                  className={classNames(
                    "mt-3 flex items-center",
                    !expandDetailsSection ? "hidden" : ""
                  )}
                  // onMouseOver={() => setShowEditPuzzle(true)}
                  // onMouseLeave={() => setShowEditPuzzle(false)}
                >
                  <div className="w-full">
                    <div
                      className="w-full"
                      style={{
                        overflow: "hidden",
                        // height: !showHintDetails ? '0px' : 'auto',
                        transition: "height 0.5s",
                      }}
                    >
                      <div className="mb-3 border-l-4 pl-2 border-green-500 w-full">
                        <p className="text-xs text-gray-400">Answer</p>

                        <p className="text-white">
                          {note ? (
                            note
                          ) : (
                            <span className="text-gray-500 italic text-xs">
                              No data
                            </span>
                          )}
                        </p>
                      </div>

                      <div className="mb-2 border-l-4 pl-2 border-yellow-500 w-full">
                        <p className="text-xs text-gray-400">Description</p>
                        <p className="text-white">
                          {selectedPuzzleObj.contains ? (
                            selectedPuzzleObj.contains
                          ) : (
                            <span className="text-gray-500 italic text-xs">
                              No data
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* {puzzleDoneStatus.length > 0 && (
                  <div
                  
                  >
                    <div>
                      <i
                        class="fa-solid fa-check text-green-500"
                        style={{ marginRight: "8px" }}
                      ></i>{" "}
                      <span className="text-white">Completed at {puzzleDoneStatus[0].completed_at.timer.m}:
                      {puzzleDoneStatus[0].completed_at.timer.s}</span>
                    </div>

                    <button
                      onClick={(e) =>
                        markPuzzleAsDone(selectedPuzzleObj, false)
                      }
                      type="button"
                      className="btn btn-xs btn-dark"
                    >
                      Undo
                    </button>
                  </div>
                )} */}
              </div>
            </React.Fragment>
          ) : null}

          {/* CLUE SECTION */}
          <div className="overflow-scroll scrollbar-hide">
            <div
              className={classNames(
                "flex flex-col",
                selectedPuzzleName ? "border-b border-v1_dark-hover" : "",
                expandClueSection ? "pb-2" : "p-0"
              )}
            >
              {selectedPuzzleName && (
                <div className="flex items-center">
                  <button
                    onClick={() => !hoverOverSettings ? setExpandClueSection(!expandClueSection) : null}
                    className={classNames(
                      "py-2 px-3 flex justify-between items center focus:outline-none duration-100 hover:bg-v1_dark-hover hover:bg-opacity-20 w-full"
                    )}
                  >
                    <div className="flex items-center space-x-2">
                      <p className="text-white font-medium mt-[1px]">Clues</p>
                      <p className="text-gray-500 mt-[1px]">
                        {sortableClues.length ? sortableClues.length : "0"}
                      </p>
                    </div>

                    <div className="flex items-center justify-center">
                      {/* <DropdownSettingsClue userPermissions={userPermissions} /> */}



                      <button
                        onMouseEnter={()=> setHoverOverSettings(true)}
                        onMouseLeave={()=> setHoverOverSettings(false)}
                        href={
                          userPermissions.isAdmin ||
                          userPermissions.canEditPuzzles
                            ? "#addClueModal"
                            : "#"
                        }
                        id="addPuzzleModal"
                        disabled={
                          userPermissions.isAdmin ||
                          userPermissions.canEditPuzzles
                            ? false
                            : true
                        }
                        data-toggle="modal"
                        className={classNames(
                          "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75 mr-1 relative"
                        )}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </button>
                      


                      <button
                        onClick={() => setExpandClueSection(!expandClueSection)}
                        className={classNames(
                          "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75",
                          !expandClueSection
                            ? "rotate-[180deg]"
                            : "rotate-[270deg]"
                        )}
                      >
                        <i className="fa-solid fa-chevron-left"></i>
                      </button>

                      
                    </div>
                    
                  </button>
                </div>
              )}

              {allClues ? (
                <div id="chatDirectMsg" className="chat-msg-list pl-2">
                  {!selectedPuzzleName ? (
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "60px",
                        opacity: "0.5",
                      }}
                    >
                      <ion-icon
                        style={{ color: "#a8b8ba" }}
                        size="large"
                        name="cube-outline"
                      ></ion-icon>
                      <h6>No objective selected.</h6>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div
                        className={classNames(
                          "pb-3 px-3 hidden space-x-2 font-medium",
                          !expandClueSection ? "hidden" : ""
                        )}
                      >
                        <button
                          onClick={() => setCurrentGroup("open")}
                          className={classNames(
                            " text-xs rounded-full px-2 py-0.5 text-white duration-75 focus:outline-none",
                            currentGroup === "open"
                              ? "bg-blue-800 hover:bg-blue-900"
                              : "bg-gray-600 hover:bg-gray-700"
                          )}
                        >
                          {sortableClues.length - sentClues.length} Open
                        </button>
                        <button
                          onClick={() => setCurrentGroup("complete")}
                          className={classNames(
                            " text-xs rounded-full px-2 py-0.5 text-white duration-75 focus:outline-none",
                            currentGroup === "complete"
                              ? "bg-blue-800 hover:bg-blue-900"
                              : "bg-gray-600 hover:bg-gray-700"
                          )}
                        >
                          {sentClues.length} Sent
                        </button>
                        <button
                          onClick={() => setCurrentGroup("all")}
                          className={classNames(
                            " text-xs rounded-full px-2 py-0.5 text-white duration-75 focus:outline-none",
                            currentGroup === "all"
                              ? "bg-blue-800 hover:bg-blue-900"
                              : "bg-gray-600 hover:bg-gray-700"
                          )}
                        >
                          {sortableClues.length} All
                        </button>
                      </div>

                      {/* {currentGroup === "open" &&
                      sortableClues.length > 0 &&
                      sortableClues.length - sentClues.length === 0 && (
                        <div className="mt-3 px-3">
                          <p className="text-gray-500 text-xs">
                            All clues have been sent.
                          </p>
                        </div>
                      )} */}

                      {sortableClues.length < 1 ? (
                        <p
                          className={classNames(
                            "px-2 pt-2 pb-1 text-xs text-gray-500 italic",
                            !expandClueSection ? "hidden" : null
                          )}
                        >
                          No clues yet...
                        </p>
                      ) : (
                        <React.Fragment>
                          <ReactSortable
                            disabled={
                              userPermissions.isAdmin ||
                              userPermissions.canEditHints
                                ? false
                                : true
                            }
                            list={sortableClues}
                            setList={setSortableClues}
                            animation={200}
                            delayOnTouchStart={true}
                            delay={5}
                            onSort={() => sortableChange()}
                            className={classNames(
                              "mx-2 mr-3 mt-2 max-h-[400px] overflow-scroll scrollbar-hide",
                              !expandClueSection ? "hidden" : ""
                            )}
                          >
                            {sortableClues.map((clueObj, idx) => {
                              var isSent = false;
                              if (sentClues.includes(clueObj.id)) isSent = true;

                              if (!clueObj.type || clueObj.type === "text") {
                                return (
                                  <ClueItem
                                    key={clueObj.id}
                                    data-id={clueObj.id}
                                    //style={style}
                                    clueObj={clueObj}
                                    handleLoadClueClick={handleLoadClueClick}
                                    loadedClue={loadedClue}
                                    puzzlesDone={puzzlesDone}
                                    selectedPuzzleObj={selectedPuzzleObj}
                                    color={puzzleColor}
                                    isSent={isSent}
                                    userPermissions={userPermissions}
                                    sortClueStatus={sortClueStatus}
                                    currentGroup={currentGroup}
                                  />
                                );
                              }

                              return;
                            })}
                          </ReactSortable>
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}
                </div>
              ) : null}
            </div>

            {/* SOUND SECTION */}
            <SoundClues
              puzzlesDone={puzzlesDone}
              selectedPuzzleName={selectedPuzzleName}
              userPermissions={userPermissions}
              sentClues={sentClues}
              handleLoadClueClick={handleLoadClueClick}
              //sounds={sounds}
              loadedClue={loadedClue}
              selectedPuzzleObj={selectedPuzzleObj}
              id={id}
            />
          </div>

          {/* {selectedPuzzleName && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "10px",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Tooltip title="Add Text Clue" placement="top" arrow>
                <button
                  href={
                    userPermissions.isAdmin || userPermissions.canEditHints
                      ? "#addClueModal"
                      : "#"
                  }
                  id="addTextClue"
                  data-toggle="modal"
                  className="bolt-icon-button"
                >
                  <i class="fa-solid fa-message-plus"></i>
                </button>
              </Tooltip>

              <div>
                <Tooltip title="Puzzle Settings" placement="top" arrow>
                  <button
                    href={
                      userPermissions.isAdmin || userPermissions.canEditHints
                        ? "#editPuzzleModal"
                        : "#"
                    }
                    data-toggle="modal"
                    className="bolt-icon-button"
                  >
                    <i className="fa-solid fa-gear"></i>
                  </button>
                </Tooltip>

                <Tooltip title="Delete Puzzle" placement="top" arrow>
                  <button
                    href={
                      userPermissions.isAdmin || userPermissions.canEditHints
                        ? "#deletePuzzleModal"
                        : "#"
                    }
                    data-toggle="modal"
                    className="bolt-icon-button"
                  >
                    <i className="fa-solid fa-trash"></i>
                  </button>
                </Tooltip>
              </div>
            </div>
          )} */}
        </div>
      </div>

      <AddClueModal
        id={id}
        game_id={gm_id}
        puzzles={puzzles}
        handleAddNewClue={handleAddNewClue}
        selectedPuzzleObj={selectedPuzzleObj}
      />

      <AddSoundModal
        id={id}
        game_id={gm_id}
        puzzles={puzzles}
        handleAddNewClue={handleAddNewClue}
        selectedPuzzleObj={selectedPuzzleObj}
      />

      <EditDeleteClueModal
        id={id}
        puzzles={puzzles}
        loadedClue={loadedClue}
        gm_id={gm_id}
      />

      <EditDeleteSoundModal
        id={id}
        puzzles={puzzles}
        loadedClue={loadedClue}
        gm_id={gm_id}
      />

      <EditPuzzleModal
        id={id}
        selectedPuzzleObj={selectedPuzzleObj}
        gm_id={gm_id}
        puzzleUpdate={puzzleUpdate}
      />

      <DeletePuzzleModal
        delete_puzzle={delete_puzzle}
        id={id}
        selectedPuzzle={selectedPuzzleObj}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addNewClue: (data, id, puzzle_id) =>
      dispatch(addNewClue(data, id, puzzle_id)),
    updatePuzzle: (doc_id, puzzle_id, update) =>
      dispatch(updatePuzzle(doc_id, puzzle_id, update)),
    reorderClues: (doc_id, puzzle_id, data) =>
      dispatch(reorderClues(doc_id, puzzle_id, data)),
    deletePuzzle: (doc_id, puzzle_id) =>
      dispatch(deletePuzzle(doc_id, puzzle_id)),
  };
};

export default connect(null, mapDispatchToProps)(LeftSideBar);
