import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signInWithEmailLink, signUp, signInWithGoogle } from '../../store/actions/authActions'
import { Redirect, Link } from 'react-router-dom'

class AccountSetup extends Component {
    state = {
        email: '',
        password: '',
        password_2: '',
        fullName: '',
        showEmailSetup: false,
        passwordError: ''
    }

    handleChange = (e) => {
        this.setState({
           [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { email, password, password_2, fullName } = this.state;

        if (password !== password_2) {
          this.setState({passwordError: "Passwords don't match."})
        } else {
          var firstName = fullName.split(' ').slice(0, -1).join(' ');
          var lastName = fullName.split(' ').slice(-1).join(' ');
  
          const newUser = {
            email,
            password,
            firstName, 
            lastName
          }
          this.props.signUp(newUser)
        }

    }

    render() {
        const { authError, auth } = this.props;
        const { showEmailSetup, passwordError } = this.state;
        if (auth.uid) return <Redirect to="/" />

        const params = new URLSearchParams(this.props.location.search);
        const email = params.get('email'); 
        console.log(email)

        return (

    <div className="content content-fixed content-auth">
      <div className="container">
        <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
          
          <div className="sign-wrapper">
            <div className="wd-100p" style={{width:"350px"}}>
                <div className="text-center" style={{paddingBottom:"20px"}}>
                <Link to="/">
                    <img src="/bolt-logo.svg" alt="Logo" style={{width:"200px", marginTop:"-8px", marginBottom:"0px"}}></img>
                </Link>

                </div>

              {/* <h3 className="tx-color-01 mg-b-5">Sign In</h3> */}
              <h2 className="mg-b-20 text-center">Setup your account.</h2>
              {/* <p>You're signing up with {email}</p> */}

              <div style={{paddingBottom:"0px", fontWeight:"bold", maxWidth:"300px"}}>
                             { authError ? <p style={{color:"red"}}>{authError}</p> : null }
                    </div>

      {!showEmailSetup ? (
        <button
        onClick={() => this.setState({showEmailSetup:true})}
        className="btn btn-primary btn-block">Create Account With Email</button>
      ): (

        <form onSubmit={this.handleSubmit}>
        <div className="form-group">
                <label htmlFor="company">Email Address*</label>
                <input type="email" id="email" className="form-control" placeholder="example@escaperoom.com" onChange={this.handleChange} required></input>
              </div>


              <div className="form-group">
                <label htmlFor="fullName">Your Full Name*</label>
                <input type="text" id="fullName" className="form-control" placeholder="First & Last Name" onChange={this.handleChange} required></input>
              </div>

              <div className="form-group">
                
                <div className="d-flex justify-content-between mg-b-5">
                  <label htmlFor="password" className="mg-b-0-f">Password*</label>

                </div>
                <input type="password" id="password" className="form-control" placeholder="Create a password" onChange={this.handleChange} required></input>
                <small className="text-muted">Password must be 8 characters or longer.</small>
              </div>

              <div className="form-group">
                <div className="d-flex justify-content-between mg-b-5">
                  <label htmlFor="password" className="mg-b-0-f">Re-enter Password*</label>

                </div>
                <input type="password" id="password_2" className="form-control" placeholder="Create a password" onChange={this.handleChange} required></input>
              </div>

              {passwordError ? <p className="tx-danger"><b>{passwordError}</b></p> : null }

              


              <button className="btn btn-primary w-100 mg-b-50"><b>Continue</b></button>

            </form>

)}
              {/* <div className="divider-text">or</div>
              <button
               onClick={() => this.props.signInWithGoogle()}
               className="btn btn-outline-facebook btn-block">Sign In With Google</button> */}
              <div className="tx-13 mg-t-20 tx-center">Already have an account? <a href="/login">Log In</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

        )
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        authError: state.auth.authError,
        authLoading: state.auth.authLoading,
        auth: state.firebase.auth
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signInWithEmailLink: (firstName, lastName, email, password) => dispatch(signInWithEmailLink(firstName, lastName, email, password)),
        signUp: (newUser) => dispatch(signUp(newUser)),
        signInWithGoogle: () => dispatch(signInWithGoogle())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountSetup)



