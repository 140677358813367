import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { updateClueOnScreenCount } from "../../../../store/actions/timerActions";

type Props = {
  updateClueOnScreenCount: (clueArray: any, count: number, game_doc: string) => void;
  game_doc: string;
  count: number;
};

const EditClueIconsModal = (props: Props) => {
  const [settings, setSettings] = useState({
    count: 3,
  });

  useEffect(()=> {
    setSettings({
        count: props.count
    })
  }, [props.count])

  const updateCount = (e: any) => {
    setSettings({
      ...settings,
      count: Number(e),
    });
  };

  const saveSettings = () => {
    var count;
    if (settings.count === 1) count = { 0: true };
    if (settings.count === 2) count = { 0: true, 1: true };
    if (settings.count === 3) count = { 0: true, 1: true, 2: true };
    if (settings.count === 4) count = { 0: true, 1: true, 2: true, 3: true };
    if (settings.count === 5)
      count = { 0: true, 1: true, 2: true, 3: true, 4: true };
    if (settings.count === 6)
      count = { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true };
    if (settings.count === 7)
      count = { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true };
    if (settings.count === 8)
      count = {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
      };
    if (settings.count === 9)
      count = {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
      };
    if (settings.count === 10)
      count = {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
      };

    props.updateClueOnScreenCount(count, settings.count, props.game_doc);
  };

  console.log(settings);

  return (
    <div
      className="modal effect-scale"
      id="editClueIconsModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-lg mb-1 text-white font-medium">Clue settings</p>

            <div className="flex flex-col mb-3">
              <label className="label-dark">Number of clue icons</label>
              <select
                name="count"
                value={settings.count}
                onChange={(e) => updateCount(e.target.value)}
                className="input-dark"
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </div>
          </div>
          <div className="modal-footer-dark">
            <div className="flex justify-between float-right">
              <div className="flex">
                <button
                  type="button"
                  className="button-outline-dark mr-2"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => saveSettings()}
                  className="button-blue-dark"
                  data-dismiss="modal"
                >
                  Save settings
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateClueOnScreenCount: (clueCountArray: any, count: number, game_doc: string) =>
      dispatch(updateClueOnScreenCount(clueCountArray, count, game_doc)),
  };
};

export default connect(null, mapDispatchToProps)(EditClueIconsModal);
