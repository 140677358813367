import React, { useState } from "react";
import moment from "moment";


type Props = {
  messageObj: any;
};

export const Message_AlertTone = (props: Props) => {
  const { messageObj } = props;
  const [hover, setHover] = useState(false);

  return (
    <div
      key={Math.random() * 1000}
      className="w-full flex py-2 items-center justify-center text-gray-400 text-xs"
    >
      <span
        className="
       mr-0"
      >
        Alert tone
      </span><span className="text-gray-500 px-1">&#183;</span>{" "}
      <span>{messageObj && moment(messageObj.timestamp).format("LT")}</span>
    </div>
  );
};
