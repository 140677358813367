import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import App from "./App";
import './App.css'

// import reportWebVitals from './reportWebVitals';

import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./store/reducers/rootReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { reduxFirestore, getFirestore } from "redux-firestore";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import firebase from "./config/firebase_config";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { initState } from './store/reducers/appReducer'
import { browserName, browserVersion, isMobile, isChrome } from "react-device-detect";

// Sentry.io
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

//Error Screens
import ChromeOnly from "./pages/error_screens/ChromeOnly";
import NoMobile from "./pages/error_screens/NoMobile";

//Auth
import SignIn from "./pages/auth/SignInV3/SignIn";
import ResetPassword from "./pages/auth/SignInV3/ForgotPassword";
import UpdatePassword from "./pages/auth/SignInV3/UpdatePassword";
import AccountSetup from "./pages/auth/AccountSetup";
import SignUp from './pages/auth/SignUpV3/SignUp'
import SignUpWithInvite from './pages/auth/SignUpV2/SignUpInvite'

//Onboarding
import Onboarding from './pages/auth/Onboarding/Onboarding'

//Account
import AccountHome from './pages/account/Account'

// Game Settings
import GameSettings from "./components/_settings/Game/GameSettings";

//GameMaster
import GameMaster from "./components/_gamemaster/GameMaster";

import { loadStateFromLocalStorage, saveState } from "./localStorage";

// React 18 Requirements
const container = document.getElementById('root');
const root = createRoot(container);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

Sentry.init({
  dsn: "https://465997e8d1494378be8fa140ec411076@sentry.io/4634381",
  release: "bolt@" + initState.app_version,
  environment: process.env.NODE_ENV !== "development" ? 'production' : 'development',
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click') {
      const { target } = hint.event;
      if (target.ariaLabel) {
        breadcrumb.message = target.ariaLabel;
      }
    }
    return breadcrumb;
  },
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2
});

const persistedStateFromLocalStorage = loadStateFromLocalStorage();
const store = createStore(
  rootReducer,
  persistedStateFromLocalStorage,
  compose(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(firebase),
    reactReduxFirebase(firebase, {
      useFirestoreForProfile: true,
      userProfile: "users",
      attachAuthIsReady: true,
    }),
    sentryReduxEnhancer
  )
);

store.subscribe(() => {
  saveState({
    //gameMaster: store.getState().gameMaster,
    timer: store.getState().timer,
  });
});

// Remove all console.logs in production
//if (process.env.NODE_ENV !== "development") console.log = () => {};

// Is Mobile
// const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;


store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>   
      <BrowserRouter>


        {!isMobile ?  (
          <Switch>
          <Route
            path="/gm/v1"
            render={(props) => {
              const oldPath = props.location.pathname;
              const length = oldPath.length;
              const path = props.location.pathname.slice(6, length);
              return <Redirect to={"/r" + path} />;
            }}
          />

          {/* App */}
          <Route exact path="/" component={App} />
          <Route path="/account" component={AccountHome} />
          <Route path="/settings/game/:id" component={GameSettings} />

          {/* Auth */}
          <Route exact path="/login" render={() => <SignIn />} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route exact path="/recover" component={UpdatePassword} />

           {/* Sign Up */}
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/signup-invite" component={SignUpWithInvite} />
          <Route exact path="/account-setup" component={AccountSetup} />

          {/* Onboarding */}
          <Route exact path="/onboarding" component={Onboarding} />

          {/* Gamemaster */}
          <Route path="/r" component={GameMaster} />

        </Switch>
        ) : (
          <Switch>
            
            <Route exact path="/login" render={() => <SignIn />} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/recover" component={UpdatePassword} />
            <Route exact path="/account-setup" component={AccountSetup} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/signup-invite" component={SignUpWithInvite} />
            <Route path="/" component={NoMobile} />
          </Switch>
        )}
        </BrowserRouter>


    </Provider>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workfers: https://bit.ly/CRA-PWA
  // serviceWorkerRegistration.unregister();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

});
