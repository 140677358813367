import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { classNames } from "../../../../utils/Utils";
import { reorderSounds } from "../../../../store/actions/gmActions";

// React Sortable
import { ReactSortable } from "react-sortablejs";

// Howler
import { Howl } from "howler";

import SoundItem from "../ListItems/SoundItem/SoundItem";
import SoundItemSkeleton from "../ListItems/SoundItem/SoundItemSkeleton";

type Props = {
  selectedPuzzleName: string;
  userPermissions: {
    isAdmin: boolean;
    canEditPuzzles: boolean;
    canEditHints: boolean;
  };
  selectedPuzzleObj: any;
  sentClues: any[];
  puzzlesDone: any[];
  handleLoadClueClick: (a: any) => void;
  sounds: any[];
  loadedClue: any;
  sounds_updated: any;
  gameMaster: any;
  reorderSounds: any;
  id: string;
};

function SoundClues(props: Props) {
  const [expandSoundSection, setExpandSoundSection] = useState(false);
  const [soundClues, setSoundClues] = useState(props.sounds);
  const [previousSounds, setPreviousSounds] = useState(props.sounds);
  const [soundLoading, setSoundsLoading] = useState(false);
  const [zeroSounds, setZeroSounds] = useState(false);
  const soundsLength = useRef(0);
  const [hoverOverSettings, setHoverOverSettings] = useState(false);

  // Bolt Local Storage Preferences
  useEffect(() => {
    try {
      const serializedState = localStorage.getItem("BOLT_preferences");

      if (serializedState === null) {
        return localStorage.setItem("BOLT_preferences", JSON.stringify({}));
      }
      setExpandSoundSection(JSON.parse(serializedState).soundsSectionToggle);
    } catch (err) {
      return;
    }
  }, []);

  useEffect(() => {
    console.log("SoundClues.tsx - UPDATE SOUNDS LIST");
    setSoundsLoading(true);
    setSoundClues([]);
    setTimeout(() => {
      setSoundClues(props.sounds);
      setPreviousSounds(props.sounds);
      setSoundsLoading(false);
    }, 1500);
  }, [props.sounds]);

  useEffect(() => {
    if (checkLength() === 0) {
      setZeroSounds(true);
    } else {
      setZeroSounds(false);
    }
  }, [props.selectedPuzzleObj]);

  const checkLength = () => {
    var length = [];
    if (props.selectedPuzzleObj) {
      length = props.sounds.filter((sound) => {
        if (sound.puzzle_id === props.selectedPuzzleObj.puzzle_id) return sound;
      });
    }
    return length.length;
  };

  const sortableChange = () => {
    // props.reorderSounds(props.id, props.selectedPuzzleObj.id, soundClues.filter((sound) => {
    //   if (sound.puzzle_id === props.selectedPuzzleObj.puzzle_id) return sound
    // } ));
  };

  // Expand Details Section
  const ExpandDetailsSection = (bool: boolean) => {
    setExpandSoundSection(bool);
    localStorage.setItem(
      "BOLT_preferences",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("BOLT_preferences") || ""),
        soundsSectionToggle: bool,
      })
    );
  };

  return (
    <div
      className={classNames(
        "flex flex-col",
        props.selectedPuzzleName ? "border-b border-v1_dark-hover" : "hidden",
        expandSoundSection ? "pb-2" : "p-0"
      )}
    >
      {props.selectedPuzzleName && (
        <div className="flex items-center">
          <button
            onClick={() =>
              !hoverOverSettings
                ? ExpandDetailsSection(!expandSoundSection)
                : null
            }
            className={classNames(
              "py-2 px-3 flex justify-between items center focus:outline-none duration-100 hover:bg-v1_dark-hover hover:bg-opacity-20 w-full"
            )}
          >
            <div className="flex items-center space-x-2">
              <p className="text-white font-medium mt-[1px]">Sounds</p>
              <p className="text-gray-500 mt-[1px]">{checkLength()}</p>
            </div>

            <div className="flex items-center justify-center">
              {/* <DropdownSettingsClue userPermissions={userPermissions} /> */}

              <a
                onMouseEnter={() => setHoverOverSettings(true)}
                onMouseLeave={() => setHoverOverSettings(false)}
                href={
                  props.userPermissions.isAdmin ||
                  props.userPermissions.canEditPuzzles
                    ? "#addSoundModal"
                    : "#"
                }
                data-toggle="modal"
                className={classNames(
                  "text-gray-500 hover:text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75 mr-1 relative"
                )}
              >
                <i className="fa-solid fa-plus"></i>
              </a>

              <button
                onClick={() => ExpandDetailsSection(!expandSoundSection)}
                className={classNames(
                  "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75",
                  !expandSoundSection ? "rotate-[180deg]" : "rotate-[270deg]"
                )}
              >
                <i className="fa-solid fa-chevron-left"></i>
              </button>
            </div>
          </button>
        </div>
      )}

      {!soundLoading && soundClues.length > 0 ? (
        <React.Fragment>
          <ReactSortable
            // disabled={
            //   props.userPermissions.isAdmin ||
            //   props.userPermissions.canEditHints
            //     ? false
            //     : true
            // }
            disabled={true}
            list={soundClues}
            setList={setSoundClues}
            animation={200}
            // delayOnTouchStart={true}
            delay={5}
            onSort={() => sortableChange()}
            className={classNames(
              "mx-2 mr-3 ml-3 mt-2 max-h-[400px] overflow-scroll scrollbar-hide",
              !expandSoundSection ? "hidden" : ""
            )}
          >
            {soundClues.map((clueObj: any, idx: number) => {
              //console.log('clueobj', clueObj)
              var isSent = false;
              if (props.sentClues.includes(clueObj.id)) isSent = true;
              return (
                <SoundItem
                  key={clueObj.id}
                  data-id={clueObj.id}
                  clueObj={clueObj}
                  handleLoadClueClick={props.handleLoadClueClick}
                  loadedClue={props.loadedClue}
                  selectedPuzzleObj={props.selectedPuzzleObj}
                  isSent={isSent}
                  userPermissions={props.userPermissions}
                  sounds_updated={props.sounds_updated}
                  gameMaster={props.gameMaster}
                />
              );
            })}
          </ReactSortable>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ReactSortable
            disabled={
              props.userPermissions.isAdmin ||
              props.userPermissions.canEditHints
                ? false
                : true
            }
            list={previousSounds}
            setList={setPreviousSounds}
            animation={200}
            // delayOnTouchStart={true}
            delay={5}
            //onSort={() => sortableChange()}
            className={classNames(
              "mx-2 mr-3 ml-3 mt-2 max-h-[400px] overflow-scroll scrollbar-hide",
              !expandSoundSection ? "hidden" : ""
            )}
          >
            {previousSounds.map((clueObj: any, idx: number) => {
              return (
                <SoundItemSkeleton
                  key={clueObj.id}
                  data-id={clueObj.id}
                  clueObj={clueObj}
                  selectedPuzzleObj={props.selectedPuzzleObj}
                />
              );
            })}
          </ReactSortable>
        </React.Fragment>
      )}

      {zeroSounds && (
        <p
          className={classNames(
            "px-3 pb-1 text-xs text-gray-500 italic",
            !expandSoundSection ? "hidden" : null
          )}
        >
          No sounds yet...
        </p>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts
      : null,
    sounds_updated: state.sounds.sounds_updated
      ? state.sounds.sounds_updated
      : null,
    sounds: state.sounds.sounds ? state.sounds.sounds : null,
    gameMaster: state.gameMaster,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    reorderSounds: (doc_id: string, puzzle_id: string, data: any) =>
      dispatch(reorderSounds(doc_id, puzzle_id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundClues);
