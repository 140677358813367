import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";

const EditPuzzleModal = (props) => {
  const { id, selectedPuzzleObj, puzzleUpdate } = props;

  const [data, setData] = useState({
    name: "",
    note: "",
    contains: "",
    color: "",
    type: "generic",
  });

  const [canEditPuzzleColor, setCanEditPuzzleColor] = useState(false);

  useEffect(() => {
    if (selectedPuzzleObj) setData(selectedPuzzleObj);
  }, [selectedPuzzleObj]);

  const updateData = (name, value) => {
    const newObj = {
      ...data,
      [name]: value,
    };
    setData(newObj);
  };

  const savePuzzle = () => {
    props.puzzleUpdate(data, id);
    setData({
      name: "",
      note: "",
      contains: "",
      color: "",
      type: "",
    });
  };


  return (
    <div
      className="modal effect-scale"
      id="editPuzzleModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-lg mb-1 text-white font-medium">Edit objective</p>

            <div className="flex flex-col mb-3">
              <label className="label-dark">
                Name
              </label>
              <input
                value={data.name}
                onChange={(e) => updateData("name", e.target.value)}
                type="text"
                className="input-dark"
                placeholder="Name of puzzle"
              ></input>
            </div>

            <div className="flex flex-col mb-3">
              <label className="label-dark">
                Custom Icon
              </label>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div
                  style={{
                    width: "20px",
                    marginRight: "15px",
                    display: "flex",
                  }}
                >
                  {data.type === "generic" && (
                    <svg
                      fill="#ffc107"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M227.3 265.9L141.3 352L182.6 393.4C195.1 405.9 195.1 426.1 182.6 438.6C170.1 451.1 149.9 451.1 137.4 438.6L96 397.3L77.25 416L118.6 457.4C131.1 469.9 131.1 490.1 118.6 502.6C106.1 515.1 85.87 515.1 73.37 502.6L9.372 438.6C-3.124 426.1-3.124 405.9 9.372 393.4L182.1 220.7C168.1 198.5 159.1 172.2 159.1 144C159.1 64.47 224.5 0 304 0C383.5 0 448 64.47 448 144C448 223.5 383.5 288 304 288C275.8 288 249.5 279.9 227.3 265.9H227.3zM304 224C348.2 224 384 188.2 384 144C384 99.82 348.2 64 304 64C259.8 64 224 99.82 224 144C224 188.2 259.8 224 304 224z" />
                    </svg>
                  )}

                  {data.type === "chest" && (
                    <svg
                      fill="#ffc107"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M352 336c0 8.875-7.125 16-16 16h-96C231.1 352 224 344.9 224 336V288H128v192h320V288h-96V336zM0 128v128h96V32C43 32 0 75 0 128zM0 448c0 17.62 14.38 32 32 32h64V288H0V448zM480 32v224h96V128C576 75 533 32 480 32zM304 304v-64C304 231.1 296.9 224 288 224S272 231.1 272 240v64C272 312.9 279.1 320 288 320S304 312.9 304 304zM480 480h64c17.62 0 32-14.38 32-32V288h-96V480zM128 256h96V208C224 199.1 231.1 192 240 192h96C344.9 192 352 199.1 352 208V256h96V32H128V256z" />
                    </svg>
                  )}

                  {data.type === "door" && (
                    <svg
                      fill="#ffc107"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path d="M560 448H512V113.5c0-27.25-21.5-49.5-48-49.5L352 64.01V128h96V512h112c8.875 0 16-7.125 16-15.1v-31.1C576 455.1 568.9 448 560 448zM280.3 1.007l-192 49.75C73.1 54.51 64 67.76 64 82.88V448H16c-8.875 0-16 7.125-16 15.1v31.1C0 504.9 7.125 512 16 512H320V33.13C320 11.63 300.5-4.243 280.3 1.007zM232 288c-13.25 0-24-14.37-24-31.1c0-17.62 10.75-31.1 24-31.1S256 238.4 256 256C256 273.6 245.3 288 232 288z" />
                    </svg>
                  )}

                  {data.type === "code" && (
                    <svg
                      fill="#ffc107"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path d="M336 160H352C369.7 160 384 174.3 384 192C384 209.7 369.7 224 352 224H256C238.3 224 224 209.7 224 192C224 174.3 238.3 160 256 160H272V76.4L266.1 78.36C249.4 83.95 231.2 74.89 225.6 58.12C220.1 41.35 229.1 23.23 245.9 17.64L293.9 1.642C303.6-1.61 314.4 .0261 322.7 6.04C331.1 12.05 336 21.71 336 32L336 160zM111.1 448H127.1C145.7 448 159.1 462.3 159.1 480C159.1 497.7 145.7 512 127.1 512H31.1C14.33 512-.0003 497.7-.0003 480C-.0003 462.3 14.33 448 31.1 448H47.1V364.4L42.12 366.4C25.35 371.9 7.231 362.9 1.642 346.1C-3.947 329.4 5.114 311.2 21.88 305.6L69.88 289.6C79.64 286.4 90.37 288 98.71 294C107.1 300.1 112 309.7 112 320L111.1 448zM-.0003 64C-.0003 28.65 28.65 .0001 63.1 .0001H127.1C163.3 .0001 191.1 28.65 191.1 64V160C191.1 195.3 163.3 224 127.1 224H63.1C28.65 224-.0003 195.3-.0003 160V64zM127.1 64H63.1V160H127.1V64zM320 288C355.3 288 384 316.7 384 352V448C384 483.3 355.3 512 320 512H256C220.7 512 191.1 483.3 191.1 448V352C191.1 316.7 220.7 288 256 288H320zM256 448H320V352H256V448z" />
                    </svg>
                  )}
                </div>

                <select
                  value={data.type}
                  onChange={(e) => updateData("type", e.target.value)}
                  className="input-dark"
                >
                  <option value="generic">Default</option>
                  <option value="chest">Chest</option>
                  <option value="door">Door</option>
                  <option value="code">Code</option>
                </select>
              </div>
            </div>

            <div className="flex flex-col mb-3">
            <label className="label-dark">
                  Answer
                </label>
              <input
                value={data.note}
                onChange={(e) => updateData("note", e.target.value)}
                type="text"
                className="input-dark"
                placeholder="Note (ex: Answer: 7083)"
              ></input>
            </div>

            <div className="flex flex-col mb-3">
            <label className="label-dark">
                  Description
                </label>
              <input
                value={data.contains}
                onChange={(e) => updateData("contains", e.target.value)}
                type="text"
                className="input-dark"
                placeholder="Golden key, message about second room."
              ></input>
            </div>

            {/* <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">
              Color
            </label>

            <Tooltip title="Change Color" placement="bottom">
              <div
                style={{ width: "24px", padding: "2px", background: "#d7e2e3" }}
                className="color-picker-swatch"
                onClick={() => setCanEditPuzzleColor(!canEditPuzzleColor)}
              >
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    background:
                      data.color === "transparent" ? "#fff" : data.color,
                  }}
                />
              </div>
            </Tooltip> */}

            {canEditPuzzleColor ? (
              <div
                style={{
                  position: "absolute",
                  zIndex: "2",
                  top: "60px",
                  left: "50px",
                }}
              >
                <div
                  style={{
                    position: "fixed",
                    top: "50px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                  }}
                  onClick={() => setCanEditPuzzleColor(!canEditPuzzleColor)}
                />
                <SketchPicker
                  color={data.color}
                  onChange={(e) => updateData("color", e.hex)}
                  onChangeComplete={(e) => updateData("color", e.hex)}
                  disableAlpha={true}
                  presetColors={[
                    "#EB144C",
                    "#FF6900",
                    "#FCB900",
                    "#04a015",
                    "#ff00ba",
                    "#4769e9",
                    "#003bf3",
                    "#2277a1",
                    "#ABB8C3",
                    "#000",
                    "#efefef",
                    "transparent",
                  ]}
                />
              </div>
            ) : null}
          </div>
          <div className="modal-footer-dark">
           
            {data.name ? (
              <button
                type="button"
                onClick={() => savePuzzle()}
                className="button-blue-dark"
                data-dismiss="modal"
              >
                Save
              </button>
            ) : (
              <button
                type="button"
                className="button-blue-dark opacity-50"
                data-dismiss="modal"
              >
                Save
              </button>
            )}
             <button
              type="button"
              className="button-outline-dark ml-2"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPuzzleModal;
