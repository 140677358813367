import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  updateClueOnScreenCount,
  updateInfiniteClues,
  updateHideCluesOnScreen
} from "../../../store/actions/timerActions";
import {
  remoteUpdateClueCount,
  remoteSetInfiniteClues,
} from "../../../store/actions/remoteActions";

import { classNames } from "../../../utils/Utils";
import EditClueIconsModal from "./modals/EditClueIconsModal";

const ThreeClues = (props) => {
  const { remoteLiveWindow, game_doc } = props;

  const originalClueCount = useRef(0)

  const [clues, setClues] = useState({
    0: true,
    1: true,
    2: true,
  });

  useEffect(() => {
    setClues(props.timer.clueOnScreenCount);
  }, [props.timer]);


  const [unlimitedHints, setUnlimitedHints] = useState(false);
  const [hideHints, setHideHints] = useState(false);
  const [hoverOverSettings, setHoverOverSettings] = useState(false);

  // Expand Section
  const [expandSection, setExpandSection] = useState(true);

  useEffect(() => {
    try {
      const serializedState = localStorage.getItem("BOLT_preferences");

      if (serializedState === null) {
        return localStorage.setItem("BOLT_preferences");
      }

      setExpandSection(JSON.parse(serializedState).cluesOnScreenToggle);
      setHideHints(JSON.parse(serializedState).hideCluesOnScreen);
      customSwitch1.checked = JSON.parse(serializedState).hideCluesOnScreen;
    } catch (err) {
      return false;
    }
  }, []);

  const ExpandSection = (bool) => {
    setExpandSection(bool);
    localStorage.setItem(
      "BOLT_preferences",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("BOLT_preferences")),
        cluesOnScreenToggle: bool,
      })
    );
  };

  const HideCluesOnScreen = (bool) => {
    setHideHints(bool);
    localStorage.setItem(
      "BOLT_preferences",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("BOLT_preferences")),
        hideCluesOnScreen: bool,
      })
    );
  };

  const updateClue = (name, value) => {
    const newObj = {
      ...clues,
      [name]: value,
    };
    setClues(newObj);

    if (remoteLiveWindow) {
      props.remoteUpdateClueCount(newObj, props.id);
    } else {
      props.updateClueOnScreenCount(newObj);
    }
  };

  const updateUnlimitedHints = () => {
    setUnlimitedHints(!unlimitedHints);

    if (remoteLiveWindow) {
      props.remoteSetInfiniteClues(!unlimitedHints, props.id);
    } else {
      props.updateInfiniteClues(!unlimitedHints);
    }
  };

  const updateHideHints = (status) => {
    HideCluesOnScreen(!hideHints);
    if (status) {
      setHideHints(true);
      props.updateHideCluesOnScreen(true);
    } else {
      setHideHints(false);
      props.updateHideCluesOnScreen(false);
    }
  };

  const showClues = () => {
    if (!hideHints) {
      return (
        <React.Fragment>
          {Object.keys(clues).map((clue, index) => {
            const location = "clues." + index;

            if (clues[clue] === true) {
              return (
                <button
                  key={location}
                  onClick={(e) => updateClue(index, !clues[clue])}
                  type="button"
                  className="col-span-1 flex justify-center items-center bg-v1_dark-hover hover:bg-gray-600 duration-75 w-full h-[40px] border border-v1_dark-hover rounded-sm focus:outline-none group"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {/* <i className="icon ion-ios-flash" style={{color:"#ecec58"}}></i> */}
                  <div>
                    {/* <svg fill='#67ab67' style={{height:'20px', width:'20px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M80 192V144C80 64.47 144.5 0 224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80zM144 192H304V144C304 99.82 268.2 64 224 64C179.8 64 144 99.82 144 144V192z"/></svg> */}
                    <svg
                      //   fill="#67ab67"
                      className="fill-green-500 group-hover:fill-green-400"
                      style={{ height: "20px", width: "20px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80V144C80 64.47 144.5 0 224 0zM224 64C179.8 64 144 99.82 144 144V192H304V144C304 99.82 268.2 64 224 64zM256 320C256 302.3 241.7 288 224 288C206.3 288 192 302.3 192 320V384C192 401.7 206.3 416 224 416C241.7 416 256 401.7 256 384V320z" />
                    </svg>
                  </div>
                </button>
              );
            } else {
              return (
                <button
                  key={location}
                  onClick={(e) => updateClue(index, !clues[clue])}
                  type="button"
                  className="col-span-1 flex justify-center items-center bg-v1_dark-hover hover:bg-gray-700 duration-75 w-full h-[40px] rounded-sm focus:outline-none border border-v1_dark-hover"
                  style={{
                    fontSize: "18px",
                  }}
                  //   style={{
                  //     fontSize: "28px",
                  //     padding: "0px 20px",
                  //     height: "45px",
                  //     background: "rgb(34, 34, 43)",
                  //   }}
                >
                  <i
                    className="icon ion-md-close"
                    // style={{ color: "#e54646" }}
                  ></i>
                </button>
              );
            }
          })}
        </React.Fragment>
      );
    } else {
      return (
        <button
          disabled
          type="button"
          className="col-span-1  flex justify-center items-center bg-v1_dark-hover hover:bg-gray-700 duration-75 w-full h-[40px] rounded-sm focus:outline-none border border-v1_dark-hover text-[24px]"
        >
          <i
            className="icon ion-md-eye-off"
            style={{ color: "white", opacity: "0.5" }}
          ></i>
        </button>
      );
    }
  };


  return (
    <div id="hints-remaining-box" className=" border-b border-v1_dark-hover">
      {/* <p className="sidebar-header-title-dark">Clues Available</p> */}

      <button
        onClick={() => !hoverOverSettings ? ExpandSection(!expandSection) : null}
        className={classNames(
          "px-3 py-2 flex justify-between items center space-between w-full focus:outline-none duration-100 hover:bg-v1_dark-hover hover:bg-opacity-20"
        )}
      >
        <div>
          <div className="flex items-center space-x-2">
            <p className="text-white font-medium">Clues</p>
            <i
              className={classNames(
                "icon ion-md-eye-off text-gray-400",
                !hideHints ? "hidden" : null
              )}
            ></i>
          </div>
        </div>

        <div className="flex items-center justify-center">
          
          <button
            href="#editClueIconsModal"
            data-toggle="modal"
            onMouseEnter={()=> setHoverOverSettings(true)}
            onMouseLeave={()=> setHoverOverSettings(false)}
            className={classNames(
              "text-gray-500 hover:text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75"
            )}
          >
            <i class="fa-solid fa-ellipsis"></i>
          </button>
          <button
            onClick={() => ExpandSection(!expandSection)}
            className={classNames(
              "text-gray-500 px-1.5 rounded-sm hover:bg-gray-700 focus:outline-none duration-75",
              !expandSection ? "rotate-[180deg]" : "rotate-[270deg]"
            )}
          >
            <i className="fa-solid fa-chevron-left"></i>
          </button>
        </div>
      </button>

      <div
        className={classNames(
          "px-3 py-2 text-center mb-1",
          !expandSection ? "hidden" : ""
        )}
      >
        <div>
          <div className={classNames(
            "",
            Object.keys(clues).length > 5 ? "grid grid-cols-5 gap-2" : "flex items-center justify-between space-x-2",
            hideHints ? "grid-cols-1" : null
          )}>
            {showClues()}
          </div>
        </div>

        <div className="custom-control custom-switch mt-3">
          <input
            onChange={() => updateHideHints(!hideHints)}
            type="checkbox"
            className="custom-control-input"
            id="customSwitch1"
          />
          <label
            className="custom-control-label text-xs"
            htmlFor="customSwitch1"
          >
            Hide clue icons on live window
          </label>
        </div>
      </div>

      <EditClueIconsModal game_doc={game_doc} count={Object.keys(clues).length} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    remote: state.remote,
    timer: state.timer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClueOnScreenCount: (clueArray) =>
      dispatch(updateClueOnScreenCount(clueArray)),
    updateInfiniteClues: (bool) => dispatch(updateInfiniteClues(bool)),
    remoteUpdateClueCount: (array, doc) =>
      dispatch(remoteUpdateClueCount(array, doc)),
    remoteSetInfiniteClues: (bool, doc) =>
      dispatch(remoteSetInfiniteClues(bool, doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreeClues);
