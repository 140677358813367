import React, { useState } from "react";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

import { Style, ProfilePic } from "./Exports";

type Props = {
  type: string;
  profile: any;
  messages: any;
  messageObj: any;
  timer: any;
  progressBar: any;
};

export const Bot = (props: Props) => {
  const { messageObj, timer, type, progressBar } = props;
  const clueOnScreen = timer ? timer.clueOnScreen : "0";

  const Text = () => {
    if (type === "timer-start")
      return "Game started at " + moment(messageObj.timestamp).format("LT");
    if (type === "timer-adjust") return messageObj.message;
    if (type === "game-ended")
      return messageObj.status === "complete"
        ? "Game completed at " +
            moment(messageObj.timestamp).format("LT") +
            ". The players had " +
            messageObj.game_timestamp.m +
            ":" +
            messageObj.game_timestamp.s +
            " left on the clock."
        : "Game over at " +
            moment(messageObj.timestamp).format("LT") +
            ". The players had " +
            messageObj.game_timestamp.m +
            ":" +
            messageObj.game_timestamp.s +
            " left on the clock.";
    if (type === "alert-button") return "The alert tone has been triggered.";
  };

  const Title = () => {
    if (type === "video-playing") return "Video Brief is playing.";
    if (type === "timer-start") return "The game has started.";
    if (type === "game-ended")
      return messageObj.status === "complete" ? "Game Complete" : "Game over";
    if (type === "timer-adjust") return messageObj.message;
    if (type === "alert-button") return "Alert tone.";
    return "Bolt";
  };

  const [hover, setHover] = useState(false);

  if (messageObj && messageObj.shortened) {
    return (
      <div
        key={Math.random() * 1000}
        className="media message-feed-item"
        style={Style(null, clueOnScreen, messageObj, "bot")}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                opacity: hover ? "1" : "0",
                width: "46px",
              }}
            >
              <span
                className="text-muted"
                style={{
                  fontWeight: "500",
                  fontSize: "12px",
                  textAlign: "right",
                  whiteSpace: "nowrap",
                }}
              >
                {messageObj && moment(messageObj.timestamp).format("LT")}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "15px",
                width: "100%",
              }}
            >
              <h5
                className="mg-t-2 mg-b-2"
                style={{
                  fontWeight: "400",
                  fontSize: "15px",
                  whiteSpace: "pre-wrap",
                }}
              >
                {messageObj && messageObj.message}
              </h5>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      key={Math.random() * 1000}
      className="media"
      style={Style(null, clueOnScreen, messageObj, "bot")}
    >
      <div
        className="media-body"
        style={{
          marginLeft: "10px",
          paddingRight: "20px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft: "23px",
              marginBottom: "0px",
              marginTop: "0px",
              height: "18px",
            }}
          >
            <i
              className="icon ion-ios-eye"
              style={{ marginRight: "10px", fontSize: "18px" }}
            ></i>
            <small
              className="d-block text-muted"
              style={{
                fontSize: "12px",
              }}
            >
              Only visible to you
            </small>
          </div> */}

          <div
          className="flex items-center"
          >
            {/* <ProfilePic icon="rocket" type="bot" /> */}
            <div>
              <div className="flex items-center mb-0">
                <p className="mb-0 whitespace-pre-wrap font-semibold text-white">
                  {Title()}
                </p>

                <div>
                  <span className="text-gray-500 text-xs ml-2">
                    {messageObj && moment(messageObj.timestamp).format("LT")}
                  </span>
                </div>
              </div>

              {/* <p className="mb-0 whitespace-pre-wrap text-white">{Text()}</p> */}

              {type === "video-playing" && (
                <div style={{ width: "400px" }}>
                  <div
                    className="progress"
                    style={{ marginTop: "10px", width: "50%", height: "5px" }}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: progressBar + "%" }}
                      role="progressbar"
                      aria-valuenow={25}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* <small className="d-block text-muted">
  {moment(messageObj.timestamp).fromNow()}
</small> */}

          {/* {messageObj.puzzle ? (
  <small className="d-block text-muted">
    <span style={{ fontWeight: "500" }}>{messageObj.puzzle}</span> -
    {moment(messageObj.timestamp).fromNow()}
  </small>
) : (
  <small className="d-block text-muted">
    {moment(messageObj.timestamp).fromNow()}
  </small>
)} */}
        </div>
      </div>
    </div>
  );
};
