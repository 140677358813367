import Chart from "chart.js/auto";

export default function PostGameChart(props) {
  const { messages, puzzlesDone, time, startingSeconds } = props;
  const seconds = (startingSeconds / 60) +1;

  const filterMessages = () => {
    
    var newMessages = [];
    messages.forEach((msg) => {
      if (msg.type === "text" || msg.type === "audio") {
        return newMessages.push(msg);
      }
    });

    // var result = newMessages.reduce(function (r, a) {
    //     r[a.game_timestamp.m] = r[a.game_timestamp.m] || [];
    //     r[a.game_timestamp.m].push(a)
    //     return r;
    //    }, Object.create(null))

    //    console.log('result raw', Object.entries(result)[0][0])

    var times = [];
    for (let i = 0; i < seconds; i++) {
      const elements = newMessages.filter((x) => x.game_timestamp.m === i);
      //   console.log("elements", elements);

      if (elements.length > 0) {
        
        times[i] = {
          m: i,
          count: elements.length,
        };
      } else {
        times.push({
          m: i,
          count: 0,
        });
      }
    }
    return times.reverse();
  };

  const getPuzzleData = () => {
    var times = [];
    for (let i = 0; i < seconds; i++) {
      const elements = puzzlesDone.filter((x) => x.completed_at.timer.m === i);
    //   console.log("elements", elements);

      if (elements.length > 0) {
        times[i] = {
          m: i,
          count: elements.length,
        };
      } else {
        times.push({
          m: i,
          count: 0,
        });
      }
    }

    // console.log("puzzle times", times);
    return times.reverse();
  };

  Chart.defaults.backgroundColor = "#fff";

  setTimeout(() => {
    // const times = getTimes();
    const messagesData = filterMessages();
    const puzzleData = getPuzzleData();

    new Chart(document.getElementById("analytics-card-01").getContext("2d"), {
      type: "line",
      data: {
        // labels: messagesData.map(data => {
        //     return data.game_timestamp.m + ":" + data.game_timestamp.s
        // }),
        labels: messagesData.map((time) => time.m + ":00"),
        datasets: [
          {
            label: "Messages Sent",
            data: messagesData.map((time) => time.count),
            fill: true,
            backgroundColor: "rgba(59, 130, 246, 0.08)",
            borderColor: "rgb(99, 102, 241)",
            borderWidth: 2,
            tension: 0,
            pointRadius: 1,
            pointHoverRadius: 3,
            pointBackgroundColor: "rgb(99, 102, 241)",
          },
          {
            label: "Objective Complete",
            data: puzzleData.map((puzzle) => puzzle.count),
            borderColor: 'rgb(203, 213, 225, 0.5)',
            fill: false,
            borderWidth: 2,
            tension: 0,
            pointRadius: 1,
            pointHoverRadius: 3,
            pointBackgroundColor: "rgb(203, 213, 225)",
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            min:0,
            ticks: {
                autoSkipPadding: 24,
                maxRotation: 0,
              },
            grid: {
              drawBorder: false,
            },
          },
          x: {
            beginAtZero: true,
            
            ticks: {
              autoSkipPadding: 48,
              maxRotation: 0,
              includeBounds: true,
              
            },
            grid: {
              display: false,
              drawBorder: false,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: () => false, // Disable tooltip title
            },
          },
        },
        interaction: {
          intersect: false,
          mode: "nearest",
        },
      },
    });
  }, 2000);
}
