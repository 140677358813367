export const initState = {
    active_players: 0,
    total_players: 0,
    notes: "",
    team_name: "",
    gamemaster: {
        name: null,
        profile_pic: null
    }
}

const GameDetailsReducer = (state = initState, action) => {
    switch (action.type) {

        case 'UPDATE_GAME_DETAILS':
            //console.log("Updated timer!", action.timer)
            return {
                ...initState,
                ...action.details  
            }


        default:
            return state;
    }
}

export default GameDetailsReducer;