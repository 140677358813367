export const initState = {
    sounds_updated: false,
    sounds: []
}

const soundsReducer = (state = initState, action) => {
    switch (action.type) {

        case 'LOAD_SOUNDS':
            //console.log("File Uploaded")
        return {
            ...state,
            sounds: action.sounds
        };

        case 'UPLOADED_SOUND':
            //console.log("File Uploaded")
        return state;

        case 'UPDATED_SOUND':
            //console.log("File Uploaded")
        return {
            ...state,
            sounds_updated: action.status
        };


        default:
            return state;
    }
}

export default soundsReducer;