import React, { useState, useEffect } from "react";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";

import { Style, ProfilePic } from "./Exports";
import { classNames } from "../../../../../utils/Utils";

type Props = {
  account: {
    account_email: string;
    admins: any;
    company_size: string;
    created_at: any;
    id: string;
    onboarding: { gm: boolean };
    permissions: any;
    room_usage: number;
    staff: any;
    subscription: {
      customer_id: string;
    };
    title: string;
  };
  profile: any;
  messages: any;
  messageObj: any;
  timer: any;
};

export const Message_Text = (props: Props) => {
  const { messageObj, timer, messages, account, profile } = props;

  const message = messageObj ? messageObj.message : "0";
  const clueOnScreen = timer ? timer.clueOnScreen : "0";

  // const style = () => {
  //   var style: any = {
  //       padding: "10px 20px",
  //       margin: "10px 0px",
  //       alignItems: "center"
  //   };

  //   if (hover) style = { ...style, background: "#2c2c35"}

  //   return style
  // };

  const isClueOnScreen = () => {
    if (message === clueOnScreen) return true;
  };

  const [hover, setHover] = useState(false);
  const [loaded, setLoaded] = useState(true);
  // useEffect(()=> {
  //   setTimeout(() => {
  //     setLoaded(true)
  //   }, 700);
  // },[])

  if (messageObj.shortened) {
    return (
      <div
        key={Math.random() * 1000}
        className="media message-feed-item"
        style={Style(hover, clueOnScreen, messageObj)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div>
          {/* {isClueOnScreen() && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "3px",
                marginLeft:'35px',
                marginTop: "0px",
                height: "18px",
              }}
            >
              <i
                className="icon ion-ios-eye"
                style={{ marginRight: "10px", fontSize: "18px" }}
              ></i>
              <small
                className="d-block text-muted"
                style={{
                  fontSize: "12px",
                }}
              >
                Visible to players
              </small>
            </div>
          )} */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginLeft:'35px'
            }}
          >
            {/* <div
              style={{
                opacity: hover ? "1" : "0",
                width: "46px",
              }}
            >
              <span
                className="text-xs text-gray-500 whitespace-nowrap"
              >
                {moment(messageObj.timestamp).format("LT")}
              </span>
            </div> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "15px",
                width: "100%",
              }}
            >
              <p className="mb-0 whitespace-pre-wrap text-white">
                <span
                className="mr-2 font-medium text-blue-600"
                >
                  {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
                </span>
                {messageObj.message}
                </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
       {/* {isClueOnScreen() && (
             <div key={Math.random() * 1000} className="chat-group-divider text-muted w-full pt-8">
             <i className="icon ion-md-tv" style={{ marginRight: "5px" }}></i> Visible on Player Screen
           </div>
          )} */}


      <div
        key={Math.random() * 1000}
        className={classNames(
          "media message-feed-item duration-150",
          !loaded ? "opacity-40" : "opacity-100",
        )}
        style={Style(hover, clueOnScreen, messageObj)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {/* <div style={{ marginRight: "20px" }}>
        <h5>
          {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
        </h5>
      </div> */}
      

        <div
          className="media-body w-full"
          style={{
            marginLeft: "10px",
            paddingRight: "20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          
          <div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "4px",
              }}
            >
              <ProfilePic icon="flash" type="text" />
              <div>
                {/* <div
                  className="flex items-center mb-1"
                >
                  <p
                    className="mb-0 whitespace-pre-wrap font-semibold text-white"
                  >
                    {account.title}
                  </p>
 
                  <div>
                    <span className="text-gray-500 text-xs ml-2">
                      {moment(messageObj.timestamp).format("LT")}
                    </span>
                    {messageObj.puzzle && (<span className="text-gray-500 px-1">&#183;</span>)}
                    <span className="text-gray-500 text-xs">
                      {messageObj.puzzle && messageObj.puzzle}
                    </span>
                  </div>
                </div> */}

                <p
                  className="mb-0 whitespace-pre-wrap text-white"

                >
                  {/* <span
                    className="mr-2 font-medium text-blue-600"
                  >
                    {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
                  </span> */}
                  {messageObj.message}
                </p>

                <div>
                <span
                    className="mr-2 font-medium text-blue-600"
                  >
                    {messageObj.game_timestamp.m}:{messageObj.game_timestamp.s}
                  </span>
                    <span className="text-gray-500 text-xs">
                      {moment(messageObj.timestamp).format("LT")}
                    </span>
                    {messageObj.puzzle && (<span className="text-gray-500 px-1">&#183;</span>)}
                    <span className="text-gray-500 text-xs">
                      {messageObj.puzzle && messageObj.puzzle}
                    </span>
                  </div>


              </div>
            </div>

            {/* <small className="d-block text-muted">
  {moment(messageObj.timestamp).fromNow()}
</small> */}

            {/* {messageObj.puzzle ? (
  <small className="d-block text-muted">
    <span style={{ fontWeight: "500" }}>{messageObj.puzzle}</span> -
    {moment(messageObj.timestamp).fromNow()}
  </small>
) : (
  <small className="d-block text-muted">
    {moment(messageObj.timestamp).fromNow()}
  </small>
)} */}
          </div>
        </div>
      </div>
    </div>
  );
};
