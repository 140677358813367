

// Game Details
export const updateGameDetails = (details) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const db = getFirestore();
      const account_id = getState().firebase.profile.account_id;
  
    
      dispatch({ type: "UPDATE_GAME_DETAILS", details});
    }
  }