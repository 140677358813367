import React from "react";

import CountdownTimer from "./CountdownTimer";
//import LiveWindowControls from './LiveWindowControls'
import ThreeClues from "./ThreeClues";
import SettingsModal from "../GMControl/modals/SettingsModal";

function RightSideBar(props) {
  const {
    id,
    account,
    handleAddMessage,
    game_assets,
    handleResetChat,
    handleResetSentClues,
    loaded_assets,
    handleAddedMinutes,
    remoteLiveWindow,
    profile,
    messages,
    handlePlayVideoMessage,
    handleStartTimerMessage,
    handleSaveGameData,
    handleEndGameMessage,
    puzzlesDone,
    puzzles,
    hintsUsed,
    game_name,
    addedMinutes,
  } = props;

  const isAlertTone = () => {
    if (loaded_assets.alert) {
      //const time = loaded_assets.alert_duration;
      const message = loaded_assets.alert_name;
      return (
        <div>
          <span
            style={{
              marginLeft: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "125px",
            }}
            className="badge badge-dark"
          >
            {message}
          </span>
        </div>
      );
    } else {
      return <span className="badge badge-danger">No Alert</span>;
    }
  };

  const isSoundtrack = () => {
    if (loaded_assets.soundtrack) {
      //const duration = loaded_assets.soundtrack_duration;
      const message = loaded_assets.soundtrack_name;
      return (
        <div>
          <span
            style={{
              marginLeft: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "110px",
            }}
            className="badge badge-dark"
          >
            {message}
          </span>
        </div>
      );
    } else {
      return <span className="badge badge-danger">No Soundtrack</span>;
    }
  };

  const isSuccess = () => {
    if (loaded_assets.success) {
      const message = loaded_assets.success_name;
      //const time = loaded_assets.success_duration;
      return (
        <div>
          <span
            style={{
              marginLeft: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "125px",
            }}
            className="badge badge-dark"
          >
            {message}
          </span>
        </div>
      );
    } else {
      return <span className="badge badge-danger">No Success Track</span>;
    }
  };

  const isFail = () => {
    if (loaded_assets.fail) {
      const message = loaded_assets.fail_name;
      //const time = loaded_assets.fail_duration;
      return (
        <div>
          <span
            style={{
              marginLeft: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "125px",
            }}
            className="badge badge-dark"
          >
            {message}
          </span>
        </div>
      );
    } else {
      return <span className="badge badge-danger">No Fail Track</span>;
    }
  };

  const isVideoBrief = () => {
    if (loaded_assets.video_brief) {
      //const message = loaded_assets.video_briefIsLoaded;
      //const time = loaded_assets.fail_duration;
      return (
        <div>
          <span style={{ marginLeft: "10px" }} className="badge badge-dark">
            Video Available
          </span>
        </div>
      );
    } else {
      return <span className="badge badge-danger">No Video</span>;
    }
  };

  const displayName = () => {
    if (profile.firstName && profile.lastName)
      return profile.firstName + " " + profile.lastName;
    else return account.title;
  };

  return (
    <div
      className="chat-sidebar-right"
      style={{ top: "0px", background: "none", border: "none" }}
    >
      {/* <div className="box-header" style={{ paddingRight: "10px" }}>
        <p className="mb-0 text-gray-300">
          <i class="fa-regular fa-dial" style={{ marginRight: "8px" }}></i>
          Controls
        </p>
      </div> */}
      {/* <div className="chat-content-header" style={{paddingRight:"10px"}}>
            
            
            <h6 style={{marginBottom:"0px"}}>
            <i className="icon ion-ios-contact" style={{marginRight:"5px"}}></i>
               {displayName()}
            </h6>
            <div className="d-flex">
              <nav id="channelNav" style={{fontSize:"18px"}}>
                <a href="#settingsModal" data-toggle="modal"><span data-toggle="tooltip" title="Room Settings">
                    <i className="icon ion-ios-help-buoy" style={{fontSize:"20px"}}></i></span></a>
              </nav>
            </div>
          </div> */}

      <div
        className="chat-sidebar-body border-l border-v1_dark-hover shadow overflow-scroll"
        style={{
        //   borderLeft: "1px solid black",
          top: "0px",
          paddingTop: "0px",
        }}
      >
        <CountdownTimer
          id={id}
          messages={messages}
          game_assets={game_assets}
          timeTillDate={new Date() + 1}
          handleAddMessage={handleAddMessage}
          handleResetChat={handleResetChat}
          handleResetSentClues={handleResetSentClues}
          handleAddedMinutes={handleAddedMinutes}
          remoteLiveWindow={remoteLiveWindow}
          handlePlayVideoMessage={handlePlayVideoMessage}
          handleStartTimerMessage={handleStartTimerMessage}
          handleSaveGameData={handleSaveGameData}
          pushToMessagesFeed={props.pushToMessagesFeed}
          handleTimerAdjustMessage={props.handleTimerAdjustMessage}
          handleEndGameMessage={handleEndGameMessage}
          puzzlesDone={puzzlesDone}
          puzzles={puzzles}
          hintsUsed={hintsUsed}
          game_name={game_name}
          addedMinutes={addedMinutes}
        />

        <ThreeClues remoteLiveWindow={remoteLiveWindow} game_doc={id} />

        {/* <div className="card" style={{marginBottom:"20px"}}>
        <div className="card-body" style={{padding:"10px"}}>
            

            <div style={{display:"flex", flexDirection:"row"}}>
                <span style={{marginRight:"10px"}}>
                    <p className="card-text text-muted" style={{marginBottom:"5px"}}>
                        Alert:</p>
                </span>

    <span>
        {isAlertTone()}
    </span>
            </div>


            <div style={{display:"flex", flexDirection:"row"}}>
                <span style={{marginRight:"10px"}}>
                    <p className="card-text text-muted" style={{marginBottom:"5px"}}>
                        Soundtrack:</p>
                </span>

    <span>
        {isSoundtrack()}
    </span>
            </div>


            <div style={{display:"flex", flexDirection:"row"}}>
                <span style={{marginRight:"10px"}}>
                    <p className="card-text text-muted" style={{marginBottom:"5px"}}>
                        Victory:</p>
                </span>

                <span>
                    {isSuccess()}
                </span>
            </div>


            <div style={{display:"flex", flexDirection:"row"}}>
                <span style={{marginRight:"10px"}}>
                    <p className="card-text text-muted" style={{marginBottom:"5px"}}>
                        Fail:</p>
                </span>

                <span>
                    {isFail()}
                </span>
            </div>
            
    </div>
                

              </div> */}

        <SettingsModal />
      </div>
    </div>
  );
}

export default RightSideBar;
