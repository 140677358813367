import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { classNames } from "../../../../utils/Utils";

import { addNewSound, uploadSound } from "../../../../store/actions/gmActions";

type Props = {
  id: string;
  puzzles: any;
  handleAddNewClue: (e: any) => void;
  selectedPuzzleObj: any;
  game_id: string;
  addNewSound: (data: any, id: string, puzzle_id: string) => void;
  uploadSound: (file: any, game_doc: any) => void;
  upload_sound_progress: number;
  uploaded_sound_data: any;
};

const AddSoundModal = (props: Props) => {
  const {
    id,
    puzzles,
    handleAddNewClue,
    selectedPuzzleObj,
    game_id,
    upload_sound_progress,
    uploaded_sound_data,
    uploadSound,
  } = props;

  const [error, setError] = useState("");

  const [data, setData] = useState({
    title: "",
    file: {
      name: "",
      url: null,
      duration: 0,
      size: 0,
    },
  });

  const updateData = (name: string, value: any) => {
    const newObj = {
      ...data,
      [name]: value,
    };
    setData(newObj);
  };

  useEffect(() => {
    if (uploaded_sound_data) {
      setData({
        ...data,
        title: !data.title ? uploaded_sound_data.name : data.title,
        file: uploaded_sound_data,
      });
    }
  }, [uploaded_sound_data]);

  const title = selectedPuzzleObj ? selectedPuzzleObj.name : "";

  const uploadFile = (file: any) => {
    console.log("file", file);
    setError("");
    // Check if size is less than 100MB
    if (file.size <= 100000000) {
      uploadSound(file, game_id);
    } else {
      setError("File is too big. Max size is 100MB.");
    }
  };

  return (
    <div
      className="modal effect-scale"
      id="addSoundModal"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered wd-sm-650"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body">
            <div className="mb-b-10">
              <p className="text-lg mb-1 text-white font-medium">Add sound</p>
            </div>

            <div className="flex flex-col mb-3">
              <label className="label-dark">Name</label>
              <input
                value={data.title}
                onChange={(e) => updateData("title", e.target.value)}
                type="text"
                className="input-dark"
                placeholder="Name of sound"
              ></input>
            </div>

            <div className="flex flex-col">
              <label className="label-dark">File</label>
              <div
                className={classNames(
                  "flex items-center justify-center w-full",
                  uploaded_sound_data ? "hidden" : null
                )}
              >
                <label
                  //for="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 bg-v1_dark-hover hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                >
                  <div className="flex flex-col items-center text-center justify-center">
                    {upload_sound_progress > 0 ? (
                      <div>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">
                            Uploading: {upload_sound_progress.toFixed(0)}%
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          MP3, WAV (MAX. 100MB)
                        </p>
                      </div>
                    )}

                    <p className="text-red-500 font-medium mt-3">
                      {error && error}
                    </p>
                  </div>
                  <input
                    onChange={(e: any) => uploadFile(e.target.files[0])}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                    accept=".mp3, .wav"
                  />
                </label>
              </div>

              {uploaded_sound_data && (
                <div className={classNames("w-full flex items-center")}>
                  <i className="fa-solid fa-file-check text-green-500 h-8 mr-3"></i>
                  <div>
                    <p className="text-white">{uploaded_sound_data.name}</p>
                    <p className="text-sm text-gray-500">
                      {uploaded_sound_data.type}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="modal-footer-dark">
            {data.file.url ? (
              <button
                onClick={() => {
                  props.addNewSound(data, game_id, selectedPuzzleObj.id)
                  setTimeout(() => {
                    setData({
                      title: "",
                      file: {
                        name: "",
                        url: null,
                        duration: 0,
                        size: 0,
                      },
                    });
                  }, 1000);
                }}
                type="button"
                className="button-blue-dark"
                data-dismiss="modal"
              >
                Create
              </button>
            ) : (
              <button
                disabled
                type="button"
                className="button-blue-dark opacity-50"
                data-dismiss="modal"
              >
                Create
              </button>
            )}
            <button
              type="button"
              className="button-outline-dark ml-2"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
    upload_sound_progress: state.settings.upload_sound_progress,
    uploaded_sound_data: state.settings.uploaded_sound_data,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    addNewSound: (data: any, id: string, puzzle_id: string) =>
      dispatch(addNewSound(data, id, puzzle_id)),
    uploadSound: (file: any, game_doc: string) =>
      dispatch(uploadSound(file, game_doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSoundModal);
