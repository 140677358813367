import React, { Component } from "react";
import { connect } from "react-redux";
import {
  loadStateFromLocalStorage,
  loadAudioState,
} from "../../../localStorage";

import uuid from "react-uuid";

// Sentry.io
import * as Sentry from "@sentry/react";

import RightSideBar from "./RightSideBar";
import { updateClueOnScreen } from "../../../store/actions/timerActions";
import { remoteSendClue } from "../../../store/actions/remoteActions";
import { finishGameDataUpload } from "../../../store/actions/gmActions";

import Messages from "./ControlsBox/Messages";
import AudioControls from "./ControlsBox/AudioControls";

class Chat extends Component {
  state = {
    messages: [],
    previousMessage: [],
    audioCluePlay: false,
    hintsUsed: 0,
    avgCluePerMinute: 0,
    addedMinutes: 0,
    gameStartedAt: "",
    gameEndedAt: "",
    finishedPuzzles: this.props.puzzlesDone
  };

  // Audio Files
  default_alert_tone = new Audio("/assets/audio/default_alert.mp3");
  timeout;

  componentDidMount() {
    this.props.updateClueOnScreen("");
  }
  
  // componentDidUpdate(prevProps, prevState, snapshot) {

  //   if (this.state.finishedPuzzles !== prevState.finishedPuzzles){
  //     console.log('yes', this.state.finishedPuzzles)
  //     this.pushToMessagesFeed('puzzle', this.state.finishedPuzzles[this.state.finishedPuzzles.length - 1])
  //   }

  // }

  componentWillUnmount() {
    Sentry.configureScope((scope) => scope.clear());
  }

  processIncomingMessage = (msgObj, type, func) => {
    const messages = this.state.messages;
    const previousMessage = this.state.previousMessage;
    const last_message = this.state.messages[this.state.messages.length - 1];

    const puzzle = msgObj.puzzle;
    const puzzle_doc_id = msgObj.puzzle_doc_id;
    const puzzle_id = msgObj.puzzzle_id;
    const selected = msgObj.selected;

    const message = msgObj.message;
    const id = msgObj.id;

    if (message) {
      const localStorage = loadStateFromLocalStorage();

      var messageObj = {
        key: uuid(),
        ...msgObj,
        game_timestamp: {
          m: Number(localStorage.timer.time.m),
          s: Number(localStorage.timer.time.s)
        },
        timestamp: new Date(),
        type: type,
        shortened: false
    };

      if (func === 'join'){

        if (messageObj.type === 'text' && last_message.type === 'text'){
            messages.pop()
            messages.push({
                ...last_message,
                message: last_message.message + '\n\n' + message,
                type: type,
                key: uuid(),
            })

            // Update Player Window
            this.props.updateClueOnScreen(last_message.message + '\n\n' + message);

        } else {
            // Add Primary Message to Feed
            messages.push(messageObj);
            // Update Player Window
            this.props.updateClueOnScreen(messageObj.message);
        }

      } else {

            // Check if timestamps are less than 15 seconds a part
            if (last_message && new Date(last_message.timestamp).getTime() + 15000 >= new Date(new Date()).getTime()) {
                
                // console.log(new Date(last_message.timestamp).getTime());
                // console.log(new Date(new Date()).getTime())
        
                // console.log('last_message', last_message)
                
                if (messageObj.type === 'text' && last_message.type === 'text'){
                    messageObj = {
                        ...messageObj,
                        shortened: true
                    }
                }
        
            }
        
            // Add Primary Message to Feed
            messages.push({
                ...messageObj,
                message: messageObj.message
            });
            // Update Player Window
            this.props.updateClueOnScreen(messageObj.message);
      }


      this.setState({
        messages: messages,
        previousMessage: messageObj.type === 'text' ? messageObj : this.state.previousMessage,
        hintsUsed: this.state.hintsUsed + 1,
        avgCluePerMinute: this.avgCluePerMin(),
        showCountdownBar: true,
      });
    }
  };

  pushToMessagesFeed = (type, message) => {
    var messageObj = {
      type: type,
      message,
      timestamp: new Date(),
      key: uuid()
    };

    var messagesArray = this.state.messages;
    messagesArray.push(messageObj);

    this.setState({
      messages: messagesArray,
    });
  };

  playAlertAndFadeSoundtrack = () => {
    const { assets } = this.props.gameMaster;
    const audioState = loadAudioState();
    //const soundtrack_vol = assets.audio_soundtrack.vol;

    if (assets.audio_soundtrack && assets.audio_soundtrack.sound) {
      const soundtrackState = audioState["audio_soundtrack"];
      const fadeToLevel = soundtrackState * 0.1;

      // Fade Down Soundtrack
      assets.audio_soundtrack.sound.fade(soundtrackState, fadeToLevel, 500);
      // Play Alert Sound
      assets.audio_alert.sound.play();
      // Fade in soundtrack after duration of Alert Sound
      setTimeout(() => {
        assets.audio_soundtrack.sound.fade(fadeToLevel, soundtrackState, 5000);
      }, assets.audio_alert.sound.duration()*1000);
      
    } else {
      assets.audio_alert.sound.play();
    }
  };

  togglePlayAudioClue = (type) => {
    const { assets } = this.props.gameMaster;
    if (type === "alert-button") {
      if (assets) {
        if (assets.audio_alert.sound !== null)
          this.playAlertAndFadeSoundtrack();
      }
      this.pushToMessagesFeed("alert-button", "Alert Tone Triggered");
    } else {
      if (assets) {
        if (assets.audio_alert.sound !== null)
          this.playAlertAndFadeSoundtrack();
      }
    }
  };

  avgCluePerMin = () => {
    const localStorage = loadStateFromLocalStorage();
    const startingSeconds = localStorage.timer.startingSeconds;
    const seconds = localStorage.timer.seconds;

    if (seconds !== startingSeconds) {
      const timeUsed = (startingSeconds - seconds) / 60;
      return (this.state.hintsUsed / timeUsed).toFixed(1);
    } else {
      return 0;
    }
  };

  handleAddedMinutes = (minutes) => {
    this.setState({
      addedMinutes: this.state.addedMinutes + minutes,
    });
  };

  handleAddMessage = (msgObj, func) => {
    if (msgObj.message) {

      // Process Message
      this.processIncomingMessage(msgObj, "text", func);

      //Update Sent clues on GMControl
      this.props.handleAddToSentClues(msgObj.id);
      this.props.handleClearLoadedClue();
      this.togglePlayAudioClue();



      // -----> this has been moved to ProcessIncomingMessage

      // Update ClueOnScreen or Remote Live Window
    //   if (!this.props.remoteLiveWindow) {
    //     this.props.updateClueOnScreen(msgObj.message, func);
    //   } else {
    //     this.props.remoteSendClue(msgObj.message, this.props.id);
    //   }

    // ------------------------------------------


      // Remove clue after 30 seconds
      //this.handleClueOnScreenTimeout(30000)
    }
  };

  handleAddAudioClue = (msgObj) => {

    const puzzle_id = msgObj.puzzle_id;
    const puzzle_doc_id = msgObj.puzzle_doc_id;
    const file = msgObj.file;
    const url = file.url;

    if (url) {
      const audio = new Audio(url);
      audio.play();
    }

    // const { assets } = this.props.gameMaster;
    // const audioState = loadAudioState()

    // if (assets.audio_soundtrack && assets.audio_soundtrack.sound){
    //     const soundtrackState = audioState['audio_soundtrack'];
    //     const fadeToLevel = soundtrackState * 0.3;

    //     assets.audio_soundtrack.sound.fade(soundtrackState, fadeToLevel, 200);
    //     assets.audio_alert.sound.play()
    //     assets.audio_soundtrack.sound.fade(fadeToLevel, soundtrackState, 8000);
    // } else {
    //     assets.audio_alert.sound.play()
    // }

    if (msgObj.message) {
      const localStorage = loadStateFromLocalStorage();
      var messageObj = {
        ...msgObj,
        game_timestamp: localStorage.timer.time,
        timestamp: new Date(),
        type: "audio",
      };
      var messagesArray = this.state.messages;
      messagesArray.push(messageObj);
      //console.log(messagesArray)

      this.setState({
        messages: messagesArray,
        hintsUsed: this.state.hintsUsed + 1,
        avgCluePerMinute: this.avgCluePerMin(),
        showCountdownBar: true,
      });

      //Update Sent clues on GMControl
      this.props.handleAddToSentClues(msgObj.id);
      this.props.handleClearLoadedClue();
      //this.togglePlayAudioClue()

      this.props.handleClearLoadedClue();
    }
  };

  handleAddMessageKeyDown = (e) => {
    // if CMD & ENTER pressed at same time
    // if (e.keyCode === 13 && e.keyCode <= 90 && !e.shiftKey){
    if (e.target.value && !/^\s*$/.test(e.target.value)) {
      const localStorage = loadStateFromLocalStorage();

      var messageObj = {
        message: e.target.value,
        game_timestamp: localStorage.timer.time,
        timestamp: new Date(),
        type: "text",
      };

      var messagesArray = this.state.messages;
      messagesArray.push(messageObj);

      this.setState({
        messages: messagesArray,
        hintsUsed: this.state.hintsUsed + 1,
        avgCluePerMinute: this.avgCluePerMin(),
        showCountdownBar: true,
      });

      this.props.handleClearLoadedClue();
      this.togglePlayAudioClue();

      // Update ClueOnScreen or Remote Live Window
      if (!this.props.remoteLiveWindow) {
        this.props.updateClueOnScreen(e.target.value);
      } else {
        this.props.remoteSendClue(e.target.value, this.props.id);
      }

      this.props.handleClearLoadedClue();

      // Remove clue after 30 seconds
      //this.handleClueOnScreenTimeout(30000)
    }

    // }
  };

  handleScreenClear = () => {
    // Update ClueOnScreen or Remote Live Window
    if (!this.props.remoteLiveWindow) {
      this.props.updateClueOnScreen("");
    } else {
      this.props.remoteSendClue("", this.props.id);
    }

    var messageObj = {
      type: "screen-cleared",
      timestamp: new Date(),
      key: uuid()
    };

    var messagesArray = this.state.messages;
    messagesArray.push(messageObj);

    this.setState({
      messages: messagesArray,
      countdownWidth: 100,
      showCountdownBar: false,
    });

    // Clear Countdown interval
    if (this.countdown) clearInterval(this.countdown);
    if (this.timeout) clearTimeout(this.timeout);
  };

  handleResetChat = () => {
    this.setState({
      messages: [],
      previousMessage: [],
      finishedPuzzlesLog: [],
      gameStartedAt: "",
      audioCluePlay: false,
      hintsUsed: 0,
      avgCluePerMinute: 0,
      addedMinutes: 0,
    });
  };

  handlePlayVideoMessage = (type) => {
    const localStorage = loadStateFromLocalStorage();
    var messageObj = {
      type: type,
      timestamp: new Date(),
      game_timestamp: localStorage.timer.time,
    };

    var messagesArray = this.state.messages;
    messagesArray.push(messageObj);

    this.setState({
      messages: messagesArray,
    });
  };

  handleStartTimerMessage = (type) => {
    const localStorage = loadStateFromLocalStorage();
    var messageObj = {
      key: uuid(),
      type: type,
      timestamp: new Date(),
      game_timestamp: localStorage.timer.time,
    };

    var messagesArray = this.state.messages;
    messagesArray.push(messageObj);

    this.setState({
      messages: messagesArray,
      gameStartedAt: new Date(),
    });
  };

  handleTimerAdjustMessage = (type, message, newTime) => {
    const localStorage = loadStateFromLocalStorage();
    var messageObj = {
      id: uuid(),
      key: uuid(),
      type: type,
      timestamp: new Date(),
      game_timestamp: localStorage.timer.time,
      message: message,
      shortened: false
    };

    var messagesArray = this.state.messages;
    const last_message = this.state.messages[this.state.messages.length - 1]

    if (last_message && new Date(last_message.timestamp).getTime() + 15000 >= new Date(new Date()).getTime()) {

        if (messageObj.type === 'timer-adjust' && last_message.type === 'timer-adjust'){

            messageObj = {
                ...messageObj,
                shortened: true
            }
        } 
    } 


        messagesArray.push(messageObj);
        this.setState({
            messages: messagesArray,
            gameStartedAt: new Date(),
        });
  

    
  };

  handleEndGameMessage = (type, status) => {
    const localStorage = loadStateFromLocalStorage();
    var messageObj = {
      type: type,
      timestamp: new Date(),
      game_timestamp: localStorage.timer.time,
      status,
      key: uuid()
    };

    var messagesArray = this.state.messages;
    messagesArray.push(messageObj);

    this.setState({
      messages: messagesArray,
      gameStartedAt: new Date(),
    });
  };

  handleClueOnScreenTimeout = (milliseconds) => {
    // Remove clue after 30 seconds
    if (this.timeout) clearTimeout(this.timeout);
    //this.handleClueTimeoutCountdown(milliseconds)
    this.timeout = setTimeout(() => {
      this.handleScreenClear();
    }, milliseconds);
  };

  handleClueTimeoutCountdown = (milliseconds) => {
    const seconds = milliseconds / 1000;
    const knockdown = 100 / seconds;
    if (this.countdown) clearInterval(this.countdown);
    this.countdown = setInterval(
      () =>
        this.setState({
          countdownWidth: this.state.countdownWidth - knockdown,
        }),
      1000
    );

    setTimeout(() => {
      clearInterval(this.countdown);
      this.setState({ countdownWidth: 100, showCountdownBar: false });
    }, milliseconds);
  };

  handleSaveGameData = (data) => {
    const game_data = {
      game: {
        name: this.props.game_data.name,
        id: this.props.game_data.id,
      },
      version: this.props.app.app_version,
      user: { ...this.props.profile },
      created_at: new Date(),
      started_at: this.state.gameStartedAt,
      ended_at: new Date(),
      message_log: this.state.messages,
      puzzles: this.props.finishedPuzzlesLog,
      timeRemaining: data.timeRemaining ? data.timeRemaining : null,
      didWinGame: data.didWinGame ? data.didWinGame : null,
      totalMessagesSent: this.state.hintsUsed,
      totalAdjustedTime: this.state.addedMinutes,
      gameDetails: {
        active_players: data.active_players,
        team_name: data.team_name
      }
    };

    this.props.finishGameDataUpload(game_data, this.props.id);
  };

  render() {
    const {
      loadedClue,
      handleLoadClue,
      id,
      game_name,
      game_assets,
      handleResetSentClues,
      loaded_assets,
      profile,
      puzzlesDone,
      puzzles,
      handleLoadPreviousClue
    } = this.props;
    const { messages, hintsUsed, avgCluePerMinute } = this.state;



    Sentry.setContext("chat state", {
      ...this.state,
    });

    return (
      <div className="chat-content" style={{ bottom: "42px" }}>
        {/* <div className="chat-content-header">
            <ul className="nav nav-line " id="myTab" role="tablist" style={{border:"none"}}>
        <li className="nav-item">
            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                <i className="icon ion-ios-flash" style={{marginRight:"5px"}}></i>
                Messages</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" style={{opacity:"0.4"}}>
            <i className="icon ion-md-options" style={{marginRight:"5px"}}></i>
                Audio Mixer</a>
        </li>
        </ul>

        </div> */}

        <div className="chat-content-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {this.props.view && this.props.view === "puzzles" ? (
                <React.Fragment>
                  {/* <div className="box-header">
                  <p className="mb-0 text-gray-300">
                    <i class="fa-regular fa-message-lines" style={{marginRight:'8px'}}></i>
                    Message Feed</p>
                  </div> */}

                  <Messages
                    messages={messages}
                    loadedClue={loadedClue}
                    handleLoadClue={handleLoadClue}
                    handleAddMessage={this.handleAddMessage}
                    handleAddAudioClue={this.handleAddAudioClue}
                    handleAddMessageKeyDown={this.handleAddMessageKeyDown}
                    handleScreenClear={this.handleScreenClear}
                    hintsUsed={hintsUsed}
                    avgCluePerMinute={avgCluePerMinute}
                    addedMinutes={this.state.addedMinutes}
                    remoteLiveWindow={this.props.remoteLiveWindow}
                    togglePlayAudioClue={this.togglePlayAudioClue}
                    countdownWidth={this.state.countdownWidth}
                    showCountdownBar={this.state.showCountdownBar}
                    handleLoadPreviousClue={handleLoadPreviousClue}
                    previousMessage={this.state.previousMessage}
                    handleClearLoadedClue={this.props.handleClearLoadedClue}
                  />
                </React.Fragment>
              ) : null}
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              {/* <div className="box-header">
                <h6 style={{ marginBottom: "0px" }}>Audio</h6>
              </div> */}

              <AudioControls
                generateAudioMeter={this.generateAudioMeter}
                loaded_assets={loaded_assets}
              />
            </div>
            <div
              className="tab-pane fade"
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div className="box-header">
                <h6 style={{ marginBottom: "0px" }}>Video</h6>
              </div>
              <p>...</p>
            </div>
          </div>
        </div>

        <RightSideBar
          profile={profile}
          account={this.props.account}
          messages={this.state.messages}
          state={this.props.state}
          id={id}
          hintsUsed={hintsUsed}
          avgCluePerMinute={avgCluePerMinute}
          game_assets={game_assets}
          game_name={game_name}
          handleAddMessage={this.handleAddMessage}
          handleResetChat={this.handleResetChat}
          handleResetSentClues={handleResetSentClues}
          togglePlayAudioClue={this.togglePlayAudioClue}
          loaded_assets={loaded_assets}
          addedMinutes={this.state.addedMinutes}
          handleAddedMinutes={this.handleAddedMinutes}
          remoteLiveWindow={this.props.remoteLiveWindow}
          handlePlayVideoMessage={this.handlePlayVideoMessage}
          handleStartTimerMessage={this.handleStartTimerMessage}
          handleSaveGameData={this.handleSaveGameData}
          pushToMessagesFeed={this.pushToMessagesFeed}
          handleTimerAdjustMessage={this.handleTimerAdjustMessage}
          handleEndGameMessage={this.handleEndGameMessage}
          puzzlesDone={puzzlesDone}
          puzzles={puzzles}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gameMaster: state.gameMaster,
    game_data: state.firestore.ordered.gamemaster
      ? state.firestore.ordered.gamemaster[0]
      : null,
    account: state.firestore.ordered.user_accounts[0],
    profile: state.firebase.profile,
    app: state.app,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClueOnScreen: (clue) => dispatch(updateClueOnScreen(clue)),
    remoteSendClue: (clue, doc) => dispatch(remoteSendClue(clue, doc)),
    finishGameDataUpload: (data, doc) =>
      dispatch(finishGameDataUpload(data, doc)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
