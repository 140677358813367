import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import {
  updateAssets,
  audio_control,
  getAssetsData,
  update_onboarding,
  loadSounds
} from "../../../store/actions/gmActions";
import {
  updateTimer,
  initializeState,
  updateClueOnScreen,
} from "../../../store/actions/timerActions";
import { loadAudioState, saveAudioState } from "../../../localStorage";
import { Howl } from "howler";
import FadeIn from "react-fade-in";
import Joyride from "react-joyride";
import { classNames } from "../../../utils/Utils";

import LeftSideBar from "./LeftSideBar";
import Chat from "./Chat";
import PropsSidebar from "./PropsSidebar";
import ExitToDashboardModal from "./modals/ExitToDashboardModal";
import Header from "./Layout/Header";
import AudioControls from "./ControlsBox/AudioControls";

import GMSnackBar from "./CustomComponents/GMSnackBar";

// Sentry.io
import * as Sentry from "@sentry/react";

//Toasts
import { Toaster, toast } from 'sonner'

// IndexedDB
import { get, createStore } from "idb-keyval";
import {
  gameId,
  addToDB,
  deleteAsset,
  deleteFromDB,
  readBlob,
  downloadFileToBlob,
} from "../../_settings/Game/Functions/SettingsFunctions";

export class GMControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versionNumber: this.props.app.app_version,
      loadedClue: "",
      selectedClue: "",
      clues: [],
      sounds: [],
      allSounds: [],
      sentClues: [],
      puzzles: [],
      puzzlesDone: [],
      finishedPuzzlesLog: [],
      selectedPuzzle: "",
      puzzleIsSelected: false,
      selectedPuzzleName: "",
      darkMode: true,
      dataLoaded: false,
      alertIsLoaded: false,
      soundtrackIsLoaded: false,
      successIsLoaded: false,
      failIsLoaded: false,
      video_briefIsLoaded: false,
      allAssetsLoaded: false,
      basicDataLoaded: false,
      remoteLiveWindow: false,
      charge_failed: false,
      userPermissions: "",
      joyride_run:
        this.props.account && this.props.account.onboarding
          ? !this.props.account.onboarding.gm
          : false,
      onboarding_steps: [
        {
          content:
            "This quick tutorial will show you around. Let's get started!",
          title: <h2>Getting Started Tutorial</h2>,
          locale: { skip: <strong aria-label="skip">Skip</strong> },
          placement: "center",
          target: "body",
          styles: {
            options: {
              backgroundColor: "#fff",
              width: 400,
            },
          },
        },
        {
          target: "#puzzles-sidebar",
          content:
            "This is where you add your objectives and organize your puzzle flow.",
          placement: "right",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Puzzles",
        },
        {
          target: "#addPuzzleModal",
          content:
            'Click on the menu to add a new objective.',
          placement: "top",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Create a New Puzzle",
        },
        {
          target: "#clue-box",
          content: "This is where you add and view your pre-saved clues.",
          placement: "right",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Clues",
        },
        {
          target: "#addTextClue",
          content:
            'Click on the "plus" icon to add a new clue',
          placement: "top",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Create a New Clue",
        },
        {
          target: "#messageBox",
          content:
            "This is where you type custom messages or send pre-saved clues to your players. Type a custom message now.",
          placement: "top",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Message Box",
        },
        {
          target: "#messageFeed",
          content: "This is where sent messages are displayed.",
          placement: "left",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Message Feed",
        },
        {
          target: "#timer-box",
          content:
            "This is where you start and stop the timer or play a video brief.",
          placement: "left",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Timer Box",
        },
        {
          target: "#adjust-timer-box",
          content: "Add or remove time from the clock.",
          placement: "left",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Adjust the Timer",
        },
        {
          target: "#hints-remaining-box",
          content: "Mark off the remaining clues shown on the player screen.",
          placement: "left",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Available Clues",
        },
        {
          target: "#completeGameButton",
          content:
            "End the timer and trigger the success screen by clicking the finish room button.",
          placement: "left",
          styles: {
            options: {
              width: 300,
            },
          },
          title: "Finish Room",
        },
      ],
      videoBriefLoading: false,
      backgroundImageLoading: false,
      backgroundImageFailLoading: false,
      backgroundImageVictoryLoading: false
    };

    this.handleLoadClue = this.handleLoadClue.bind(this);
    this.handleLoadClueClick = this.handleLoadClueClick.bind(this);
    this.handleLoadPreviousClue = this.handleLoadPreviousClue.bind(this);
    this.handleClearLoadedClue = this.handleClearLoadedClue.bind(this);
    this.handleSelectPuzzle = this.handleSelectPuzzle.bind(this);
    this.getData = this.getData.bind(this);
    this.getIndexDB = this.getIndexDB.bind(this);
  }

  componentDidMount() {
    const game_id = this.props.match.params.id;
    this.props.getAssetsData(game_id);

    //Init IndexDB
    gameId(game_id);
    // this.getIndexDB().then((data) => {
    //     this.props.updateAssets({
    //         assets: data.assets
    //     })
    // })

    // Window Close - Update Timer
    window.addEventListener("unload", (ev) => {
      ev.preventDefault();

      return this.props.updateTimer({
        ...this.props.timer,
        timerRunning: false,
        isPlayerWindowOpen: false,
        gameOver: false,
        gameComplete: false,
        videoIsPlaying: false,
        clueOnScreenCount: { 0: true, 1: true, 2: true },
        isInfiniteClues: false,
        interval: 1000, // was 1000 as of July 3, 2021
        gameIsReady: true,
      });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Typical usage (don't forget to compare props):
    if (this.props.gm !== prevProps.gm) {
      document.title = this.props.gm.name + " - Bolt";

      //Check User Permissions
      this.checkUserPermissions();

      // Get Data
      this.getData().then(
        (data) => {
          console.log('DATA', data)
          this.setState({ ...data });
          this.props.updateTimer({
            ...this.props.timer,
            clueOnScreenCount: data.clueOnScreenCount,
            time: data.time,
            seconds: data.seconds,
            startingSeconds: data.startingSeconds,
            startingTime: data.time,
            clock_halfTime: false,
            clock_doubleTime: false,
            gameFail: false,
            startWithVideoBrief: false
            //video_brief: data.assets.video_brief
          });
        },
        (error) => {
          console.log("No GM Data found", error);
        }
      );

      //FIXME: this.handleLoadClues(this.props.gm.puzzles)
    }

    if (this.props.gm_puzzles !== prevProps.gm_puzzles) {

      console.log('**** updated gm_puzzles')
      //console.log('GM: PUZZLES UPDATED')

      // var puzzleArray = []
      // this.props.gm_puzzles.forEach(puzzle => {
      //     puzzleArray.push(puzzle)
      // });

      // this.setState({
      //     puzzles: puzzleArray
      // })
      this.handleLoadPuzzlesArray(this.props.gm_puzzles);
      this.handleLoadClues(this.props.gm_puzzles);
      this.handleLoadALLCLUESLIST(this.props.gm_puzzles);
    }

    if (this.props.new_assets !== prevProps.new_assets) {
      // console.log('TRIGGER NEW ASSETS', this.props.new_assets)

      // Get Assets
      this.getAssets().then((data) => {
        // console.log("ASSET DATA", data);

        this.setState({ ...data });
        this.props.updateAssets({
          assets: data.assets,
        });

        // this.props.updateTimer({
        //     ...this.props.timer,
        //     video_brief: data.assets.video_brief,
        //     background_image: {
        //         timer: data.assets.background_image,
        //         victory: data.assets.background_image_victory,
        //         fail: data.assets.background_image_fail
        //     }
        // })
      });
    }

    // Check for onboarding status
    if (this.props.account !== prevProps.account) {
      if (this.props.account.onboarding) {
        this.setState({ joyride_run: !this.props.account.onboarding.gm });
      }
    }

    if (this.state.puzzles !== prevState.puzzles) {
      if (this.state.joyride_run) {
        const puzzles = this.state.puzzles;
        this.handleSelectPuzzle(puzzles[0]);
      }
      {
        const puzzles = this.state.puzzles;
        //this.handleSelectPuzzle(puzzles[0])
      }
    }
  }

  componentWillUnmount() {
    Sentry.configureScope((scope) => scope.clear());
  }

  handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const { account } = this.props;
    const finishedStatuses = [status.finished, status.skipped];

    if (
      data.action === "close" ||
      data.action === "reset" ||
      finishedStatuses.includes("skipped")
    ) {
      this.setState({ joyride_run: false });
      this.props.update_onboarding(account.id, true);
    }
  };

  checkUserPermissions() {
    const uid = this.props.uid;
    const account = this.props.account;
    const permissions =
      account && account.permissions ? account.permissions : null;

    function search(id, myArray) {
      for (var i = 0; i < myArray.length; i++) {
        if (myArray[i].user_id === id) {
          return myArray[i];
        }
      }
    }

    if (permissions) {
      const gm_permissions = search(uid, permissions);
      if (gm_permissions) {
        this.setState({ userPermissions: gm_permissions });
      }
    } else {
      this.setState({ userPermissions: { isAdmin: true } });
    }
  }

  handleLoadPuzzlesArray = (puzzles) => {
    if (puzzles) {
      var puzzleArray = [];
      var arrayForSort = [...puzzles];
      arrayForSort
        .sort((b, a) => b.index - a.index)
        .forEach((puzzle) => {
          puzzleArray.push(puzzle);
        });

      this.setState({
        puzzles: puzzleArray,
      });
    }
  };

  getIndexDB = () => {
    return new Promise(async (resolve, reject) => {
      const game_id = this.props.match.params.id;
      const assetsStore = createStore(game_id, "assets");

      const audioState = loadAudioState();

      const format = (val) => {
        if (val.type === "audio/wav") return "wav";
        if (val.type === "audio/mp3") return "mp3";
        if (val.type === "audio/mpeg") return "mpeg";
      };

      var audio_alert,
        audio_soundtrack,
        audio_success,
        audio_fail,
        video_brief_url,
        video_brief_vol,
        video_brief_type,
        background_image,
        background_image_victory,
        background_image_fail,
        background_image_type;

      var audio_alert_vol,
        audio_soundtrack_vol,
        audio_success_vol,
        audio_fail_vol;

      // Get Victory Music
      await get("victory_music", assetsStore).then((val) => {
        //console.log('GET VICTORY', val)
        if (val) {
          const blob = readBlob(val);
          var url = URL.createObjectURL(blob);

          const savedAudio = audioState ? audioState["audio_success"] : null;
          if (!savedAudio) {
            // Set audio volume in local storage
            saveAudioState("audio_success", audio_success_vol);
          }

          if (url) {
            audio_success = new Howl({
              src: [url],
              autoplay: false,
              preload: true,
              html5: true,
              format: [format(val)],
              onloaderror: function (error, err) {
                console.error("VICTORY MUSIC ERROR", err);
              }.bind(this),
              onload: function () {
                console.log("Offline Victory Music is Loaded!");

                //adjust volume to saved state
                const savedAudioState = audioState
                  ? audioState["audio_success"]
                  : 1;
                audio_success.volume(savedAudioState);

                this.setState({
                  successIsLoaded: true,
                  success_duration: audio_success.duration(),
                  success_message: "Ready to Play",
                  success_name: val.name,
                });
              }.bind(this),
              onplayerror: function () {
                console.error("Error playing Success");
                this.setState({
                  success_message: "Error Loading",
                });
                audio_success.once("unlock", function () {
                  //console.log("Unlocked")
                  audio_success.play();
                });
              }.bind(this),
              onplay: function () {
                this.setState({
                  success_message: "Playing",
                });
              }.bind(this),
              onpause: function () {
                this.setState({
                  success_message: "Paused",
                });
              }.bind(this),
              onstop: function () {
                this.setState({
                  success_message: "Ready to Play",
                });
              }.bind(this),
            });
          }
        }
      });

      // Get Fail Music
      await get("fail_music", assetsStore).then((val) => {
        if (val) {
          const blob = readBlob(val);
          var url = URL.createObjectURL(blob);

          const savedAudio = audioState ? audioState["audio_fail"] : null;
          if (!savedAudio) {
            // Set audio volume in local storage
            saveAudioState("audio_fail", audio_fail_vol);
          }

          audio_fail = new Howl({
            src: [url],
            autoplay: false,
            preload: true,
            html5: true,
            format: [format(val)],
            onloaderror: function (error, err) {
              console.error("FAIL MUSIC LOAD ERROR", err);
            }.bind(this),
            onload: function () {
              console.log("Offline Fail Music is Loaded!");

              //adjust volume to saved state
              const savedAudioState = audioState ? audioState["audio_fail"] : 1;
              audio_fail.volume(savedAudioState);

              this.setState({
                failIsLoaded: true,
                fail_duration: audio_fail.duration(),
                fail_message: "Ready to Play",
                fail_name: val.name,
              });
            }.bind(this),
            onplayerror: function () {
              console.error("Error playing Fail Music");
              this.setState({
                fail_message: "Error Loading",
              });
              audio_fail.once("unlock", function () {
                console.log("Unlocked");
                audio_fail.play();
              });
            }.bind(this),
            onplay: function () {
              this.setState({
                fail_message: "Playing",
              });
            }.bind(this),
            onpause: function () {
              this.setState({
                fail_message: "Paused",
              });
            }.bind(this),
            onstop: function () {
              this.setState({
                fail_message: "Ready to Play",
              });
            }.bind(this),
          });
        }
      });

      // Get Soundtrack
      await get("soundtrack", assetsStore).then((val) => {
        if (val) {
          const blob = readBlob(val);
          var url = URL.createObjectURL(blob);

          const savedAudio = audioState ? audioState["audio_soundtrack"] : null;
          if (!savedAudio) {
            // Set audio volume in local storage
            saveAudioState("audio_soundtrack", audio_soundtrack_vol);
          }

          audio_soundtrack = new Howl({
            src: [url],
            autoplay: false,
            preload: true,
            loop: true,
            html5: true,
            format: [format(val)],
            onloaderror: function (error, err) {
              console.error("SOUNDTRACK LOAD ERROR", err);
              this.setState({
                soundtrack_message: "Error Loading",
              });
            }.bind(this),
            onload: function () {
              console.log("Offline Soundtrack is Loaded!");

              //adjust volume to saved state
              const savedAudioState = audioState
                ? audioState["audio_soundtrack"]
                : 1;
              audio_soundtrack.volume(savedAudioState);

              this.setState({
                soundtrackIsLoaded: true,
                soundtrack_duration: audio_soundtrack.duration(),
                soundtrack_message: "Ready to Play",
                soundtrack_name: val.name,
              });
            }.bind(this),
            onplayerror: function () {
              console.error("Error playing Soundtrack");
              this.setState({
                soundtrack_message: "Error Loading",
              });
              audio_soundtrack.once("unlock", function () {
                console.log("Unlocked");
                audio_soundtrack.play();
              });
            }.bind(this),
            onplay: function () {
              this.setState({
                soundtrack_message: "Playing",
              });
            }.bind(this),
            onpause: function () {
              this.setState({
                soundtrack_message: "Paused",
              });
            }.bind(this),
            onstop: function () {
              this.setState({
                soundtrack_message: "Ready to Play",
              });
            }.bind(this),
          });
        }
      });

      // Get Alert Tone
      await get("audio_alert", assetsStore).then((val) => {
        if (val) {
          const blob = readBlob(val);
          var url = URL.createObjectURL(blob);

          const savedAudio = audioState ? audioState["audio_alert"] : null;
          if (!savedAudio) {
            // Set audio volume in local storage
            saveAudioState("audio_alert", audio_alert_vol);
          }

          audio_alert = new Howl({
            src: [url],
            autoplay: false,
            preload: true,
            html5: true,
            format: [format(val)],
            onloaderror: function (error, err) {
              console.error("AUDIO ALERT LOAD ERROR", err);
              this.setState({
                alert_message: "Error Loading",
              });
            }.bind(this),
            onload: function () {
              console.log("Offline Alert is Loaded!");

              //adjust volume to saved state
              const savedAudioState = audioState
                ? audioState["audio_alert"]
                : 1;
              audio_alert.volume(savedAudioState);

              this.setState({
                alertIsLoaded: true,
                alert_duration: audio_alert.duration(),
                alert_message: "Ready to Play",
                alert_name: val.name,
              });
            }.bind(this),
            onplayerror: function () {
              console.error("Error playing Alert Tone");
              this.setState({
                alert_message: "Error Playing",
              });
              audio_alert.once("unlock", function () {
                console.log("Unlocked");
                audio_alert.play();
              });
            }.bind(this),
            onplay: function () {
              this.setState({
                alert_message: "Playing",
              });
            }.bind(this),
            onpause: function () {
              this.setState({
                alert_message: "Paused",
              });
            }.bind(this),
            onstop: function () {
              this.setState({
                alert_message: "Ready to Play",
              });
            }.bind(this),
            onend: function () {
              audio_alert.stop();
            },
          });
        }
      });

      const data = {
        assets: {
          audio_success: {
            sound: audio_success ? audio_success : null,
            // id: audio_soundtrack ? audio_soundtrack_id : null,
            vol: audio_success ? audio_success : 1,
          },
          audio_alert: {
            sound: audio_alert ? audio_alert : null,
            // id: audio_alert ? audio_alert_id : null,
            vol: audio_alert ? audio_alert_vol : 1,
          },
          audio_soundtrack: {
            sound: audio_soundtrack ? audio_soundtrack : null,
            // id: audio_soundtrack ? audio_soundtrack_id : null,
            vol: audio_soundtrack ? audio_soundtrack_vol : 1,
          },
          audio_fail: {
            sound: audio_fail ? audio_fail : null,
            // id: audio_fail ? audio_fail_id : null,
            vol: audio_fail ? audio_fail_vol : 1,
          },
          video_brief: {
            url: video_brief_url ? video_brief_url : null,
            type: video_brief_type ? video_brief_type : null,
            vol: video_brief_vol ? video_brief_vol : 1,
          },
          background_image_fail: {
            url: background_image_fail ? background_image_fail : null,
          },
        },
      };

      if (data) {
        resolve(data);
        this.setState({
          allAssetsLoaded: true,
        });
      } else {
        reject("No IndexDB Items!");
      }
    });
  };

  getAssets = () => {
    return new Promise((resolve, reject) => {
      //const assets = this.props.gm_assets

      const game_id = this.props.match.params.id;
      const assetsStore = createStore(game_id, "assets");

      const assets = this.props.new_assets;
      // console.log("raw assets", assets);
      const audioState = loadAudioState();

      var audio_alert,
        audio_soundtrack,
        audio_success,
        audio_fail,
        video_brief_url,
        video_brief_vol,
        video_brief_type,
        video_brief_id,
        background_image_url,
        background_image_id,
        background_image_victory_url,
        background_image_victory_type,
        background_image_victory_id,
        background_image_fail_url,
        background_image_fail_type,
        background_image_fail_id,
        background_image_type;

      //const { timer } = this.props.gm;
      var audio_alert_vol,
        audio_soundtrack_vol,
        audio_success_vol,
        audio_fail_vol;

      if (assets && assets.audio_alert) {
        audio_alert_vol = assets.audio_alert.volume;

        const savedAudio = audioState ? audioState["audio_alert"] : null;
        if (!savedAudio) {
          // Set audio volume in local storage
          saveAudioState("audio_alert", audio_alert_vol);
        }

        audio_alert = new Howl({
          src: [assets.audio_alert.url],
          autoplay: false,
          preload: true,
          html5: true,
          onload: function () {
            console.log("Alert is Loaded!");

            //adjust volume to saved state
            const savedAudioState = audioState ? audioState["audio_alert"] : 1;
            audio_alert.volume(savedAudioState);

            this.setState({
              alertIsLoaded: true,
              alert_duration: audio_alert.duration(),
              alert_message: "Ready to Play",
              alert_name: assets.audio_alert.name,
            });
          }.bind(this),
          onplayerror: function () {
            console.error("Error playing Alert Tone");
            this.setState({
              alert_message: "Error Loading",
            });
            audio_alert.once("unlock", function () {
              console.log("Unlocked");
              audio_alert.play();
            });
          }.bind(this),
          onplay: function () {
            this.setState({
              alert_message: "Playing",
            });
          }.bind(this),
          onpause: function () {
            this.setState({
              alert_message: "Paused",
            });
          }.bind(this),
          onstop: function () {
            this.setState({
              alert_message: "Ready to Play",
            });
          }.bind(this),
          onend: function () {
            audio_alert.stop();
          },
        });
        // var audio_alert_id = audio_alert.play()
        // audio_alert.stop()
      }

      if (assets && assets.audio_soundtrack) {

        audio_soundtrack_vol = assets.audio_soundtrack.volume;

        const savedAudio = audioState ? audioState["audio_soundtrack"] : null;
        if (!savedAudio) {
          // Set audio volume in local storage
          saveAudioState("audio_soundtrack", audio_soundtrack_vol);
        }

        audio_soundtrack = new Howl({
          src: [assets.audio_soundtrack.url],
          autoplay: false,
          preload: true,
          loop: true,
          html5: true,
          onload: function () {
            console.log("Soundtrack is Loaded!");

            //adjust volume to saved state
            const savedAudioState = audioState
              ? audioState["audio_soundtrack"]
              : 1;
            audio_soundtrack.volume(savedAudioState);

            this.setState({
              soundtrackIsLoaded: true,
              soundtrack_duration: audio_soundtrack.duration(),
              soundtrack_message: "Ready to Play",
            });
          }.bind(this),
          onplayerror: function () {
            console.error("Error playing Soundtrack");
            this.setState({
              soundtrack_message: "Error Loading",
            });
            audio_soundtrack.once("unlock", function () {
              console.log("Unlocked");
              audio_soundtrack.play();
            });
          }.bind(this),
          onplay: function () {
            this.setState({
              soundtrack_message: "Playing",
            });
          }.bind(this),
          onpause: function () {
            this.setState({
              soundtrack_message: "Paused",
            });
          }.bind(this),
          onstop: function () {
            this.setState({
              soundtrack_message: "Ready to Play",
            });
          }.bind(this),
        });

        // var audio_soundtrack_id = audio_soundtrack.play()
        // audio_soundtrack.stop()
      }

      if (assets && assets.audio_success) {
        audio_success_vol = assets.audio_success.volume;

        const savedAudio = audioState ? audioState["audio_success"] : null;
        if (!savedAudio) {
          // Set audio volume in local storage
          saveAudioState("audio_success", audio_success_vol);
        }

        audio_success = new Howl({
          src: [assets.audio_success.url],
          autoplay: false,
          preload: true,
          html5: true,
          onload: function () {
            console.log("Success is Loaded!");

            //adjust volume to saved state
            const savedAudioState = audioState
              ? audioState["audio_success"]
              : 1;
            audio_success.volume(savedAudioState);

            this.setState({
              successIsLoaded: true,
              success_duration: audio_success.duration(),
              success_message: "Ready to Play",
            });
          }.bind(this),
          onplayerror: function () {
            console.error("Error playing Success");
            this.setState({
              success_message: "Error Loading",
            });
            audio_success.once("unlock", function () {
              console.log("Unlocked");
              audio_success.play();
            });
          }.bind(this),
          onplay: function () {
            this.setState({
              success_message: "Playing",
            });
          }.bind(this),
          onpause: function () {
            this.setState({
              success_message: "Paused",
            });
          }.bind(this),
          onstop: function () {
            this.setState({
              success_message: "Ready to Play",
            });
          }.bind(this),
        });
        // var audio_success_id = audio_success.play();
        // audio_success.stop()
      }

      if (assets && assets.audio_fail) {
        audio_fail_vol = assets.audio_fail.volume;

        const savedAudio = audioState ? audioState["audio_fail"] : null;
        if (!savedAudio) {
          // Set audio volume in local storage
          saveAudioState("audio_fail", audio_fail_vol);
        }

        audio_fail = new Howl({
          src: [assets.audio_fail.url],
          autoplay: false,
          preload: true,
          html5: true,
          onload: function () {
            console.log("Success is Loaded!");

            //adjust volume to saved state
            const savedAudioState = audioState ? audioState["audio_fail"] : 1;
            audio_fail.volume(savedAudioState);

            this.setState({
              failIsLoaded: true,
              fail_duration: audio_fail.duration(),
              fail_message: "Ready to Play",
            });
          }.bind(this),
          onplayerror: function () {
            console.error("Error playing Fail");
            this.setState({
              fail_message: "Error Loading",
            });
            audio_fail.once("unlock", function () {
              console.log("Unlocked");
              audio_fail.play();
            });
          }.bind(this),
          onplay: function () {
            this.setState({
              fail_message: "Playing",
            });
          }.bind(this),
          onpause: function () {
            this.setState({
              fail_message: "Paused",
            });
          }.bind(this),
          onstop: function () {
            this.setState({
              fail_message: "Ready to Play",
            });
          }.bind(this),
        });
        // var audio_fail_id = audio_fail.play();
        // audio_fail.stop()
      }

      if (assets && assets.video_brief) {
        this.setState({
          videoBriefLoading: true,
        });
        video_brief_url = assets.video_brief.url;
        video_brief_vol = assets.video_brief.volume;
        video_brief_type = assets.video_brief.type;
        video_brief_id = assets.video_brief.id || null;

        // Get Video Brief
        get("video_brief", assetsStore).then((val) => {
          if (!val) {
            downloadFileToBlob(
              assets.video_brief,
              "video_brief",
              game_id,
              video_brief_id
            );

            this.setState({
              video_briefIsLoaded: true,
              videoBriefLoading: false
            });
          } else {
            console.log("LOCAL VIDEO FOUND");

            if (val.id && val.id === video_brief_id) {
              console.log("ID MATCHES PREVIOUS ID")
              const savedAudio = audioState
                ? audioState["audio_video_brief"]
                : null;
              if (!savedAudio) {
                // Set audio volume in local storage
                saveAudioState("audio_video_brief", video_brief_vol);
              }

              this.setState({
                video_briefIsLoaded: true,
                videoBriefLoading: false
              });
            } else {
              console.log("DID NOT MATCH PREVIOUS ID")
              downloadFileToBlob(
                assets.video_brief,
                "video_brief",
                game_id,
                video_brief_id
              );

              this.setState({
                video_briefIsLoaded: true,
                videoBriefLoading: false
              });
            }
          }
        });

      } else {
        get("video_brief", assetsStore).then((val) => {
          if (val) {
            deleteFromDB('video_brief')
          }
        });
      }

      if (assets && assets.background_image) {
        this.setState({
          backgroundImageLoading: true,
        });

        background_image_url = assets.background_image.url || null;
        background_image_type = assets.background_image.type || null;
        background_image_id = assets.background_image.id || null;

        // Get Video Brief
        get("background_image", assetsStore).then((val) => {
          if (!val) {
            downloadFileToBlob(
              assets.background_image,
              "background_image",
              game_id,
              background_image_id
            );

            this.setState({
              backgroundImageLoading: false,
            });
          } else {
            console.log("BG: LOCAL FILE FOUND");

            if (val.id && val.id === background_image_id) {
              console.log("BG: ID MATCHES PREVIOUS ID")
              const savedAudio = audioState
                ? audioState["audio_video_brief"]
                : null;
              if (!savedAudio) {
                // Set audio volume in local storage
                saveAudioState("audio_video_brief", video_brief_vol);
              }

              this.setState({
                backgroundImageLoading: false,
              });
            } else {
              console.log("BG: DID NOT MATCH PREVIOUS ID")
              downloadFileToBlob(
                assets.background_image,
                "background_image",
                game_id,
                background_image_id
              );

              this.setState({
                backgroundImageLoading: false,
              });
            }
          }
        });

      } else {
        get("background_image", assetsStore).then((val) => {
          if (val) {
            deleteFromDB('background_image')
          }
        });
      }

      if (assets && assets.background_image_victory) {
        this.setState({
          backgroundImageVictoryLoading: true,
        });

        background_image_victory_url = assets.background_image_victory.url;
        background_image_victory_type = assets.background_image_victory.type || null;
        background_image_victory_id = assets.background_image_victory.id || null;

        // Get Video Brief
        get("background_image_victory", assetsStore).then((val) => {
          if (!val) {
            downloadFileToBlob(
              assets.background_image_victory,
              "background_image_victory",
              game_id,
              background_image_victory_id
            );

            this.setState({
              backgroundImageVictoryLoading: false,
            });
          } else {
            console.log("BG VICTORY: LOCAL FILE FOUND");

            if (val.id && val.id === background_image_victory_id) {
              console.log("BG VICTORY: ID MATCHES PREVIOUS ID")
              this.setState({
                backgroundImageVictoryLoading: false,
              });
            } else {
              console.log("BG VICTORY: DID NOT MATCH PREVIOUS ID")
              downloadFileToBlob(
                assets.background_image_victory,
                "background_image_victory",
                game_id,
                background_image_victory_id
              );

              this.setState({
                backgroundImageVictoryLoading: false,
              });
            }
          }
        });
        
      } else {
        get("background_image_victory", assetsStore).then((val) => {
          if (val) {
            deleteFromDB('background_image_victory')
          }
        });
      }

      if (assets && assets.background_image_fail) {
        this.setState({
          backgroundImageFailLoading: true,
        });

        background_image_fail_url = assets.background_image_fail.url;
        background_image_fail_type = assets.background_image_fail.type || null;
        background_image_fail_id = assets.background_image_fail.id || null;

        // Get Video Brief
        get("background_image_fail", assetsStore).then((val) => {
          if (!val) {
            downloadFileToBlob(
              assets.background_image_fail,
              "background_image_fail",
              game_id,
              background_image_fail_id
            );

            this.setState({
              backgroundImageFailLoading: false,
            });
          } else {
            console.log("BG FAIL: LOCAL FILE FOUND");

            if (val.id && val.id === background_image_fail_id) {
              console.log("BG FAIL: ID MATCHES PREVIOUS ID")
              this.setState({
                backgroundImageFailLoading: false,
              });
            } else {
              console.log("BG FAIL: DID NOT MATCH PREVIOUS ID")
              downloadFileToBlob(
                assets.background_image_fail,
                "background_image_fail",
                game_id,
                background_image_fail_id
              );

              this.setState({
                backgroundImageFailLoading: false,
              });
            }
          }
        });
      } else {
        get("background_image_fail", assetsStore).then((val) => {
          if (val) {
            deleteFromDB('background_image_fail')
          }
        });
      }

      var data = {
        assets: {
          audio_alert: {
            sound: audio_alert ? audio_alert : null,
            // id: audio_alert ? audio_alert_id : null,
            vol: audio_alert ? audio_alert_vol : 1,
          },
          audio_soundtrack: {
            sound: audio_soundtrack ? audio_soundtrack : null,
            // id: audio_soundtrack ? audio_soundtrack_id : null,
            vol: audio_soundtrack ? audio_soundtrack_vol : 1,
          },
          audio_success: {
            sound: audio_success ? audio_success : null,
            // id: audio_success ? audio_success_id : null,
            vol: audio_success ? audio_success_vol : 1,
          },
          audio_fail: {
            sound: audio_fail ? audio_fail : null,
            // id: audio_fail ? audio_fail_id : null,
            vol: audio_fail ? audio_fail_vol : 1,
          },
          video_brief: {
            url: video_brief_url ? video_brief_url : null,
            type: video_brief_type ? video_brief_type : null,
            vol: video_brief_vol ? video_brief_vol : 1,
            id: video_brief_id,
          },
        },
      };

      if (assets && assets.background_image) {
        data = {
          assets: {
            ...data.assets,
            background_image: {
              url: background_image_url,
              type: background_image_type,
              id: background_image_id
            },
          },
        };
      }

      if (assets && assets.background_image_victory) {
        data = {
          assets: {
            ...data.assets,
            background_image_victory: {
              url: background_image_victory_url,
              type: background_image_victory_type,
              id: background_image_victory_id
            },
          },
        };
      }

      if (assets && assets.background_image_fail) {
        data = {
          assets: {
            ...data.assets,
            background_image_fail: {
              url: background_image_fail_url,
              type: background_image_fail_type,
              id: background_image_fail_id
            },
          },
        };
      }

      if (data) {
        resolve(data);
        this.setState({
          allAssetsLoaded: true,
        });
      } else {
        reject("No Assets!");
      }
    });
  };

  getData = () => {
    return new Promise((resolve, reject) => {
      const { timer, clueCount } = this.props.gm;

      const data = {
        time: {
          m: timer.time.m ? timer.time.m : "60",
          s: timer.time.s ? timer.time.s : "00",
        },
        seconds: timer.seconds ? timer.seconds : 3600,
        startingSeconds: timer.seconds ? timer.seconds : 3600,
        clueOnScreenCount: this.handleClueCount(clueCount)
      };

      if (data) {
        resolve(data);
        this.setState({
          basicDataLoaded: true,
          //allAssetsLoaded: true
        });
      } else {
        reject("No Assets!");
      }
    });
  };

  handleClueCount = (count) => {
    if (!count) return { 0: true, 1: true, 2: true }
    if (count === 1) return { 0: true };
    if (count === 2) return { 0: true, 1: true };
    if (count === 3) return { 0: true, 1: true, 2: true };
    if (count === 4) return { 0: true, 1: true, 2: true, 3: true };
    if (count === 5)
      return { 0: true, 1: true, 2: true, 3: true, 4: true };
    if (count === 6)
      return { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true };
    if (count === 7)
      return { 0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true };
    if (count === 8)
      return {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
      };
    if (count === 9)
      return {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
      };
    if (count === 10)
      return {
        0: true,
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
      };
  }

  handleLoadClue = (clue) => {

    if (!clue.target.value) {
      this.setState({
        loadedClue: {},
      });
    } else {
      this.setState({
        loadedClue: {
          ...this.state.loadedClue,
          message: clue.target.value,
        },
      });
    }
  };

  handleLoadClueClick = (clue) => {
    this.setState({
      loadedClue: clue,
    });
  };

  handleLoadPreviousClue = (previousClue) => {

    this.setState({
      loadedClue: previousClue,
    });
  };

  handleClearLoadedClue = () => {
    this.setState({
      loadedClue: { message: "" },
    });
  };

  handleClearFilteredClues = () => {
    this.setState({
      selectedPuzzle: "",
      clues: this.state.allClues,
      puzzleIsSelected: false,
      selectedPuzzleName: "",
    });
  };

  handleLoadALLCLUESLIST = (puzzles) => {
    if (puzzles) {
      var cluesArray = [];
      var soundsArray = [];
      //var puzzlesArray = []

      puzzles.forEach((puzzle) => {
        const clues = puzzle.clues;
        const puzzleSounds = puzzle.sounds;

        // if Clues
        if (clues) {
          clues.forEach((clue) => {
            const obj = {
              message: clue.message,
              id: clue.id,
              puzzle_id: puzzle.id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };
            cluesArray.push(obj);
          });
        }

        if (puzzleSounds) {
          const sentClues = this.state.sentClues;
          puzzleSounds.forEach((sound) => {
            var isClueSent = false;
            if (sentClues.includes(sound.id)) {
              isClueSent = true;
            } else {
              isClueSent = false;
            }
            const obj = {
              ...sound,
              done: false,
              id: sound.id,
              clueSent: isClueSent,
              puzzle_id: puzzle.puzzle_id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };
            soundsArray.push(obj);
          });
        }
      });

      this.props.loadSounds(soundsArray)

      // set state for puzzles
      this.setState({
        allClues: cluesArray,
        allSounds: soundsArray
      });
    } else {
      this.setState({
        allClues: [],
      });
    }
  };

  handleLoadClues = (puzzles) => {

    if (puzzles && this.state.selectedPuzzle) {
      const selectedPuzz = [
        ...puzzles.filter((x) => x.puzzle_id === this.state.selectedPuzzle),
      ];

      var cluesArray = [];
      var soundsArray = [];
      selectedPuzz.forEach((puzzle) => {
        const clues = puzzle.clues;
        const sounds = puzzle.sounds;

        // if Clues
        if (clues) {
          clues.forEach((clue) => {
            const obj = {
              ...clue,
              message: clue.message,
              id: clue.id,
              puzzle_id: puzzle.id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };

            cluesArray.push(obj);
          });
        }

        if (sounds) {
          const sentClues = this.state.sentClues;
          sounds.forEach((sound) => {
            var isClueSent = false;
            if (sentClues.includes(sound.id)) {
              isClueSent = true;
            } else {
              isClueSent = false;
            }

            const obj = {
              ...sound,
              done: false,
              id: sound.id,
              clueSent: isClueSent,
              puzzle_id: puzzle.puzzle_id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };
            soundsArray.push(obj);
          });
        }
      });

      // set state for puzzles
      this.setState({
        clues: cluesArray,
        sounds: soundsArray
        //allClues: allCluesArray,
        //puzzles: [...puzzles].sort((a,b) => a.index - b.index)
      });
    } else if (puzzles && !this.state.selectedPuzzle) {
      var cluesArray = [];
      var soundsArray = [];
      puzzles.forEach((puzzle) => {
        const clues = puzzle.clues;
        // Sounds
        const sounds = puzzle.sounds;
        

        // if Clues
        if (clues) {
          clues.forEach((clue) => {
            const obj = {
              ...clue,
              message: clue.message,
              id: clue.id,
              puzzle_id: puzzle.id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };
            cluesArray.push(obj);
          });
        }

        if (sounds) {
          const sentClues = this.state.sentClues;
          sounds.forEach((sound) => {
            var isClueSent = false;
            if (sentClues.includes(sound.id)) {
              isClueSent = true;
            } else {
              isClueSent = false;
            }

            const obj = {
              ...sound,
              done: false,
              id: sound.id,
              clueSent: isClueSent,
              puzzle_id: puzzle.puzzle_id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };
            soundsArray.push(obj);
          });
        }
      });

      // set state for puzzles
      this.setState({
        clues: cluesArray,
        sounds: soundsArray
        //allClues: cluesArray,
        //puzzles: [...puzzles].sort((a,b) => a.index - b.index)
      });
    } else {
      this.setState({
        clues: [],
        sounds: [],
        //allClues: [],
        puzzles: [],
      });
    }
  };

  handleSelectPuzzle = (puzzleObj) => {
    if (!puzzleObj) {
      this.setState({
        selectedPuzzle: "",
        selectedPuzzleObj: {},
        puzzleIsSelected: false,
        selectedPuzzleName: "",
      });
    } else {
      const selectedPuzzle = this.state.selectedPuzzle;

      // Find selected puzzle from master list
      const puzzle = [
        ...this.props.gm_puzzles.filter((x) => x.id === puzzleObj.id),
      ][0];

      // Check if already selected
      if (puzzle.puzzle_id === selectedPuzzle) {
        // this.setState({
        //     selectedPuzzle: '',
        //     selectedPuzzleObj: puzzle,
        //     clues: this.state.allClues,
        //     puzzleIsSelected: false,
        //     selectedPuzzleName: ''
        // })
      } else {

        // Clues
        const puzzleClues = puzzle.clues;
        var cluesArray = [];

        // Sounds
        const puzzleSounds = puzzle.sounds;
        var soundsArray = [];

        if (puzzleSounds) {
          const sentClues = this.state.sentClues;
          puzzleSounds.forEach((sound) => {
            var isClueSent = false;
            if (sentClues.includes(sound.id)) {
              isClueSent = true;
            } else {
              isClueSent = false;
            }

            const obj = {
              ...sound,
              done: false,
              id: sound.id,
              clueSent: isClueSent,
              puzzle_id: puzzle.puzzle_id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };
            soundsArray.push(obj);
          });
        }

        if (puzzleClues) {
          const sentClues = this.state.sentClues;

          puzzleClues.forEach((clue) => {
            var isClueSent = false;
            if (sentClues.includes(clue.id)) {
              isClueSent = true;
            } else {
              isClueSent = false;
            }

            const obj = {
              ...clue,
              done: false,
              message: clue.message,
              id: clue.id,
              clueSent: isClueSent,
              puzzle_id: puzzle.puzzle_id,
              puzzle_doc_id: puzzle.id,
              puzzle: puzzle.name,
              sent: false,
              selected: false,
            };
            cluesArray.push(obj);
          });

        }

        this.setState({
          selectedPuzzle: puzzle.puzzle_id,
          selectedPuzzleObj: puzzle,
          puzzleIsSelected: true,
          selectedPuzzleName: puzzle.name,
          clues: cluesArray,
          sounds: soundsArray
        })



      }
    }
  };

  handleAddToSentClues = (id) => {
    var sentClues = this.state.sentClues;
    sentClues.push(id);
    this.setState({
      sentClues: sentClues,
    });
    this.handleCheckmarkSentClue(id);
  };

  handleCheckmarkSentClue = (id) => {
    //console.log("handle checkmark clues")
    var allClues = this.state.allClues;
    var clues = this.state.clues;

    const selectedPuzzle = this.state.selectedPuzzle;
    const sentClues = this.state.sentClues;

    allClues.forEach((item) => {
      if (sentClues.includes(item.id)) {
        item.clueSent = true;
      } else {
        item.clueSent = false;
      }
    });

    this.setState({
      allClues: allClues,
    });
  };

  handleResetSentClues = () => {
    this.setState({
      sentClues: [],
      selectedPuzzle: "",
      puzzleIsSelected: false,
      selectedPuzzleName: "",
      puzzlesDone: [],
      finishedPuzzlesLog: [],
      loadedClue: "",
    });

    //this.handleLoadClues(this.props.gm.puzzles)
    this.handleLoadClues(this.props.gm_puzzles);
  };

  handleRefreshClues = () => {
    //this.handleLoadClues(this.props.gm.puzzles)
    this.handleLoadClues(this.props.gm_puzzles);
  };

  markPuzzleAsDone = (obj, done) => {
    var puzzlesDone = this.state.puzzlesDone;
    var finishedPuzzlesLog = this.state.finishedPuzzlesLog;

    if (done) {
      const doneObj = {
        id: obj.id,
        completed_at: {
          time: new Date(),
          timer: {
            m: Number(this.props.timer.time.m),
            s: Number(this.props.timer.time.s)
          }
        },
        data: obj,
      };
      puzzlesDone.push(doneObj);
      finishedPuzzlesLog.push({ ...obj, completed_at: new Date() });

      // Clear Loaded Clue
      // this.handleClearLoadedClue()
      // this.props.updateClueOnScreen('')
    } else {
      puzzlesDone = puzzlesDone.filter((puzzle) => {
        if (puzzle.id !== obj.id) return puzzle;
      });
      finishedPuzzlesLog = finishedPuzzlesLog.filter((puzzle) => {
        if (puzzle.id !== obj.id) return puzzle;
      });
    }

    const doneData = {
      done: true,
      completed_at: {
        time: new Date(),
        timer: {
          m: Number(this.props.timer.time.m),
          s: Number(this.props.timer.time.s)
        },
      },
    };

    const updatedPuzzleList = this.state.puzzles.map((puzzle) =>
      puzzle.id === obj.id ? { ...puzzle, ...doneData } : puzzle
    );

    this.setState({
      puzzles: updatedPuzzleList,
      puzzlesDone: puzzlesDone,
      selectedPuzzleObj: { ...this.state.selectedPuzzleObj, ...doneData },
      finishedPuzzlesLog: finishedPuzzlesLog,
    });
  };


  render() {
    const {
      loadedClue,
      clues,
      puzzles,
      allClues,
      selectedPuzzle,
      selectedPuzzleName,
      selectedPuzzleObj,
      darkMode,
      puzzlesDone,
      basicDataLoaded,
      allAssetsLoaded,
      onboarding_steps,
      charge_failed,
    } = this.state;
    const { gm, profile } = this.props;

    const divClass = () => {
      if (darkMode) {
        return "chat-wrapper chat-wrapper-two dark";
      } else {
        return "chat-wrapper chat-wrapper-two";
      }
    };

    const game_name = gm ? gm.name : null;
    const game_stats = gm ? gm.stats : null;
    const assets = this.props.gameMaster ? this.props.gameMaster.assets : null;

    const loaded_assets = {
      alert: this.state.alertIsLoaded,
      alert_duration: this.state.alert_duration,
      alert_message: this.state.alert_message,
      alert_name: this.state.alert_name,
      soundtrack: this.state.soundtrackIsLoaded,
      soundtrack_duration: this.state.soundtrack_duration,
      soundtrack_message: this.state.soundtrack_message,
      soundtrack_name: this.state.soundtrack_name,
      success: this.state.successIsLoaded,
      success_duration: this.state.success_duration,
      success_message: this.state.success_message,
      success_name: this.state.success_name,
      fail: this.state.failIsLoaded,
      fail_duration: this.state.fail_duration,
      fail_message: this.state.fail_message,
      fail_name: this.state.fail_name,
      video_brief: this.state.video_briefIsLoaded,
      // video_brief: this.state.video_duration,
      // video_brief: this.state.video_message
    };

    const id = this.props.match.params.id ? this.props.match.params.id : null;
    const view = new URLSearchParams(this.props.location.search).get("view");

    if (!view)
      window.location.replace(window.location.pathname + "?view=puzzles");

    Sentry.setContext("gamemaster", {
      name: game_name,
      id: id,
      assets_loaded: allAssetsLoaded,
    });

    return (
      <div className={divClass()}>
        {this.state.joyride_run && (
          <Joyride
          steps={onboarding_steps}
          callback={this.handleJoyrideCallback}
          run={this.state.joyride_run}
          showSkipButton={true}
          continuous={true}
          hideBackButton={false}
          showProgress={true}
          spotlightClicks={true}
          styles={{
            options: {
              arrowColor: "#000",
              backgroundColor: "#fff",
              overlayColor: "rgba(0, 0, 0, 0.5)",
              primaryColor: "#000",
              fontSize: "12px",
              textColor: "#000",
              width: 900,
              zIndex: 1000,
            },
          }}
        />
        )}

        {/* <GMSnackBar add_new_puzzle={this.props.add_new_puzzle} /> */}
        <Toaster position="top-center" />

        {allAssetsLoaded ? (
          <React.Fragment>
            <Header
              game={game_name}
              profile={this.props.profile}
              account={this.props.account}
              userPermissions={this.state.userPermissions}
            />

<div className={classNames(
                  "shadow",
                  view && view !== "puzzles" ? "hidden" : null
                )}>
                <PropsSidebar
                  id={id}
                  puzzles={puzzles}
                  handleGreyOutClue={this.handleGreyOutClue}
                  handleSelectPuzzle={this.handleSelectPuzzle}
                  selectedPuzzle={selectedPuzzle}
                  selectedPuzzleObj={selectedPuzzleObj}
                  puzzlesDone={puzzlesDone}
                  markPuzzleAsDone={this.markPuzzleAsDone}
                  userPermissions={this.state.userPermissions}
                />

                <LeftSideBar
                  id={id}
                  allClues={allClues}
                  handleClearFilteredClues={this.handleClearFilteredClues}
                  clues={clues}
                  // sounds={this.state.allSounds}
                  puzzles={puzzles}
                  handleLoadClueClick={this.handleLoadClueClick}
                  selectedPuzzleName={selectedPuzzleName}
                  selectedPuzzleObj={selectedPuzzleObj}
                  handleRefreshClues={this.handleRefreshClues}
                  markPuzzleAsDone={this.markPuzzleAsDone}
                  loadedClue={loadedClue}
                  gm_id={this.props.gm_id}
                  puzzlesDone={puzzlesDone}
                  sentClues={this.state.sentClues}
                  updatePuzzleStateWithUpdate={this.updatePuzzleStateWithUpdate}
                  userPermissions={this.state.userPermissions}
                  handleSelectPuzzle={this.handleSelectPuzzle}
                />
                </div>

              <div className={classNames(
                view && view !== "audio-mixer" ? "hidden" : null
              )}>
                <AudioControls game_id={id} loaded_assets={loaded_assets} />
              </div>

            <Chat
              view={view}
              id={id}
              versionNumber={this.state.versionNumber}
              profile={profile}
              state={this.state}
              handleResetSentClues={this.handleResetSentClues}
              handleAddToSentClues={this.handleAddToSentClues}
              game_name={game_name}
              loadedClue={loadedClue}
              game_assets={assets}
              handleLoadClue={this.handleLoadClue}
              handleLoadClueClick={this.handleLoadClueClick}
              handleClearLoadedClue={this.handleClearLoadedClue}
              loaded_assets={loaded_assets}
              remoteLiveWindow={this.state.remoteLiveWindow}
              finishedPuzzlesLog={this.state.finishedPuzzlesLog}
              puzzlesDone={puzzlesDone}
              puzzles={puzzles}
              handleLoadPreviousClue={this.handleLoadPreviousClue}
            />

            <footer
              className=" bg-v1_dark-header border-t border-v1_dark-hover absolute bottom-0 left-0 right-0 text-xs px-2 py-1 w-full flex items-center space-between"
            >
              <div style={{ marginLeft: "0px" }}>
                <a href="#ExitToDashboardModal" data-toggle="modal">
                  <button
                    className="button-outline-dark"
                    style={{ marginRight: "20px", padding: "3px 10px" }}
                  >
                    <i
                      className="icon ion-md-return-left"
                      style={{ marginRight: "5px" }}
                    ></i>
                    Exit to Dashboard
                  </button>
                </a>

                {/* {this.props.app_data && this.props.app_data.app_version !== this.state.versionNumber ? (
            <div style={{display:'inline-block'}}>
                <FadeIn>
                <button 
                    onClick={() => window.location.reload()} 
                    className="btn btn-xs btn-outline-warning" 
                    style={{marginRight:"40px", padding:'3px 10px'}}
                    disabled={this.props.timer.timerRunning}
                    >
                A new version is available.
                </button>
            </FadeIn>

            </div>
        ): null} */}

                {this.props.subscription_status &&
                  this.props.subscription_status.type === "charge.failed" && (
                    <a
                      href="/account/billing"
                      target="_blank"
                      className="button-red-dark"
                      style={{
                        marginRight: "40px",
                        padding: "3px 10px",
                        color: "white",
                        textTransform: "none",
                        fontSize: "12px",
                        letterSpacing: "0",
                      }}
                    >
                      <b>Payment Declined:</b> Please update your payment info.
                    </a>
                  )}



              </div>

              <div>
                <nav
                  className="nav text-gray-400"
                  style={{ alignItems: "center" }}
                >
                  {/* <a href="https://themeforest.net/licenses/standard" className="nav-link">Licenses</a>
            <a href="../../change-log.html" className="nav-link">Change Log</a> */}
                  {/* <span className="nav-link">{account[0].title}</span> */}
                  <span style={{ padding: "8px 15px" }} className="font-medium">
                  {game_name ? game_name : ""}
                  </span>
                  <span style={{ padding: "8px 15px"}}>
                    <b>
                      {game_stats ? game_stats.escape_rate.toFixed() : "0"}%
                    </b>{" "}
                    Success Rate
                  </span>
                  <span style={{ padding: "8px 15px"}}>
                    <b>{game_stats ? game_stats.wins : "0"}</b> Wins
                  </span>
                  <span style={{ padding: "8px 15px" }}>
                    <b>{game_stats ? game_stats.losses : "0"}</b> Losses
                  </span>
                  <span style={{ padding: "8px 15px" }}>
                    v{this.state.versionNumber}
                  </span>
                </nav>
              </div>
            </footer>
          </React.Fragment>
        ) : (
          <div
            className="text-center"
            style={{
              left: "50%",
              marginLeft: "-2em",
              position: "absolute",
              top: "40%",
            }}
          >
            <div
              className="spinner-border"
              style={{ width: "5rem", height: "5rem" }}
            ></div>
          </div>
        )}

        <ExitToDashboardModal />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const gm = state.firestore.data.gamemaster;
  const gm_id = gm ? gm[id] : null;
  const account = state.firestore.ordered.user_accounts
    ? state.firestore.ordered.user_accounts[0]
    : null;

  return {
    timer: state.timer,
    gm_id: id,
    gm: gm_id,
    gm_puzzles: state.firestore.ordered.puzzles
      ? state.firestore.ordered.puzzles
      : null,
    // gm_assets: state.firestore.data.assets
    // ? state.firestore.data.assets
    // : null,
    new_assets: state.gameMaster.new_assets
      ? state.gameMaster.new_assets
      : null,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    uid: state.firebase.auth.uid ? state.firebase.auth.uid : "123",
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts[0]
      : null,
    subscription_status:
      account && account.subscription_status
        ? account.subscription_status
        : null,
    app: state.app,
    app_data: state.firestore.ordered.app_data
      ? state.firestore.ordered.app_data[0]
      : null,
    add_new_puzzle: state.gameMaster.add_new_puzzle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAssets: (assets) => dispatch(updateAssets(assets)),
    updateTimer: (data) => dispatch(updateTimer(data)),
    audio_control: (type, audio) => dispatch(audio_control(type, audio)),
    initializeState: () => dispatch(initializeState()),
    getAssetsData: (game_id) => dispatch(getAssetsData(game_id)),
    updateClueOnScreen: (clue, func) =>
      dispatch(updateClueOnScreen(clue, func)),
    update_onboarding: (account_id, boolean) =>
      dispatch(update_onboarding(account_id, boolean)),
    loadSounds: (sounds) => dispatch(loadSounds(sounds)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => [
    {
      collection: "gamemaster",
      doc: props.match.params.id,
    },
    {
      collection: "gamemaster",
      doc: props.match.params.id,
      subcollections: [
        {
          collection: "puzzles",
          orderBy: ["created_at", "asc"],
        },
      ],
      storeAs: "puzzles",
    },
    {
      collection: "app",
      doc: "stats",
      storeAs: "app_data",
    },
  ])
)(GMControl);
