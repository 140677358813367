import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  NavLink,
  Link,
} from "react-router-dom";
import "./Account.css";

import AccountBilling from "./AccountBilling";
import AccountBillingHistory from "./AccountBillingHistory";
import AccountBillingMethod from "./AccountBilllingMethod";
import AccountProfile from "./AccountProfile";
import AccountSettings from "./AccountSettings";
import AccountUsers from "./AccountUsers";

// Sentry.io
import * as Sentry from "@sentry/react";

export class AccountHome extends Component {
  state = {
    userPermissions: null,
  };

  render() {
    const { auth, account } = this.props;
    const userPermissions = this.state.userPermissions;

    Sentry.setUser({
      email: auth.email,
      id: auth.uid,
    });

    if (!account) {
      return (
        <div className="content ht-100v pd-0">
          <div
            className="text-center"
            style={{
              left: "50%",
              marginLeft: "-2em",
              position: "absolute",
              top: "40%",
            }}
          >
            <div
              className="spinner-border"
              style={{ width: "5rem", height: "5rem" }}
            ></div>
          </div>
        </div>
      );
    }

    return (
      <BrowserRouter>
        <div className="account-wrapper">
          <div class="account-header">
            <div class="div-block">
              <h3
                style={{
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: "0",
                }}
              >
                BOLT
              </h3>
              {/* <img src="/hintspace_white.svg" alt="Hintspace Logo" width="125px" /> */}
            </div>
            <div class="div-block-5">
              <div class="div-block-6">
                <p class="paragraph-2">Currently logged in as</p>
                <p class="paragraph-2-copy">{this.props.profile.email}</p>
              </div>
            </div>
          </div>
          <div class="account-container">
            <div class="account-sidebar">
              <a href="/" className="account-back">
                <div class="div-block-2">
                  <ion-icon name="chevron-back-outline"></ion-icon>
                  <span style={{ color: "#c5cbd4" }}>Back to App</span>
                </div>
              </a>
              <div class="div-block-21">
                <p class="paragraph-15">ACCOUNT</p>
              </div>

              <div className="account-nav-item">
                <NavLink exact to="/account">
                  Account Settings
                </NavLink>
              </div>

              {/* <div className="account-nav-item">
                <NavLink to="/account/users">Users</NavLink>
              </div> */}

              <div className="account-nav-item">
                <NavLink to="/account/billing">Billing</NavLink>
              </div>
            </div>
            <div class="account-main">
              <Switch>
                <Route
                  exact
                  path="/account"
                  component={() => <AccountSettings account={account} />}
                />
                <Route
                  exact
                  path="/account/billing"
                  component={() => (
                    <AccountBilling account={account} uid={this.props.uid} />
                  )}
                />
                <Route
                  exact
                  path="/account/billing/history"
                  component={AccountBillingHistory}
                  uid={this.props.uid}
                />
                <Route
                  exact
                  path="/account/billing/payment"
                  component={() => (
                    <AccountBillingMethod
                      account={account}
                      uid={this.props.uid}
                    />
                  )}
                />
                <Route
                  exact
                  path="/account/profile"
                  component={AccountProfile}
                />
                <Route exact path="/account/users" component={AccountUsers} />
              </Switch>
            </div>
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  console.log(state);

  const account_id = state.firebase.profile.account_id
    ? state.firebase.profile.account_id
    : null;

  return {
    auth: state.firebase.auth,
    account_id: account_id,
    uid: state.firebase.auth.uid ? state.firebase.auth.uid : "123",
    profile: state.firebase.profile,
    app: state.app,
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts[0]
      : null,
  };
};

//   const mapDispatchToProps = (dispatch) => {
//     return {
//       addNewRoom: (name) => dispatch(addNewRoom(name)),
//       deleteRoom: (room_id) => dispatch(deleteRoom(room_id)),
//     };
//   };

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => [
    {
      collection: "user_accounts",
      doc: props.account_id,
    },
  ])
)(AccountHome);
