import React, { useState } from "react";
import Rating from 'react-rating'
import { connect } from 'react-redux'

import IconButton from '@mui/material/IconButton';
import Backspace from '@mui/icons-material/Backspace';

import {addNewClue} from '../../../../store/actions/gmActions'


const AddClueModal = (props) => {
  const { id, puzzles, handleAddNewClue, selectedPuzzleObj, game_id } = props;

  const [data, setData] = useState({
    message: "",
    rating: 0
  });

  const updateData = (name, value) => {
    const newObj = {
      ...data,
      [name]: value,
    };
    setData(newObj);
  };

  
  const title = selectedPuzzleObj ? selectedPuzzleObj.name : '';

  return (
    <div className="modal effect-scale" id="addClueModal" tabIndex="-1" role="dialog" aria-hidden="true">

    <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
      <div className="modal-content">
        <div className="modal-body">
          <div className="mb-b-10">
        
          <p className='text-lg mb-1 text-white font-medium'>Create clue</p>
          </div>


          <div className="flex flex-col">
            <label className="label-dark">Message</label>
            <input value={data.name} onChange={(e) => updateData("message", e.target.value)} type="text" className="input-dark" placeholder="Type a clue here..."></input>
          </div>

          {/* <div className="form-group">
              <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5 tx-color-03">Rating</label>
              <div style={{display:'flex', alignItems:'center'}}>
              <Rating 
                placeholderRating={data.rating} 
                emptySymbol={<i className='icon ion-ios-radio-button-off' style={{fontSize:'22px', marginRight:'1px'}}></i>} 
                fullSymbol={<i className='icon ion-ios-radio-button-on' style={{fontSize:'22px', color: 'rgb(236 236 88)'}}></i>} 
                placeholderSymbol={<i className='icon ion-ios-radio-button-on' style={{fontSize:'22px', color: 'rgb(236 236 88)'}}></i>} 
                onChange={(rating) => updateData('rating', rating)}
            />

          {data.rating >= 1 && (
            <IconButton onClick={() => updateData('rating', 0)} aria-label="add" size="small" style={{marginLeft:'20px'}}>    
            <Backspace 
            fontSize="small" 
            style={{color:'#9f9793'}}
             />

          </IconButton>
          )}

              </div>
            </div> */}



      
        </div>
        <div className="modal-footer-dark">
          
          {data.message ? (
            <button onClick={()=> props.addNewClue(data, game_id, selectedPuzzleObj.id)} type="button" className="button-blue-dark" data-dismiss="modal">Create</button>
          ) : (
            <button disabled type="button" className="button-blue-dark opacity-50" data-dismiss="modal">Create</button>
          )}
          <button type="button" className="button-outline-dark ml-2" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  );
};

const mapDispatchToProps = (dispatch) => {
    return {
      addNewClue: (data, id, puzzle_id) => dispatch(addNewClue(data, id, puzzle_id))
    }
}

export default connect(null, mapDispatchToProps)(AddClueModal)
