import uuid from "react-uuid";
import { toast } from "sonner";

export const updateAssets = (assets) => {
  return (dispatch, getState) => {
    dispatch({ type: "UPDATE_ASSETS", assets });
  };
};

// Clues
export const addNewClue = (data, id, puzzle_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();

    let ref = db
      .collection("gamemaster")
      .doc(id)
      .collection("puzzles")
      .doc(puzzle_id);

    let transaction = db
      .runTransaction((t) => {
        return t.get(ref).then((doc) => {
          const clueObj = {
            ...data,
            id: uuid(),
            created_at: db.Timestamp.now(),
            type: "text",
          };
          return t.update(ref, {
            clues: firebase.firestore.FieldValue.arrayUnion(clueObj),
          });
        });
      })
      .then((result) => {
        // console.log("Transaction success", result);
        dispatch({ type: "ADD_NEW_CLUE" });

        toast("New clue created", {
          className: "toast-dark",
        });

        firebase.analytics().logEvent("add_clue", {
          data,
          bolt_version: getState().app.app_verison,
        });
      })
      .catch((error) => {
        console.log("Transaction failure:", error);
        dispatch({ type: "ADD_NEW_CLUE_ERROR", error });
      });
  };
};
export const updateClue = (doc_id, puzzle_id, clue_id, update, type) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    let ref = db
      .collection("gamemaster")
      .doc(doc_id)
      .collection("puzzles")
      .doc(puzzle_id);

    let transaction = db
      .runTransaction((t) => {
        return t.get(ref).then((doc) => {
          let clues = doc.data().clues;

          if (type === "delete") {
            // clues = clues.map((clue) =>
            //   clue.id !== clue_id ? clue : null
            // );

            clues = clues.filter((clue) => {
              if (clue.id !== clue_id) return clue;
            });

            // console.log('new clues', clues)
          } else {
            clues = clues.map((clue) =>
              clue.id === clue_id ? { ...clue, ...update } : clue
            );
          }

          return t.update(ref, {
            clues: clues,
          });
        });
      })
      .then((result) => {
        console.log("Edit Hint Success!");
        //dispatch({ type: "UPDATE_PUZZLE_NAME" });
      })
      .catch((error) => {
        console.log("Edit Hint ERROR", error);
      });
  };
};
export const reorderClues = (doc_id, puzzle_id, data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    var newOrder = [];
    data.forEach((el) => {
      const obj = {
        updated: db.Timestamp.now(),
        ...el,
      };
      newOrder.push(obj);
    });

    db.collection("gamemaster")
      .doc(doc_id)
      .collection("puzzles")
      .doc(puzzle_id)
      .update({
        clues: newOrder,
      })
      .then(() => {
        // console.log('successfully reorder')
      })
      .catch((error) => {
        console.log(error);
        //dispatch({ type: "ADD_NEW_PUZZLE_ERROR", error });
      });
  };
};

// Puzzles
export const addNewPuzzle = (puzzle, id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = getFirestore();

    // var puzzleObj = puzzle;
    // puzzleObj.id = uuid();

    const puzzle_id = uuid();

    const data = {
      ...puzzle,
      puzzle_id: puzzle_id,
      created_at: db.Timestamp.now(),
      example: false,
    };

    db.collection("gamemaster")
      .doc(id)
      .collection("puzzles")
      .add(data)
      .then((puzzle) => {
        console.log(puzzle.id);

        toast("New puzzle created", {
          className: "toast-dark",
        });

        dispatch({ type: "ADD_NEW_PUZZLE", status: "success" });

        firebase.analytics().logEvent("add_puzzle", {
          data,
          bolt_version: getState().app.app_verison,
        });

        setTimeout(() => {
          dispatch({ type: "ADD_NEW_PUZZLE", status: "" });
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "ADD_NEW_PUZZLE_ERROR", error });
      });
  };
};
export const updatePuzzle = (doc_id, puzzle_id, update) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    db.collection("gamemaster")
      .doc(doc_id)
      .collection("puzzles")
      .doc(puzzle_id)
      .update({
        ...update,
      })
      .then(() => {
        dispatch({ type: "UPDATE_PUZZLE_NAME" });
        toast("Puzzle updated", {
          className: "toast-dark",
        });
      })
      .catch((error) => {
        console.log(error);
        //dispatch({ type: "ADD_NEW_PUZZLE_ERROR", error });
      });
  };
};
export const reorderPuzzles = (doc_id, puzzle_id, index) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    db.collection("gamemaster")
      .doc(doc_id)
      .collection("puzzles")
      .doc(puzzle_id)
      .update({
        index: index,
      })
      .then(() => {
        // console.log('successfull puzzle reorder')
      })
      .catch((error) => {
        console.log(error);
        //dispatch({ type: "ADD_NEW_PUZZLE_ERROR", error });
      });
  };
};
export const deletePuzzle = (doc_id, puzzle_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();

    console.log("doc id", doc_id);
    console.log("puzzle id", puzzle_id);

    db.collection("gamemaster")
      .doc(doc_id)
      .collection("puzzles")
      .doc(puzzle_id)
      .delete()
      .then(() => {
        toast("Puzzle deleted", {
          className: "toast-dark",
        });

        console.log("success");
        //dispatch({ type: "DELETE ROOM" });
        firebase.analytics().logEvent("delete_puzzle", {
          data: { doc_id, puzzle_id },
          bolt_version: getState().app.app_verison,
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error");
        //dispatch({ type: "DELETE_ROOM_ERROR", error });
      });
  };
};

// Sounds
export const addNewSound = (data, id, puzzle_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();

    let ref = db
      .collection("gamemaster")
      .doc(id)
      .collection("puzzles")
      .doc(puzzle_id);

    let transaction = db
      .runTransaction((t) => {
        return t.get(ref).then((doc) => {
          const soundObj = {
            ...data,
            id: uuid(),
            created_at: db.Timestamp.now(),
            type: "sound",
            volume: 100,
          };
          return t.update(ref, {
            sounds: firebase.firestore.FieldValue.arrayUnion(soundObj),
          });
        });
      })
      .then((result) => {
        // console.log("Transaction success", result);
        dispatch({ type: "ADD_NEW_SOUND" });
        dispatch({ type: "UPLOADED_SOUND_DATA", data: null });

        toast("New sound added", {
          className: "toast-dark",
        });

        firebase.analytics().logEvent("add_sound", {
          data,
          bolt_version: getState().app.app_verison,
        });
      })
      .catch((error) => {
        console.log("Transaction failure:", error);
        dispatch({ type: "ADD_NEW_CLUE_ERROR", error });
      });
  };
};
export const uploadSound = (file, game_doc) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const user_account = getState().firebase.profile.account_id;

    // Create a root reference
    var storageRef = firebase.storage().ref();

    // Create the file metadata
    var metadata = {
      cacheControl: "private,max-age=86400",
    };

    console.log("file", file);

    const fileName = file.name + "_" + uuid().substring(0, 10);

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = storageRef
      .child(
        "gamemaster/" + user_account + "/" + game_doc + "/sounds/" + fileName
      )
      .put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        // Dispatch - Trigger Upload Progress Bar
        dispatch({
          type: "UPLOAD_SOUND_PROGRESS",
          progress: progress,
        });

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;

          case "storage/canceled":
            // User canceled the upload
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log("File available at", downloadURL);

          dispatch({ type: "UPLOAD_SOUND_PROGRESS", progress: 0 });
          dispatch({
            type: "UPLOADED_SOUND_DATA",
            data: {
              url: downloadURL,
              name: file.name,
              type: file.type,
              size: file.size,
              file_name: fileName,
              path:
                "gamemaster/" +
                user_account +
                "/" +
                game_doc +
                "/sounds/" +
                fileName,
            },
          });
        });
      }
    );
  };
};
export const updateSound = (doc_id, puzzle_id, sound_obj, update, type) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    var storageRef = firebase.storage().ref();

    let ref = db
      .collection("gamemaster")
      .doc(doc_id)
      .collection("puzzles")
      .doc(puzzle_id);

    let transaction = db
      .runTransaction((t) => {
        return t.get(ref).then((doc) => {
          let sounds = doc.data().sounds;

          if (type === "delete") {
            sounds = sounds.filter((sound) => {
              if (sound.id !== sound_obj.id) return sound;
            });

            if (sound_obj.file.path){
              var fileRef = storageRef.child(sound_obj.file.path);
              fileRef
                .delete()
                .then(() => {
                  // File deleted successfully
                  console.log("File deleted from Storage");
                })
                .catch((error) => {
                  // Uh-oh, an error occurred!
                  console.log("Error deleting file:", error);
                });
            }
            
          } else {
            sounds = sounds.map((sound) =>
              sound.id === sound_obj.id
                ? {
                    ...sound,
                    ...update,
                    volume: Number(update.volume),
                  }
                : sound
            );
          }

          return t.update(ref, {
            sounds: sounds,
          });
        });
      })
      .then((result) => {
        console.log("Edit Sound Success!");
        dispatch({ type: "UPDATED_SOUND", status: new Date() });
        // setTimeout(() => {
        //   dispatch({ type: "UPDATED_SOUND", status: false});
        // }, 500);
      })
      .catch((error) => {
        console.log("Edit Sound ERROR", error);
      });
  };
};
export const loadSounds = (sounds) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: "LOAD_SOUNDS", sounds });
  };
};
export const reorderSounds = (doc_id, puzzle_id, data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    var newOrder = [];
    data.forEach((el) => {
      const obj = {
        updated: db.Timestamp.now(),
        ...el,
      };
      newOrder.push(obj);
    });

    db.collection("gamemaster")
      .doc(doc_id)
      .collection("puzzles")
      .doc(puzzle_id)
      .update({
        sounds: newOrder,
      })
      .then(() => {
        // console.log('successfully reorder')
      })
      .catch((error) => {
        console.log(error);
        //dispatch({ type: "ADD_NEW_PUZZLE_ERROR", error });
      });
  };
};

// Data
export const getAssetsData = (game_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();

    db.collection("gamemaster")
      .doc(game_id)
      .collection("assets")
      .get()
      .then((querySnapshot) => {
        var assets = {};
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          assets[doc.id] = doc.data();
        });

        dispatch({ type: "GET_ASSETS_DATA", new_assets: assets });

        firebase.analytics().logEvent("get_assets", {
          data: assets,
          bolt_version: getState().app.app_verison,
        });
      });
  };
};
export const getLiveWindowSettings = (game_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    db.collection("gamemaster")
      .doc(game_id)
      .collection("settings")
      .doc("live_window")
      .get()
      .then(function (doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          dispatch({ type: "GET_LIVEWINDOW_SETTINGS", settings: doc.data() });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          return db
            .collection("gamemaster")
            .doc(game_id)
            .collection("settings")
            .doc("live_window")
            .set({
              victory: {
                font: "",
                text_size: "",
                show: true,
                text: "Success!",
                background_color: "transparent",
              },
              fail: {
                font: "",
                text_size: "",
                show: true,
                text: "Game Over.",
                background_color: "transparent",
              },
              background_color: "#1e1d23",
            });
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };
};

// Rooms
export const addNewRoom = (name, timer) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const batch = db.batch();

    const userId = getState().firebase.auth.uid;
    const account_id = getState().firebase.profile.account_id;

    var name_id = name;
    name_id = name_id
      .replace(/([^a-zA-Z0-9])/, "")
      .replace(/\s/g, "-")
      .replace("#", "", "")
      .toLowerCase()
      .trim();

    const room_id = name_id + "-" + uuid().substring(0, 10);

    const minutes = Number(timer) / 60;

    const data = {
      created_at: new Date(),
      name: name,
      timer: {
        seconds: timer,
        time: { m: minutes.toString(), s: "00" },
      },
      user_id: userId,
      account_id: account_id,
      stats: { wins: 0, losses: 0, escape_rate: 0 },
      userPermissions: [{ isAdmin: true, user_id: userId }],
    };

    // Create Game Doc
    const create_game = db.collection("gamemaster").doc(room_id);
    batch.set(create_game, data);

    // Create Live Window Settings Doc
    const lw_settings = db
      .collection("gamemaster")
      .doc(room_id)
      .collection("settings")
      .doc("live_window");
    batch.set(lw_settings, {
      victory: {
        font: "",
        size: 10,
        size_timeRemaining: 22,
        show: true,
        showTimeRemaining: true,
        text: "You Escaped!",
        color: "#ffff",
        bg_color: "#000",
      },
      fail: {
        font: "",
        size: 16,
        show: true,
        show_timeRemaining: true,
        text: "Game Over.",
        color: "#fff",
        bg_color: "#d0021b",
      },
      timer: {
        font: "",
        size: 22,
        show: true,
        show_hintTimer: true,
        show_hintIcons: true,
        show_timeRemaining: true,
        color: "#fff",
        bg_color: "#000",
        bg_color_hintBox: "#222222",
        bg_color_hintTimer: "#3A3A3A",
        hintColor: "#fff",
        hintTimerColor: "#fff",
        hintIconColor: "#F8E71C",
      },
      background_color: "#1e1d23",
    });

    // Create Starter Puzzle Doc
    const create_puzzle = db
      .collection("gamemaster")
      .doc(room_id)
      .collection("puzzles")
      .doc(uuid());
    batch.set(create_puzzle, {
      clues: [
        {
          created_at: db.Timestamp.now(),
          id: uuid(),
          message: "Did you check behind the bookshelf?",
        },
        {
          created_at: db.Timestamp.now(),
          id: uuid(),
          message:
            "Drag to re-order clues. Click and drag any clue to re-order it.",
          rating: 0,
        },
      ],
      color: "#fcb900",
      note: "CODE: 1279",
      contains: "Contains skeleton key.",
      name: "Puzzle #1: Pirate Chest",
      type: "generic",
      puzzle_id: uuid(),
      created_at: db.Timestamp.now(),
      example: true,
    });

    // Add Default Audio Alert Doc
    const create_default_alert = db
      .collection("gamemaster")
      .doc(room_id)
      .collection("assets")
      .doc("audio_alert");
    batch.set(create_default_alert, {
      name: "default_alert.mp3",
      type: "audio/mpeg",
      url: "https://firebasestorage.googleapis.com/v0/b/escaperoomwaiver-v2.appspot.com/o/gamemaster%2F_default%2Faudio_alert.mp3?alt=media&token=c1518d8e-b51d-4742-871d-cdd9fae51104",
      volume: 1,
    });

    return batch
      .commit()
      .then(() => {
        toast.success("Room has been created");

        firebase.analytics().logEvent("add_game", {
          data,
          bolt_version: getState().app.app_verison,
        });
      })
      .catch((error) => {
        console.log("ERROR", error);
        toast.error("Error");
      });
  };
};
export const deleteRoom = (room_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();

    db.collection("gamemaster")
      .doc(room_id)
      .delete()
      .then(() => {
        dispatch({ type: "DELETE ROOM" });

        firebase.analytics().logEvent("delete_game", {
          data: { room_id },
          bolt_version: getState().app.app_verison,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: "DELETE_ROOM_ERROR", error });
        toast.error("Error");
      });
  };
};

// Other
export const audio_control = (type, audio) => {
  return (dispatch, getState) => {
    const sound = audio.sound;
    const id = audio.id;

    if (type === "play") {
      sound.play();
    }

    if (type === "pause") {
      sound.pause();
    }

    if (type === "stop") {
      sound.stop();
    }

    dispatch({ type: "AUDIO_CONTROL" });
  };
};
export const finishGameDataUpload = (data, doc_id) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const account_id = getState().firebase.profile.account_id;

    //Loading
    dispatch({ type: "COMPLETE_DATA_UPLOAD", status: "loading" });

    console.log("Finished game data", data);

    db.collection("user_accounts")
      .doc(account_id)
      .collection("gm_reports")
      .add(data)
      .then(() => {
        //Success
        setTimeout(() => {
          dispatch({ type: "COMPLETE_DATA_UPLOAD", status: "success" });
        }, 1000);

        console.log("Game Data Saved to DB");
      })
      .catch((error) => {
        //Error
        dispatch({ type: "COMPLETE_DATA_UPLOAD", status: "error" });

        //dispatch({ type: "COMPLETE_DATA_UPLOAD_ERROR", error });
        console.log("Game Data Save Error", error);
      });
  };
};

// Onboarding
export const update_onboarding = (account_id, boolean) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    db.collection("user_accounts")
      .doc(account_id)
      .update({
        "onboarding.gm": boolean,
      })
      .then(() => {
        // console.log('successfull puzzle reorder')
      })
      .catch((error) => {
        console.log(error);
        //dispatch({ type: "ADD_NEW_PUZZLE_ERROR", error });
      });
  };
};
