import { get, set, createStore, del, update } from "idb-keyval";

let game_id;
export function gameId(val) {
  game_id = val;
}

export function blobToBlob2(blob, name, callback, setState, state, unique_id) {
  var reader = new FileReader();
  reader.readAsArrayBuffer(blob);
  reader.onprogress = function (e) {
    const loaded = e.loaded;
    const total = e.total;

    const progress = ((loaded / total) * 100).toFixed(0) + "%";

    setState({
      ...state,
      name: "Importing: " + progress,
    });
  };
  reader.onerror = function (err) {
    console.log("ERROR", err);
  };
  reader.onload = function (e) {
    callback(game_id, setState, {
      buffer: e.target.result,
      type: blob.type,
      name: blob.name,
      asset: name,
      id: unique_id
    });
  };
}

export function saveBlob(game_id, setState, blob) {
  console.log("blob", blob);

  const key = blob.id ? blob.asset + "-" + blob.id : blob.asset

  // Add to IndexedDB
  const assetsStore = createStore(game_id, "assets");
  set(blob.asset, blob, assetsStore);

  // Set Timer Preview BG
  if (setState) {
    const newBlob = readBlob(blob);
    var url = URL.createObjectURL(newBlob);
    setState({ ...blob, url });
  }

  // if (blob.asset === 'background_image'){
  //   setState({
  //     ...blob,
  //     url
  //   })
  // }

  // if (blob.asset === 'video_brief'){
  //     setState({
  //     ...blob,
  //     url
  //   })
  // }
}

export function readBlob(blob) {
  return new Blob([blob.buffer], { type: blob.type, name: blob.name });
}

export function addToDB(file, name, setState, state, unique_id) {
  if (!file) {
    console.log("no file found");
    return;
  }
  if (file) {
    console.log("File", file);
    if (file.size > 1073741824) {
      setState({ ...state, error: "File too large." });
    } else {
      blobToBlob2(file, name, saveBlob, setState, state, unique_id);
    }
  }
}

export function downloadFileToBlob(file, type, game_id, unique_id) {
  var request = new XMLHttpRequest();
  request.open("GET", file.url, true);
  request.responseType = "blob";
  request.onload = function () {
    var reader = new FileReader();
    reader.readAsArrayBuffer(request.response);
    reader.onprogress = function (e) {
      const loaded = e.loaded;
      const total = e.total;

      const progress = ((loaded / total) * 100).toFixed(0) + "%";
      console.log("progress", progress);
    };
    reader.onerror = function (err) {
      console.log("ERROR", err);
    };
    reader.onload = function (e) {
      saveBlob(game_id, null, {
        buffer: e.target.result,
        type: file.type,
        name: file.name,
        asset: type,
        id: unique_id
      });
    };
  };
  request.send();
}

export function deleteAsset(type, file, setState) {
  const assetsStore = createStore(game_id, "assets");

  const name = file.id ? type + "-" + file.id : type;
  del(name, assetsStore);
  setState("");

  // if (name === 'background_image') setState('')
  // if (name === 'video_brief') setState('')
}

export function deleteFromDB(name) {
  const assetsStore = createStore(game_id, "assets");
  del(name, assetsStore);
}
