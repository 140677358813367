import React, {useState, useEffect, useRef, ReactElement, CSSProperties} from 'react'
import FadeIn from 'react-fade-in'
// const FadeIn = require('react-fade-in')

interface Props {
    settings: any,
    gameComplete: boolean,
    gameFail: boolean,
    gameSuccess: boolean,
    time: any,
    videoIsPlaying: boolean,
    background_image: any,
    background_image_victory: any,
    background_image_fail: any
}


function VictoryScreen({settings, gameComplete, gameFail, gameSuccess, time, videoIsPlaying, background_image, background_image_fail, background_image_victory}: Props): ReactElement {


    const defaultFontFamily: string = 'Roboto';
   
   const bgVideo: any = useRef();
   const [videoKey, setVideoKey] = useState('victoryVideo')
   const [victoryVideoPlaying, setVictoryVideoPlaying] = useState(false)
   const [failVideoPlaying, setFailVideoPlaying] = useState(false)

   useEffect(() => {
    if (gameSuccess && background_image_victory && background_image_victory.type.startsWith('video/')) {
        console.log('victory video play')
        setFailVideoPlaying(false)
        setVictoryVideoPlaying(true)
        setVideoKey('victoryVideo')
        setTimeout(() => {
          bgVideo.current.play()
          bgVideo.current.onended = function(e: any) { 
            setVictoryVideoPlaying(false)
            bgVideo.current.pause();
            bgVideo.current.currentTime = 0
          }
        }, 500);
    }

    if (gameFail && background_image_fail && background_image_fail.type.startsWith('video/')) {
        console.log('fail video play')
        setVictoryVideoPlaying(false)
        setFailVideoPlaying(true)
        setVideoKey('failVideo')
        setTimeout(() => {
          bgVideo.current.play()
          bgVideo.current.onended = function(e: any) { 
            setFailVideoPlaying(false)
            bgVideo.current.pause();
            bgVideo.current.currentTime = 0
          }
        }, 500);
        
    }

    if (!gameComplete){
      setFailVideoPlaying(false)
      setVictoryVideoPlaying(false)
      bgVideo.current.pause()
      bgVideo.current.currentTime = 0;
    }
}, [gameComplete])

console.log('SETTINGS', settings)


  const victoryTextStyle = () => {

    // interface CustomStyles {
    //     fontSize: string,
    //     color: string,
    //     display: string,
    //     lineHeight: string,
    //     fontFamily: string,
    //     textAlign: string
    // }

        var style: CSSProperties = {
            fontSize: settings.victory && settings.victory.size ? String(settings.victory.size) + 'vh' : '22vh',
            color: settings.victory && settings.victory.color ? settings.victory.color : "#fafafa",
            display: settings.victory && settings.victory.show === false ? 'none' : 'block',
            lineHeight: settings.victory && settings.victory.size ? String(settings.victory.size) + 'vh' : '22vh',
            fontFamily: settings.victory && settings.victory.font ? settings.victory.font : defaultFontFamily,
            textAlign: 'center'
        }

        console.log('VICTORY STYLE', style)

        return {
          ...style
        };
  }

  const endScreenText = () => {
    const { victory, fail } = settings;

    const victory_text: string = victory && victory.text ? victory.text : null;
    const fail_text: string = fail && fail.text ? fail.text : null;

    if (gameSuccess && !victory_text) return 'Success!'
    if (gameFail && !fail_text) return 'Game Over!'
    if (gameFail && fail_text) return fail_text
    if (gameSuccess && victory_text) return victory_text

  }

  const failTextStyle = () => {

    interface CustomStyles {
        fontSize: string,
        color: string,
        display: string,
        lineHeight: string,
        fontFamily: string,
        textAlign: string
    }

    var style: CSSProperties = {
      fontSize: settings.fail && settings.fail.size ? String(settings.fail.size) + 'vh' : '22vh',
      color: settings.fail && settings.fail.color ? settings.fail.color : "#fafafa",
      display: settings.fail && settings.fail.show === false ? 'none' : 'block',
      lineHeight: settings.fail && settings.fail.size  ? String(settings.fail.size) + 'vh' : '22vh',
      fontFamily: settings.fail && settings.fail.font ? settings.fail.font : defaultFontFamily,
      textAlign: 'center'
    }
    return {
      ...style
    }
  }

  const timeRemainingTextStyle = () => {
    if (gameSuccess){

      var style: CSSProperties = {
        fontSize: settings.victory && settings.victory.size_timeRemaining ? settings.victory.size_timeRemaining + 'vh' : '12vh', 
        color: settings.victory && settings.victory.color ? settings.victory.color : "#fafafa",
        textAlign:'center',
        display: settings.victory && settings.victory.show_timeRemaining === false ? 'none' : 'block',
        fontFamily: settings.victory && settings.victory.font ? settings.victory.font : defaultFontFamily,
        fontWeight: "bold",
      }
      return {
        ...style,
      }
    } else {

      var style: CSSProperties = {
        fontSize: settings.fail && settings.fail.size_timeRemaining ? settings.fail.size_timeRemaining + 'vh' : '12vh', 
        color: settings.fail && settings.fail.color ? settings.fail.color : "#fafafa",
        textAlign:'center',
        display: settings.fail && settings.fail.show_timeRemaining === false ? 'none' : 'block',
        fontFamily: settings.fail && settings.fail.font ? settings.fail.font : defaultFontFamily,
        fontWeight: "bold",
      }
      return {
        ...style,
      }
    }
  }

  const bgStyle = () => {

    if (gameSuccess){
      if (background_image_victory && background_image_victory.type.startsWith('image/')) {
        return {
          background: 'url(' + background_image_victory.url + ')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }
      } else if (background_image_victory && background_image_victory.type.startsWith('video/')) {
        return {
          background: '#000'
        }
      } else {
        return {
          background: settings.victory && settings.victory.bg_color ? settings.victory.bg_color : '#000'
        }
      }
    }

    if (gameFail){
      if (gameFail && background_image_fail && background_image_fail.type.startsWith('image/')) {
        return {
          background: 'url(' + background_image_fail.url + ')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }
      } else if (background_image_fail && background_image_fail.type.startsWith('video/')) {
        return {
          background: '#000'
        }
      } else {
        return {
          background: settings.fail && settings.fail.bg_color ? settings.fail.bg_color : '#000'
        }
      }
    }

      


  }



    return (
        <div id='victory-layer' 
          className="container pd-0 fade-in" 
          style={{
            ...bgStyle(),
            //background: gameSuccess && !videoIsPlaying ? 'green' : null, 
            maxWidth: "100%",
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            display: gameComplete && !videoIsPlaying ? 'flex' : 'none',
            position:'fixed'
            }}>



          <video width='100%' className='fade-in' style={{ 
                    objectFit: 'cover',
                    position:'fixed',
                    minWidth:'100%',
                    minHeight:'100%',
                    display: !gameComplete ? 'none' : 'block'
                }}
                    preload="auto"
                    key={videoKey}
                    ref={bgVideo}
                >

            {victoryVideoPlaying && background_image_victory && background_image_victory.type.startsWith('video/') && (
                <source 
                    key="victory-video"
                    src={background_image_victory ? background_image_victory.url : null} 
                    type={background_image_victory ? background_image_victory.type : null}
                    />
            )}  

          {failVideoPlaying && background_image_fail && background_image_fail.type.startsWith('video/') && (
                <source 
                    key="fail-video"
                    src={background_image_fail ? background_image_fail.url : null} 
                    type={background_image_fail ? background_image_fail.type : null}
                    />
            )}  

      </video>



        <div id="successBox"className="row" style={{marginRight:'0px', marginLeft:'0px',marginBottom:'5vh'}} >
            <div className="col-sm-12" style={{ marginTop: "20px", marginBottom: "10px !important" }}>


                
                  {gameComplete && (
                    <React.Fragment>
                      <FadeIn>
                        {gameSuccess ? (
                          <h1 style={{...victoryTextStyle()}}>
                            {endScreenText()}
                          </h1>
                        ) : (
                          <h1 style={{...failTextStyle()}}>
                            {endScreenText()}
                          </h1>
                        )}

                        {time && (
                          <h1
                           style={timeRemainingTextStyle()}>
                            {time.m}:{time.s}
                        </h1>

                        )}
                      </FadeIn>
                    </React.Fragment>
                  )}



              </div>
            </div>
          </div>
    )
}

export default VictoryScreen
