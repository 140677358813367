import React, { useState, useEffect, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  loadStateFromLocalStorage,
  loadAudioState,
} from "../../../../../localStorage";

import { Howl } from "howler";

import { classNames } from "../../../../../utils/Utils";

// type Props = {
//   clueObj: any;
//   loadedClue: any;
//   handleLoadClueClick: (e: any) => void;
//   selectedPuzzleObj: any;
//   isSent: boolean;
//   userPermissions: {
//     isAdmin: boolean;
//     canEditPuzzles: boolean;
//     canEditHints: boolean;
//   };
// };

function secondsToTime(secs) {
  // let hours = Math.floor(secs / (60 * 60));
  let divisor_for_minutes = Math.floor(secs % (120 * 60));

  let minutes = Math.floor(divisor_for_minutes / 60);
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);
  //let seconds = Math.floor(divisor_for_seconds);
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  if (seconds < 1) {
    seconds = "0" + 1;
  }

  let obj = {
    m: minutes,
    s: seconds,
  };
  return obj;
}

export default function SoundItem(props) {
  const {
    clueObj,
    loadedClue,
    handleLoadClueClick,
    selectedPuzzleObj,
    isSent,
    userPermissions,
    sounds_updated,
    sounds,
    gameMaster,
  } = props;
  const [showControls, setShowControls] = useState(true);

  const selectedPuzzleID = selectedPuzzleObj ? selectedPuzzleObj.puzzle_id : 0;

  const clueObject = {
    ...clueObj,
  };

  // Sound States
  const [soundPlaying, setSoundPlaying] = useState(false);
  const [soundDuration, setSoundDuration] = useState(0);
  const [playbackWidth, setPlaybackWidth] = useState(0);
  const [soundPosition, setSoundPosition] = useState(0);
  const [audioId, setAudioId] = useState(0);
  const [sound, setSound] = useState(null);

  useEffect(() => {
    const format = (type) => {
      if (type === "audio/wav") return "wav";
      if (type === "audio/mp3") return "mp3";
      if (type === "audio/mpeg") return "mpeg";
    };

    const s = new Howl({
      src: [clueObj.file.url],
      autoplay: false,
      preload: true,
      html5: true,
      volume: clueObj.volume > 1 ? clueObj.volume / 100 : clueObj.volume,
      loop: clueObj.loop || false,
      format: [format(clueObj.file.type)],
      onload: function () {
        //console.log(clueObj.title + " is Loaded!");
        setSoundDuration(secondsToTime(s.duration()));
      },
      onplay: function (id) {
        setAudioId(id);
        setSoundPlaying(true);
      },
      onpause: function (id) {
        setSoundPlaying(false);
      },
      onend: function () {
        setSoundPlaying(false);
      },
      onstop: function () {
        setSoundPlaying(false);
      },
      onloaderror: function (id, error) {
        console.log(`a load error has occured on id ${id}`);
        console.error(error);
      },
      onplayerror: function (id, error) {
        console.log(`a play error has occured on id ${id}`);
        console.error(error);
      },
    });

    setSound(s);
  }, [sounds_updated]);

  const playSound = () => {
    const audioState = loadAudioState();
    const soundtrack = gameMaster.assets.audio_soundtrack;
    const soundtrackState = audioState["audio_soundtrack"];
    const fadeToLevel = soundtrackState * 0.1;
    var setWidth;

    // Set a play callback for the howl
    // sound.once("play", () => {
    //   console.log("PLAY!");
    //   setWidth = setInterval(() => {
    //     const width = (sound.seek() / sound.duration()) * 100;
    //     console.log("WIDTH", width);
    //     setPlaybackWidth(width);
    //   }, 50);
    // });



    if (!clueObj.duck_soundtrack) {
      sound.play();
    } else {
      // Fade Down Soundtrack
      if (soundtrack) soundtrack.sound.fade(soundtrackState, fadeToLevel, 500);

      // Play Alert Sound
      sound.play();

      sound.once("end", function () {
        console.log('SOUND END')
        if (soundtrack) soundtrack.sound.fade(fadeToLevel, soundtrackState, 5000);
        // setPlaybackWidth(0);
        // clearInterval(setWidth);
        // sound.off("play");
      });
  
      sound.once("stop", function () {
        console.log('SOUND STOP')
        if (soundtrack) soundtrack.sound.fade(fadeToLevel, soundtrackState, 5000);
        // setPlaybackWidth(0);
        // clearInterval(setWidth);
        // sound.off("play");
      });
    }
  };

  //console.log('sounditem', sound)
  //console.log("play status", soundPlaying);
  //console.log('dur', soundDuration)

  return (
    <button
      className={classNames(
        "clue-item-dark group flex justify-between border group relative overflow-hidden",
        clueObj.puzzle_id !== selectedPuzzleID ? "hidden" : null,
        soundPlaying
          ? "bg-green-900/50"
          : "hover:bg-v1_dark-hover bg-[#2f3036]",
        isSent && loadedClue.id !== clueObj.id
          ? "bg-opacity-20"
          : "bg-opacity-100"
      )}
    >
      <div className="w-full">
        <div
          className="bg-green-900 absolute border-r-2 border-green-800"
          style={{
            width: playbackWidth + "%",
            height: "51px",
            marginTop: "-7px",
            marginLeft: "-16px",
          }}
          role="progressbar"
          aria-valuenow="25"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <div className="w-[90%] flex items-start space-x-2">
          {/* {isSent && (
          <div className="flex items-center space-x-2 mr-2 mt-[3px] text-green-500">
            <i className="fa-solid fa-send w-[12px]"></i>
          </div>
        )} */}

          <div>
            <button
              onClick={() => playSound()}
              className={classNames(
                "w-[35px] h-[35px] rounded-full bg-green-800 flex items-center justify-center hover:bg-green-700 focus:outline-none",
                soundPlaying ? "hidden" : null
              )}
            >
              <i className="fa-solid fa-play text-gray-100"></i>
            </button>

            <button
              onClick={() => sound && sound.stop(audioId)}
              className={classNames(
                "w-[35px] h-[35px] rounded-full bg-green-600 flex items-center justify-center hover:bg-green-600 focus:outline-none",
                !soundPlaying ? "hidden" : null
              )}
            >
              <i className="fa-solid fa-pause text-gray-100"></i>
            </button>
          </div>

          <div className="w-[80%]">
            <p
              className={classNames(
                "mb-0 overflow-hidden whitespace-nowrap text-ellipsis",
                loadedClue.id !== clueObj.id && isSent
                  ? "text-gray-400 italic"
                  : "text-gray-400",
                !isSent ? "font-semibold text-white" : "italic"
              )}
            >
              {clueObj.title}
            </p>
            <div className="flex items-center space-x-3">
              <p className="text-xs text-gray-500">
                {soundDuration && soundDuration.m}:
                {soundDuration && soundDuration.s}
              </p>

              {clueObj.volume && (
                <div className="flex items-center space-x-1">
                  <svg
                    className="h-3 w-3 fill-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path d="M32 160V352H160L304 480h48V32H304L160 160H32zM441.6 332.8C464.9 315.3 480 287.4 480 256s-15.1-59.3-38.4-76.8l-28.8 38.4c11.7 8.8 19.2 22.7 19.2 38.4s-7.5 29.6-19.2 38.4l28.8 38.4zm57.6 76.8c46.6-35 76.8-90.8 76.8-153.6s-30.2-118.6-76.8-153.6l-28.8 38.4c35 26.3 57.6 68.1 57.6 115.2s-22.6 88.9-57.6 115.2l28.8 38.4z" />
                  </svg>
                  <p className="text-xs text-gray-500">{clueObj.volume}</p>
                </div>
              )}

              {clueObj.loop && (
                <svg
                  className="h-3 w-3 fill-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path d="M0 254.5c-.8 13.2 9.2 24.6 22.5 25.5s24.6-9.2 25.5-22.5l.5-8C51.9 194.7 97.3 152 152.2 152H366.1l-55 55c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l96-96c9.4-9.4 9.4-24.6 0-33.9L345 15c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l55 55H152.2C72 104 5.6 166.4 .5 246.5l-.5 8zm511.9 3c.8-13.2-9.2-24.6-22.5-25.5s-24.6 9.2-25.4 22.5l-.5 8c-3.4 54.8-48.9 97.5-103.8 97.5H145.9l55-55c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L71 367c-9.4 9.4-9.4 24.6 0 33.9l96 96c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-55-55H359.8c80.3 0 146.7-62.4 151.7-142.5l.5-8z" />
                </svg>
              )}

              {clueObj.duck_soundtrack && (
                <svg
                  className="h-3 w-3 fill-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path d="M128 64c0-17.7 14.3-32 32-32H320c17.7 0 32 14.3 32 32V416h96V256c0-17.7 14.3-32 32-32H608c17.7 0 32 14.3 32 32s-14.3 32-32 32H512V448c0 17.7-14.3 32-32 32H320c-17.7 0-32-14.3-32-32V96H192V256c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h96V64z" />
                </svg>
              )}

              {/* <p className="text-xs text-gray-400">{clueObj.file.name}</p> */}
            </div>
          </div>
        </div>
      </div>

      <Tooltip title="Edit" placement="right">
        <a
          href="#editDeleteSoundModal"
          onClick={() => handleLoadClueClick({ ...clueObj, audio_id: audioId })}
          data-toggle="modal"
        >
          <i className="icon ion-ios-more font-[18px] hidden group group-hover:inline absolute right-[10px]"></i>
        </a>
      </Tooltip>
    </button>
  );
}
