import { loadStripe } from "@stripe/stripe-js";

// Check is User is Subscribed
export const isSubscribed = (account) => {
  const plan_type = account ? account.subscription.product_id : null;

  if (plan_type === "prod_JDMsnc2gCLWWkA" || plan_type === undefined)
    return false;
  if (plan_type === "prod_JDMvOig4rtxg0U") return true;
  if (plan_type === "prod_JDMw9iECHFJwCs") return true;
};

export const handleCheckoutClick = async (customer_id, price_id, subscriptionStatus) => {
  const stripePromise = loadStripe(
    "pk_live_51IalDdGI8KW4Qd3J28lgIKcaZ3LzTul98KOFNm2DJZZsrRPT9AyIf8mohCscZWAgGUQRfDs4ywwNQtM0lUcXmVbS00is33DZeV"
  );
  console.log("Handle Checkout", customer_id, price_id);

  const stripe = await stripePromise;

  return fetch(
    "https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/stripe_CheckoutSession",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        line_items: [
          {
            price: price_id,
            quantity: 1,
          },
        ],
        customer_id: customer_id,
        subscriptionStatus: subscriptionStatus
      }),
    }
  )
    .then(function (result) {
      return result.json();
    })
    .then((data) => {
      stripe
        .redirectToCheckout({
          sessionId: data.sessionId,
        })
        .then((result) => console.log("success", result));
    });
};

export const checkBrowswer = () => {
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    return "Opera";
  } else if (navigator.userAgent.indexOf("Edg") !== -1) {
    return "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    return "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    return "IE";
  } else {
    return "unknown";
  }
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
