import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

type Props = {
  handleAddMessage: any;
  handleLoadClue: any;
  handleAddMessageKeyDown: any;
  loadedClue: { message: string; type: string, puzzle: string };
  hintsUsed: number;
  addedMinutes: number;
  togglePlayAudioClue: any;
  handleAddAudioClue: any;
  handleLoadPreviousClue: any;
  previousMessage: any;
  handleClearLoadedClue: any;
};

const MessageInput = (props: Props) => {
  const {
    handleAddMessage,
    handleLoadClue,
    handleAddAudioClue,
    handleAddMessageKeyDown,
    handleLoadPreviousClue,
    previousMessage,
    loadedClue,
    hintsUsed,
    //avgCluePerMinute,
    addedMinutes,
    togglePlayAudioClue,
    handleClearLoadedClue
  } = props;


  const textArea = useRef<HTMLTextAreaElement>(null);
  const [loaded, setLoaded] = useState(loadedClue);
  const [isMac, setIsMac] = useState();
  const [focusTextArea, setFocusTextArea] = useState(false);

  useEffect(() => {
    // Clear text box when game resets
    if (!loadedClue) {
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }
    setLoaded(loadedClue);
  }, [loadedClue]);



  const handleMessage = (type: string) => {
    if (type === "audio") {
      handleAddAudioClue(loadedClue);
    }

    if (!type || type === "text") {
      handleAddMessage(loaded);
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }
  };

  const joinMessage = (type: string) => {
    if (!type || type === "text") {
      // handleAddMessage(loadedClue, 'join'); OLD
      handleAddMessage(loaded, 'join'); // NEW
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }
  }

  const handleKeyDown = (e : any) => {
    if (e.keyCode === 13 && e.keyCode <= 90 && !e.shiftKey) {
      e.preventDefault();
      //handleAddMessage(loadedClue); OLD
      handleAddMessage(loaded); // NEW
      if (null !== textArea.current) {
        textArea.current.value = "";
      }
    }

    // if (loaded && loaded.puzzle && !loaded.message.trim().length){
    //   console.log('EMPTY')
    //   handleClearLoadedClue()
    // }
  };

  const onInputChange = (e: any) => {
    if (e.target.value){
      setLoaded({...loadedClue, message: e.target.value})
    } else {
      handleClearLoadedClue()
    }
  }

  const EnterButton = (disabled: boolean) => {
    if (!disabled) {
      return (
        <button
          type="button"
          disabled
          className="button-blue-dark disabled:opacity-50"
          onClick={() => handleAddMessage(loaded)}
        >
          <i
            className="icon ion-md-send"
            style={{ color: "white", marginRight: "10px" }}
          ></i>
          Send
        </button>
      );
    }

    if (loadedClue.type && loadedClue.type === "audio") {
      return (
        <button
          type="button"
          className="btn btn-xs btn-purple"
          onClick={() => handleMessage(loadedClue.type)}
        >
          <i
            className="icon ion-md-play"
            style={{ color: "white", marginRight: "0px" }}
          ></i>
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="button-blue-dark"
          onClick={() => handleMessage('text')}
        >
          <i
            className="icon ion-md-send"
            style={{ color: "white", marginRight: "10px" }}
          ></i>
          Send
        </button>
      );
    }
  };



  return (
    <div>
    <div id="messageBox" style={{position:'absolute', bottom:'0px', right:'20px', left:'20px'}}>

{loaded.puzzle ? (
        <div
          style={{
            opacity: "0.5",
            paddingLeft:'5px',
            paddingBottom:'5px'
          }}
        >
          <small style={{ marginRight: "20px" }}>
            <b>Puzzle:</b> {loadedClue.puzzle}
          </small>
        </div>
      ) : null}



      <div 
        className="controlsBox card" 
        style={{
          background:'#27282e', 
          // border: '1px solid #46484b'
          border: loadedClue.message || focusTextArea
                  ? "2px solid rgb(1, 104, 250)"
                  : "1px solid #46484b",
          transition: '0.10s',
          display:'flex',

        }}>



        <div className="pd-10" style={{ paddingTop: "5px" }}>
          {loadedClue.type !== "audio" ? (
            <textarea
              key="MessageInput"
              ref={textArea}
              className="form-control align-self-center bd-0 hint-message-input"
              placeholder="Type a message here..."
              style={{
                resize: "none",
                maxHeight: "80px",
                minHeight:'40px',
                height:'100%',
                fontSize: "15px",
                fontWeight: "400",
                border: 'none',
                color:'#c9cece',
                // border: loadedClue.message
                //   ? "2px solid rgb(1, 104, 250)"
                //   : "1px solid black",
                transition: "0.2s",
              }}
              value={loaded.message}
              onChange={(e) => onInputChange(e)}
              onKeyDown={(e) => handleKeyDown(e)}
              onFocus={()=> setFocusTextArea(true)}
              onBlur={()=> setFocusTextArea(false)}
              
            ></textarea>
          ) : (
            <div
              style={{
                display: "flex",
                padding: "10px",
                background: "#27282e",
                borderRadius: "8px",
                border: "2px solid rgb(1, 104, 250)",
              }}
            >
              <i
                className="icon ion-md-volume-high"
                style={{ color: "white", marginRight: "10px" }}
              ></i>
              <div>
                <h5 style={{ margin: "0" }}>{loadedClue.message}</h5>
                <small className="text-muted">clue_hint.mp3 - 00:05</small>
              </div>
            </div>
          )}

          <div className="row group" style={{ padding: "0px 15px" }}>
            <div
              className="col-6"
              style={{ fontSize: "20px", paddingLeft: "5px" }}
            ></div>

            <div
              className="col-12"
              style={{ textAlign: "right", paddingRight: "0px", paddingLeft:'5px' }}
            >


              <div style={{float:'left'}}>
              {/* <button className="bolt-icon-button">
                  <i className="fa-solid fa-face-awesome"></i>
                </button> */}


                {/* <button className="bolt-icon-button">
                  <i className="fa-solid fa-message-arrow-down"></i>
                </button> */}


                <Tooltip title="Join Messages" placement="top" arrow>
                  <button 
                    onClick={previousMessage.message && loaded.message ? () => joinMessage('text') : ()=>null} 
                    style={previousMessage.message && loaded.message ? { opacity: '1'} : { opacity: '0.5'}}
                    className="bolt-icon-button">
                    <i className="fa-solid fa-message-arrow-up-right"></i>
                  </button>
                </Tooltip>

                <Tooltip title="Load Previous Message" placement="top" arrow disableFocusListener disableTouchListener>
                  <button 
                  style={previousMessage.message ? { opacity: '1'} : { opacity: '0.5'}}
                  onClick={()=> handleLoadPreviousClue(previousMessage)} className="bolt-icon-button">
                    <i className="fa-solid fa-rotate-left"></i>
                  </button>
                </Tooltip>
                

              </div>


              {/* <button
                onClick={() => togglePlayAudioClue("alert-button")}
                type="button"
                className="button-outline-dark mr-2"
              >
                Nudge
              
              </button> */}


              <button
                onClick={() => togglePlayAudioClue("alert-button")}
                type="button"
                className="button-outline-dark mr-2"
              >
                <i className="fa-solid fa-bell mr-2"></i>
                Alert
              
              </button>

              {loaded.message ? EnterButton(true) : EnterButton(false)}
            </div>
            
          </div>
        </div>
      </div>

      {loadedClue.message ? (
        <div
          style={{
            position: "absolute",
            bottom: "0",
            right: "10px",
            opacity: "0.5",
          }}
        >
          <small style={{ marginRight: "20px" }}>
            <b>Return</b> to send
          </small>
          <small>
            <b>Shift + Return</b> to add a new line
          </small>
        </div>
      ) : null}
    </div>
    </div>
  );
};

export default MessageInput;
