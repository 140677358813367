import React from "react";

import Hints from './Hints'

function TimerPreview(props) {

    const { backgroundColor, backgroundImage, settings, game_data, textColor, upload_progress, customIcon } = props;

  return (
    <div
      id="lw-bgimage"
      style={{
        width: "570px",
        height: "320px",
        display: "flex",
        justifyContent:'center',
        alignItems: "center",
        backgroundColor: backgroundImage && backgroundImage.type && backgroundImage.type.startsWith("video/") ? null : backgroundColor,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage:
          backgroundImage &&
          backgroundImage.type &&
          backgroundImage.type.startsWith("image/")
            ? "url(" + backgroundImage.url + ")"
            : null,
        borderRadius: "8px",
        margin: "0 auto",
        position:'relative'
      }}
    >
      {backgroundImage &&
      backgroundImage.type &&
      backgroundImage.type.startsWith("video/") ? (
        <video
          style={{
            minWidth: "100%",
            borderRadius: "8px",
            opacity: upload_progress > 0 ? '0.25' : '1'
          }}
          muted
          preload="auto"
          //ref="vidRef"
          id="lw-bg-video"
          autoPlay={true}
          loop={true}
          src={backgroundImage ? backgroundImage.url : null}
          //type="video/mp4"
        ></video>
      ) : null}

      <div
        style={{
          margin: "0 auto",
          textAlign: "center",
          display: settings && settings.timer && settings.timer.show !== true ? "none" : "block",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <p
          style={{
            color: "#fafafa",
            marginBottom: "0",
            fontSize: "11px",
            color: textColor,
            display: settings && settings.timer && settings.timer.show_timeRemaining !== true ? "none" : "block",
          }}
        >
          Time Remaining
        </p>
        <h1
          style={{
            color: textColor,
            fontSize:
              settings && settings.timer
                ? settings.timer.size * 3.4 + "px"
                : "75px",
            marginBottom: "25px",
            fontFamily: settings && settings.timer ? settings.timer.font : null,
          }}
        >
          {game_data.timer.time.m}:{game_data.timer.time.s}
        </h1>
      </div>

      {settings && settings.timer && settings.timer.show_hintIcons && (
        <Hints clueOnScreenCount={[ true, true, true ]} settings={settings} customIcon={customIcon} />
      )}


    </div>
  );
}

export default TimerPreview;
