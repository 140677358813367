import React, { Component } from 'react'
import { connect } from 'react-redux'

//import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'

//import AudioMeter from './AudioElements/AudioMeter'
import AudioTrack from './AudioElements/AudioTrack'
//import AudioUpload from './Components/AudioUpload'

//import { uploadFileToAWS } from '../../../../store/actions/gmActions'


class AudioControls extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoaded_alertTone: false,
            volume_alertTone: 100,
            currentVolume_alertTone: 0,
            alert_tone_isLoading: false
        }
        this.soundtrack_default = new Audio('/assets/audio/expedition_soundtrack.mp3');
    }


    // handleUploadFile = (file, game_name, file_type) => {
    //     this.setState({alert_tone_isLoading: true})
    //     this.props.uploadFileToAWS(file, game_name, file_type)
    // }

    render() {
        

        const alert = () => {
            if (this.props.loaded_assets.alert){
                return (
                <AudioTrack 
                    disabled='false'
                    title={"Alert"} 
                    save_key={'audio_alert'}
                    audio={this.props.gameMaster.assets.audio_alert} 
                    status={this.props.loaded_assets.alert_message}
                />
                )
            } else {
                return (
                    <AudioTrack 
                        disabled='true'
                        title={"Alert"} 
                        save_key={'audio_alert'}
                    />
                    )
            }
        }

        const soundtrack = () => {
            if (this.props.loaded_assets.soundtrack){
                return (
                <AudioTrack 
                    title={"Game Music"} 
                    save_key={'audio_soundtrack'}
                    audio={this.props.gameMaster.assets.audio_soundtrack} 
                    status={this.props.loaded_assets.soundtrack_message}
                />
                )
            } else {
                return (
                    <AudioTrack 
                        disabled='true'
                        title={"Game Music"} 
                        save_key={'audio_soundtrack'}
                    />
                    )
            }
        }

        const success = () => {
            if (this.props.loaded_assets.success){
                return (
                <AudioTrack 
                    title={"Success"} 
                    save_key={'audio_success'}
                    audio={this.props.gameMaster.assets.audio_success} 
                    status={this.props.loaded_assets.success_message}
                />
                )
            } else {
                return (
                    <AudioTrack 
                        disabled='true'
                        title={"Success"} 
                        save_key={'audio_success'}
                    />
                    )
            }
        }

        const fail = () => {
            if (this.props.loaded_assets.fail){
                return (
                <AudioTrack 
                    title={"Fail"} 
                    save_key={'audio_fail'}
                    audio={this.props.gameMaster.assets.audio_fail} 
                    status={this.props.loaded_assets.fail_message}
                />
                )
            } else {
                return (
                    <AudioTrack 
                        disabled='true'
                        title={"Fail"} 
                        save_key={'audio_fail'}

                    />
                    )
            }
        }

        const videoBrief = () => {
            if (this.props.loaded_assets.video_brief){
                return (
                <AudioTrack 
                    title={"Video"} 
                    save_key={'audio_video_brief'}
                    type={'video_brief'}
                    status={'Video Volume'}
                />
                )
            } else {
                return (
                    <AudioTrack 
                        disabled='true'
                        title={"Video"} 
                        save_key={'audio_video_brief'}
                    />
                    )
            }
        }

        const masterVolume = () => {
            return (
                <AudioTrack 
                    title={"Master"} 
                    save_key={'audio_master'}
                    type={'Howler'}
                    status={'Master'}
                />
                )
        }
        
        

        return (
<div style={{width:'550px'}}>

    <div className='px-3 pt-2'>
        <p className='text-white font-medium'>Audio mixer</p>
    </div>

    <div className='shadow ml-2 mt-2 rounded-sm border-l border-b border-v1_dark-hover' style={{display:"flex", flexDirection:"row"}}>
                <React.Fragment>
                        {alert()}
                        {soundtrack()}
                        {success()}
                        {fail()}
                        {videoBrief()}
                        {masterVolume()}        
                </React.Fragment>  

            {/* <div className="pd-20 text-center w-100">
                <button className="btn btn-primary btn-sm">Save Audio Settings</button>
            </div> */}
        </div>

        
<div style={{padding:'10px'}} className="flex items-center">
<i class="fa-solid fa-circle-info text-white mr-2"></i>
    <p className='text-white'>You can upload custom audio in the <a className='text-blue-500 hover:text-blue-600' target="_blank" href={"/settings/game/" + this.props.game_id}>game settings.</a></p>
</div>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        gameMaster: state.gameMaster,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        // uploadFileToAWS: (file, game_name, file_type) => dispatch(uploadFileToAWS(file, game_name, file_type))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AudioControls)
