import React, { useState, useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { active } from 'sortablejs';

import { classNames } from '../../../../utils/Utils';


export const Header = (props) => {

    const { game, profile, account, userPermissions } = props;
    const displayName = () => {
        if (profile.firstName && profile.lastName) return profile.firstName + ' ' + profile.lastName
        else if (account) return account.title
        else return ''
    }

    const [username, setUsername] = useState();
    useEffect(() => {
        if (account) setUsername(account.title || account.company_title || "")
    }, [account])

    const [activeButton, setActiveButton] = useState('puzzles')

    const toggleFullScreen = () => {
        if (
          (document.fullScreenElement && document.fullScreenElement !== null) ||
          (!document.mozFullScreen && !document.webkitIsFullScreen)
        ) {
          if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(
              Element.ALLOW_KEYBOARD_INPUT
            );
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
      };

    return (
        <div className='h-[40px] bg-v1_dark-header border-b border-v1_dark-hover w-full flex items-center py-3 px-2'
        // style={{
        //     height:'40px',
        //     background:'rgb(26, 27, 32)',
        //     borderBottom:'1px solid black',
        //     width:'100%',
        //     display:'flex',
        //     alignItems:'center',
        //     paddingLeft:'10px',
        //     paddingRight:'20px'
        // }}
        >

    <a href='#ExitToDashboardModal' 
        data-toggle='modal'
        style={{
            borderRadius:'50%',
            display:'flex',
            padding:'5px'
        }}
    className='btn btn-outline-light'>
        {/* <img src="/hintspace_icon.svg" width="20px" alt="icon" /> */}
    {/* <ion-icon 
        name="flash"
        style={{color:'rgb(236, 236, 88)', fontSize:'20px'}}
        ></ion-icon>  */}

<i class="fa-solid fa-bolt text-yellow-500 w-4"></i>
    </a>


    <div className='flex ml-4'>
        <div
         style={{
            marginRight:'30px',
            marginBottom: activeButton !== 'puzzles' ? '2px' : '0px',
            paddingTop:'2px',
            padding:'0px 10px',
            height:'40px',
            display:'flex',
            alignItems:'center',
            borderBottom: activeButton === 'puzzles' ? '1px solid white' : null
        }}
        >
            <Link to='?view=puzzles' 
            onClick={ ()=> setActiveButton('puzzles') }
            style={activeButton !== 'puzzles' ? {opacity:'0.5'} : null}
            >
                <h6 className='mg-b-0'><i class="fa-solid fa-rectangles-mixed" style={{marginRight:'8px', opacity:'0.5'}}></i><b>Control Panel</b></h6>
            </Link>
        </div>
        

        <div style={{
            marginRight:'30px',
            marginBottom: activeButton !== 'audio-mixer' ? '2px' : '0px',
            paddingTop:'2px',
            padding:'0px 10px',
            height:'40px',
            display:'flex',
            alignItems:'center',
            borderBottom: activeButton === 'audio-mixer' ? '1px solid white' : null
        }}>
            <Link to='?view=audio-mixer' 
            onClick={()=> setActiveButton('audio-mixer') }
            style={activeButton !== 'audio-mixer' ? {opacity:'0.5'} : null}
            >
                {/* <i className="icon ion-md-options" style={{marginRight:"5px"}}></i> */}
                <h6 className='mg-b-0'><i class="fa-solid fa-speakers" style={{marginRight:'8px', opacity:'0.5'}}></i><b>Audio Mixer</b></h6>
            </Link>
        </div>
        
        {/* <Link to='?view=rules'>
            <h6 className='mg-b-0' style={{marginRight:'30px'}}>Rules</h6>
        </Link> */}

    </div>

    <div style={{position:'absolute', left:'50%'}}>
        
        <h6 className='mg-b-0' style={{fontSize:'14px'}}><i class="fa-solid fa-door-open" style={{marginRight:'5px'}}></i> <b>{game}</b></h6>
    </div>



    <div style={{marginLeft:'auto', display:'flex', flexDirection:'row', alignItems:'center'}}>
        <button onClick={(e) => toggleFullScreen()} className="button-gray-dark text-xs" style={{marginRight:'20px'}}>
        <i class="fa-solid fa-expand" style={{marginRight:'8px'}}></i>
            Fullscreen
        </button>
    <p className="mb-0 text-white mr-2">
            <i class="fa-solid fa-user" style={{marginRight:'8px'}}></i>
                {username}
            </p>
    </div>
                 

        </div>
    )
}

export default Header

