import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

import { classNames } from "../../../../../utils/Utils";

export const ClueItem = (props) => {
  const {
    clueObj,
    loadedClue,
    handleLoadClueClick,
    puzzlesDone,
    selectedPuzzleObj,
    color,
    isSent,
    userPermissions,
    sortClueStatus,
    currentGroup
  } = props;
  const [showControls, setShowControls] = useState();

  const includesId = selectedPuzzleObj ? selectedPuzzleObj.id : 0;

  const style = () => {
    if (isSent) {
      if (loadedClue.id === clueObj.id) {
        return {
          background: "rgb(23, 24, 28)",
          border: "2px solid #0168fa",
          opacity: "0.7",
        };
      } else {
        return {
          border: "2px solid transparent",
          opacity: "0.7",
        };
      }
    } else if (loadedClue.id === clueObj.id) {
      return {
        background: "rgb(23, 24, 28)",
        border: "2px solid #0168fa",
      };
    } else {
      return {
        border: "2px solid transparent",
      };
    }
  };

  const clueObject = {
    ...clueObj,
    color,
  };

  const clueRating = () => {
    if (clueObj.rating) {
      if (clueObj.rating === 1) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
          </div>
        );
      } else if (clueObj.rating === 2) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
          </div>
        );
      } else if (clueObj.rating === 3) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
          </div>
        );
      } else if (clueObj.rating === 4) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-off"
            ></i>
            {/* <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i>
          <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i>
          <i className="icon ion-md-star" style={{color: 'rgb(236, 236, 88)'}}></i> */}
          </div>
        );
      } else if (clueObj.rating === 5) {
        return (
          <div className="clue-rating-box">
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
            <i
              className="icon ion-ios-radio-button-on"
              style={{ color: "rgb(236 236 88)" }}
            ></i>
          </div>
        );
      }
    }
  };



  //Current Group checks
  // if (currentGroup === "open" && isSent) return null
  // if (currentGroup === "complete" && !isSent) return null

  return (
    <button
    className={classNames(
        "clue-item-dark group flex justify-between border group",
        loadedClue.id === clueObj.id ? "bg-gray-700" : "hover:bg-v1_dark-hover bg-[#2f3036]",
        isSent && loadedClue.id !== clueObj.id ? "bg-opacity-20" : "bg-opacity-100"
    )}
      onClick={() => handleLoadClueClick(clueObject)}
      onMouseOver={() =>
        setShowControls(
          userPermissions.isAdmin || userPermissions.canEditHints ? true : false
        )
      }
      onMouseLeave={() => setShowControls(false)}
    //   style={{ ...style() }}
    >


      {/* {isSent ? (
        <Tooltip title="Sent" placement="top">
          <i className="icon ion-md-send tx-16"></i>
        </Tooltip>
      ) : (
        <Tooltip title="Text Hint" placement="top">
          <i
          className="icon ion-ios-text tx-16"
          style={color !== 'transparent' ? { color: color, marginTop:'-2px' } : {marginTop:'-2px'}}
        ></i>

          <i
            className="icon ion-ios-flash tx-16"
            style={{ color: "rgb(114 124 128)", marginTop: "-2px" }}
          ></i>

<i className="fa-solid fa-quote-left" style={{ color: "rgb(114 124 128)", marginTop:'3px'}}></i>

        
        </Tooltip>
      )} */}

      <div className="w-[90%] flex items-start">

      {isSent && (
            <div className="flex items-center space-x-2 mr-2 mt-[3px] text-green-500">
                <i className="fa-solid fa-send w-[12px]"></i>
                {/* <p className="text-xs">{</p> */}
            </div>
        )}
        
        <p
          className={classNames(
            "mb-0 ",
            loadedClue.id !== clueObj.id && isSent ? "text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis italic" : "text-gray-400",
            !isSent ? "font-base text-white" : "italic"
          )}
        >
          "{clueObj.message}"
        </p>

        

        {/* <p className="text-xs text-gray-500">Dragon chest</p> */}

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          {clueObj.rating >= 1 && (
            <small
            className="d-block text-muted"
            style={{ display: "flex", marginRight: "10px" }}
          >
            {clueObj.rating ? clueRating() : null}
          </small>
          )}

          <small className="d-block text-muted" style={{ display: "flex" }}>
            {clueObj.puzzle}
          </small>
        </div> */}

        
      </div>
      <Tooltip title="Edit" placement="right">
            <i
              className="icon ion-ios-more font-[18px] hidden group group-hover:inline"
              // style={{fontSize:'18px', height:'16px', float:'right'}}
              // style={{
              //   fontSize: "18px",
              //   position: "absolute",
              //   right: "20px",
              //   transform: "translate(0px, -5px)",
              // }}
              href="#editDeleteClueModal"
              onClick={() => handleLoadClueClick(clueObj)}
              data-toggle="modal"
            ></i>
          </Tooltip>
    </button>
  );
};
