import React, {useState, useEffect} from "react";
import FadeIn from "react-fade-in";

function Hints(props) {
  const { isInfiniteClues, clueOnScreenCount, settings, customIcon, hideCluesOnScreen } = props;

  const [hideClues, setHideClues] = useState(false)


  useEffect(()=> {
    try {
      const serializedState = localStorage.getItem('BOLT_preferences')

      if (serializedState === null){
          return localStorage.setItem('BOLT_preferences')
      } 

      setHideClues(JSON.parse(serializedState).hideCluesOnScreen)

      console.log(hideClues)

  } catch (err){
      return false;
  }
  }, [clueOnScreenCount])



  const showClues = () => {
    if (!hideClues) {
      return (
        <React.Fragment>
          {clueOnScreenCount
            ? Object.keys(clueOnScreenCount).map((clue, index) => {
                if (clueOnScreenCount[clue] === true) {
                  return (
                    <FadeIn>
                      {/* <i
                            className="icon ion-ios-flash"
                            style={{ color: settings && settings.timer ? settings.timer.hintIconColor : "rgb(228, 246, 28)"  }}
                          ></i> */}
                      <div style={{ width: "11vh", paddingBottom: "20px" }}>
                        {/* <svg 
                            fill={settings && settings.timer ? settings.timer.hintIconColor : "rgb(228, 246, 28)"}
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z"/></svg> */}
                        {customIcon ? (
                          <img style={{ width: "100%" }} src={customIcon.url} />
                        ) : (
                          <svg
                            fill={
                              settings && settings.timer
                                ? settings.timer.hintIconColor
                                : "#67ab67"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80V144C80 64.47 144.5 0 224 0zM224 64C179.8 64 144 99.82 144 144V192H304V144C304 99.82 268.2 64 224 64zM256 320C256 302.3 241.7 288 224 288C206.3 288 192 302.3 192 320V384C192 401.7 206.3 416 224 416C241.7 416 256 401.7 256 384V320z" />
                          </svg>
                        )}
                      </div>
                    </FadeIn>
                  );
                } else {
                  return (
                    <FadeIn>
                      {/* <i
                            className="icon ion-md-close"
                            style={{ color: "rgb(221, 55, 55)" }}
                          ></i> */}
                      <div style={{ width: "11vh", paddingBottom: "20px" }}>
                        {/* <svg 
                            style={{opacity:'0.2'}}
                            fill={settings && settings.timer ? settings.timer.hintIconColor : "rgb(228, 246, 28)"}
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 400c-18 0-32-14-32-32s13.1-32 32-32c17.1 0 32 14 32 32S273.1 400 256 400zM325.1 258L280 286V288c0 13-11 24-24 24S232 301 232 288V272c0-8 4-16 12-21l57-34C308 213 312 206 312 198C312 186 301.1 176 289.1 176h-51.1C225.1 176 216 186 216 198c0 13-11 24-24 24s-24-11-24-24C168 159 199 128 237.1 128h51.1C329 128 360 159 360 198C360 222 347 245 325.1 258z"/></svg> */}

                        {customIcon ? (
                          <img
                            style={{ width: "100%", opacity: "0.2" }}
                            src={customIcon.url}
                          />
                        ) : (
                          <svg
                            style={{ opacity: "0.2" }}
                            fill={
                              settings && settings.timer
                                ? settings.timer.hintIconColor
                                : "#67ab67"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                          >
                            <path d="M224 0C303.5 0 368 64.47 368 144V192H384C419.3 192 448 220.7 448 256V448C448 483.3 419.3 512 384 512H64C28.65 512 0 483.3 0 448V256C0 220.7 28.65 192 64 192H80V144C80 64.47 144.5 0 224 0zM224 64C179.8 64 144 99.82 144 144V192H304V144C304 99.82 268.2 64 224 64zM256 320C256 302.3 241.7 288 224 288C206.3 288 192 302.3 192 320V384C192 401.7 206.3 416 224 416C241.7 416 256 401.7 256 384V320z" />
                          </svg>
                        )}
                      </div>
                    </FadeIn>
                  );
                }
              })
            : null}
        </React.Fragment>
      );
    } else {
      return null
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        bottom: "20px",
        textAlign: "center",
      }}
    >
      <div
        className="w-full flex items-center justify-center"
        style={{ marginBottom: "10px !important" }}
      >
        <FadeIn>
          <div
            id="liveWindowClues"
            className="flex items-center justify-between space-x-4"
            // style={{
            //   padding: "0px 2vw",
            //   fontSize: "11vh",
            //   width: "48vh",
            //   margin: "0 auto",
            //   display: "flex",
            //   flexDirection: "row",
            //   justifyContent: "space-around",
            //   background:'transparent',
            //   borderRadius: "20vw",
            // }}
          >
            {showClues()}
          </div>
        </FadeIn>
      </div>
    </div>
  );
}

export default Hints;
