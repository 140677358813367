import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  signIn,
  signInWithGoogle,
  checkUserExists,
} from "../../../store/actions/authActions";
import { Redirect, Link } from "react-router-dom";
//Toasts
import { Toaster } from "sonner";

import { classNames } from "../../../utils/Utils";
import { quotes } from "./Quotes";


function SignInV3(props) {
  const { authError, auth, account } = props;
  if (auth.uid) return <Redirect to="/" />;
  // if (auth.uid && !account) return <Redirect to="/onboarding" />;
  const [isLoading, setIsLoading] = useState(false)


  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState();
  const [emailExists, setEmailExists] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState()

  useEffect(()=> {
    setSelectedQuote(quotes[Math.floor(Math.random() * quotes.length)])
  }, [])

  const handleLogin = (e) => {
    const data = {
      email: email,
      password: password,
    };
    props.signIn(data);
  };

  const checkEmail = async () => {
    setLoading(true);
    props.checkUserExists(email).then((exists) => {
      setTimeout(() => {
        setEmailExists(exists);
        setLoading(false);
      }, 1000);
    });
  };

  return (
    <React.Fragment>
      <Toaster richColors position="top-center" />
      <div
        className="w-full flex h-screen bg-gray-100
    "
      >
        <div className="w-full lg:max-w-lg px-20 py-12 border border-gray-200  bg-white shadow-sm">
          <div>
            <div
              className="text-center flex items-center justify-center flex-col"
              style={{ paddingBottom: "20px" }}
            >
              <Link to="/">
                <img
                  src="/bolt-logo.svg"
                  alt="Logo"
                  style={{
                    width: "175px",
                    marginTop: "-8px",
                    marginBottom: "30px",
                  }}
                ></img>
              </Link>

              {!emailExists ? (
                <p className="text-2xl font-medium">Sign in to Bolt</p>
              ) : (
                <p className="text-2xl font-medium">Enter your password</p>
              )}
            </div>

            <div className="mb-4">
              <div className={classNames(emailExists ? "hidden" : "mb-3")}>
                <input
                  type="email"
                  id="email"
                  className="auth-input"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                ></input>
              </div>
              <div className={classNames(!emailExists ? "hidden" : "mb-3")}>
                <input
                  type="password"
                  id="password"
                  className="auth-input"
                  placeholder="Your password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                ></input>
              </div>
              <div>
                <button
                  onClick={() => checkEmail()}
                  disabled={!email || loading ? true : false}
                  className={classNames(
                    "auth-button flex justify-center",
                    emailExists ? "hidden" : null
                  )}
                >
                  <svg
                    className={classNames(
                      !loading
                        ? "hidden"
                        : "w-[22px] fill-gray-300  animate-spin"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                  </svg>

                  <span className={classNames(loading ? "hidden" : null)}>
                    Next
                  </span>
                </button>

                <button
                  onClick={() => handleLogin()}
                  disabled={!password ? true : false}
                  className={classNames(
                    "auth-button flex justify-center",
                    !emailExists ? "hidden" : null
                  )}
                >
                  <svg
                    className={classNames(
                      !loading
                        ? "hidden"
                        : "w-[22px] fill-gray-300  animate-spin"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                  </svg>

                  <span className={classNames(loading ? "hidden" : null)}>
                    Sign in
                  </span>
                </button>
              </div>
            </div>

            {!emailExists ? (
              <p className="text-xs text-gray-500 text-center">
                Want to get your company started on Bolt?{" "}
                <a
                  className="text-blue-500 hover:text-blue-600"
                  href="/signup"
                >
                  Create an account.
                </a>
              </p>
            ) : (
              <div className="flex flex-col justify-center">
                <a
                  className="text-blue-500 mb-3 text-xs text-center hover:text-blue-600"
                  href="/reset-password"
                >
                  Forgot password
                </a>

                <button
                  onClick={() => setEmailExists(false)}
                  className="flex items-center bg-white py-1 px-2 rounded hover:bg-gray-200 hover:text-black duration-150 text-gray-500 focus:outline-none"
                >
                  <svg
                    className="w-[12px] fill-gray-300 mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                  </svg>
                  Back
                </button>
              </div>
            )}
          </div>
        </div>


        <div className="w-full justify-center items-center hidden lg:flex">
          {selectedQuote && (
            <div className="max-w-md">
            <p className="text-3xl leading-10">"{selectedQuote.quote}"</p>
            <div className="py-4">
              <p className="text-lg font-medium">{selectedQuote.name}</p>
              <div className="flex items-center space-x-2">
                <p>{selectedQuote.company}</p>
                <p>&#x2022;</p>
                <p>{selectedQuote.location}</p>
              </div>
            </div>
          </div>
          )}
        </div>

      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    authError: state.auth.authError,
    authLoading: state.auth.authLoading,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (creds) => dispatch(signIn(creds)),
    checkUserExists: (email) => dispatch(checkUserExists(email)),
    signInWithGoogle: () => dispatch(signInWithGoogle()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInV3);
