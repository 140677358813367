// Sentry.io
import * as Sentry from "@sentry/react";
import { toast } from 'sonner'

const { v4: uuidv4 } = require('uuid');

export const signIn = (credentials) => {
    return (dispatch, getState, {getFirebase }) => {
        const firebase = getFirebase();

        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then((userCredential) => {
            dispatch({type: 'LOGIN_SUCCESS'})
        }).catch(error => {
            dispatch({ type: 'LOGIN_ERROR', error})
            var errorMessage;
            if (error.code === "auth/invalid-email"){
                errorMessage = "Invalid email address."
            } else if (error.code === "auth/wrong-password"){
                errorMessage = "Password is incorrect."
            } else if (error.code === "auth/user-not-found"){
                errorMessage = "User couldn't be found or does not exist."
            } else if (error.code === "auth/email-already-exists"){
                errorMessage = "Email address is already in use."
            }

            toast.error(errorMessage)
        })
    }
}

export const signInWithGoogle = () => {
    return (dispatch, getState, {getFirebase }) => {
        const firebase = getFirebase();
        var provider = new firebase.auth.GoogleAuthProvider();

        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            // ...
            dispatch({type: 'LOGIN_SUCCESS'})
          }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            dispatch({ type: 'LOGIN_ERROR', error: error.message})
            // ...
          });


        // firebase.auth().signInWithEmailAndPassword(
        //     credentials.email,
        //     credentials.password
        // ).then(() => {
        //     dispatch({type: 'LOGIN_SUCCESS'})
        // }).catch(error => {
        //     dispatch({ type: 'LOGIN_ERROR', error})
        // })
    }
}

export const signOut = () => {
    return (dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            Sentry.configureScope(scope => scope.setUser(null));
            dispatch({ type: 'SIGNOUT_SUCESS'})
        })
    }
} 

export const checkUserExists = (email) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const db = getFirestore()

        return new Promise((resolve, reject) => {
            return db.collection('users').where('email', '==', email).get()
                .then((querySnapshot) => {

                    var exists = false

                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        // console.log(doc.id, " => ", doc.data());
                        exists = true

                    });

                    resolve(exists)

                })
                .catch((error) => {
                    console.log("Error getting documents: ", error);
                });
    })

        }
} 


export const signUp = (newUser, verification_id) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const db = getFirestore();
        const batch = db.batch();
        const account_id = uuidv4();

        dispatch({ type: 'SIGNUP', status:'creating_account' })


        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((data) => {

        // Create Doc in Collection - Users
        const create_user = db.collection('users').doc(data.user.uid)
        batch.set(create_user, {
            account_id: account_id,
            accounts: [account_id],
            created_at: new Date(),
            email: newUser.email,
            emailVerified: false,
            fullName: newUser.fullName,
            name: {first: newUser.name.first, last: newUser.name.last}
        });


        // Create Doc in Account ID
        const create_account = db.collection('user_accounts').doc(account_id)
        batch.set(create_account, {
            admins: [data.user.uid],
            staff: [],
            title: newUser.company,
            company_size: newUser.company_size,
            subscription: {
                plan_picked_at_signup: newUser.plan_type,
                customer_id: '',
            },
            account_email: newUser.email,
            created_at: new Date(),
            permissions: [
                {
                    user_id: data.user.uid,
                    isAdmin: true,
                    role: "Admin",
                    canEditHints: true,
                    canEditPuzzles: true,
                    canEditLiveWindow: true
                }
            ],
            onboarding: { gm: false },
        });

        // Delete Verification Doc
        const verification_doc = db.collection('user_verification').doc(verification_id)
        batch.delete(verification_doc);

        // Commit the batch
        return batch.commit()


        }).then(() => {
            dispatch({ type: 'SIGNUP', status:'success' })
        }).catch(error => {
            dispatch({ type: 'SIGNUP_ERROR', error })
        })
    }
} 

export const signUpV2 = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const db = getFirestore();
        const batch = db.batch();
        const account_id = uuidv4();

        dispatch({ type: 'SIGNUP', status:'creating_account' })


        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((data) => {

        // Create Doc in Collection - Users
        const create_user = db.collection('users').doc(data.user.uid)
        batch.set(create_user, {
            account_id: account_id,
            accounts: [account_id],
            created_at: new Date(),
            email: newUser.email,
            emailVerified: false,
            fullName: null
        });

        // Commit the batch
        return batch.commit()


        }).then(() => {
            dispatch({ type: 'SIGNUP', status:'success' })
        }).catch(error => {
            dispatch({ type: 'SIGNUP_ERROR', error })
        })
    }
} 


export const signUpWithInvite = (newUser, account_id, invite_data) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const db = getFirestore();
        const batch = db.batch();

        dispatch({ type: 'SIGNUP', status:'creating_account' })


        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((data) => {

        // Create Doc in Collection - Users
        const create_user = db.collection('users').doc(data.user.uid)
        batch.set(create_user, {
            account_id: account_id,
            accounts: [account_id],
            created_at: new Date(),
            email: newUser.email,
            emailVerified: true,
            fullName: newUser.fullName
        });


        // Create Doc in Account ID
        const update_account = db.collection('user_accounts').doc(account_id)
        batch.update(update_account, {
            permissions: firebase.firestore.FieldValue.arrayUnion({
                ...invite_data.permissions,
                user_id: data.user.uid
            })
        });


        // Delete Invitation
        const delete_invite = db.collection('user_invites').doc(invite_data.invite_id)
        batch.delete(delete_invite);

        // Commit the batch
        return batch.commit()


        }).then(() => {
            dispatch({ type: 'SIGNUP', status:'success' })
        }).catch(error => {
            dispatch({ type: 'SIGNUP_ERROR', error })
        })
    }
} 


export const signInWithEmailLink = (firstName, lastName, company, email, password) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        //const db = getFirestore();

        // The client SDK will parse the code from the link for you.
        firebase.auth().signInWithEmailLink(email, window.location.href)
        .then(function(result) {
            // const user = result.user;
            // return db.collection('users').doc(user.uid).set({
            //     account: company,
            //     firstName: firstName,
            //     lastName: lastName,
            //     email: email,
            //     emailVerified: user.emailVerified,
            //     created_at: user.metadata.creationTime
            // })

            return;
            
        }).then(() => {
            var currentUser = firebase.auth().currentUser;
            return currentUser.updatePassword(password).then(function() {
                // Update successful.
                console.log("updated password")
            }).catch(function(error) {
                console.log("error updating password", error)
            // An error happened.
            });
        }).then(() => {
            dispatch({ type: 'SIGNUP_SUCCESS' })
        })
        .catch(function(error) {
            dispatch({ type: 'SIGNUP_ERROR', error })
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
} 
