import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../../../store/actions/authActions";

import moment from "moment";

const Header = (props) => {
  const { profile, account, trial_expired } = props;
  const firstNameLetter = profile.firstName
    ? profile.firstName.charAt(0)
    : null;
  const lastNameLetter = profile.lastName ? profile.lastName.charAt(0) : null;

  const account_title = account.company_title || account.title || "";

  const [trial, setTrial] = useState({
    trial_start:
      account.subscription && account.subscription.trial_start
        ? account.subscription.trial_start
        : null,
    trial_end:
      account.subscription && account.subscription.trial_end
        ? account.subscription.trial_end
        : null,
  });

  useEffect(() => {
    if (account) {
      setTrial({
        trial_start:
          account.subscription && account.subscription.trial_start
            ? account.subscription.trial_start
            : null,
        trial_end:
          account.subscription && account.subscription.trial_end
            ? account.subscription.trial_end
            : null,
      });
    }
  }, [account]);

  const isAdminUser = () => {
    const uid = props.auth.uid;
    const user_account = props.account ? props.account[0] : [];
    const isAdminUser = props.account
      ? user_account.admins.includes(uid)
      : false;
    return isAdminUser;
  };

  const isSubscribed = () => {
    const user_account = props.account ? props.account[0] : [];
    const status = user_account ? user_account.subscription.status : null;
    const product_id = user_account
      ? user_account.subscription.product_id
      : null;
    const isBeta = user_account ? user_account.isBeta : null;
    const pro_plan = "prod_IIlkUib4nS3RBH";
    const basic_plan = "prod_IIkAc15oZKrJPG";

    if (isBeta) return "Beta";
    if (status === "active" && product_id === pro_plan) return "Pro";
    if (status === "active" && product_id === basic_plan) return "Basic";
    if (status === "cancelled") return "Canceled";
    if (!status) return "Trial";
    if (!status && trial_expired) return "Trial Expired";
  };

  console.log("trial", trial);
  console.log("account - header", account);

  return (
    <React.Fragment>
      <header className="navbar navbar-header navbar-header-fixed">
        <a href="" id="mainMenuOpen" className="burger-menu">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-menu"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </a>
        <div className="navbar-brand">
          <a href="/">
            <img src="/bolt-logo.svg" alt="Logo" width="100px"></img>
          </a>
        </div>
        <div id="navbarMenu" className="navbar-menu-wrapper">
          {/* <div className="navbar-menu-header">
          <a href="../../index.html" className="df-logo">dash<span>forge</span></a>
          <a id="mainMenuClose" href=""><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></a>
        </div> */}

          <ul className="nav navbar-menu">
          <li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
          <li className="nav-item">
            <NavLink to="/" exact={true} className="nav-link">
               <span>Dashboard</span></NavLink>
            </li>
        
        {/* <li className="nav-item">
            <NavLink to="/reports" className="nav-link">
               <span>Reports</span></NavLink>
        </li>

        <li className="nav-item">
        <NavLink to="/settings/account" className="nav-link">
               <span>Account Settings</span></NavLink>
        </li> */}
        </ul>
        </div>

        <div className="navbar-right" style={{ width: "600px" }}>
          {/* <a id="navbarSearch" href="" className="search-link"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg></a> */}

          {/* <div className="dropdown dropdown-notification">
          <a href="" className="dropdown-link new-indicator" data-toggle="dropdown">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-bell"><path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path></svg>
            <span>2</span>
          </a>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-header">Notifications</div>
            <a href="" className="dropdown-item">
              <div className="media">
                <div className="avatar avatar-sm avatar-online"><img src="../https://via.placeholder.com/350" className="rounded-circle" alt=""></img></div>
                <div className="media-body mg-l-15">
                  <p>Congratulate <strong>Socrates Itumay</strong> for work anniversaries</p>
                  <span>Mar 15 12:32pm</span>
                </div>
              </div>
            </a>
            <a href="" className="dropdown-item">
              <div className="media">
                <div className="avatar avatar-sm avatar-online"><img src="../https://via.placeholder.com/500" className="rounded-circle" alt=""></img></div>
                <div className="media-body mg-l-15">
                  <p><strong>Joyce Chua</strong> just created a new blog post</p>
                  <span>Mar 13 04:16am</span>
                </div>
              </div>
            </a>
            <a href="" className="dropdown-item">
              <div className="media">
                <div className="avatar avatar-sm avatar-online"><img src="../https://via.placeholder.com/600" className="rounded-circle" alt=""></img></div>
                <div className="media-body mg-l-15">
                  <p><strong>Althea Cabardo</strong> just created a new blog post</p>
                  <span>Mar 13 02:56am</span>
                </div>
              </div>
            </a>
            <a href="" className="dropdown-item">
              <div className="media">
                <div className="avatar avatar-sm avatar-online"><img src="../https://via.placeholder.com/500" className="rounded-circle" alt=""></img></div>
                <div className="media-body mg-l-15">
                  <p><strong>Adrian Monino</strong> added new comment on your photo</p>
                  <span>Mar 12 10:40pm</span>
                </div>
              </div>
            </a>
            <div className="dropdown-footer"><a href="">View all Notifications</a></div>
          </div>
        </div> */}

          {/* {isSubscribed() === 'Pro' ? (
  <Link to='/settings/account' className="btn btn-outline-primary">
    <i className="icon ion-ios-flash" style={{ marginRight: "5px" }}></i>
        <b>Pro</b>
  </Link>
) : null}

{isSubscribed() === 'Basic' ? (
  <Link to='/account' className="btn btn-outline-primary">
    <i className="icon ion-ios-flash" style={{ marginRight: "5px" }}></i>
        <b>Basic</b>
  </Link>
) : null}

{isSubscribed() === 'Trial' ? (
  <Link to='/account' className="btn btn-outline-primary">
    <i className="icon ion-ios-flash" style={{ marginRight: "5px" }}></i>
        <b>Trial ends {account[0].trial_ends ? moment(account[0].trial_ends.seconds*1000).fromNow() : null}</b>
  </Link>
) : null}

{isSubscribed() === 'Trial Expired'? (
  <Link to='/account' className="btn btn-outline-primary">
    <i className="icon ion-ios-flash" style={{ marginRight: "5px" }}></i>
        <b>Upgrade</b>
  </Link>
) : null}

{isSubscribed() === 'Beta' ? (
  <Link to='/account' className="btn btn-outline-primary">
    <i className="icon ion-ios-flash" style={{ marginRight: "5px" }}></i>
        <b>Beta</b>
  </Link>
) : null} */}

          <div>
            {trial.trial_end && (
              <p style={{ color: "#0168fa", marginBottom:'0' }}>
                <i
                  className="fa-solid fa-sparkles"
                  style={{ marginRight: "5px" }}
                ></i>{" "}
                <b>Free trial ends on {moment(trial.trial_end * 1000).format("ll")}</b>
              </p>
            )}
          </div>

          <div
            className="dropdown dropdown-profile"
            style={{ marginRight: "10px" }}
          >
            <a
              href=""
              className="dropdown-link"
              data-toggle="dropdown"
              data-display="static"
            >
              {/* <div className="avatar avatar-sm">
              
            </div> */}

              <span style={{ fontSize: "18px", color: "rgb(71 83 98)" }}>
                {/* <ion-icon name="person"></ion-icon> */}
                <i className="fa-solid fa-user-astronaut"></i>
              </span>

              <div style={{ marginLeft: "8px", display: "inline" }}>
                <h6 className="tx-semibold mg-b-0">{account_title}</h6>
                {/* <p className="tx-color-03 tx-12 mg-b-0">{profile.email}</p> */}
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-right" style={{paddingTop:'10px', paddingBottom:'10px'}}>
              {/* <h6 className="tx-semibold mg-b-5">{profile.firstName} {profile.lastName}</h6>
            <p className="tx-color-03 tx-12 mg-b-20">{profile.account}</p> */}
            <p className="tx-color-03 tx-12 mg-b-5" style={{paddingLeft:'10px', paddingRight:'10px'}}>{profile.email}</p>

              <a href="/account/billing" className="dropdown-item">
                {/* <i className="icon ion-ios-cog" style={{fontSize:"18px",marginRight:"10px"}}></i>  */}
                <i className="fa-solid fa-file-invoice"></i>
                <span>Account & Billing</span>
              </a>

              {/* <a href='https://boltgm.launchnotes.io/' target='_blank' className="dropdown-item">
                <span>What's New</span>
            </a> */}

              <a href="/" onClick={props.signOut} className="dropdown-item">
                {/* <i className="icon ion-md-log-out" style={{fontSize:"18px",marginRight:"10px"}}></i>  */}
                <i className="fa-solid fa-right-from-bracket"></i>
                <span>Sign Out</span>
              </a>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    profile: state.firebase.profile,
    account: state.firestore.ordered.user_accounts[0],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
