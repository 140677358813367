import React, { useState } from "react";

const SubsModal = (props) => {
  const { handleCheckoutClick, stripe_id, subscription_status } = props;

  //   const [cardNumber, setCardNumber] = useState("");
  //   const [expiry, setExpiry] = useState("");
  //   const [cvc, setCVC] = useState("");
  //   const [country, setCountry] = useState("");
  //   const [region, setRegion] = useState("");

  const [quantity, setQuantity] = useState(1);
  const [basicLoader, setBasicLoader] = useState(false);
  const [proLoader, setProLoader] = useState(false);
  const [scaleLoader, setScaleLoader] = useState(false);

  const changeQuantity = (quantity) => {
    if (quantity > 0) return setQuantity(quantity);
    else return setQuantity(1);
  };

  const basicPrice = () => {
    if (quantity > 0) return quantity * 9;
    else return 0;
  };

  const choosePlan = (type, stripe_id, price_id) => {
    if (type === "pro") setProLoader(true);
    else if (type === "basic") setBasicLoader(true);
    else if (type === "scale") setScaleLoader(true);
    handleCheckoutClick(stripe_id, price_id, subscription_status);
  };


  return (
    <div
      className="modal effect-scale"
      id="subsModal"
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-body" style={{ marginBottom: "40px" }}>
            <div className="row justify-content-center">
              <div className="pd-20 col-md-6 flex-column">
                {/* <div className="tx-100 lh-1"><i className="icon ion-ios-bicycle"></i></div> */}
                <div style={{ textAlign: "center" }}>
                <p className="text-2xl font-medium">Basic</p>
                  <p className="tx-color-01 mg-b-10">
                    Timer, chat and pre-saved hints for 1 escape room.
                  </p>
                  <p className="text-2xl">
                    <b>$0</b>
                    <span className="tx-18">/mo</span>
                  </p>
                  {/* <p className="tx-18 mg-b-0 mg-t-auto"><b>$9</b><span className="tx-18">/game/mo</span></p> */}
                  <small className="tx-color-03">Includes 1 room</small>
                  <button
                  type="button"
                    data-dismiss="modal"
                    className="w-full button-outline mt-2"
                  >
                    {basicLoader ? (
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    Stay with free
                  </button>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Includes 1 room
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Countdown timer
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Chat & pre-saved hints
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Custom soundtrack & alert tone
                      </h5>
                    </div>
                  </div>

                  {/* <div className="row pd-5">
                    <div className="col-2">
                        <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                        <h5 className="tx-rubik tx-normal tx-16">Live window customization</h5>
                    </div>
                </div> */}
                </div>
              </div>

              <div className="pd-20 col-md-6 flex-column">
                {/* <div className="tx-100 lh-1"><i className="icon ion-ios-bicycle"></i></div> */}
                <div style={{ textAlign: "center" }}>
                  <p className="text-2xl font-medium">Unlimited</p>
                  <p className="tx-color-01 mg-b-10">
                    Best for companies with multiple escape rooms.
                  </p>
                  <p className="text-2xl">
                    <b>$29</b>
                    <span className="tx-18">/mo</span>
                  </p>
                  <small className="text-gray-500">Unlimted rooms</small>
                  <button
                    onClick={() =>
                      choosePlan(
                        "pro",
                        stripe_id,
                        "price_1MTw8pGI8KW4Qd3JkhndyAG8"
                      )
                    }
                    className="button-black w-full mt-2"
                  >
                    {proLoader ? (
                      <span
                        className="spinner-border spinner-border-sm mg-r-10"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : null}
                    {subscription_status === "cancelled" || subscription_status === "canceled" ? 'Upgrade' : 'Free 30-day trial' }
                  </button>
                  <small>No credit card required.</small>
                </div>

                <div style={{ marginTop: "20px" }}>
                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        <b>Everything from Free</b>
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Unlimited rooms
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Player window customization
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Custom clue icons
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Custom briefing video
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        Multiple account users
                      </h5>
                    </div>
                  </div>

                  <div className="row pd-5">
                    <div className="col-2">
                      <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                      <h5 className="tx-rubik tx-normal tx-16">
                        User-based permissions
                      </h5>
                    </div>
                  </div>

                  

                  {/* <div className="row pd-5">
                    <div className="col-2">
                        <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                        <h5 className="tx-rubik tx-normal tx-16">Post-game reports</h5>
                    </div>
                </div> */}
                </div>
              </div>

              {/* <div className="pd-20 col-md-4 mg-t-40 mg-sm-t-0 flex-column" style={{alignItems:'center'}}>
            <div style={{textAlign:'center'}}>
                <h1 className="mg-b-10">Scale</h1>
                <p className="tx-color-01 mg-b-10">Everything for up to 10 escape rooms.</p>
                <p className="tx-28 mg-b-10 mg-t-auto"><b>$79</b><span className="tx-18">/mo</span></p>
                <small className="tx-color-03">or $7.90 per room</small>

<button onClick={() => choosePlan('scale', stripe_id,"price_1IawCHGI8KW4Qd3J4gkWxy15")} className="btn btn-primary btn-block mg-t-20">
    {scaleLoader ? <span className="mg-r-10 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : null}
    <b>Choose Plan</b>
    </button>
            </div>

            <div style={{marginTop:'20px'}}>
                <div className="row pd-5">
                    <div className="col-2">
                        <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                        <h5 className="tx-rubik tx-normal tx-16"><b>Everything from Start & Grow</b></h5>
                    </div>
                </div>

                <div className="row pd-5">
                    <div className="col-2">
                        <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                        <h5 className="tx-rubik tx-normal tx-16">Includes 10 rooms</h5>
                    </div>
                </div>

                <div className="row pd-5">
                    <div className="col-2">
                        <ion-icon name="checkmark"></ion-icon>
                    </div>
                    <div className="col-10">
                        <h5 className="tx-rubik tx-normal tx-16">Dedicated support</h5>
                    </div>
                </div>

            </div>
          </div> */}
            </div>
          </div>
          {/* <div className="modal-footer pd-x-20 pd-y-15">
            <button
              type="button"
              className="btn btn-xs btn-white"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-xs btn-primary"
              data-dismiss="modal"
            >
              Save Settings
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

// const mapDispatchToProps = (dispatch) => {
//     return {
//         addNewClue: (data, id) => dispatch(addNewClue(data, id))
//     }
// }

// export default connect(null, mapDispatchToProps)(AddClueModal)
export default SubsModal;
