import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
//Toasts
import { Toaster } from "sonner";

import { classNames } from "../../../utils/Utils";
import { quotes } from "./Quotes";

function ForgotPassword(props) {
  const { authError, auth } = props;
  if (auth.uid) return <Redirect to="/" />;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();
  const [selectedQuote, setSelectedQuote] = useState()

  useEffect(()=> {
    setSelectedQuote(quotes[Math.floor(Math.random() * quotes.length)])
  }, [])


  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    if (email){
    
    fetch("https://us-central1-escaperoomwaiver-v2.cloudfunctions.net/resetUserPassword?email=" + email, {
        mode: 'no-cors'
    })
        .then(() => {
            console.log('fetch complete')
            setEmailSent(true)
            setLoading(false)
        })
        .catch(error => {
            console.log('fetch error', error)
            setError(error)
            setLoading(false)
        });
    } else {
        setError("Please enter a valid email address.")
    }

}



  return (
    <React.Fragment>
      <Toaster richColors position="top-center" />
      <div className="w-full flex h-screen bg-gray-100">
        <div className="w-full lg:max-w-lg  px-20 pt-12 border-r border-gray-200 h-full bg-white">
          <div>
            <div
              className="text-center flex items-center justify-center flex-col"
              style={{ paddingBottom: "20px" }}
            >
              <Link to="/">
                <img
                  src="/bolt-logo.svg"
                  alt="Logo"
                  style={{
                    width: "175px",
                    marginTop: "-8px",
                    marginBottom: "30px",
                  }}
                ></img>
              </Link>

              {emailSent && (
                <i class="fa-solid fa-inbox-full text-5xl mb-2"></i>
              )}

              {!emailSent ? (
                <p className="text-2xl font-medium">Reset password</p>
              ) : <p className="text-2xl font-medium">Password reset</p>}
            </div>

            <p className="text-gray-500 mb-2 text-center">Look out for an email from us with instructions on how to reset your password!</p>

            <div className={classNames(
                "mb-4",
                emailSent ? "hidden" : null
            )}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-3">
                <input
                  type="email"
                  id="email"
                  className="auth-input"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                ></input>
              </div>

              <div>
                <button
                type="submit"
                  disabled={!email || loading ? true : false}
                  className="auth-button flex justify-center"
                >
                  <svg
                    className={classNames(
                      !loading
                        ? "hidden"
                        : "w-[22px] fill-gray-300  animate-spin"
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M256 64c106 0 192 86 192 192h64C512 114.6 397.4 0 256 0V64zM448 256c0 35-9.4 67.8-25.7 96L477.7 384c21.8-37.7 34.3-81.5 34.3-128H448z" />
                  </svg>

                  <span className={classNames(loading ? "hidden" : null)}>
                    Send reset email
                  </span>
                </button>

              </div>
              </form>
            </div>

            <p className={classNames(
                "text-xs text-gray-500 text-center",
                emailSent ? "hidden" : null
            )}>
                Need help?{" "}
                <button
                onClick={()=> drift.api.toggleChat()}
                  className="text-blue-500 hover:text-blue-600"
                >
                  Chat with us!
                </button>
              </p>

              <a
                    href="/login"
                     className={classNames(
                        "flex items-center bg-white py-1 px-2 rounded hover:bg-gray-200 hover:text-black duration-150 text-gray-500 focus:outline-none",
                        emailSent ? "hidden" : null
                     )}>
                    <svg className="w-[12px] fill-gray-300 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>
                        Back
                    </a>

            
          </div>
        </div>

        <div className="w-full justify-center items-center hidden lg:flex">
          {selectedQuote && (
            <div className="max-w-md">
            <p className="text-3xl leading-10">"{selectedQuote.quote}"</p>
            <div className="py-4">
              <p className="text-lg font-medium">{selectedQuote.name}</p>
              <div className="flex items-center space-x-2">
                <p>{selectedQuote.company}</p>
                <p>&#x2022;</p>
                <p>{selectedQuote.location}</p>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    authError: state.auth.authError,
    authLoading: state.auth.authLoading,
    auth: state.firebase.auth,
  };
};


export default connect(mapStateToProps, null)(ForgotPassword);
