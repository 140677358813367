import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'

//Save Account Settings
import {saveAccountSettings} from '../../store/actions/settingsActions'

function AccountSettings(props) {
  
  const { account } = props;
  const title = account ? account.title : null;

  const [data, setData] = useState({
    title: '',
    id: ''
  })

  const [editedCompanyName, setEditedCompanyName] = useState(false)


  useEffect(() => {
    if (account) setData(account)
  }, [account])

  const editData = (e) => {
    setData({...data, title : e.target.value})
    setEditedCompanyName(true)
  }

  const saveSettings = () => {
    console.log('click')
    const settings = {
      ...data
    }

    props.saveAccountSettings(settings)
  }

  console.log(data)


    return (
        <div>
        <div class="account-main-header">
          <h1 class="heading-2">Account Settings - {data.title}</h1>
        </div>
        <div class="account-settings">
          <div class="account-settings-container">
            <div class="account-subheader">
              <h3 class="heading-3">General Information</h3>
              <p class="account-subtitle">Details about your company.</p>
            </div>
            <div class="account-settings-main">
              <div class="account-row">
                <div class="account-col-6">
                  <h4 class="heading-4">Company Name</h4>
                  <p class="account-subtitle">Choose a name for your account.</p>
                </div>
                <div class="account-col-6">
                  <div className="form-group">
                    <input
                      onChange={(e) => editData(e)}
                      type="text"
                      className="form-control"
                      placeholder="Your company"
                      value={data.title}
                    ></input>

              </div>
                </div>
              </div>

              <div class="account-row">
                <div class="account-col-6">
                  <h4 class="heading-4">Company ID</h4>
                  <p class="account-subtitle">Your unique Hintspace ID.</p>
                </div>
                <div class="account-col-6">
                  <div className="form-group">
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      placeholder="Your company"
                      value={data.id}
                    ></input>

              </div>
                </div>
              </div>


            </div>

            <div style={{margin:'20px 0px'}}>
            <button
                  disabled={!editedCompanyName ? true : false}
                  data-toggle="modal"
                  style={{
                    border:'none',
                    opacity: editedCompanyName ? '1' : '0.5'
                  }}
                  className="account-button-blue w-button"
                  onClick={()=> saveSettings()}
                >
                  <b>Save Account Settings</b>
                </button>
            </div>


          </div>
        </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveAccountSettings: (settings) => dispatch(saveAccountSettings(settings))
  }
}

export default connect(null, mapDispatchToProps)(AccountSettings)
