import React, { Component } from "react";
import FadeIn from "react-fade-in";
import moment from "moment";
import { connect } from "react-redux";

import {
  loadAudioState,
  loadStateFromLocalStorage,
} from "../../../../localStorage";

import Send from "./Send";
import MessageInput from "./MessageInput";
import { ClueMessage } from "./Components/ClueMessage";

// Message Components
import { Message_Text } from "./MessageComponents/Message_Text";
import { Message_Audio } from "./MessageComponents/Message_Audio";
import { Message_ClearScreen } from "./MessageComponents/Message_ClearScreen";
import { Message_TimerAdjust } from "./MessageComponents/Message_TimerAdjust";
import { Bot } from "./MessageComponents/Bot";
import Message_Intro from "./MessageComponents/Message_Intro";
import { Message_AlertTone } from "./MessageComponents/Message_AlertTone";

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      video_brief_current: 0,
      video_brief_duration: 0,
      progressBar: 100,
      videoIsPlaying: false,
      timerRunning: false,
      isPlayerWindowOpen: loadStateFromLocalStorage().timer.isPlayerWindowOpen,
    };

    this.localStorageUpdated = this.localStorageUpdated.bind(this);
  }

  clueOnScreenTime = () => {
    return "0:31";
  };

  componentDidMount() {
    // Add Timer Event Listener
    window.addEventListener("storage", this.localStorageUpdated);
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("storage", this.localStorageUpdated);
    }
  }

  localStorageUpdated() {
    if (localStorage.getItem("state")) {
      const ls = loadStateFromLocalStorage();
      this.setState({
        ...this.state,
        videoIsPlaying: ls.timer.videoIsPlaying,
        timerRunning: ls.timer.tmerRunning,
        isPlayerWindowOpen: ls.timer.isPlayerWindowOpen,
      });
    }

    if (localStorage.getItem("audio")) {
      const audioState = loadAudioState();

      const current = audioState.video_brief_current;
      const duration = audioState.video_brief_duration;

      const progressBar = (Number(current) / Number(duration)) * 100;
      const newProgress = 100 - progressBar;

      //console.log('progress', newProgress)

      this.setState({
        ...this.state,
        progressBar: newProgress,
      });
    }
  }

  render() {
    const {
      messages,
      loadedClue,
      handleLoadClue,
      handleAddMessage,
      handleAddAudioClue,
      handleAddMessageKeyDown,
      handleScreenClear,
      hintsUsed,
      avgCluePerMinute,
      addedMinutes,
      togglePlayAudioClue,
      account,
      profile,
      game,
      handleLoadPreviousClue,
      previousMessage,
      //countdownWidth,
      //showCountdownBar,
      handleClearLoadedClue,
    } = this.props;

    const { progressBar, timerRunning, videoIsPlaying } = this.state;

    //const MessageInputMemo = React.memo(MessageInput)

    return (
      <React.Fragment>
        <div className="chat-messages-box w-full">
          <div
            className="chat-group"
            style={{ paddingLeft: "0", paddingRight: "0" }}
          >
            <div id="messageFeed">
              <div>
                {/* <Message_Intro id={this.props.game.id} game={game} isPlayerWindowOpen={this.state.isPlayerWindowOpen} /> */}
              </div>

              {messages
                ? messages.map((messageObj) => {
  
                    if (messageObj.type === "screen-cleared") {
                      return <Message_ClearScreen key={messageObj.key} />;
                    } else if (messageObj.type === "timer-start") {
                      return (
                        <Bot key={messageObj.key} type={"timer-start"} messageObj={messageObj} />
                      );
                    } else if (messageObj.type === "alert-button") {
                      return (
                        <Message_AlertTone
                          key={messageObj.key}
                          type={"alert-button"}
                          messageObj={messageObj}
                        />
                      );
                    } else if (messageObj.type === "puzzle-header") {
                      return (
                        <div
                          key={messageObj.key}
                          className="chat-group-divider"
                        >
                          <ion-icon
                            name="rocket"
                            style={{ marginRight: "5px" }}
                          ></ion-icon>
                          {messageObj.puzzle}
                        </div>
                      );
                    } else if (messageObj.type === "timer-adjust") {
                      return (
                        <Message_TimerAdjust
                          key={messageObj.key}
                          type={"timer-adjust"}
                          messageObj={messageObj}
                        />
                      );
                    } else if (messageObj.type === "game-ended") {
                      return (
                        <Bot key={messageObj.key} type={"game-ended"} messageObj={messageObj} />
                      );
                    } else if (messageObj.type === "audio") {
                      return (
                        <Message_Audio
                          key={messageObj.key}
                          account={account}
                          messageObj={messageObj}
                          timer={this.props.timer}
                          messages={messages}
                        />
                      );
                    } else {
                      return (
                        <Message_Text
                          key={messageObj.key}
                          account={account}
                          profile={profile}
                          messageObj={messageObj}
                          timer={this.props.timer}
                          messages={messages}
                        />
                      );
                    }
                  })
                : null}

              {/* {messages && messages.length < 1 ? 
                <div key={Math.random() * 1000} style={{margin:'0 auto', opacity:'0.5', textAlign:'center'}}>
                  <i className="icon ion-ios-text" style={{fontSize:'28px'}}></i>
                  <h5>No messages.</h5>
                </div>: null} */}

              {videoIsPlaying && (
                <Bot type={"video-playing"} progressBar={progressBar} />
                // <div
                //   key={Math.random() * 1000}
                //   className="media"
                //   style={{ padding: "10px 20px", margin:'10px 0px' }}
                // >
                //   <i
                //     className="icon ion-ios-play"
                //     style={{ color: "white" }}
                //   ></i>
                //   <div
                //     className="media-body"
                //     style={{ marginLeft: "10px" }}
                //   >
                //     <h5
                //       className="mg-b-0"
                //       style={{ fontWeight: "500", fontSize:'15px' }}
                //     >
                //       Video brief is playing.
                //     </h5>
                //     <small className="d-block text-muted">
                //       The timer will automatically start when finished.
                //     </small>

                //     <div className="progress" style={{marginTop:'10px', width:'50%', height:'5px'}}>
                //       <div className="progress-bar"
                //       style={{width: progressBar + "%"}}
                //       role="progressbar"
                //       aria-valuenow="25"
                //       aria-valuemin="0"
                //       aria-valuemax="100"></div>
                //     </div>
                //   </div>
                // </div>
              )}
            </div>
          </div>
        </div>

        {/* <div
          style={{
            position: "absolute",
            bottom: "235px",
            left: "10px",
            width: "60%",
          }}
        >
          {showCountdownBar ? (
            <div>
              <div className="progress ht-2 mg-b-10 mg-t-10">
              <div
                style={{ width: countdownWidth + "%" }}
                className="progress-bar bg-danger"
                role="progressbar"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            </div>
          ) : null}
        </div> */}

        {this.props.timer.clueOnScreen || this.props.remote.clueOnScreen ? (
          <div style={{ position: "absolute", bottom: "175px", right: "20px" }}>
            <button
              onClick={handleScreenClear}
              type="button"
              className="button-red-dark clear-screen"
              style={{
                fontSize: "12px",
                fontWeight: "500",
                padding: "0px 10px",
                height: "25px",
              }}
            >
              <i
                class="fa-solid fa-x"
                style={{ height: "12px", marginRight: "5px" }}
              ></i>
              Clear Player Screen
            </button>
          </div>
        ) : (
          <div style={{ position: "absolute", bottom: "175px", right: "20px" }}>
            <button
              disabled
              onClick={handleScreenClear}
              type="button"
              className="button-outline-dark disabled:opacity-50"
              style={{
                fontSize: "12px",
                fontWeight: "500",
                padding: "0px 10px",
                height: "25px",
              }}
            >
              <i
                class="fa-solid fa-x"
                style={{ height: "12px", marginRight: "5px" }}
              ></i>
              Clear Player Screen
            </button>
          </div>
        )}

        <MessageInput
          handleAddMessage={handleAddMessage}
          handleAddAudioClue={handleAddAudioClue}
          handleAddMessageKeyDown={handleAddMessageKeyDown}
          handleLoadPreviousClue={handleLoadPreviousClue}
          loadedClue={loadedClue}
          handleLoadClue={handleLoadClue}
          hintsUsed={hintsUsed}
          avgCluePerMinute={avgCluePerMinute}
          addedMinutes={addedMinutes}
          togglePlayAudioClue={togglePlayAudioClue}
          previousMessage={previousMessage}
          handleClearLoadedClue={handleClearLoadedClue}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timer: state.timer,
    remote: state.remote,
    account: state.firestore.ordered.user_accounts
      ? state.firestore.ordered.user_accounts[0]
      : null,
    profile: state.firebase.profile,
    game: state.firestore.ordered.gamemaster
      ? state.firestore.ordered.gamemaster[0]
      : null,
  };
};

export default connect(mapStateToProps, null)(Messages);
