import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function DropdownSettingsClue(props) {
    const { userPermissions } = props;

  return (
    <Menu as="div" className="relative inline-block text-left z-50">
      <div>
        <Menu.Button className="flex items-center text-gray-500 px-1.5 py-1 rounded-sm hover:bg-gray-700 focus:outline-none duration-75 mr-1 z-10">
          <span className="sr-only">Open options</span>
          <i class="fa-solid fa-ellipsis"></i>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-50 mt-2 w-48 origin-top-right rounded-sm bg-v1_dark-bg border divide-y divide-v1_dark-hover  border-gray-200  shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:outline-none">
          <div className="py-0">
            <Menu.Item>
              {({ active }) => (
                <button
                href={
                    userPermissions.isAdmin || userPermissions.canEditPuzzles
                      ? "#addClueModal"
                      : "#"
                  }
                  // id="addPuzzleModal"
                  disabled={userPermissions.isAdmin || userPermissions.canEditPuzzles ? false : true}
                  data-toggle="modal"
                  className={classNames(
                    active ? 'bg-gray-700 text-gray-300' : 'text-gray-300',
                    'block px-2 py-1.5 text-sm w-full text-left disabled:opacity-50'
                  )}
                >
                  Add clue
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                href={
                    userPermissions.isAdmin || userPermissions.canEditPuzzles
                      ? "#addSoundModal"
                      : "#"
                  }
                  // id="addPuzzleModal"
                  disabled={userPermissions.isAdmin || userPermissions.canEditPuzzles ? false : true}
                  data-toggle="modal"
                  className={classNames(
                    active ? 'bg-gray-700 text-gray-300' : 'text-gray-300',
                    'block px-2 py-1.5 text-sm w-full text-left disabled:opacity-50'
                  )}
                >
                  Add sound
                </button>
              )}
            </Menu.Item>
  

          </div>

          <div className='py-0'>
          <Menu.Item>
              {({ active }) => (
                <button
                href={
                    userPermissions.isAdmin || userPermissions.canEditPuzzles
                      ? "#editPuzzleModal"
                      : "#"
                  }
                  id="addPuzzleModal"
                  disabled={userPermissions.isAdmin || userPermissions.canEditPuzzles ? false : true}
                  data-toggle="modal"
                  className={classNames(
                    active ? 'bg-gray-700 text-gray-300' : 'text-gray-300',
                    'block px-2 py-1.5 text-sm w-full text-left disabled:opacity-50'
                  )}
                >
                  Objective settings
                </button>
              )}
            </Menu.Item>
          </div>

          <div className="py-0">
          <Menu.Item>
              {({ active }) => (
                <button
                href={
                    userPermissions.isAdmin || userPermissions.canEditPuzzles
                      ? "#deletePuzzleModal"
                      : "#"
                  }
                  id="addPuzzleModal"
                  disabled={userPermissions.isAdmin || userPermissions.canEditPuzzles ? false : true}
                  data-toggle="modal"
                  className={classNames(
                    active ? 'bg-gray-700 text-gray-300' : 'text-gray-300',
                    'block px-2 py-1.5 text-sm w-full text-left disabled:opacity-50'
                  )}
                >
                  Delete objective
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}